import AGRetroImage from "../../assets/images/AG-retro-image.jpg";
import { Helmet } from 'react-helmet-async';

function AboutUsPage() {
	return (
		<div className="page-container lg-box">
			{/* About Antigranular */}
			<Helmet prioritizeSeoTags>
				<title>Antigranular</title>
				<meta charSet="utf-8" />
				<meta name="description" content="Antigranular About Us"/>
			</Helmet>
			<div>
				<div className="page-heading-container">
					<span className="text-3xl mb-21">About Antigranular</span>
					<div className="text-xs">
						The world is full of data. Data which helps us draw insights, develop innovative solutions, and drive positive social change. There is a problem though. Less than 1% of this powerful data gets utilised. The rest? It sits in silos across organisations and is locked tightly due to privacy concerns. 
					</div>
					<br />
					<div className="text-xs">
						Privacy-enhancing technologies (PETs) hold the key to unlocking the potential of the world’s most impactful data. PETs represent a range of methods, systems, and tools that ensure the secure handling of sensitive data. They facilitate data processing tasks without compromising individual privacy.
					</div>
					<div className="">
						<img src={AGRetroImage} className="about-us-image" alt="About Us"  />
					</div>
					<div className="text-xs">
						While there’s plenty of theoretical work focused on PETs, their practical implementation in data science often falls short. Antigranular was established to bridge this gap. Our mission is to offer a practical way for data scientists to utilise PETs and encourage their broad adoption.
					</div>
					<br />
					<div className="text-xs">
						Our implementation of a differential private framework for data scientists is designed for optimal simplicity and efficiency. Data scientists interact with sensitive data through Jupyter notebooks using the %%ag magic command. This command facilitates the execution of Python code in a remote, secure environment, we call it “private-python”. It is a restricted variant of Python that limits operational capabilities and ensures that data frames adhere to approved differential privacy mechanisms.
					</div>
					<br />
					<div className="text-xs">
						Antigranular aims to cultivate a community where privacy-conscious data scientists can come together to learn, compete in hackathons, develop, and enhance these technologies. It encourages experimentation, refinement, and collaboration to enable the widespread adoption of PETs.
					</div>
				</div>
			</div>
			{/* AGENT */}
			<div>
				<div className="page-heading-container">
					<span className="text-3xl mb-21">AGENT</span>
					<div className="text-xs">
					Antigranular Enterprise (AGENT) is a data privacy platform built specifically for enterprises to facilitate interactive data science with sensitive data sources. It builds upon the standard features of Antigranular by providing self-hosting capabilities, allowing organisations to deploy AGENT on their own infrastructure and configure it to support their preferred libraries, privacy budgets and authorisation flows. 
					</div>
					<br />
					<div className="text-xs">
						Like Antigranular, AGENT enforces the requirements of differential privacy by ensuring that all queries and processes comply with strict privacy standards, thereby ensuring a secure data environment.
					</div>
					<br />
					<div className="text-xs">
						AGENT is built to manage and track the privacy budget for each user and team, associated with every sensitive data source. This prevents breaches of pre-established privacy constraints. The system is built upon custom Jupyter Kernels, facilitating the execution of client code within a secure, isolated environment, 'private-python'.
					</div>
					<br />
					<div className="text-xs">
						For data scientists, AGENT offers a seamless and flexible coding experience. They can effortlessly switch between 'private-python' for remote execution and standard Python for local execution, using simple Jupyter notebook magic commands.
					</div>
					<br />
					<div className="text-xs">
						All users of the AGENT interact with the system through the AGENT Console, a user portal that allows administrators, data owners, and data scientists to manage all aspects of the system effectively. AGENT is designed around core principles like zero-trust security, self-contained infrastructure, minimal support needs, detailed user control, cost efficiency, and an emphasis on ease of use
					</div>
					<br />
					<div className="text-xs">
						With AGENT you can connect to over 200 data sources and drive meaningful insights in your organisation while keeping granular data private and confidential.
					</div>
				</div>
			</div>
			{/* About Oblivious  */}
			<div>
				<div className="page-heading-container">
					<span className="text-3xl mb-21">About Oblivious</span>
					<div className="text-xs">
						Antigranular, AGENT, and OBLV Deploy are cutting-edge privacy-by-design products developed by <a className="link-ag-1" href="https://www.oblivious.com/" target="_blank" rel='noreferrer'>Oblivious</a>. Oblivious, privacy and confidentiality company specialising in PET solutions for businesses, was established in 2020 by Robert Pisarczyk and Jack Fitzsimons and is based in Dublin.
					</div>
					<br />
					<div className="text-xs">
						Through Oblivious solutions, enterprises can adopt a zero-trust architecture and securely deploy cloud services within a trusted execution environment
					</div>
					<br />
					<div className="text-xs">
						As a part of our commitment to the PETs community, Oblivious organises the annual <a className="link-ag-1" href="https://www.eodsummit.com/" target="_blank" rel='noreferrer'>Eyes-Off Data Summit</a>. The event offers a forum that unites diverse stakeholders where they can collaborate on advancing PETs and privacy.
					</div>
					<br />
					<div className="text-xs">
						With a strong focus on nurturing innovation, interaction, and knowledge sharing, the event seeks to shift the trust paradigm from traditional, document-based methods to more robust technological assurances, work towards championing responsible innovation and advocating secure data sharing practices.
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutUsPage;