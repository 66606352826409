

import shareIcon from "../../assets/icons/share.png"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from "react-bootstrap/Button";
import {
    EmailShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    TelegramShareButton
} from "react-share";
import copy from "../../assets/icons/copy-icon.svg";

import mail from "../../assets/logos/mail_icon.png";
import telegram from "../../assets/logos/telegram.png";
import whatsapp from "../../assets/logos/whatsapp.png";
import linkedin from "../../assets/logos/linkedin.png";
import "./index.scss";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import EmailIcon from "react-share/lib/EmailIcon";

type TShareButtonProps = {
    url: string;
    subject: string;
    description: string;
    slim?: boolean;
    className?: string;
    messageText?: string;
    messageTitle?: string;
    modalHead: string;
    modalBody?: string;
};

export default function ShareButton(props: TShareButtonProps) {
    const [share, setShare] = useState(false);
    const [copied, setCopied] = useState(false);

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props} className="shareTooltip">
          Share your profile
        </Tooltip>
    );

    return (
        <div className={props.className}>
            {/* <OverlayTrigger
                rootClose
                trigger="click"
                placement={"auto"}
                overlay={
                    <Popover id="share-pop">
                        <Popover.Body>
                            <EmailShareButton
                                body={props.description}
                                subject={props.subject}
                                url={props.url}
                            >
                                <EmailIcon size={26} className="m-2 rounded" />
                            </EmailShareButton>

                            <WhatsappShareButton
                                title="Share on Whatsapp"
                                url={props.url}
                            >
                                <WhatsappIcon size={26} className="m-2 rounded" />
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={props.url}
                                title={props.subject}
                                summary={props.description}
                            >
                                <LinkedinIcon size={26} className="m-2 rounded" />
                            </LinkedinShareButton>
                        </Popover.Body>
                    </Popover>
                }
            >
                {!props.slim ? <Button variant="dark feature-icon">
                    <FontAwesomeIcon icon={faShareAlt} /> Share
                </Button> : <FontAwesomeIcon title="Share" className="px-2 feature-icon" icon={faShareAlt} />}
            </OverlayTrigger> */}
            
            {props.messageText ?
                <div className="share-container">
                    {!props.slim ? <Button variant="link" className="me-2" onClick={() => setShare(true)}>Refer a friend</Button>
                    : <></>}
                    <div className="share-btn-message colored-card d-none d-md-block">
                        <p className="text-xs mb-1 text-bold">{props.messageTitle}</p>
                        <p className="text-xs mb-0">{props.messageText}</p>
                    </div>
                </div> : (
                !props.slim ? <Button variant="link" className="me-2" onClick={() => setShare(true)}>Refer a friend</Button> :
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 400 }}
                    overlay={renderTooltip}
                    >
                    <img className="cursor-pointer" onClick={() => setShare(true)} width={24} height={24} src={shareIcon} alt="share" />
                </OverlayTrigger>)
            }
            
            
            <Modal className="login-modal" show={share} centered onHide={() => setShare(false)}>
                <Modal.Header className="border-0 p-4 pb-0" closeButton>
                    <p className="mb-0 text-lg">{props.modalHead}</p>
                </Modal.Header>
                <Modal.Body className="p-4 pt-2">
                    {props.modalBody ? <p className="text-color-secondary text-xs mb-3">{props.modalBody}</p> : <></>}
                    
                    <div className="form-external-brand-links mb-3 text-color-secondary flex-wrap">
                        <div className="my-2">
                            <div className="share-image-cover ms-21 me-21">
                                <EmailShareButton
                                    body={props.description}
                                    subject={props.subject}
                                    url={props.url}
                                >
                                    <img width={32} height={32} src={mail} alt="mail-icon"/>
                                </EmailShareButton>
                            </div>
                            <p className="mt-2 mb-0 text-xs">Mail</p>
                        </div>
                        <div className="my-2">
                            <div className="share-image-cover ms-21 me-21">
                                <WhatsappShareButton
                                    title="Share on Whatsapp"
                                    url={props.url}
                                >
                                    <img src={whatsapp} alt="whatsapp" width={32} height={32}/>
                                </WhatsappShareButton>
                            </div>
                            <p className="mt-2 mb-0 text-xs">WhatsApp</p>
                        </div>
                        <div className="my-2">
                            <div className="share-image-cover ms-21 me-21">
                                <TelegramShareButton
                                    url={props.url}
                                    title={props.subject}
                                >
                                    <img src={telegram} alt="whatsapp" width={32} height={32}/>
                                </TelegramShareButton>
                            </div>
                            <p className="mt-2 mb-0 text-xs">Telegram</p>
                        </div>
                        <div className="my-2">
                            <div className="share-image-cover ms-21 me-21">
                                <LinkedinShareButton
                                    url={props.url}
                                    title={props.subject}
                                    summary={props.description}
                                >
                                    <img src={linkedin} alt="whatsapp" width={32} height={32}/>
                                </LinkedinShareButton>
                            </div>
                            <p className="mt-2 mb-0 text-xs">LinkedIn</p>
                        </div>
                    </div>

                    <div className="form-input-fields text-xs text-color-secondary">
                        <p className="text-color-secondary text-xs mb-2">Or simply copy the link below:</p>
                        <div className="copyLink text-overflow pe-5">
                            <span className="text-overflow">{props.url}</span>
                            <img width={16} height={16} src={copy} alt="" className={`copyTrigger ${copied ? "d-none" : ""}`} 
                                onClick={() => {
                                    navigator.clipboard.writeText(props.url || "");
                                    setCopied(true);
                                    setTimeout(() => {
                                        setCopied(false)
                                    }, 2000)
                                }}
                            />
                            <span className={`copyTrigger text-xs text-color-tertiary ${!copied ? "d-none" : ""}`}>copied</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
