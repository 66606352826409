import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router";

// import: assets
// import: styles

// import: constants
import { BLOG, CREATE } from '../../router/routes';
// import: enums
import { UserRole } from "../../enums/roles";

// import: types
// import: utils
// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { loadBlogs, setBlogPageData } from "../../store/slices/blog-slice";

// import: api
// import: config
// import: components
import LoadingSpinner from "../../components/loading-spinner";
import Button from "react-bootstrap/Button";
import BlogsListItem from "../../components/list-items/blogs-list-item";

function BlogPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const pageEnd = useRef<HTMLDivElement>(null);

    const user = useAppSelector((s) => s.auth.user);

    const fetchingblogs = useAppSelector((s) => s.blog.fetching);
    const blogPosts = useAppSelector((s) => s.blog.pageData.data);
    const [page, setPage] = useState(0);
    const pageSize = parseInt(process.env.React_APP_BLOGS_DATA_LENGTH || "20");
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        /* reset data to reset height of container
         * so the observer can trigger fresh data fetch
         */
        dispatch(setBlogPageData({
            data: [],
        }));
    }, [dispatch]);

    const fetchData = useCallback(() => {
        if (page < totalPages && !fetchingblogs) {
            dispatch(loadBlogs({
                page: page + 1,
                size: pageSize,
                site: (user?.roles?.includes(UserRole.Admin) ? "" : "Antigranular")
            }))
                .unwrap()
                .then((res) => {
                    setPage(res.page);
                    setTotalPages(Math.ceil((res.totalCount || pageSize) / pageSize));
                })
        }
    }, [
        dispatch,
        page,
        totalPages,
        fetchingblogs,
        pageSize,
        user?.roles,
    ]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !fetchingblogs) {
                    fetchData();
                }
            });
        });

        if (pageEnd.current) observer.observe(pageEnd.current);

        return () => {
            if (pageEnd.current) observer.unobserve(pageEnd.current);
        };
    }, [fetchData, fetchingblogs]);

    const noBlogs = (
        blogPosts?.length === 0 && !fetchingblogs ? (
            <div className="py-5 text-center text-color-tertiary w-100 my-0">
                No posts yet
            </div>
        ) : (
            <></>
        )
    )

    return (
        <div className="page-container lg-box">
            <div>
                <div className="page-heading-container">
                    <span className="text-3xl mb-21">Blog</span>
                    <div className="text-xs">
                        The blog is our thought hub where we delve into industry
                        trends, share engaging ideas, and valuable perspectives
                        from renowned industry experts. Explore our insights,
                        empower yourself with knowledge, and stay ahead in the
                        rapidly changing world of data privacy.
                    </div>
                </div>
                {user?.roles?.includes(UserRole.Admin) ? (
                    <Button
                        className="mb-3 rounded-pill"
                        onClick={() => navigate(`/${BLOG}/${CREATE}`)}
                    >
                        Create
                    </Button>
                ) : undefined}
            </div>

            <div className="listing-table-container xs-box">
                {blogPosts && blogPosts?.length > 0 ? (
                    blogPosts.map((blg, idx) => (
                        <BlogsListItem
                            key={blg._id + idx}
                            to={`/${BLOG}/` + (blg.slug ?? blg._id)}
                            item={blg}
                        />
                    ))
                ) : noBlogs}
            </div>

            <div id="blogs-page-end" ref={pageEnd} className="">
                <div className="pt-20 d-flex justify-content-center align-middle items-center">
                    <LoadingSpinner
                        inLine
                        show={fetchingblogs}
                        text={"Getting Blog posts"}
                    />
                </div>
            </div>

        </div>
    );
}

export default BlogPage;

