import { IAccomplishment } from "../types/accomplishment-types";
import { BaseApi } from "./base-api";

export default class AccomplishmentApi extends BaseApi {
    private static url = (action: string) => 'accomplishment/' + action;
    /**
    * Get profile
    * @param userId //Only for admin use
    * @returns
    */
    public static async get(userId?: string, competitionId?: string): Promise<IAccomplishment[]> {
        // const path =  + (userId ? '/' + userId : "")+ (competitionId ? '/' + competitionId : "");
        let data: any = {};
        if(userId) data.userId = userId;
        if(competitionId) data.competitionId = competitionId;
        return this._get(this.url('getaccomplishments'), data);
    }
    
}