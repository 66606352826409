import { ISidebarMenuItem } from "../types/sidebar-menu-item-type";
import homeIcon from "../assets/icons/home.svg";
import selectedHomeIcon from "../assets/icons/home-selected.svg";
import blogIcon from "../assets/icons/blog.svg";
import selectedBlogIcon from "../assets/icons/blog-selected.svg";
import datasetIcon from "../assets/icons/datasets.svg";
import selectedDatasetIcon from "../assets/icons/selected-datasets.svg";
import notebookIcon from "../assets/icons/notebook.svg";
import selectedNotebookIcon from "../assets/icons/selected-notebook.svg";
// import eventsIcon from "../assets/icons/events.svg";
// import selectedEventsIcon from "../assets/icons/selected-events.svg";
import competitionsIcon from "../assets/icons/competitions.svg";
import selectedCompetitionsIcon from "../assets/icons/selected-competitions.svg";

import aboutUsIcon from "../assets/logos/mini-logo.png";
// import selectedAboutUsIcon from "../assets/icons/info-selected-circle.png";
import communityIcon from "../assets/icons/users-01.svg";
import selectedCommunityIcon from "../assets/icons/users-01-selected.svg";

import docsIcon from "../assets/icons/file-05.svg";
import selectedDocsIcon from "../assets/icons/file-05-selected.svg";

import dropDownIcon from "../assets/icons/chevron-down.svg";
import selectedDropDownIcon from "../assets/icons/selected-chevron-down.svg";
import { ABOUT_US, BLOG, COMPETITIONS, DATASETS, NOTEBOOKS, ROOT } from "./routes";

export const SidebarMenus: ISidebarMenuItem[] = [
  {
    text: "Home",
    path: ROOT,
    iconImg: homeIcon,
    activeIconImg: selectedHomeIcon,
    hidden: false,
    external: false,
  },
  {
    text: "Competitions",
    path: COMPETITIONS,
    iconImg: competitionsIcon,
    activeIconImg: selectedCompetitionsIcon,
    hidden: false,
    external: false,
  },
  {
    text: "Datasets",
    path: DATASETS,
    iconImg: datasetIcon,
    activeIconImg: selectedDatasetIcon,
    hidden: false,
    external: false,
  },
  {
    text: "Notebooks",
    path: NOTEBOOKS,
    iconImg: notebookIcon,
    activeIconImg: selectedNotebookIcon,
    hidden: false,
    external: false,
  },
  {
    text: "Docs",
    path: "https://docs.antigranular.com",
    iconImg: docsIcon,
    activeIconImg: selectedDocsIcon,
    hidden: false,
    external: true,
  },
  {
    text: "Community",
    path: "#",
    iconImg: communityIcon,
    activeIconImg: selectedCommunityIcon,
    hidden: false,
    external: false,
    dropdownIconImg: dropDownIcon,
    activeDropDownIconImg: selectedDropDownIcon,
    dropdownItems: [
      {
        text: {
          title: "Blog",
          content: "Explore our articles",
        },
        path: BLOG,
        iconImg: blogIcon,
        activeIconImg: selectedBlogIcon,
        hidden: false,
        external: false,
      },
      // {
      //   text: {
      //     title: "Events",
      //     content: "Get involved with the community",
      //   },
      //   path: EVENTS,
      //   iconImg: eventsIcon,
      //   activeIconImg: selectedEventsIcon,
      //   hidden: false,
      //   external: false,
      // },
      {
        text: {
          title: "About us",
          content: "Learn about Antigranular",
        },
        path: ABOUT_US,
        iconImg: aboutUsIcon,
        activeIconImg: aboutUsIcon,
        hidden: false,
        external: false,
      },
    ],
  },
];

export const SidebarFooterMenus: ISidebarMenuItem[] = [
  {
    text: "Support",
    path: ROOT,
    iconImg: homeIcon,
    activeIconImg: homeIcon,
    hidden: false,
    external: false,
  },
];
