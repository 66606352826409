/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from "react-bootstrap/Button";
import "./index.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ProfileApi from "../../api/profile-api";
import { IUser } from "../../types/user-type";
import { 
    useAppDispatch,
    useAppSelector
} from "../../store/store-hooks";
import { refreshUser } from "../../store/slices/auth-slice";
import FileInput from "../../components/input/file-input";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { HandleError } from "../../errors/handler";
import Input from "../input/input";
import InputSelect from "../../components/input/input-select";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import countries from "./countries.json";
import {
    AvatarCarousel, 
    AvatarSelector
} from "./avatar-carousel";
// import AuthApi from "../../api/auth-api";

type ProfileEditModalProps = {
    profile: IUser;
    show: boolean;
    onHide: (update: boolean) => void;
    // onReset?: () => void;
};

type CountryListProps = {
    name: string;
    code: string;
};

export default function ProfileEditModal(props: ProfileEditModalProps) {
    const user = useAppSelector((s) => s.auth.user);
    const dispatch = useAppDispatch();
    //form states
    const [formErrors, setFormErrors] = useState<any>({});

    const [fullName, setFullName] = useState<string>(props.profile?.fullName);
    // const [lastName, setLastName] = useState<string>("");
    const [about, setAbout] = useState<string>(props.profile?.about || "");
    const [userBio, setUserBio] = useState<string>(props.profile?.about || "");
    const [occupation, setOccupation] = useState<string>(props.profile?.occupation || "");
    const [organization, setOrganization] = useState<string>(props.profile?.organization || "");
    const [location, setLocation] = useState<string>(props.profile?.location || "");
    const [avatar, setAvatar] = useState<File | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [editBio, setEditBio] = useState<boolean>(false);
    const [avatarMaxView, setAvatarMaxView] = useState<boolean>(false);

    

    useEffect(() => {
        if(props.show === true) {
            setFullName(props.profile?.fullName);
            setAbout(props.profile?.about || "");
            setOccupation(props.profile?.occupation || "");
            setOrganization(props.profile?.organization || "");
            setLocation(props.profile?.location || "");
        }
    }, [props.show]);

    // useEffect(() => {
    //     console.log("got profile", props.profile);
    // }, [props.profile]);

    // const reset = () => {
        // setFormErrors({});
        // setFullName("");
        // setAbout("");
        // setOccupation("");
        // setOrganization("");
        // setLocation("");
        // setAvatar(null)
        // setLoading(false);
    // };

    // const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    //     try {
    //         e.preventDefault();
    //         e.stopPropagation();
    //         setFormErrors({});
    //         if (validateForm() && props.token) {
    //             setLoading(true);
    //             await AuthApi.unlinkAuthProviderVerify(props.token, password);
    //             props.onReset?.();
    //         }
    //     } catch (error) {
    //         setFormErrors(HandleError(error));
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (validateForm() && props.profile) {
                setLoading(true);
                await ProfileApi.update({
                    fullName,
                    organization,
                    occupation,
                    _id: props.profile._id,
                    location,
                    about,
                    avatar,
                });
                props.onHide(true);
                if(user) dispatch(refreshUser({...user, fullName}));
                dispatch(
                    showInfoModal({
                        title: "Updated",
                        message: "Profile updated successfully.",
                    })
                );
                
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
            if(formErrors["about"]) setEditBio(true);
        }
    };

    const validateForm = () => {
        setFormErrors({});
        const errors: any = {};
        if (!fullName) {
            errors["fullName"] = "Please write your full name.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        } else {
            return true;
        }
    };

    const updateBio = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setAbout(userBio.trim() || "");
        setEditBio(false);
    };

    useEffect(() => {
        if(formErrors["about"]){
            setEditBio(true);
        }
    }, [formErrors]);

    // useEffect(() => {
    //     if(avatar){
    //         console.log(avatar);
    //         console.log(URL.createObjectURL(avatar));
    //     }
    // }, [avatar])

    const setAntigranularAvatar = (avatar : File) => {
        setAvatar(avatar)
    } 

    return (<>
        <Modal
            show={props.show}
            onHide={() => props.onHide?.(false)}
            centered
            dialogClassName="login-modal profile-edit-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <span className="text-lg text-color-secondary">Edit your Profile</span>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form onSubmit={submit} noValidate>
                        <fieldset disabled={loading}>
                            <div className="form-input-fields text-xs text-color-secondary">
                                <Form.Group>
                                    <Input
                                        autoFocus
                                        focusOnError={true}
                                        label="Full Name"
                                        placeholder="Full Name"
                                        onChange={(e) => {
                                            if (formErrors["fullName"]) {
                                                delete formErrors.fullName;
                                                setFormErrors(formErrors);
                                            }
                                            setFullName(e.target.value);
                                        }}
                                        error={formErrors["fullName"]}
                                        value={fullName}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-2 pt-1">
                                    <InputSelect
                                        labelKey="name"
                                        valueKey="name"
                                        focusOnError={true}
                                        onChange={(val: CountryListProps) => {
                                            if (formErrors["location"]) {
                                                delete formErrors.location;
                                                setFormErrors(formErrors);
                                            }
                                            setLocation(val ? val.name : "");
                                        }}
                                        label="Location"
                                        placeholder="Your Country"
                                        value={countries.find(
                                            (x) => x.name === location
                                        )}
                                        options={countries}
                                        error={formErrors["location"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-2 pt-1">
                                    <Input
                                        focusOnError={true}
                                        label="Occupation"
                                        placeholder="Occupation"
                                        onChange={(e) => {
                                            if (formErrors["occupation"]) {
                                                delete formErrors.occupation;
                                                setFormErrors(formErrors);
                                            }
                                            setOccupation(e.target.value);
                                        }}
                                        error={formErrors["occupation"]}
                                        value={occupation}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-2 pt-1">
                                    <Input
                                        focusOnError={true}
                                        label="Organization"
                                        placeholder="Organization"
                                        onChange={(e) => {
                                            if (formErrors["organization"]) {
                                                delete formErrors.organization;
                                                setFormErrors(formErrors);
                                            }
                                            setOrganization(e.target.value);
                                        }}
                                        error={formErrors["organization"]}
                                        value={organization}
                                    />
                                </Form.Group>
                            </div>  

                            <Button variant="secondary" disabled={loading} type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEditBio(true);
                                }}
                                className="mt-3 w-100 rounded-pill">
                                Update your bio
                                <svg width="20" height="20" className="ms-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.0835 17.9167L6.70789 16.138C7.00367 16.0243 7.15156 15.9674 7.28993 15.8931C7.41283 15.8272 7.53 15.751 7.6402 15.6655C7.76426 15.5692 7.87631 15.4572 8.1004 15.2331L17.5002 5.83332C18.4207 4.91285 18.4207 3.42046 17.5002 2.49999C16.5797 1.57951 15.0873 1.57951 14.1669 2.49999L4.76706 11.8998C4.54298 12.1238 4.43093 12.2359 4.33466 12.36C4.24914 12.4702 4.173 12.5873 4.10702 12.7102C4.03275 12.8486 3.97587 12.9965 3.86211 13.2923L2.0835 17.9167ZM2.0835 17.9167L3.7986 13.4575C3.92133 13.1384 3.98269 12.9788 4.08795 12.9057C4.17993 12.8419 4.29375 12.8177 4.40374 12.8387C4.52961 12.8627 4.65048 12.9836 4.89223 13.2254L6.77481 15.1079C7.01656 15.3497 7.13743 15.4706 7.16147 15.5964C7.18247 15.7064 7.15832 15.8202 7.09445 15.9122C7.02137 16.0175 6.86182 16.0788 6.54272 16.2016L2.0835 17.9167Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </Button>

                            <Form.Group className="mt-3 d-flex is-invalid text-center">
                                <div className=""
                                    style={{
                                        backgroundImage: `url(${avatar && URL.createObjectURL(avatar)})`,
                                        borderRadius: 16,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                    }}>
                                    <FileInput
                                        height={100}
                                        width={120}
                                        selectedFiles={avatar ? [avatar] : []}
                                        accept={{
                                            "image/*": [".png", ".jpg", ".jpeg"],
                                        }}
                                        noIcon
                                        placeholder=""
                                        error={formErrors["avatar"]}
                                        onDrop={(files) => {
                                            if (formErrors["avatar"]) {
                                                delete formErrors.avatar;
                                                setFormErrors(formErrors);
                                            }
                                            if (files?.length) {
                                                setAvatar(files[0]);
                                            } else {
                                                setAvatar(null);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="ms-31 w-100 d-flex flex-column align-items-start">
                                    <p className="mb-2 text-xs text-color-secondary">Choose image to upload</p>
                                    <p className="mb-2 text-xs text-color-tertiary">JPG, JPEG, PNG</p>
                                    {formErrors["avatar"] ? (
                                        <div className="text-invalid-feedback text-xs">
                                            {formErrors["avatar"]}
                                        </div>
                                    ) : undefined}
                                    {avatar && <Button variant="secondary" type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setAvatar(null);
                                        }}
                                        className="ms-auto mt-auto rounded-pill">
                                        Delete
                                    </Button>}
                                </div>
                                
                            </Form.Group>
                                    
                            <div className="separator">
                                <hr className="w-100 me-2"/>
                                <span>or</span>
                                <hr className="w-100 ms-2"/>
                            </div>

                            <div className="form-input-fields px-2 py-3">
                                <div className="text-xs text-center mb-3">Choose from Antigranular's Avatars</div>
                                <AvatarCarousel className="mini-caurosel" setImage={setAntigranularAvatar}/>
                                <div className="text-center mx-auto mt-3 cursor-pointer text-sm link" onClick={() => setAvatarMaxView(true)}>
                                    Check all Avatars
                                    <svg width="10" height="10" className="link-icon" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                            
                            <Form.Group className="mt-5 d-flex text-end">
                                <Button variant="primary" disabled={loading} type="submit"
                                    className="ms-auto rounded-pill">
                                    {loading ? (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                spin
                                                className="me-2"
                                            />{" "}
                                            Please wait
                                        </>
                                    ) : (
                                        "Update"
                                    )}
                                </Button>
                                <Button variant="secondary" disabled={loading} type="reset"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onHide?.(false);
                                    }}
                                    className="ms-3 rounded-pill">
                                    Cancel
                                </Button>
                            </Form.Group>
                        </fieldset>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>




        <Modal
            show={editBio}
            onHide={() => setEditBio(false)}
            centered
            dialogClassName="login-modal edit-bio-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <span className="text-lg text-color-secondary">Update bio</span>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form onSubmit={updateBio} noValidate>
                        <div className="form-input-fields text-xs text-color-secondary p-2">
                            <Form.Group className="">
                                <Input
                                    asTextArea
                                    focusOnError={true}
                                    className="form-control-textarea edit-bio-textarea"
                                    rows={20}
                                    placeholder="Enter a description"
                                    onChange={(e) => {
                                        if (formErrors["about"]) {
                                            delete formErrors.about;
                                            setFormErrors(formErrors);
                                        }
                                        setUserBio(e.target.value)
                                    }}
                                    value={userBio}
                                    error={formErrors["about"]}
                                />
                            </Form.Group>
                        </div>
                        <Form.Group className="mt-5 d-flex text-end">
                            <Button variant="primary" disabled={loading} type="submit"
                                className="ms-auto rounded-pill">
                                {loading ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="me-2"
                                            spin
                                        />{" "}
                                        Please wait
                                    </>
                                ) : (
                                    "Save"
                                )}
                            </Button>
                            <Button variant="secondary" disabled={loading} type="reset"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setUserBio(about)
                                    setEditBio(false);
                                }}
                                className="ms-3 rounded-pill">
                                Cancel
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>


        <Modal
            show={avatarMaxView}
            onHide={() => setAvatarMaxView(false)}
            centered
            dialogClassName="login-modal choose-avatar-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <span className="text-lg text-color-secondary">Choose your avatar</span>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <AvatarSelector close={() => setAvatarMaxView(false)} className="mini-caurosel" setImage={setAntigranularAvatar}/>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}
