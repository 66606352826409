import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Input from "./input";
import Button from "react-bootstrap/Button";
import { HandleError } from "../../errors/handler";
import DatasetApi from "../../api/dataset-api";
import { useAppDispatch } from "../../store/store-hooks";
import { showErrorModal, showInfoModal } from "../../store/slices/modal-states-slice";
import LoadingSpinner from "../loading-spinner";

export type TChunkedFileUploadProps = {
    id: string;
};

export default function ChunkedFileUploader(props: TChunkedFileUploadProps) {
    const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fileKey, setFileKey] = useState<string>("");
  const [uploadError, setUploadError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const chunkSize = 1024 * 1024; // 1 MB

  const onDrop = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const uploadFile = async (file: File) => {
    const fileSize = file.size;
    const totalChunks = Math.ceil(fileSize / chunkSize);
    let offset = 0;
    try {
        while (offset < fileSize) {
            const chunk = file.slice(offset, offset + chunkSize);
            const chunkNumber = Math.ceil(offset / chunkSize) + 1;
            // console.log(chunk, chunkNumber);
            
            if(chunk){
                await DatasetApi.UploadFileChunks(
                    {
                        datasetId: props.id,
                        totalChunks: totalChunks,
                        chunkNumber: chunkNumber.toString(),
                        fileKey: fileKey,
                        file: chunk,
                    }
                );
            }

            offset += chunkSize;
            const progress = (offset / fileSize) * 100;
            setUploadProgress(progress);
        }

        dispatch(
            showInfoModal({
                title: "Uploaded",
                message: "Dataset Uploaded succcessfully.",
            })
        );
    } catch (error) {
        setUploadError(HandleError(error)[""]);
        return false
        // dispatch(
        //     showErrorModal({
        //         title: "Error",
        //         message: HandleError(error)[""],
        //     })
        // );
    } finally {
        setLoading(false);
        setFiles(undefined);
        setUploadProgress(0);
        setFileKey("");
    }
  };


  const startUpload = () => {
    setUploadProgress(0);
    if(!fileKey){
        setUploadError("Upload file key");
        return;
    }
    if(files) uploadFile(files);
  };

  return (
    <div>
        <div className="file-input w-fit text-center d-flex flex-column justify-content-center cursor-pointer mb-4"
            style={{
                height: "200px",
                width: "250px",
            }} {...getRootProps()}>
            <input {...getInputProps()} />
            <p className='text-xs text-color-tertiary mx-auto'>{files? files.name : "Drag & drop some files here, or click to select files"}</p>
        </div>

        <Input
            label="File Key"
            placeholder="Enter file key"
            value={fileKey}
            className='mb-3 w-50'
            onChange={(e) => {
                if (uploadError) setUploadError("");
                setFileKey(e.target.value);
            }}
        />
        <p className='text-color-red-normal text-xs text-thick mb-3'>
            {uploadError}
        </p>
        
        <div className='mt-3 d-flex flex-wrap align-items-center'>
            <Button title="Upload" onClick={startUpload} variant="primary rounded-pill">
                Start Upload
            </Button>

            {files && (
                <div className='ms-3 d-flex flex-wrap align-items-center'>
                    <p className='text-xs mb-0 text-color-tertiary me-3'>Uploading File: {files.name}</p>
                    {uploadProgress > 0 && (
                        <p className='text-xs text-color-secondary mb-0'>{uploadProgress.toFixed(2)}%</p>
                    )}
                </div>
            )}
        </div>
    
      <LoadingSpinner show={loading} text="Loading..." />
    </div>
  );
};