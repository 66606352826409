import { IJwtUser } from "../../types/user-type";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegisterPayload, ISignInPayload } from "../../types/auth-types";
import AuthApi from "../../api/auth-api";
import { USR_LOCALSTORAGE_KEY } from "../../configs/constants";

interface IAuthState {
    user?: IJwtUser;
    tempToken?: string;
    logingin: boolean;
    showLoginModal: boolean;
    showRegistrationModal: boolean;
    showVerificationModal: boolean;
    showForgotPasswordModal: boolean;
}

const initialState: IAuthState = {
    user: localStorage.getItem(USR_LOCALSTORAGE_KEY)
        ? (JSON.parse(localStorage.getItem(USR_LOCALSTORAGE_KEY)!) as IJwtUser)
        : undefined,
    logingin: false,
    showLoginModal: false,
    showRegistrationModal: false,
    showVerificationModal: false,
    showForgotPasswordModal: false,
};

/*thunks*/
const login = createAsyncThunk(
    "auth/login",
    async (payload: ISignInPayload, thunkAPI) => {
        try {
            const response = await AuthApi.login(payload);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const gitLogin = createAsyncThunk(
    "auth/gitlogin",
    async (code: string, thunkAPI) => {
        try {
            const referralCode = localStorage.getItem("AG_REFERRAL") ?? undefined;
            const response = await AuthApi.gitLogin(code, referralCode);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const googleLogin = createAsyncThunk(
    "auth/googlelogin",
    async (code: string, thunkAPI) => {
        try {
            const referralCode = localStorage.getItem("AG_REFERRAL") ?? undefined;
            const response = await AuthApi.googleLogin(code, referralCode);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const registerWithEmail = createAsyncThunk(
    "auth/register",
    async (payload: IRegisterPayload, thunkAPI) => {
        try {
            const response = await AuthApi.register(payload);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const logout = createAsyncThunk("auth/logout", async (_: void, thunkAPI) => {
    return thunkAPI.fulfillWithValue({});
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        showLoginModal: (state) => {
            state.user = undefined;
            localStorage.removeItem(USR_LOCALSTORAGE_KEY);
            state.showLoginModal = true;
        },
        refreshUser: (s, { payload }: PayloadAction<IJwtUser>) => {
            s.user = {...payload};
            localStorage.setItem(
                USR_LOCALSTORAGE_KEY,
                JSON.stringify(payload)
            );
            if(localStorage.getItem("AG_REFERRAL")) localStorage.removeItem("AG_REFERRAL");
        },
        closeLoginModal: (s) => {
            s.showLoginModal = false;
        },
        setRegistrationModalVisible: (
            s,
            { payload }: PayloadAction<boolean>
        ) => {
            s.showRegistrationModal = payload;
        },
        setVerificationModalVisible: (
            s,
            { payload }: PayloadAction<boolean>
        ) => {
            s.showVerificationModal = payload;
        },
        setForgotPasswordModalVisible: (
            s,
            { payload }: PayloadAction<boolean>
        ) => {
            s.showForgotPasswordModal = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (s, a) => {
            s.logingin = true;
        });
        builder.addCase(login.fulfilled, (s, a) => {
            if (a.payload.isVerified) {
                s.user = a.payload;
                s.tempToken = undefined;
                localStorage.setItem(
                    USR_LOCALSTORAGE_KEY,
                    JSON.stringify(a.payload)
                );
            } else {
                s.tempToken = a.payload.token;
                s.showVerificationModal = true;
            }
            s.logingin = false;
        });
        builder.addCase(login.rejected, (s, a) => {
            s.logingin = false;
        });
        builder.addCase(gitLogin.pending, (s, a) => {
            s.logingin = true;
        });
        builder.addCase(gitLogin.fulfilled, (s, a) => {
            if (a.payload.isVerified) {
                s.user = a.payload;
                s.tempToken = undefined;
                localStorage.setItem(
                    USR_LOCALSTORAGE_KEY,
                    JSON.stringify(a.payload)
                );
            } else {
                s.tempToken = a.payload.token;
                s.showVerificationModal = true;
            }
            s.logingin = false;
        });
        builder.addCase(gitLogin.rejected, (s, a) => {
            s.logingin = false;
        });
        builder.addCase(googleLogin.pending, (s, a) => {
            s.logingin = true;
        });
        builder.addCase(googleLogin.fulfilled, (s, a) => {
            if (a.payload.isVerified) {
                s.user = a.payload;
                s.tempToken = undefined;
                localStorage.setItem(
                    USR_LOCALSTORAGE_KEY,
                    JSON.stringify(a.payload)
                );
            } else {
                s.tempToken = a.payload.token;
                s.showVerificationModal = true;
            }
            s.logingin = false;
        });
        builder.addCase(googleLogin.rejected, (s, a) => {
            s.logingin = false;
        });
        builder.addCase(registerWithEmail.fulfilled, (s, a) => {
            s.tempToken = a.payload.token;
            s.showRegistrationModal = false;
            s.showVerificationModal = true;
        });
        builder.addCase(logout.fulfilled, (s, a) => {
            localStorage.removeItem(USR_LOCALSTORAGE_KEY);
            s.user = undefined;
            window.location.href =
                window.location.protocol + "//" + window.location.host;
        });
    },
});

export { login, registerWithEmail, logout, gitLogin, googleLogin };
export const {
    showLoginModal,
    refreshUser,
    closeLoginModal,
    setRegistrationModalVisible,
    setVerificationModalVisible,
    setForgotPasswordModalVisible,
} = authSlice.actions;
export default authSlice.reducer;
