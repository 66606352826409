import { AxiosError, AxiosResponse } from "axios";
import { appAxios } from "../configs/api-config";
import { AppError } from "../errors/app-error";

export class BaseApi {
    protected static async _post<T>(api: string, data: any, headers: any = null) {
        try {
            let response: AxiosResponse<T, any>;
            if (headers) {
                response = await appAxios.post<T>(api, data, {
                    headers
                });
            } else {
                response = await appAxios.post<T>(api, data);
            }
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new AppError(response.status, response.statusText, response.data);
            }
        } catch (error) {
            throw this.HandleError(error);
        }
    }

    protected static async _get<T>(api: string, data: any = null, headers: any = null) {
        try {
            let url = api;
            if (data) {
                url += "?"
                for (let key in data) {
                    url = `${url}${key}=${data[key]}&`
                }
                url = url.substring(0, url.length - 1);
            }
            let response: AxiosResponse<T, any>;
            if (headers) {
                response = await appAxios.get<T>(url, {
                    headers
                });
            } else {
                response = await appAxios.get<T>(url);
            }
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new AppError(response.status, response.statusText, response.data);
            }
        } catch (error) {
            throw this.HandleError(error);
        }
    }

    protected static async _patch<T>(api: string, data: any, headers: any = null) {
        try {
            let response: AxiosResponse<T, any>;
            if (headers) {
                response = await appAxios.patch<T>(api, data, {
                    headers
                });
            } else {
                response = await appAxios.patch<T>(api, data);
            }
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new AppError(response.status, response.statusText, response.data);
            }
        } catch (error) {
            throw this.HandleError(error);
        }
    }

    protected static async _delete<T>(api: string, data: any = null, headers: any = null) {
        try {
            let url = api;
            if (data) {
                url += "?"
                for (let key in data) {
                    url = `${url}${key}=${data[key]}&`
                }
                url = url.substring(0, url.length - 1);
            }
            let response: AxiosResponse<T, any>;
            if (headers) {
                response = await appAxios.delete<T>(url, {
                    headers
                });
            } else {
                response = await appAxios.delete<T>(url);
            }
            if (response.status === 200) {
                return response.data;
            }
            else {
                throw new AppError(response.status, response.statusText, response.data);
            }
        } catch (error) {
            throw this.HandleError(error);
        }
    }

    private static HandleError(error: any) {
        if (error instanceof AxiosError) {
            if (error.response?.status) {
                return new AppError(error.response?.status, 'Internal server error.', error.response.data);
            }
        }
        return error;
    }
}



