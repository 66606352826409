export default {
  "pre[class*=\"language-\"]": {
    "color": "#d4d4d4",
    "fontSize": "12px",
    "textShadow": "none",
    "fontFamily": "Menlo, Monaco, Consolas, \"Andale Mono\", \"Ubuntu Mono\", \"Courier New\", monospace",
    "direction": "ltr",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "lineHeight": "20px",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "padding": "1rem 1rem",
    "margin": "0 0",
    "borderRadius": "16px",
    "overflow": "auto",
    "background": "#141414"
  },
  "code[class*=\"language-\"]": {
    "color": "#d4d4d4",
    "fontSize": "12px",
    "textShadow": "none",
    "fontFamily": "Menlo, Monaco, Consolas, \"Andale Mono\", \"Ubuntu Mono\", \"Courier New\", monospace",
    "direction": "ltr",
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "lineHeight": "20px",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none"
  },
  "pre[class*=\"language-\"]::selection": {
    "textShadow": "none",
    "background": "transparent"
  },
  "code[class*=\"language-\"]::selection": {
    "textShadow": "none",
    "background": "transparent"
  },
  "pre[class*=\"language-\"] *::selection": {
    "textShadow": "none",
    "background": "transparent"
  },
  "code[class*=\"language-\"] *::selection": {
    "textShadow": "none",
    "background": "transparent"
  },
  ":not(pre) > code[class*=\"language-\"]": {
    "padding": ".1em .3em",
    "borderRadius": ".3em",
    "color": "#db4c69",
    "background": "transparent"
  },
  ".namespace": {
    "Opacity": ".7"
  },
  "doctype.doctype-tag": {
    "color": "#569CD6"
  },
  "doctype.name": {
    "color": "#9cdcfe"
  },
  "comment": {
    "color": "#A9D9F9"
  },
  "prolog": {
    "color": "#6a9955"
  },
  "punctuation": {
    "color": "#7CF9B5"
  },
  ".language-html .language-css .token.punctuation": {
    "color": "#d4d4d4"
  },
  ".language-html .language-javascript .token.punctuation": {
    "color": "#d4d4d4"
  },
  "property": {
    "color": "#9cdcfe"
  },
  "tag": {
    "color": "rgb(78, 201, 176)"
  },
  "boolean": {
    "color": "#569cd6"
  },
  "number": {
    "color": "#83CC9B"
  },
  "constant": {
    "color": "rgb(100, 102, 149)"
  },
  "symbol": {
    "color": "#b5cea8"
  },
  "inserted": {
    "color": "#83CC9B"
  },
  "unit": {
    "color": "#b5cea8"
  },
  "selector": {
    "color": "rgb(215, 186, 125)"
  },
  "attr-name": {
    "color": "#9cdcfe"
  },
  "string": {
    "color": "#CDACF9"
  },
  "char": {
    "color": "rgb(209, 105, 105)"
  },
  "builtin": {
    "color": "#F38B5A"
  },
  "deleted": {
    "color": "#CDACF9"
  },
  ".language-css .token.string.url": {
    "textDecoration": "underline"
  },
  "operator": {
    "color": "#7CF9B5"
  },
  "entity": {
    "color": "#569cd6"
  },
  "operator.arrow": {
    "color": "#569CD6"
  },
  "atrule": {
    "color": "#F38B5A"
  },
  "atrule.rule": {
    "color": "#c586c0"
  },
  "atrule.url": {
    "color": "#9cdcfe"
  },
  "atrule.url.function": {
    "color": "#D2A8FF"
  },
  "atrule.url.punctuation": {
    "color": "#d4d4d4"
  },
  "keyword": {
    "color": "#BD9FE7"
  },
  "keyword.module": {
    "color": "#c586c0"
  },
  "keyword.control-flow": {
    "color": "#c586c0"
  },
  "function": {
    "color": "#F38B5A"
  },
  "function.maybe-class-name": {
    "color": "#D2A8FF"
  },
  "regex": {
    "color": "#d16969"
  },
  "important": {
    "color": "#569cd6"
  },
  "italic": {
    "fontStyle": "italic"
  },
  "class-name": {
    "color": "rgb(78, 201, 176)"
  },
  "maybe-class-name": {
    "color": "#4ec9b0"
  },
  "console": {
    "color": "#9cdcfe"
  },
  "parameter": {
    "color": "#9cdcfe"
  },
  "interpolation": {
    "color": "#9cdcfe"
  },
  "punctuation.interpolation-punctuation": {
    "color": "#569cd6"
  },
  "variable": {
    "color": "#F5F5F5"
  },
  "imports.maybe-class-name": {
    "color": "#9cdcfe"
  },
  "exports.maybe-class-name": {
    "color": "#9cdcfe"
  },
  "escape": {
    "color": "#d7ba7d"
  },
  "tag.punctuation": {
    "color": "#808080"
  },
  "cdata": {
    "color": "#808080"
  },
  "attr-value": {
    "color": "#F38B5A"
  },
  "attr-value.punctuation": {
    "color": "#F38B5A"
  },
  "attr-value.punctuation.attr-equals": {
    "color": "#d4d4d4"
  },
  "namespace": {
    "color": "#4ec9b0"
  },
  "pre[class*=\"language-javascript\"]": {
    "color": "#F5F5F5"
  },
  "code[class*=\"language-javascript\"]": {
    "color": "#F5F5F5"
  },
  "pre[class*=\"language-jsx\"]": {
    "color": "#9cdcfe"
  },
  "code[class*=\"language-jsx\"]": {
    "color": "#9cdcfe"
  },
  "pre[class*=\"language-typescript\"]": {
    "color": "#9cdcfe"
  },
  "code[class*=\"language-typescript\"]": {
    "color": "#9cdcfe"
  },
  "pre[class*=\"language-tsx\"]": {
    "color": "#9cdcfe"
  },
  "code[class*=\"language-tsx\"]": {
    "color": "#9cdcfe"
  },
  "pre[class*=\"language-css\"]": {
    "color": "#F38B5A"
  },
  "code[class*=\"language-css\"]": {
    "color": "#F38B5A"
  },
  "pre[class*=\"language-html\"]": {
    "color": "#d4d4d4"
  },
  "code[class*=\"language-html\"]": {
    "color": "#d4d4d4"
  },
  ".language-regex .token.anchor": {
    "color": "#D2A8FF"
  },
  ".language-html .token.punctuation": {
    "color": "#808080"
  },
  "pre[class*=\"language-\"] > code[class*=\"language-\"]": {
    "position": "relative",
    "zIndex": "1"
  },
  ".line-highlight.line-highlight": {
    "background": "transparent",
  }
}