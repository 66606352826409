import { RECAPTCHA_SITE_ID_V3 } from "../configs/constants";
export const executeV3Captcha: (action: string) => Promise<string> = (
    action: string
) => {
    return new Promise((res, rej) => {
        ((window as any).grecaptcha as any)?.enterprise.ready(async () => {
            try {
                const token = await (
                    (window as any).grecaptcha as any
                )?.enterprise.execute(RECAPTCHA_SITE_ID_V3, {
                    action: action,
                });
                res(token);
            } catch (error) {
                rej(error);
            }
        });
    });
};
