import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

// import: assets
import trophy from "../../assets/images/list_item_placeholder.png";
import { faEdit, faTrash, faClose } from "@fortawesome/free-solid-svg-icons";
import datasetsIcon from "../../assets/icons/datasets.svg";
import competitionsIcon from "../../assets/icons/competitions.svg";
import membersIcon from "../../assets/icons/users.svg";

// import: styles
import "./index.scss";

// import: constants
import { ERROR, ORGANISATION_ID, ORGANISATIONS } from "../../router/routes";

// import: enums
import { UserRole } from "../../enums/roles";

// import: types
import {
    IOrganizationDetails,
    IUpdateOrganizationDatasetsPayload,
    IUpdateOrganizationCompetitionsPayload,
    IUpdateOrganizationUsersPayload
} from "../../types/organization-types";
import { IOrganizationUserProfile } from "../../types/user-type";
import { IDataset } from "../../types/dataset-types";
import { ICompetition } from "../../types/competition-types";

// import: utils
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { deleteOrganization } from "../../store/slices/organization-slice";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import { loadDatasets } from "../../store/slices/dataset-slice";
import { loadCompetitions } from "../../store/slices/competition-slice";

// import: api
import OrganizationApi from "../../api/organization-api";
import ProfileApi from "../../api/profile-api";

// import: config
import { OrganizationBanner } from "../../configs/api-config";

// import: components
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Input from "../../components/input/input";
import LoadingSpinner from "../../components/loading-spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorAlert from "../../components/error-alert";
import EditOrganizationModal from "./edit-organization";

export default function CompetitionInfoPage() {
    const { [ORGANISATION_ID]: _id } = useParams();
    const user = useAppSelector((s) => s.auth.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const datasets = useAppSelector((s) => s.dataset.pageData.data);
    const competitions = useAppSelector((s) => s.competition.pageData.data);
    const [users, setUsers] = useState<IOrganizationUserProfile[]>([]);

    const [organization, setOrganization] = useState<IOrganizationDetails>();
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [editOrgModal, showEditOrgModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [noImage, setNoImage] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [addUserModal, showAddUserModal] = useState<boolean>(false);
    const addUserFormRef = useRef<HTMLFormElement>(null);
    const [userSearch, setUserSearch] = useState<string>("");
    const [usersToAdd, setUsersToAdd] = useState<IOrganizationUserProfile[]>([]);

    const [addDatasetModal, showAddDatasetModal] = useState<boolean>(false);
    const addDatasetFormRef = useRef<HTMLFormElement>(null);
    const [datasetSearch, setDatasetSearch] = useState<string>("");
    const [datasetsToAdd, setDatasetsToAdd] = useState<IDataset[]>([]);

    const [addCompetitionModal, showAddCompetitionModal] = useState<boolean>(false);
    const addCompetitionFormRef = useRef<HTMLFormElement>(null);
    const [competitionSearch, setCompetitionSearch] = useState<string>("");
    const [competitionsToAdd, setCompetitionsToAdd] = useState<ICompetition[]>([]);

    useEffect(() => {
        if (_id) {
            fetchOrg();
        } else {
            setOrganization(undefined);
            setError("Organization not found.");
        }
    }, [_id]);

    const fetchOrg = () => {
        if (_id) {
            setLoading(true);
            OrganizationApi.get(_id)
                .then((org) => {
                    setOrganization({ ...org });
                })
                .catch((error) => {
                    setError(HandleError(error)[""]);
                    navigate(`/${ERROR}`)
                }).finally(() => setLoading(false));
        }
    }

    const deleteSelectedOrganization = async () => {
        try {
            setLoading(true);
            if (organization?._id) {
                await dispatch(deleteOrganization(organization?._id)).unwrap();
                setConfirmDelete(false);
                navigate(`/${ORGANISATIONS}`);
            } else {
                setError("Error deleting organization: unknown ID");
            }
        } catch (error) {
            setError(HandleError(error)[""]);
        } finally { }
    };

    const removeDatasetFromOrganization = (id: number) => {
        if (organization) {
            setLoading(true);

            OrganizationApi.removeDatasets({
                orgId: organization?._id,
                datasets: [organization.datasets[id]._id]
            }).then(() => {
                let holder = { ...organization };
                holder.datasets.splice(id, 1);
                setOrganization(holder);
            })
                .catch((error) => {
                    setError(HandleError(error)[""]);
                }).finally(() => setLoading(false));
        }
    };
    const removeCompetitionFromOrganization = (id: number) => {
        if (organization) {
            setLoading(true);

            OrganizationApi.removeCompetitions({
                orgId: organization?._id,
                competitions: [organization.competitions[id]._id]
            }).then(() => {
                let holder = { ...organization };
                holder.competitions.splice(id, 1);
                setOrganization(holder);
            })
                .catch((error) => {
                    setError(HandleError(error)[""]);
                }).finally(() => setLoading(false));
        }
    };
    const removeUserFromOrganization = (id: number) => {
        if (organization) {
            setLoading(true);
            OrganizationApi.removeMembers({
                orgId: organization?._id,
                members: [organization.members[id]._id]
            }).then(() => {
                let holder = { ...organization };
                holder.members.splice(id, 1);
                setOrganization(holder);
            })
                .catch((error) => {
                    setError(HandleError(error)[""]);
                }).finally(() => setLoading(false));
        }
    };

    // FETCHING AND ADDING COMPETITIONS------------------------------------

    // FETCHING COMPETITIONS ON SEARCH STRING UPDATE
    useEffect(() => {
        if (competitionSearch.trim().length > 3) {
            let delay = setTimeout(() => {
                dispatch(loadCompetitions({ page: 1, size: 7, title: competitionSearch }))
            }, 1000);

            return () => clearTimeout(delay);
        }
    }, [competitionSearch]);

    // PREPARING SUBMIT ID ARRAY TO ADD COMPETITIONS
    const prepareForCompetitionSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let holder: string[] = [];
        competitionsToAdd.forEach((competition, id) => {
            if (holder.length === 0 || !holder.includes(competition._id)) holder.push(competition._id);
            if (id === competitionsToAdd.length - 1) submitAddCompetitions(holder);
        })
    };

    // ADD COMPETITIONS SUBMIT
    const submitAddCompetitions = (idArray: string[]) => {
        try {
            setLoading(true);
            const obj = {
                orgId: organization?._id,
                competitions: idArray
            } as IUpdateOrganizationCompetitionsPayload;

            OrganizationApi.addCompetitions(obj).then(() => {
                if (organization) {
                    let holder = { ...organization };
                    let ids = holder.competitions.map(competition => competition._id);
                    competitionsToAdd.forEach((competition, idx) => {
                        if (!ids?.includes(competition._id)) holder.competitions.push(competition);
                        if (idx === competitionsToAdd.length - 1) setOrganization(holder);
                    });
                }

                dispatch(
                    showInfoModal({
                        title: "Competitions Added",
                        message: "Competitions successfully added to the Organization.",
                    })
                );
            }).catch((error) => {
                setError(HandleError(error)[""]);
            }).finally(() => { });
            resetAddCompetitionForm();
        } catch (error) {
            setError(HandleError(error));
        } finally {
            fetchOrg();
            setLoading(false);
        }
    }

    // ADDING COMPETITIONS TO LIST FOR SUBMISSION
    const addCompetitionToList = (competition: ICompetition) => {
        let compHolder = [...competitionsToAdd];
        compHolder.push(competition);
        setCompetitionsToAdd(compHolder);
        setCompetitionSearch("");
    };

    // REMOVING COMPETITIONS FROM LIST OF SUBMISSION
    const removeCompetitionFromList = (i: number) => {
        let holder = [...competitionsToAdd];
        holder.splice(i, 1);
        setCompetitionsToAdd(holder);
    }

    // RESET ADD COMPETITION FORM
    const resetAddCompetitionForm = () => {
        setCompetitionsToAdd([]);
        setCompetitionSearch("");
        showAddCompetitionModal(false);
    };


    // FETCHING AND ADDING DATASETS------------------------------------

    // FETCHING DATASETS ON SEARCH STRING UPDATE
    useEffect(() => {
        if (datasetSearch.trim().length > 3) {
            let delay = setTimeout(() => {
                dispatch(loadDatasets({ page: 1, size: 7, title: datasetSearch }))
            }, 1000);

            return () => clearTimeout(delay);
        }
    }, [datasetSearch]);

    // PREPARING SUBMIT ID ARRAY TO ADD DATASETS
    const prepareForDatasetSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let holder: string[] = [];
        datasetsToAdd.forEach((dataset, id) => {
            if (holder.length === 0 || !holder.includes(dataset._id)) holder.push(dataset._id);
            if (id === datasetsToAdd.length - 1) submitAddDatasets(holder);
        })
    };

    // ADD DATASETS SUBMIT
    const submitAddDatasets = (idArray: string[]) => {
        try {
            setLoading(true);
            const obj = {
                orgId: organization?._id,
                datasets: idArray
            } as IUpdateOrganizationDatasetsPayload;

            OrganizationApi.addDatasets(obj).then(() => {
                if (organization) {
                    let holder = { ...organization };
                    let ids = holder.datasets.map(dataset => dataset._id);
                    datasetsToAdd.forEach((dataset, idx) => {
                        if (!ids?.includes(dataset._id)) holder.datasets.push(dataset);
                        if (idx === datasetsToAdd.length - 1) setOrganization(holder);
                    });
                }

                dispatch(
                    showInfoModal({
                        title: "Datasets Added",
                        message: "Datasets successfully added to the Organization.",
                    })
                );
            }).catch((error) => {
                setError(HandleError(error)[""]);
            }).finally(() => { });
            resetAddDatasetForm();
        } catch (error) {
            setError(HandleError(error));
        } finally {
            fetchOrg();
            setLoading(false);
        }
    }

    // ADDING DATASETS TO LIST FOR SUBMISSION
    const addDatasetToList = (dataset: IDataset) => {
        let datHolder = [...datasetsToAdd];
        datHolder.push(dataset);
        setDatasetsToAdd(datHolder);
        setDatasetSearch("");
    };

    // REMOVING DATASETS FROM LIST OF SUBMISSION
    const removeDatasetFromList = (i: number) => {
        let holder = [...datasetsToAdd];
        holder.splice(i, 1);
        setDatasetsToAdd(holder);
    }

    // RESET ADD DATASET FORM
    const resetAddDatasetForm = () => {
        setDatasetsToAdd([]);
        setDatasetSearch("");
        showAddDatasetModal(false);
    };


    // FETCHING AND ADDING USERS------------------------------------

    // FETCHING USERS ON SEARCH STRING UPDATE
    useEffect(() => {
        if (userSearch.trim().length > 3) {
            let delay = setTimeout(() => {
                ProfileApi.getUserProfiles({ page: 1, size: 7, email: userSearch })
                    .then((userData) => setUsers(userData.data))
            }, 1000);

            return () => clearTimeout(delay);
        }
    }, [userSearch]);

    // PREPARING SUBMIT ID ARRAY TO ADD USERS
    const prepareForUserSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let holder: string[] = [];
        usersToAdd.forEach((user, id) => {
            if (holder.length === 0 || !holder.includes(user._id)) holder.push(user._id);
            if (id === usersToAdd.length - 1) submitAddUsers(holder);
        })
    };

    // ADD USERS SUBMIT
    const submitAddUsers = (idArray: string[]) => {
        try {
            setLoading(true);
            const obj = {
                orgId: organization?._id,
                members: idArray
            } as IUpdateOrganizationUsersPayload;

            OrganizationApi.addMembers(obj).then(() => {
                if (organization) {
                    let holder = { ...organization };
                    let ids = holder.members.map(member => member._id);
                    usersToAdd.forEach((member, idx) => {
                        if (!ids?.includes(member._id)) holder.members.push(member);
                        if (idx === usersToAdd.length - 1) setOrganization(holder);
                    });
                }

                dispatch(
                    showInfoModal({
                        title: "Users Added",
                        message: "Selected Users successfully added as members to the Organization.",
                    })
                );
            }).catch((error) => {
                setError(HandleError(error)[""]);
            }).finally(() => { });
            resetAddUsersForm();
        } catch (error) {
            setError(HandleError(error));
        } finally {
            fetchOrg();
            setLoading(false);
        }
    }

    // ADDING USERS TO LIST FOR SUBMISSION
    const addUsersToList = (user: IOrganizationUserProfile) => {
        let userHolder = [...usersToAdd];
        userHolder.push(user);
        setUsersToAdd(userHolder);
        setUserSearch("");
    };

    // REMOVING UESRS FROM LIST OF SUBMISSION
    const removeUsersFromList = (i: number) => {
        let holder = [...usersToAdd];
        holder.splice(i, 1);
        setUsersToAdd(holder);
    }

    // RESET ADD UESRS FORM
    const resetAddUsersForm = () => {
        setUsersToAdd([]);
        setUserSearch("");
        showAddUserModal(false);
    };

    return (
        <div className="page-container lg-box">
            <ErrorAlert errorMessage={error} show={error ? true : false} />

            <div className="page-heading-container mb-0">
                <div className="d-flex flex-sm-row flex-column-reverse">
                    {noImage ?
                        <div className="page-heading-banner mb-3 mb-sm-0">
                            <img
                                src={trophy}
                                alt=""
                                className="w-100 bg-light"
                            />
                        </div> : undefined
                    }
                    {organization && !noImage ?
                        <div className="page-heading-banner mb-3 mb-sm-0">
                            <img
                                src={OrganizationBanner(organization._id)}
                                className="w-100"
                                alt=""
                                onError={() => setNoImage(true)}
                            />
                        </div> : undefined
                    }

                    <div className="mb-21 mb-sm-0">
                        <div className="d-flex">
                            <span className="pb-1 text-3xl">{organization?.title}</span>
                            {user?.roles.includes(UserRole.Admin) ?
                                <FontAwesomeIcon icon={faEdit} className="ms-3 feature-icon"
                                    title="Edit" size="sm"
                                    onClick={() => showEditOrgModal(true)} />
                                : <></>}
                            {user?.roles.includes(UserRole.Admin) ?
                                <FontAwesomeIcon icon={faTrash} className="ms-3 feature-icon"
                                    title="Edit" size="sm"
                                    onClick={() => setConfirmDelete(true)} />
                                : <></>}
                        </div>
                        <div className="mt-2">
                            <p className="text-color-tertiary text-xs d-flex mb-2">
                                <img alt="" className="me-1" height={16} width={16} src={datasetsIcon} />{" "}
                                <span>{"Datasets : " + organization?.datasets.length}</span>
                            </p>
                            <p className="text-color-tertiary text-xs d-flex mb-2">
                                <img alt="" className="me-1" height={16} width={16} src={competitionsIcon} />{" "}
                                <span>{"Competitions : " + organization?.competitions.length}</span>
                            </p>
                            <p className="text-color-tertiary text-xs d-flex">
                                <img alt="" className="me-1" height={16} width={16} src={membersIcon} />{" "}
                                <span>{"Members : " + organization?.members.length}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-4 d-flex flex-wrap w-fit">
                <Button className="me-4 rounded-pill" variant="secondary" onClick={() => showAddDatasetModal(true)}>
                    Add Datasets
                </Button>
                <Button className="me-4 rounded-pill" variant="secondary" onClick={() => showAddCompetitionModal(true)}>
                    Add Competitions
                </Button>
                <Button className="rounded-pill" variant="secondary" onClick={() => showAddUserModal(true)}>
                    Add Members
                </Button>
            </div>

            <Tabs className="pb-1">
                <Tab title={"Datasets"} eventKey={"tabDatasets"}>
                    <div className="listing-table-container px-2 pt-4">
                        {organization ? organization.datasets?.map((dat, idx) => (
                            <p key={"OrgDataset" + idx} className="org-list-item text-sm text-color-secondary">
                                <span>{dat.title}</span>
                                <FontAwesomeIcon icon={faClose} className="feature-icon"
                                    title="Remove" size="lg"
                                    onClick={() => removeDatasetFromOrganization(idx)}
                                />
                            </p>
                        )) : <></>}

                        {organization && organization.datasets?.length === 0 ?
                            <div className="py-5 text-center text-color-tertiary w-100 my-0">
                                No datasets linked to the organization
                            </div> : <></>
                        }
                    </div>
                </Tab>

                <Tab title={"Competitions"} eventKey={"tabCompetitions"}>
                    <div className="listing-table-container px-2 pt-4">
                        {organization ? organization.competitions?.map((comp, idx) => (
                            <p key={"OrgDataset" + idx} className="org-list-item text-sm text-color-secondary">
                                <span>{comp.title}</span>
                                <FontAwesomeIcon icon={faClose} className="feature-icon"
                                    title="Remove" size="lg"
                                    onClick={() => removeCompetitionFromOrganization(idx)}
                                />
                            </p>
                        )) : <></>}

                        {organization && organization.competitions?.length === 0 ?
                            <div className="py-5 text-center text-color-tertiary w-100 my-0">
                                No competitions linked to the organization
                            </div> : <></>
                        }
                    </div>
                </Tab>

                <Tab title={"Members"} eventKey={"tabMembers"}>
                    <div className="listing-table-container px-2 pt-4">
                        {organization ? organization.members?.map((user, idx) => (
                            <p key={"OrgUser" + idx} className="org-list-item text-sm text-color-secondary">
                                <span>{user.fullName}</span>
                                <FontAwesomeIcon icon={faClose} className="feature-icon"
                                    title="Remove" size="lg"
                                    onClick={() => removeUserFromOrganization(idx)}
                                />
                            </p>
                        )) : <></>}

                        {organization && organization.members?.length === 0 ?
                            <div className="py-5 text-center text-color-tertiary w-100 my-0">
                                No members in this organization yet
                            </div> : <></>
                        }
                    </div>
                </Tab>
            </Tabs>


            {/* DELETE MODAL */}
            <Modal show={confirmDelete} className="login-modal" centered onHide={() => setConfirmDelete(false)}>
                <Modal.Header className="border-0 p-3" closeButton>
                    <p className="lead text-center">Are you sure?</p>
                </Modal.Header>

                <Modal.Body className=" pt-0">
                    <p className=" mb-5 mt-4">Are you sure you want to delete {organization?.title || "this organization"}?</p>

                    <div className="d-flex">
                        <Button
                            onClick={() => setConfirmDelete(false)}
                            className="rounded-pill ms-auto me-4"
                            variant="secondary"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={deleteSelectedOrganization}
                            className="rounded-pill"
                            variant="primary"
                        >
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ADD DATASET MODAL */}
            <Modal show={addDatasetModal} className="login-modal" centered onHide={() => { showAddDatasetModal(false); setDatasetSearch("") }}>
                <Modal.Header className="border-0 p-3" closeButton>
                    <p className="lead text-center">Add Datasets to Organization</p>
                </Modal.Header>
                <Modal.Body className=" pt-0">
                    <Form
                        onReset={resetAddDatasetForm}
                        ref={addDatasetFormRef}
                        noValidate
                        onSubmit={prepareForDatasetSubmit}
                    >
                        <Form.Group className="dropdown-parent">
                            <Input
                                autoFocus
                                focusOnError={true}
                                label="Search Dataset by Title"
                                placeholder="Dataset Title"
                                className="mb-4"
                                value={datasetSearch}
                                onChange={(e) => setDatasetSearch(e.target.value)}
                            />
                            {datasetSearch && datasets.length > 0 ? <div className="dropdown-holder glazed-card-cover radius-base">
                                <div className="glazed-card radius-base py-2">
                                    {datasets.map((dat, idx) =>
                                        <p key={"ListItm" + idx} className="dropdown-item text-color-tertiary text-xs cursor-pointer py-2 px-3 mb-0" onClick={() => addDatasetToList(dat)}>
                                            {dat.title}
                                        </p>
                                    )}
                                </div>
                            </div> : <></>}
                        </Form.Group>

                        <div className="datasets-container">
                            {datasetsToAdd.length > 0 ? datasetsToAdd.map((dat, id) =>
                                <p key={"selected" + id}
                                    className="d-flex justify-content-between align-items-center text-color-tertiary text-sm px-2 w-100">
                                    <span>{dat.title}</span>
                                    <FontAwesomeIcon icon={faClose} className="feature-icon"
                                        title="Remove" size="sm"
                                        onClick={() => removeDatasetFromList(id)}
                                    />
                                </p>
                            ) : <></>}
                        </div>

                        <Form.Group className="mt-5 d-flex gap-3 w-fit ms-auto">
                            <Button type="reset" variant="secondary" className="rounded-pill">
                                Reset
                            </Button>
                            <Button type="submit" className="rounded-pill">Add Datasets</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* ADD COMPETITION MODAL */}
            <Modal show={addCompetitionModal} className="login-modal" centered onHide={() => { showAddCompetitionModal(false); setCompetitionSearch("") }}>
                <Modal.Header className="border-0 p-3" closeButton>
                    <p className="lead text-center">Add Competitions to Organization</p>
                </Modal.Header>
                <Modal.Body className=" pt-0">
                    <Form
                        onReset={resetAddCompetitionForm}
                        ref={addCompetitionFormRef}
                        noValidate
                        onSubmit={prepareForCompetitionSubmit}
                    >
                        <Form.Group className="dropdown-parent">
                            <Input
                                autoFocus
                                focusOnError={true}
                                label="Search Competition by Title"
                                placeholder="Competition Title"
                                className="mb-4"
                                value={competitionSearch}
                                onChange={(e) => setCompetitionSearch(e.target.value)}
                            />
                            {competitionSearch && competitions.length > 0 ? <div className="dropdown-holder glazed-card-cover radius-base">
                                <div className="glazed-card radius-base py-2">
                                    {competitions.map((comp, idx) =>
                                        <p key={"ListItm" + idx} className="dropdown-item text-color-tertiary text-xs cursor-pointer py-2 px-3 mb-0" onClick={() => addCompetitionToList(comp)}>
                                            {comp.title}
                                        </p>
                                    )}
                                </div>
                            </div> : <></>}
                        </Form.Group>

                        <div className="datasets-container">
                            {competitionsToAdd.length > 0 ? competitionsToAdd.map((comp, id) =>
                                <p key={"selected" + id}
                                    className="d-flex justify-content-between align-items-center text-color-tertiary text-sm px-2 w-100">
                                    <span>{comp.title}</span>
                                    <FontAwesomeIcon icon={faClose} className="feature-icon"
                                        title="Remove" size="sm"
                                        onClick={() => removeCompetitionFromList(id)}
                                    />
                                </p>
                            ) : <></>}
                        </div>

                        <Form.Group className="mt-5 d-flex gap-3 w-fit ms-auto">
                            <Button type="reset" variant="secondary" className="rounded-pill">
                                Reset
                            </Button>
                            <Button type="submit" className="rounded-pill">Add Competitions</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* ADD MEMBER MODAL */}
            <Modal show={addUserModal} className="login-modal" centered onHide={() => { showAddUserModal(false); setUserSearch("") }}>
                <Modal.Header className="border-0 p-3" closeButton>
                    <p className="lead text-center">Add Members to Organization</p>
                </Modal.Header>
                <Modal.Body className=" pt-0">
                    <Form
                        onReset={resetAddUsersForm}
                        ref={addUserFormRef}
                        noValidate
                        onSubmit={prepareForUserSubmit}
                    >
                        <Form.Group className="dropdown-parent">
                            <Input
                                autoFocus
                                focusOnError={true}
                                label="Search Users by Email"
                                placeholder="Search Email"
                                className="mb-4"
                                value={userSearch}
                                onChange={(e) => setUserSearch(e.target.value)}
                            />
                            {userSearch && users.length > 0 ? <div className="dropdown-holder glazed-card-cover radius-base">
                                <div className="glazed-card radius-base py-2">
                                    {users.map((user, idx) =>
                                        user && user.isVerified ? <p key={"ListItm" + idx} className="dropdown-item text-color-tertiary text-xs cursor-pointer py-2 px-3 mb-0" onClick={() => addUsersToList(user)}>
                                            {`${user.fullName} (${user.email})`}
                                        </p> :
                                            <p key={"ListItm" + idx} className="text-color-red-normal text-xs py-2 px-3 mb-0">
                                                {`${user.fullName} (${user.email})`}
                                            </p>
                                    )}
                                    <p key="label" className="text-color-red-normal border-secondary text-xs pt-2 pb-1 px-3 mb-0 border-top">
                                        Users with incomplete email verification
                                    </p>
                                </div>
                            </div> : <></>}
                        </Form.Group>

                        <div className="datasets-container">
                            {usersToAdd.length > 0 ? usersToAdd.map((user, id) =>
                                <p key={"selected" + id}
                                    className="d-flex justify-content-between align-items-center text-color-tertiary text-sm px-2 w-100">
                                    <span>{`${user.fullName} (${user.email})`}</span>
                                    <FontAwesomeIcon icon={faClose} className="feature-icon"
                                        title="Remove" size="sm"
                                        onClick={() => removeUsersFromList(id)}
                                    />
                                </p>
                            ) : <></>}
                        </div>

                        <Form.Group className="mt-5 d-flex gap-3 w-fit ms-auto">
                            <Button type="reset" variant="secondary" className="rounded-pill">
                                Reset
                            </Button>
                            <Button type="submit" className="rounded-pill">Add Users</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

            {organization && <EditOrganizationModal _id={organization?._id} show={editOrgModal} onHide={() => { showEditOrgModal(false); fetchOrg() }} />}

            <LoadingSpinner show={loading} text="Loading..." />
        </div>
    );
}
