
import classification from "../../assets/categoryIcons/classification.png";
import ranking from "../../assets/categoryIcons/ranking.png";
import regression from "../../assets/categoryIcons/regression.png";
import recommendation from "../../assets/categoryIcons/recommendation.png";


export default function CategoryIcons(icon) {

    if(icon === "classification") return classification;
    else if(icon === "ranking") return ranking;
    else if(icon === "recommendation") return recommendation;
    else if(icon === "regression") return regression;
    else return classification;
}
