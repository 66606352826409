import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IModalsState {
    infoModal: {
        title?: string;
        message?: string;
        buttonText?: string;
        visible: boolean;
    },
    errorModal: {
        title?: string;
        message?: string;
        buttonText?: string;
        visible: boolean;
    }
}

const initialState: IModalsState = {
    infoModal: {
        title: "Info",
        message: "",
        buttonText: "Okay",
        visible: false
    },
    errorModal: {
        title: "Error",
        message: "",
        buttonText: "Okay",
        visible: false
    }
};

export const modalStatesSlice = createSlice({
    name: 'modal-states',
    initialState,
    reducers: {
        showInfoModal: (s, { payload }: PayloadAction<{ title?: string, message: string, buttonText?: string }>) => {
            s.infoModal = { ...payload, visible: true };
        },
        clearInfoModal: (s) => {
            s.infoModal = initialState.infoModal;
        },
        showErrorModal: (s, { payload }: PayloadAction<{ title?: string, message: string, buttonText?: string }>) => {
            s.errorModal = { ...payload, visible: true };
        },
        clearErrorModal: (s) => {
            s.errorModal = initialState.infoModal;
        },
    }
})

export const { showInfoModal, clearInfoModal, showErrorModal, clearErrorModal } = modalStatesSlice.actions;
export default modalStatesSlice.reducer;