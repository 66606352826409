/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";
import AnsiPre from "./AnsiPre";
import Markdown from "../mathMarkdown";

const base64ToImage = (mime: string, base64: string) => (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={`data:${mime};base64,${base64}`} />
);

// Putting the 'text/html' output on page is ugly and unsafe
// Instead, let's parse the raw output
function getDataFrame(raw: string) {
    const rows = raw.split("\n");
    const elements = rows.map((r) => r.split(/\s+/));
    return (
        <table className="dataframe">
            <thead>
                <tr>
                    <td />
                    {/* Column headers */}
                    {elements[0].slice(1).map((h, hidx) => (
                        <th key={hidx} scope="col">
                            {h}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {elements.slice(1).map((row, rowidx) => (
                    <tr key={rowidx}>
                        {/* Row header */}
                        <th scope="row">{row[0]}</th>
                        {row.slice(1).map((d, idx) => (
                            <td key={idx}>{d}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

const DisplayDataOutput = ({ output }: { output: NbDisplayDataOutput }) => {
    const { data: datas }: { data: any } = output;
    const formats = [
        "text/html",
        "image/svg+xml",
        "image/png",
        "image/jpeg",
        "text/plain",
    ];

    // e.g. pandas.DataFrame
    if (formats[0] in datas) return (<div dangerouslySetInnerHTML={{ __html: datas[formats[0]] }}></div>);

    if ( formats[0] in datas && formats[4] in datas && datas[formats[0]].includes('class="dataframe"') && !/^<.+>$/.test(datas[formats[4]]) ) {
        return getDataFrame(datas[formats[4]]);
    }

    for (const format of formats) {
        if (format in datas) {
            const datalines = datas[format];
            if(datalines){
                if (format === "image/svg+xml") {
                    const svg = datalines.join("");
                    return <img src={`data:image/svg+xml;utf8,${svg}`} />;
                }
                if (format === "text/html")
                    return (
                        <div className="pb-2 text-sm overflow-x-auto">
                            <div dangerouslySetInnerHTML={{ __html: datalines.join("")}} />
                        </div>
                        // <iframe srcDoc={datalines.join("")} />
                    );
                if (format.startsWith("image/")) {
                    if (Array.isArray(datalines))
                        return base64ToImage(format, datalines[0]);
                    return base64ToImage(format, datalines);
                }
            }
            return <AnsiPre>{datalines && typeof datalines === "string" ? datalines : datalines.length ? datalines.join("") : datalines}</AnsiPre>;
        }
    }
    throw new Error("Unsupported output format");
};

const StreamOutput = ({ output }: { output: NbStreamOutput }) => {
    const className = `output_stream ${
        output.name === "stderr" ? "output_stderr" : ""
    }`;
    return (
        <div className={`overflow-x-auto w-100 ${className}`}>
            <AnsiPre>{output.text.join("")}</AnsiPre>
        </div>
    );
};

const ErrorOutput = ({ output }: { output: NbErrorOutput }) => {
    // Some ANSI escape codes are used to colorize the error output
    return <AnsiPre>{output.traceback.join("")}</AnsiPre>;
};

interface CodeComponentProps {
    language: string;
    children: string;
}

const CodeCell = ({
    cell,
    ...props
}: {
    cell: NbCodeCell;
    language: string;
    code: React.ElementType<CodeComponentProps>;
}) => {
    const source = (typeof cell.source === "string" ? cell.source : cell.source.join(""));

    return (
        <div className="d-flex flex-column w-100">
            {/* "In [...]:" for every code cell */}
            <div className="d-flex w-100">
                <div className={`input_prompt d-none d-md-block ${source.length > 0 ? "" : "mt-2"}`}>
                    <pre>{`In [${cell.execution_count || " "}]:`}</pre>
                </div>
                <div className="inner_cell">
                    <props.code language={props.language}>{source}</props.code>
                </div>
            </div>
            
            {cell.outputs.length > 0 ? <div className="d-flex w-100">
                <div className="output_prompt d-none d-md-block">
                    <pre>{`Out[${cell.execution_count || " "}]:`}</pre>
                </div>
                <div className="output-box mt-0 ms-2 p-2 mb-3">
                    {cell.outputs.map((output, i) => {
                        return (
                            <div key={i} className="inner_cell rounded-0 mt-0 mb-2">
                                {(() => {
                                    switch (output.output_type) {
                                        // The only difference between these two is "Out[...]:"
                                        case "execute_result":
                                        case "display_data":
                                            return (
                                                <DisplayDataOutput
                                                    output={output}
                                                />
                                            );
                                        case "stream":
                                            return <StreamOutput output={output} />;
                                        case "error":
                                            return <ErrorOutput output={output} />;
                                        default:
                                            return undefined;
                                    }
                                })()}
                            </div>
                        );
                    })}
                </div>
            </div> : <></>}
        </div>
    );
};

export interface MarkdownProps {
    source: string;
}

interface NbViewerProps {
    source: string | NbFormat;
    markdown?: React.ElementType<MarkdownProps>;
    code?: React.ElementType<CodeComponentProps>;
}

export default function NbViewer({
    source,
    // markdown = PlainMarkdown,
    code = PlainCode,
}: NbViewerProps) {
    if (!source) return null;
    const ipynb: NbFormat = typeof source === "string" ? JSON.parse(source) : source;
    // TODO: support more versions
    if (ipynb.nbformat < 4)
        throw new Error("react-nbviewer currently supports nbformat 4 and later only");

    const language =
        ipynb.metadata.kernelspec?.language ||
        ipynb.metadata.language_info?.name ||
        "python";

    return (
        <div className="notebook_container">
            {ipynb.cells.map((cell, i) =>
                cell.cell_type === "code" ? (
                    <CodeCell
                        cell={cell}
                        language={language}
                        code={code}
                        key={"code" + i}
                    />
                ) : (
                    <div className="notebook_markdown" key={"markdown" + i}>
                        <Markdown className="text-color-tertiary">
                            {typeof cell.source === "string" ? cell.source : cell.source.join("")}
                        </Markdown>
                    </div>
                )
            )}
        </div>
    );
}

// Defaults when not provided
function PlainMarkdown(props: MarkdownProps) {
    return <div>{props.source}</div>;
}

function PlainCode(props: CodeComponentProps) {
    return (
        <pre>
            <code>{props.children}</code>
        </pre>
    );
}
