import Alert from "react-bootstrap/Alert";
type TAlertErrorProps = {
    title?: string;
    errorMessage?: string;
    show: boolean;
};
export default function ErrorAlert(props: TAlertErrorProps) {
    return (
        <Alert show={props.show} variant="danger" className=" border-0 p-0 mt-2 slide_in_appear_top mb-3">
            <div className="colored-card-cover radius-base">
                <div className="glazed-card p-3 radius-base">
                    <p className="text-xl mb-2">
                        {props?.title ?? "Oh snap!"}
                    </p>
                    <span className="text-sm text-color-secondary">
                        {props.errorMessage}
                    </span>
                </div>
            </div>
        </Alert>
    );
}
