
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customDiscord } from "../../assets/icons";

export default function BaseCard() {

    return (
        <section className="section-container xs-box text-md-center align-items-start align-items-md-center">
            <pre className="text-heading fst-italic text-thin px-2 d-none d-md-block">"</pre>
            <span className="text-heading glazed-bw-text2 mb-4 d-flex flex-column flex-md-row">
                <span className="me-21">The world's most impactful data sits behind closed doors. Privacy technologies hold the key to unlocking its potential.</span>
            </span>
            <span className="text-color-tertiary text-sm mb-42">
                Step into the forefront of data science, where you can 
                experience algorithmic transparency while ensuring data 
                processing opacity and safeguarding against the reverse 
                engineering of sensitive data sources.
            </span>
            <Button variant="secondary" className="mx-auto rounded-pill" onClick={() => window.open("https://docs.antigranular.com", "_blank", 'noopener')}>
                I want to know more
                <svg width="10" height="10" className="link-icon" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </Button>
        </section>
    );
}
