import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { AppError } from "../errors/app-error";
import { Store } from "@reduxjs/toolkit";
import { showLoginModal } from "../store/slices/auth-slice";
import { RootState } from "../store";

export const BASEURL = process.env.REACT_APP_BASEURL;
//"https://3525-2402-3a80-1aae-7e06-3f81-bd76-b6e9-8b4b.ngrok-free.app"; // process.env.REACT_APP_BASEURL || "http://localhost:8000";
export const DATASET_IMG_URL = (id: string) =>
    `${BASEURL}/dataset/cover-image/${id}`;

export const UserProfileAvatarUrl = (id: string) =>
    `${BASEURL}/profile/avatar/${id}`;

export const CompetitionBanner = (id: string) =>
    `${BASEURL}/competition/banner/${id}`;

export const OrganizationBanner = (id: string) =>
`${BASEURL}/organization/logo/${id}`;

export const BlogBanner = (id: string) =>
`${BASEURL}/blog/banner/${id}`;

let store: Store;

export const injectStore = (_store: Store) => {
    store = _store;
};
const appAxios = axios.create({
    baseURL: BASEURL,
});

//intercepting the request and response
appAxios.interceptors.request.use((conf: InternalAxiosRequestConfig) => {
    const st = store.getState() as RootState;
    if (st?.auth) {
        //const user: IJwtUser = JSON.parse(str);
        (conf.headers as AxiosHeaders).set(
            "Authorization",
            `Bearer ${st?.auth?.user?.token ?? st.auth?.tempToken}`
        );
    }
    // (conf.headers as AxiosHeaders).set(
    //     "ngrok-skip-browser-warning",
    //     `true`
    // );
    return conf;
});
appAxios.interceptors.response.use(
    (resp) => {
        if (resp.status === 401) {
            store.dispatch(showLoginModal());
            throw new AppError(401, "Unauthorized");
        } else if (resp.status === 200) {
            if (resp.data && typeof resp.data !== "string") {
                for (let key in resp.data) {
                    resp.data[key] = dateTransformer(resp.data[key]);
                }
            }
        }
        return resp;
    },
    (err) => {
        if (err?.response?.status === 401) {
            //Handle 401
            store.dispatch(showLoginModal());
            throw new AppError(
                401,
                "Your session has expired. Please login to continue"
            );
        }
        throw err;
    }
);

//this function check for dates in response and convert string to a date object
function dateTransformer(data: any): any {
    if (Array.isArray(data)) {
        return data.map((val) => dateTransformer(val));
    }
    if (typeof data === "object" && data !== null) {
        return Object.fromEntries(
            Object.entries(data).map(([key, val]) => [
                key,
                dateTransformer(val),
            ])
        );
    }
    // eslint-disable-next-line no-useless-escape
    let reg = RegExp(
        `^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$`
    );

    if (reg.test(data)) {
        return new Date(data);
    }
    return data;
}

export { appAxios };
