import { forwardRef, ForwardedRef, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type TDateInputProps = {
    error?: string;
    onChange?: (e: Date | null) => void;
    placeholder?: string;
    value?: Date;
    className?: string;
    disabled?: boolean;
    label?: string;
    focusOnError?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
};

const Input = forwardRef((props: TDateInputProps, ref: ForwardedRef<any>) => {
    const inputRef = useRef<DatePicker | any>(null);
    useEffect(() => {
        if (props.focusOnError && props.error) {
            inputRef?.current?.setFocus?.();
        }
    }, [props.error, props.focusOnError]);
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : undefined}
            <DatePicker
                selected={props.value}
                autoFocus={props.autoFocus}
                autoComplete={props.autoComplete}
                disabled={props.disabled}
                showTimeInput
                ref={(r: any) => {
                    inputRef.current = r;
                    if (typeof ref === "function") {
                        ref(r);
                    } else {
                        if (ref) {
                            ref.current = r;
                        }
                    }
                }}
                
                placeholderText={props.placeholder}
                className={`form-control ${props.className}`}
                onChange={(e) => props.onChange?.(e)}
            />

            {props.error ? (
                <div className="text-invalid-feedback mt-1">{props.error}</div>
            ) : undefined}
        </>
    );
});
export default Input;
