import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// import: assets
// import: styles
import "./index.scss";

// import: constants
import { ROOT } from "../../router/routes";

// import: enums
// import: types
// import: utils
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import { useAppDispatch } from "../../store/store-hooks";
import { gitLogin, googleLogin } from "../../store/slices/auth-slice";

// import: api
// import: config
// import: components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Spinner from "react-bootstrap/Spinner";
import LoadingSpinner from "../../components/loading-spinner";
import ErrorAlert from "../../components/error-alert";
import { Button } from "react-bootstrap";

export default function OAuthRedirectPage() {
    const { provider } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [queryParams] = useSearchParams();
    useEffect(() => {
        //get the code
        const code = queryParams.get("code");
        if (code) {
            //clear from url
            window.history.pushState(
                {},
                "",
                window.location.href.split("?")[0]
            );
            //login with code

            if (provider === "google") {
                dispatch(googleLogin(code))
                    .unwrap()
                    .then((resp) => {
                        if (resp) navigate(ROOT);
                    })
                    .catch((error) => {
                        setErrorMsg(HandleError(error)[""]);
                    });
            } else {
                dispatch(gitLogin(code))
                    .unwrap()
                    .then((resp) => {
                        if (resp) navigate(ROOT);
                    })
                    .catch((error) => {
                        setErrorMsg(HandleError(error)[""]);
                    });
            }
        }
    }, []);
    return (
        <div className="h-100 w-100">
            <Row className="d-flex flex-column justify-content-center h-100 w-100">
                <Col className="m-auto" md={6} lg={4}>
                    <div className="glass-card p-4 text-center">
                        {errorMsg ? (
                            <div className="">
                                <ErrorAlert
                                    errorMessage={errorMsg}
                                    show={true}
                                />
                                <Button
                                    onClick={() => navigate(ROOT)}
                                    className="mt-5 mx-auto rounded-pill"
                                >
                                    Back To Home
                                </Button>
                            </div>
                        ) : (
                            <>
                                <h4>Please wait</h4>
                                <LoadingSpinner inLine show={true} />
                                <p className="mt-3">Verifying your account</p>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
}
