import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RichTextEditor from "../../components/richtext-editor";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import FileInput from "../../components/input/file-input";
import Input from "../../components/input/input";
import { loadDatasets } from "../../store/slices/dataset-slice";
import { HandleError } from "../../errors/handler";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import LoadingSpinner from "../../components/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faAdd,
    faTimes,
    faClose,
    faSubtract,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import InputSelect from "../../components/input/input-select";
import DateInput from "../../components/input/date-input";
import { IDataset } from "../../types/dataset-types";
import { createCompetition } from "../../store/slices/competition-slice";
import Modal from "react-bootstrap/Modal";
import CreateDatasetPage from "../dataset/create-dataset";
import { PrizeType, CompetitionMembershipType, EditAccessType } from "../../enums/prize-type";
import {
    ICreateCompetitionPayload,
    IPrizeDetail,
    TMembers,
    ITeamRules
} from "../../types/competition-types";
import competitionTypes from "../../components/category-icons/types.json";
import { loadOrganizations } from "../../store/slices/organization-slice";
import { IOrganization } from "../../types/organization-types";
import { tryParseJSONObject } from "../../utils/helper-methods";
import Checkbox from "../../components/checkbox";


export default function CreateCompetitionPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const datasets = useAppSelector((s) => s.dataset.pageData.data);
    const loadingDatasets = useAppSelector((s) => s.dataset.fetching);
    //control refs
    const formRef = useRef<HTMLFormElement>(null);
    const titleInputRef = useRef<HTMLInputElement>(null);
    //form states
    const [formErrors, setFormErrors] = useState<any>({});
    const [title, setTitle] = useState<string>("");
    const [subTitle, setSubTitle] = useState<string>("");
    const [overview, setOverview] = useState<string>("");
    const [dataset_id, setDataset_id] = useState<string[]>([""]);
    const [dataset_dependencies, setDataset_dependencies] = useState<string>("");
    
    const [banner, setBanner] = useState<File>();
    const [startDate, setStartDate] = useState<Date>();
    // const [organizationList, setOrganizationList] = useState<string[]>();
    const organizations = useAppSelector((s) => s.organization.pageData.data);
    const [orgSearch, setOrgSearch] = useState<string>("");
    const [orgsToAdd, setOrgsToAdd] = useState<IOrganization[]>([]);
    
    const [endDate, setEndDate] = useState<Date>();
    const [registerByDate, setRegisterByDate] = useState<Date>();
    
    const [rules, setRules] = useState<string>("");
    const [type, setType] = useState<string>("");

    const [pqDelta, setPqDelta] = useState<number>(0);
    const [pqEps, setPqEps] = useState<number>(0);
    const [maxDelta, setMaxDelta] = useState<number>(0);
    const [maxEps, setMaxEps] = useState<number>(0);
    const [metrics, setMetrics] = useState<string>("");

    const [participantType, setParticipantType] = useState<CompetitionMembershipType>(CompetitionMembershipType.INDIVIDUAL);
    const [teamRules, setTeamRules] = useState<ITeamRules>();
    const [invitedMembersOnly, setInvitedMembersOnly] = useState<boolean>(false);
    const [invitedMembersString, setInvitedMembersString] = useState<string>("[]");
    // const [invitedMembers, setInvitedMembers] = useState<TMembers[]>();
    const [privateLeaderboard, setPrivateLeaderboard] = useState<boolean>(false);


    const [externalDataset, setExternalDtaset] = useState<boolean>(false);
    const [datasetDetails, setDatasetDetails] = useState<string>("");


    const [prizeDetail, setPrizeDetail] = useState<IPrizeDetail>({
        prizeType: PrizeType.None,
        detail: "",
        prizes: [],
        termsAndConditions: "",
    });

    const [createNewDataset, setCreateNewDataset] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);


    // FETCHING ORGANIZATIONS ON SEARCH STRING UPDATE
    useEffect(() => {
        if (orgSearch.trim().length > 3 ) {
            let delay = setTimeout(() => {
                dispatch(loadOrganizations({ page: 1, size: 7, title: orgSearch }))
            }, 1000);

            return () => clearTimeout(delay);
        }
    }, [orgSearch]);

    // ADDING DATASETS TO LIST OF SUBMISSION
    const addOrgToList = (org: IOrganization) => {
        let orgHolder = [...orgsToAdd];
        orgHolder.push(org);
        setOrgsToAdd(orgHolder);
        setOrgSearch("");
    };

    // REMOVING ORGANIZATIONS FROM LIST OF SUBMISSION
    const removeOrgsFromList = (i: number) => {
        let holder = [...orgsToAdd];
        holder.splice(i, 1);
        setOrgsToAdd(holder);
    }

    useEffect(() => {
        if (!datasets?.length) {
            dispatch(loadDatasets({ page: 1, size: 10000 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const datasetCreated = () => {
        setCreateNewDataset(false);
        dispatch(loadDatasets({ page: 1, size: 10000 }));
        setDataset_id([""]);
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();

            if (validateForm()) {
                setLoading(true);
                const obj = {
                    title,
                    subTitle,
                    overview,
                    banner,
                    startDate: startDate!.toUTCString(),
                    endDate: endDate!.toUTCString(),
                    dataset_id,
                    type,
                    rules,
                    pqDelta: pqDelta,
                    pqEps: pqEps,
                    maxDelta: maxDelta,
                    maxEps: maxEps,
                    metrics,
                    dataset_dependencies: dataset_dependencies || "{}",
                    participantType,
                    teamRules,
                    privateLeaderboard,
                    registerByDate: (registerByDate || endDate)!.toUTCString(),
                    invitedMembersOnly,
                    invitedMembers: JSON.parse(invitedMembersString || "[]"),
                    externalDataset,
                    datasetDetails: externalDataset ? datasetDetails : undefined,
                } as ICreateCompetitionPayload;
                if (orgsToAdd?.length) {
                    obj.organizations = orgsToAdd.map((org) => org._id);
                }
                if (
                    prizeDetail?.prizeType &&
                    prizeDetail?.prizeType !== PrizeType.None
                ) {
                    obj.prizeDetail = prizeDetail;
                }
                await dispatch(createCompetition(obj)).unwrap();
                dispatch(
                    showInfoModal({
                        title: "Created",
                        message: "Competition created succcessfully.",
                    })
                );
                resetForm();
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const validateForm: () => boolean = () => {
        setFormErrors({});
        const errors: any = {};

        if (!title) {
            errors["title"] = "Please write dataset title.";
        }

        if (!subTitle) {
            errors["subTitle"] = "Please write subtitle.";
        }

        if (!pqDelta || isNaN(Number(pqDelta))) {
            errors["pqDelta"] = "Per query delta needs to be a number.";
        }

        if (!maxDelta || isNaN(Number(maxDelta))) {
            errors["maxDelta"] = "Max delta needs to be a number.";
        }

        if (!pqEps || isNaN(Number(pqEps))) {
            errors["pqEps"] = "Per query epsilon needs tobe a number.";
        }

        if (!maxEps || isNaN(Number(maxEps))) {
            errors["maxEps"] = "Max epsilon needs tobe a number.";
        }

        if (!metrics || !tryParseJSONObject(metrics)) {
            errors["metrics"] = "Metrics needs tobe a valid json.";
        }

        if (!tryParseJSONObject(dataset_dependencies)){
            errors["dt_dep"] = "dataset dependencies needs tobe a valid json.";
        }

        if(participantType === CompetitionMembershipType.TEAM){
            if (!registerByDate) {
                setRegisterByDate(endDate);
            }

            if(teamRules){
                if(teamRules.minMembers > teamRules?.maxMembers){
                    errors["maxMembers"] = "Max members can't be lesser than min members.";
                }
                if(teamRules.minMembers < 0){
                    errors["minMembers"] = "Min members can't be lesser than 0.";
                }
                if(!teamRules.editAccessType){
                    const holder = {...teamRules};
                    holder.editAccessType = EditAccessType.admin;
                    setTeamRules(holder);
                }
            }else{
                errors["minMembers"] = "Enter min team members.";
                errors["maxMembers"] = "Enter max team members.";
            }

            if(invitedMembersOnly === true){
                if(!invitedMembersString || !tryParseJSONObject(invitedMembersString)){
                    errors["invitedMembersString"] = "invited Members array is not a valid json.";
                }else{
                    const members = JSON.parse(invitedMembersString);
                    members.forEach((member : TMembers, id : number) => {
                        if(!member.email && !errors["invitedMembersString"]){
                            errors["invitedMembersString"] = `member ${id+1} is not valid, please give an email`;
                        }
                    })
                }
            }
        }

        // if (!dataset) {
        //     errors["dataset"] = "Please select dataset.";
        // }

        if (dataset_id) {
            dataset_id.length > 0 &&
            dataset_id.forEach((dat, id) => {
                    if (dataset_id[id] === "" || dataset_id[id] === undefined) {
                        errors["dataset_id"] ??= {};
                        errors["dataset_id"][id.toString()] =
                            "Please select dataset.";
                    }
                });
        }

        if (!type) {
            errors["type"] = "Please select competition type.";
        }

        if (!startDate) {
            errors["startDate"] = "Please select start date.";
        }

        if (!endDate) {
            errors["endDate"] = "Please select end date.";
        }

        if (
            prizeDetail?.prizeType &&
            prizeDetail.prizeType !== PrizeType.None
        ) {
            if (prizeDetail?.prizes?.length) {
                prizeDetail.prizes.forEach((p, i) => {
                    if (!p.amount) {
                        errors["prizeDetail"] ??= {};
                        errors["prizeDetail"]["prizes"] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()][
                            "amount"
                        ] = "Please enter amount.";
                    }
                    if (!p.position) {
                        errors["prizeDetail"] ??= {};
                        errors["prizeDetail"]["prizes"] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()][
                            "position"
                        ] = "Please enter position.";
                    }
                });
            }
        }
        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const resetForm = () => {
        setTitle("");
        setSubTitle("");
        setOverview("");
        setDataset_id([""]);
        setBanner(undefined);
        setStartDate(undefined);
        setEndDate(undefined);
        setFormErrors({});
        setRules("");
        setType("");
        // setOrganizationList([]);
        setOrgsToAdd([]);
        setPrizeDetail({
            prizeType: PrizeType.None,
            detail: "",
            prizes: [],
            termsAndConditions: "",
        });
        setPqDelta(0);
        setPqEps(0);
        setMaxDelta(0);
        setMaxEps(0);
        setMetrics("");
        setDataset_dependencies("");
        titleInputRef?.current?.focus();
    };

    const addNewDataset = () => {
        dataset_id.push("");
        setDataset_id([...dataset_id]);
    };
    const removeDataset = () => {
        if (dataset_id.length > 1) {
            setDataset_id(dataset_id.slice(0, -1));
        }
    };

    const addNewPrize = () => {
        if (prizeDetail?.prizes?.length) {
            prizeDetail.prizes.push({
                amount: "",
                detail: "",
                position: "",
            });
            setPrizeDetail({ ...prizeDetail });
        } else if (prizeDetail) {
            prizeDetail.prizes = [
                {
                    amount: "",
                    detail: "",
                    position: "",
                },
            ];
            setPrizeDetail({ ...prizeDetail });
        }
    };

    return (
        <div>
            <div className="d-flex flex-row align-content-center">
                <FontAwesomeIcon
                    size="lg"
                    className="mx-3 cursor-pointer"
                    onClick={() => navigate(-1)}
                    icon={faArrowLeft}
                />
                <h4>Create Competition</h4>
            </div>

            <Card className="bg-primary">
                <Card.Body>
                    <Form
                        onReset={resetForm}
                        ref={formRef}
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <Form.Group>
                            <Input
                                ref={titleInputRef}
                                autoFocus
                                focusOnError={true}
                                label="Title"
                                placeholder="Competition Title"
                                value={title}
                                onChange={(e) => {
                                    if (formErrors["title"]) {
                                        delete formErrors.title;
                                        setFormErrors(formErrors);
                                    }
                                    setTitle(e.target.value);
                                }}
                                error={formErrors["title"]}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Input
                                label="Subtitle"
                                focusOnError={true}
                                placeholder="Competition Subtitle"
                                value={subTitle}
                                onChange={(e) => {
                                    if (formErrors["subTitle"]) {
                                        delete formErrors.subTitle;
                                        setFormErrors(formErrors);
                                    }
                                    setSubTitle(e.target.value);
                                }}
                                error={formErrors["subTitle"]}
                            />
                        </Form.Group>

                        <div className="d-flex flex-wrap">
                            <Form.Group className="mt-3 col-5 me-4">
                                <Input
                                    label="Per querry delta"
                                    focusOnError={true}
                                    placeholder="Per querry delta"
                                    value={pqDelta?.toString()}
                                    onChange={(e) => {
                                        if (formErrors["pqDelta"]) {
                                            delete formErrors.pqDelta;
                                            setFormErrors(formErrors);
                                        }

                                        if (!isNaN(Number(e.target.value)))
                                            setPqDelta(Number(e.target.value));
                                    }}
                                    error={formErrors["pqDelta"]}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3 col-5">
                                <Input
                                    label="Max delta"
                                    focusOnError={true}
                                    placeholder="Max delta"
                                    value={maxDelta + ""}
                                    onChange={(e) => {
                                        if (formErrors["maxDelta"]) {
                                            delete formErrors.maxDelta;
                                            setFormErrors(formErrors);
                                        }
                                        if (!isNaN(Number(e.target.value)))
                                            setMaxDelta(Number(e.target.value));
                                    }}
                                    error={formErrors["maxDelta"]}
                                />
                            </Form.Group>
                        </div>

                        <div className="d-flex flex-wrap">
                            <Form.Group className="mt-3 col-5 me-4">
                                <Input
                                    label="Per querry Epsilon"
                                    focusOnError={true}
                                    placeholder="Per querry Epsilon"
                                    value={pqEps + ""}
                                    onChange={(e) => {
                                        if (formErrors["pqEps"]) {
                                            delete formErrors.pqEps;
                                            setFormErrors(formErrors);
                                        }
                                        if (!isNaN(Number(e.target.value)))
                                            setPqEps(Number(e.target.value));
                                    }}
                                    error={formErrors["pqEps"]}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3 col-5">
                                <Input
                                    label="Max Epsilon"
                                    focusOnError={true}
                                    placeholder="Max Epsilon"
                                    value={maxEps + ""}
                                    onChange={(e) => {
                                        if (formErrors["maxEps"]) {
                                            delete formErrors.maxEps;
                                            setFormErrors(formErrors);
                                        }
                                        if (!isNaN(Number(e.target.value)))
                                            setMaxEps(Number(e.target.value));
                                    }}
                                    error={formErrors["maxEps"]}
                                />
                            </Form.Group>
                        </div>

                        <Form.Group className="mt-3">
                            <Input
                                label="Metrices"
                                asTextArea
                                focusOnError={true}
                                placeholder="Enter metrices json data"
                                value={metrics}
                                onChange={(e) => {
                                    if (formErrors["metrics"]) {
                                        delete formErrors.metrics;
                                        setFormErrors(formErrors);
                                    }
                                    setMetrics(e.target.value);
                                }}
                                error={formErrors["metrics"]}
                            />
                        </Form.Group>

                        <Form.Group className="d-flex flex-wrap align-items-end mt-3">
                            {dataset_id.map((ds, i) => (
                                <Col
                                    className="col-12 mb-3"
                                    lg={10}
                                    key={"dataset" + i}
                                >
                                    <InputSelect
                                        isLoading={loadingDatasets}
                                        loadingMessage="Loading datasets"
                                        labelKey="title"
                                        valueKey="_id"
                                        focusOnError={true}
                                        onChange={(val: IDataset) => {
                                            if (formErrors["dataset_id"]?.[i.toString()]) {
                                                delete formErrors.dataset_id?.[i.toString()];
                                                setFormErrors(formErrors);
                                            }
                                            let holder = [...dataset_id];
                                            holder[i] = val?._id;
                                            setDataset_id(holder);
                                        }}
                                        label={"Dataset " + i}
                                        placeholder="Select Dataset"
                                        value={datasets?.find(
                                            (x) => x._id === ds
                                        )}
                                        options={datasets}
                                        error={formErrors["dataset_id"]?.[i.toString()]}
                                    />
                                </Col>
                            ))}
                        </Form.Group>

                        <Form.Group className="d-flex gap-4">
                            <Button onClick={() => addNewDataset()}>
                                <FontAwesomeIcon
                                    icon={faAdd}
                                    className="me-1"
                                />{" "}
                                Add Dataset
                            </Button>

                            {dataset_id.length > 1 && (
                                <Button onClick={() => removeDataset()}>
                                    <FontAwesomeIcon
                                        icon={faSubtract}
                                        className="me-1"
                                    />{" "}
                                    Delete Dataset
                                </Button>
                            )}

                            <Button
                                className="ms-auto"
                                onClick={() => setCreateNewDataset(true)}
                            >
                                <FontAwesomeIcon
                                    className="me-1"
                                    onClick={() => navigate(-1)}
                                    icon={faAdd}
                                />
                                Add new
                            </Button>
                        </Form.Group>
                        
                        <Form.Group className="mt-4">
                            <Checkbox 
                                placeholder="External Dataset" 
                                onChange={setExternalDtaset} 
                                value={externalDataset}
                                className={`${externalDataset ? "mb-3" : ""}`}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Input
                                label="Dataset Dependencies"
                                asTextArea
                                focusOnError={true}
                                placeholder="Enter Dataset dependencies in json format eg: { dataset_id: ['ds1_id', 'ds2_id'], ... } "
                                value={dataset_dependencies}
                                onChange={(e) => {
                                    if (formErrors["dt_dep"]) {
                                        delete formErrors.dt_dep;
                                        setFormErrors(formErrors);
                                    }
                                    setDataset_dependencies(e.target.value);
                                }}
                                error={formErrors["dt_dep"]}
                            />
                        </Form.Group>

                        {externalDataset ? <Form.Group>
                            <Form.Label>Dataset Details</Form.Label>
                            <RichTextEditor
                                value={datasetDetails}
                                onChange={(val) => {
                                    setDatasetDetails(val);
                                }}
                            />
                        </Form.Group> : <></>}

                        <Form.Group className="mt-3">
                            <Row>
                                <Col sm={6} lg={4}>
                                    <InputSelect
                                        loadingMessage="Loading types"
                                        focusOnError={true}
                                        onChange={(val: any) => {
                                            if (formErrors["type"]) {
                                                delete formErrors.type;
                                                setFormErrors(formErrors);
                                            }
                                            if (val?.value) setType(val.value);
                                            else setType("");
                                        }}
                                        labelKey="label"
                                        valueKey="value"
                                        label="Competition Type"
                                        placeholder="Select Type"
                                        value={competitionTypes?.find(
                                            (x) => x.value === type
                                        )}
                                        options={competitionTypes}
                                        error={formErrors["type"]}
                                    />
                                </Col>
                                <Col sm={6} lg={4}>
                                    <DateInput
                                        focusOnError={true}
                                        value={startDate}
                                        onChange={(d) => {
                                            if (formErrors["startDate"]) {
                                                delete formErrors.startDate;
                                                setFormErrors(formErrors);
                                            }
                                            setStartDate(d ?? undefined);
                                        }}
                                        label="Start Date"
                                        placeholder="Select Start Date"
                                        error={formErrors["startDate"]}
                                    />
                                </Col>
                                <Col sm={6} lg={4}>
                                    <DateInput
                                        focusOnError={true}
                                        value={endDate}
                                        onChange={(d) => {
                                            if (formErrors["endDate"]) {
                                                delete formErrors.endDate;
                                                setFormErrors(formErrors);
                                            }
                                            setEndDate(d ?? undefined);
                                        }}
                                        label="End Date"
                                        placeholder="Select End Date"
                                        error={formErrors["endDate"]}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="my-4">
                            <Row className="flex-wrap-reverse">
                                <Col sm={12} lg={6}>
                                    <InputSelect
                                        labelKey="label"
                                        valueKey="value"
                                        focusOnError={true}
                                        onChange={(val: any) => {
                                            if (formErrors["participantType"]) {
                                                delete formErrors.participantType;
                                                setFormErrors(formErrors);
                                            }
                                            setParticipantType( val?.value ?? CompetitionMembershipType.INDIVIDUAL );
                                        }}
                                        value={
                                            Object.keys(CompetitionMembershipType)
                                                .map((x) => ({
                                                    label: x,
                                                    value: (
                                                        CompetitionMembershipType as any
                                                    )[x],
                                                }))
                                                ?.find(
                                                    (x) => x.value === participantType
                                                ) ?? CompetitionMembershipType.INDIVIDUAL
                                        }
                                        label="Participant Type"
                                        placeholder="Select Participant Type"
                                        options={Object.keys(CompetitionMembershipType).map(
                                            (x) => ({
                                                label: x,
                                                value: (CompetitionMembershipType as any)[x],
                                            })
                                        )}
                                        error={formErrors["participantType"]}
                                    />
                                </Col>
                                <Col sm={12} lg={6} className="d-flex mb-lg-0 mb-3 align-items-end">
                                    <Checkbox 
                                        placeholder="Private Leaderboard" 
                                        onChange={setPrivateLeaderboard} 
                                        value={privateLeaderboard}
                                    />
                                </Col>
                            </Row>
                            {participantType === CompetitionMembershipType.TEAM ? 
                                <Row className="mt-3">
                                    <Col sm={12} lg={6}>
                                        <InputSelect
                                            label="Edit access type"
                                            placeholder="Select edit access type"
                                            
                                            labelKey="label"
                                            valueKey="value"
                                            focusOnError={true}
                                            onChange={(val: any) => {
                                                setTeamRules({
                                                    editAccessType: val?.value  || EditAccessType.admin,
                                                    maxMembers: teamRules?.maxMembers || 0, 
                                                    minMembers: teamRules?.minMembers || 0
                                                });
                                            }}
                                            value={
                                                Object.keys(EditAccessType)
                                                    .map((x) => ({
                                                        label: x,
                                                        value: (
                                                            EditAccessType as any
                                                        )[x],
                                                    }))
                                                    ?.find(
                                                        (x) => x.value === teamRules?.editAccessType
                                                    ) ?? EditAccessType.admin
                                            }
                                            options={Object.keys(EditAccessType).map(
                                                (x) => ({
                                                    label: x,
                                                    value: (EditAccessType as any)[x],
                                                })
                                            )}
                                            error={formErrors["participantType"]}
                                        />
                                    </Col>

                                    <Col sm={12} lg={6}>
                                        <DateInput
                                            focusOnError={true}
                                            value={registerByDate}
                                            onChange={(d) => {
                                                if (formErrors["registerByDate"]) {
                                                    delete formErrors.registerByDate;
                                                    setFormErrors(formErrors);
                                                }
                                                setRegisterByDate(d ?? undefined);
                                            }}
                                            label="Register by Date"
                                            placeholder="Select Register by Date"
                                            error={formErrors["registerByDate"]}
                                        />
                                    </Col>

                                    <Col sm={12} lg={6}>
                                        <Input
                                            label="Min Members"
                                            focusOnError={true}
                                            placeholder="Min Members"
                                            value={(teamRules?.minMembers || 0) + ""}
                                            onChange={(e) => {
                                                if (formErrors["minMembers"]) {
                                                    delete formErrors.minMembers;
                                                    setFormErrors(formErrors);
                                                }
                                                if (!isNaN(Number(e.target.value)))
                                                setTeamRules({
                                                    editAccessType: teamRules?.editAccessType  || EditAccessType.admin, 
                                                    maxMembers: teamRules?.maxMembers || 0, 
                                                    minMembers: (Number(e.target.value))
                                                });
                                            }}
                                            error={formErrors["minMembers"]}
                                        />
                                    </Col>

                                    <Col sm={12} lg={6}>
                                        <Input
                                            label="Max Members"
                                            focusOnError={true}
                                            placeholder="Max Members"
                                            value={(teamRules?.maxMembers || 0) + ""}
                                            onChange={(e) => {
                                                if (formErrors["maxMembers"]) {
                                                    delete formErrors.maxMembers;
                                                    setFormErrors(formErrors);
                                                }
                                                if (!isNaN(Number(e.target.value)))
                                                setTeamRules({
                                                    editAccessType: teamRules?.editAccessType  || EditAccessType.admin, 
                                                    minMembers: teamRules?.minMembers || 0, 
                                                    maxMembers: (Number(e.target.value))
                                                });
                                            }}
                                            error={formErrors["maxMembers"]}
                                        />
                                    </Col>

                                    <div className="mt-4">
                                        <Checkbox 
                                            placeholder="Invited members Only" 
                                            onChange={setInvitedMembersOnly} 
                                            value={invitedMembersOnly}
                                            className={`${invitedMembersOnly ? "mb-3" : ""}`}
                                        />

                                        {invitedMembersOnly === true ? <Input
                                            label="Invited Members"
                                            asTextArea
                                            focusOnError={true}
                                            placeholder="Enter invited members json array"
                                            value={invitedMembersString}
                                            onChange={(e) => {
                                                if (formErrors["invitedMembersString"]) {
                                                    delete formErrors.invitedMembersString;
                                                    setFormErrors(formErrors);
                                                }
                                                setInvitedMembersString(e.target.value);
                                            }}
                                            error={formErrors["invitedMembersString"]}
                                        /> : <></>}
                                    </div>
                                    
                                </Row> : <></>
                            }
                        </Form.Group>

                        <Form.Group className="is-invalid">
                            <FileInput
                                selectedFiles={banner ? [banner] : []}
                                accept={{
                                    "image/*": [],
                                }}
                                height={180}
                                width={270}
                                placeholder="Upload Banner Image"
                                error={formErrors["banner"]}
                                onDrop={(files) => {
                                    if (formErrors["banner"]) {
                                        delete formErrors.banner;
                                        setFormErrors(formErrors);
                                    }
                                    if (files?.length) {
                                        setBanner(files[0]);
                                    } else {
                                        setBanner(undefined);
                                    }
                                }}
                                containerClass="text-white"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Overview</Form.Label>
                            <RichTextEditor
                                value={overview}
                                onChange={(val) => {
                                    if (formErrors["overview"]) {
                                        delete formErrors.overview;
                                        setFormErrors(formErrors);
                                    }
                                    setOverview(val);
                                }}
                            />
                            {formErrors["overview"] ? (
                                <div className="text-invalid-feedback mt-1">
                                    {formErrors["overview"]}
                                </div>
                            ) : undefined}
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Rules</Form.Label>
                            <RichTextEditor
                                value={rules}
                                onChange={(val) => {
                                    if (formErrors["rules"]) {
                                        delete formErrors.rules;
                                        setFormErrors(formErrors);
                                    }
                                    setRules(val);
                                }}
                            />
                            {formErrors["rules"] ? (
                                <div className="text-invalid-feedback mt-1">
                                    {formErrors["rules"]}
                                </div>
                            ) : undefined}
                        </Form.Group>
                        
                        {/* ORGANIZERS REMOVED AND REPLACED WITH ORGANIZATIONS */}
                        {/* <fieldset className="mt-4">
                            <legend>Organizers</legend>
                            <Row className="g-3">
                                {organizers?.map((o, i) => (
                                    <Col
                                        key={i}
                                        xs={12}
                                        md={4}
                                        className="d-flex flex-row"
                                    >
                                        <Input
                                            autoFocus
                                            focusOnError={true}
                                            placeholder=""
                                            value={o}
                                            onChange={(e) => {
                                                organizers[i] = e.target.value;
                                                setOrganizers([...organizers]);
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                organizers.splice(i, 1);
                                                setOrganizers([...organizers]);
                                            }}
                                            variant="outlined"
                                        >
                                            <FontAwesomeIcon
                                                icon={faClose}
                                                color="red"
                                            />
                                        </Button>
                                    </Col>
                                ))}
                                <Col
                                    key={"_2"}
                                    xs={1}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "end",
                                    }}
                                >
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            if (!organizers) {
                                                setOrganizers([""]);
                                            } else {
                                                organizers.push("");
                                                setOrganizers([...organizers]);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faAdd} /> Add
                                    </Button>
                                </Col>
                            </Row>
                        </fieldset> */}

                        <Form.Group className="dropdown-parent my-4">
                            <Input
                                autoFocus
                                focusOnError={true}
                                label="Search organizations by name"
                                placeholder="Organization Name"
                                className=""
                                value={orgSearch}
                                onChange={(e) =>  setOrgSearch(e.target.value)}
                            />
                            {orgSearch && organizations.length > 0 ? <div className="dropdown-holder glazed-card-cover radius-base">
                                <div className="glazed-card radius-base py-2">
                                    {organizations.map((org, idx) => 
                                        <p key={"ListItm" + idx} className="dropdown-item text-color-tertiary text-xs cursor-pointer py-2 px-3 mb-0" onClick={() => addOrgToList(org)}>
                                            {org.title}
                                        </p>
                                    )}
                                </div>
                            </div>: <></>}
                        </Form.Group>

                        <div className="org-container">
                            {orgsToAdd.length > 0 ? orgsToAdd.map((org, id) => 
                                <p key={"selected" + id} 
                                    className="d-flex justify-content-between align-items-center text-color-tertiary text-sm px-2 w-100">
                                    <span>{org.title}</span>
                                    <FontAwesomeIcon icon={faClose} className="feature-icon"
                                        title="Remove" size="sm"
                                        onClick={() => removeOrgsFromList(id)}
                                    />
                                </p>
                            ) : <></>}
                        </div>


                        <fieldset className="mt-3">
                            <legend>Prize</legend>
                            <Row>
                                <Col sm={6} lg={4}>
                                    <Form.Group>
                                        <InputSelect
                                            labelKey="label"
                                            valueKey="value"
                                            focusOnError={true}
                                            onChange={(val: any) => {
                                                if (formErrors["prizeDetail"]) {
                                                    delete formErrors.prizeDetail;
                                                    setFormErrors(formErrors);
                                                }
                                                setPrizeDetail({
                                                    ...prizeDetail,
                                                    prizeType:
                                                        val?.value ??
                                                        PrizeType.None,
                                                });
                                            }}
                                            value={
                                                Object.keys(PrizeType)
                                                    .map((x) => ({
                                                        label: x,
                                                        value: (
                                                            PrizeType as any
                                                        )[x],
                                                    }))
                                                    ?.find(
                                                        (x) =>
                                                            x.value ===
                                                            prizeDetail?.prizeType
                                                    ) ?? ""
                                            }
                                            label="Prize Type"
                                            placeholder="Select Prize Type"
                                            options={Object.keys(PrizeType).map(
                                                (x) => ({
                                                    label: x,
                                                    value: (PrizeType as any)[
                                                        x
                                                    ],
                                                })
                                            )}
                                            error={
                                                formErrors[
                                                    "prizeDetail"
                                                ]?.children?.find(
                                                    (x: any) =>
                                                        x.field === "type"
                                                )?.errors[0]
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {prizeDetail?.prizeType &&
                            prizeDetail?.prizeType !== PrizeType.None ? (
                                <>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Prize Detail</Form.Label>
                                        <RichTextEditor
                                            value={prizeDetail?.detail}
                                            onChange={(val) => {
                                                if (
                                                    formErrors["prizeDetail"]?.[
                                                        "detail"
                                                    ]
                                                ) {
                                                    delete formErrors[
                                                        "prizeDetail"
                                                    ]?.detail;
                                                    setFormErrors(formErrors);
                                                }
                                                prizeDetail.detail = val;
                                                setPrizeDetail({
                                                    ...prizeDetail,
                                                });
                                            }}
                                        />
                                        {formErrors["prizeDetail"]?.[
                                            "detail"
                                        ] ? (
                                            <div className="text-invalid-feedback mt-1">
                                                {
                                                    formErrors["prizeDetail"]?.[
                                                        "detail"
                                                    ]
                                                }
                                            </div>
                                        ) : undefined}
                                    </Form.Group>

                                    <Form.Group className="mt-3">
                                        <Form.Label>
                                            Terms & Conditions
                                        </Form.Label>
                                        <RichTextEditor
                                            value={
                                                prizeDetail?.termsAndConditions
                                            }
                                            onChange={(val) => {
                                                if (
                                                    formErrors["prizeDetail"]?.[
                                                        "termsAndConditions"
                                                    ]
                                                ) {
                                                    delete formErrors[
                                                        "prizeDetail"
                                                    ]?.termsAndConditions;
                                                    setFormErrors(formErrors);
                                                }
                                                prizeDetail.termsAndConditions =
                                                    val;
                                                setPrizeDetail({
                                                    ...prizeDetail,
                                                });
                                            }}
                                        />
                                        {formErrors["prizeDetail"]?.[
                                            "termsAndConditions"
                                        ] ? (
                                            <div className="text-invalid-feedback mt-1">
                                                {
                                                    formErrors["prizeDetail"]?.[
                                                        "termsAndConditions"
                                                    ]
                                                }
                                            </div>
                                        ) : undefined}
                                    </Form.Group>

                                    {prizeDetail?.prizes?.map((p, i) => (
                                        <Row key={i} className="mt-3">
                                            <Col sm={6} lg={4}>
                                                <Form.Group>
                                                    <Input
                                                        label="Position"
                                                        focusOnError={true}
                                                        placeholder="Position"
                                                        value={p.position}
                                                        onChange={(e) => {
                                                            if (
                                                                formErrors[
                                                                    "prizeDetail"
                                                                ]?.prizes?.[i]
                                                                    ?.position
                                                            ) {
                                                                delete formErrors[
                                                                    "prizeDetail"
                                                                ]?.prizes?.[i]
                                                                    ?.position;
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                });
                                                            }
                                                            p.position =
                                                                e.target.value;
                                                            setPrizeDetail({
                                                                ...prizeDetail,
                                                            });
                                                        }}
                                                        error={
                                                            formErrors[
                                                                "prizeDetail"
                                                            ]?.prizes?.[i]
                                                                ?.position
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={5} lg={3}>
                                                <Form.Group>
                                                    <Input
                                                        label="Amount"
                                                        focusOnError={true}
                                                        placeholder="Prize Amount"
                                                        value={p.amount}
                                                        onChange={(e) => {
                                                            if (
                                                                formErrors[
                                                                    "prizeDetail"
                                                                ]?.prizes?.[i]
                                                                    ?.amount
                                                            ) {
                                                                delete formErrors[
                                                                    "prizeDetail"
                                                                ]?.prizes?.[i]
                                                                    ?.amount;
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                });
                                                            }
                                                            p.amount =
                                                                e.target.value;
                                                            setPrizeDetail({
                                                                ...prizeDetail,
                                                            });
                                                        }}
                                                        error={
                                                            formErrors[
                                                                "prizeDetail"
                                                            ]?.prizes?.[i]
                                                                ?.amount
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} lg={4}>
                                                <Form.Group>
                                                    <Input
                                                        label="Detail / Perks"
                                                        focusOnError={true}
                                                        placeholder="Details"
                                                        value={p.detail}
                                                        onChange={(e) => {
                                                            if (
                                                                formErrors[
                                                                    "prizeDetail"
                                                                ]?.prizes?.[i]
                                                                    ?.detail
                                                            ) {
                                                                delete formErrors[
                                                                    "prizeDetail"
                                                                ]?.prizes?.[i]
                                                                    ?.detail;
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                });
                                                            }
                                                            p.detail =
                                                                e.target.value;
                                                            setPrizeDetail({
                                                                ...prizeDetail,
                                                            });
                                                        }}
                                                        error={
                                                            formErrors[
                                                                "prizeDetail"
                                                            ]?.prizes?.[i]
                                                                ?.detail
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                sm={1}
                                                lg={1}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "end",
                                                }}
                                            >
                                                <Button
                                                    title="Remove"
                                                    onClick={() => {
                                                        prizeDetail?.prizes?.splice(
                                                            i,
                                                            1
                                                        );
                                                        setPrizeDetail({
                                                            ...prizeDetail,
                                                        });
                                                    }}
                                                    variant="outlined"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTimes}
                                                        color="red"
                                                    />
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Form.Group className="mt-3">
                                        <Button
                                            onClick={() => {
                                                addNewPrize();
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faAdd} /> Add
                                            Prize
                                        </Button>
                                    </Form.Group>
                                </>
                            ) : (
                                <></>
                            )}
                        </fieldset>

                        {formErrors[""] ? (
                            <div className="text-invalid-feedback m-2">
                                {formErrors[""]}
                            </div>
                        ) : undefined}

                        <Form.Group
                            className="mt-5 d-flex gap-3"
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button type="submit">Create</Button>
                            <Button type="reset" variant="secondary">
                                Reset
                            </Button>
                        </Form.Group>
                    </Form>
                </Card.Body>
                <LoadingSpinner show={loading} text={"Creating Competition"} />
            </Card>

            <Modal
                show={createNewDataset}
                onHide={() => setCreateNewDataset(false)}
                centered
                dialogClassName="login-modal dataset-create-modal"
            >
                <Modal.Header className="" closeButton>
                    <p className="text-lg">Create Dataset</p>
                </Modal.Header>
                <Modal.Body>
                    <CreateDatasetPage submitted={() => datasetCreated()} />
                </Modal.Body>
            </Modal>
        </div>
    );
}
