import av1 from "../../assets/avatars/av1.png";
import av2 from "../../assets/avatars/av2.png";
import av3 from "../../assets/avatars/av3.png";
import av4 from "../../assets/avatars/av4.png";
import av5 from "../../assets/avatars/av5.png";
import av6 from "../../assets/avatars/av6.png";
import av7 from "../../assets/avatars/av7.png";
import av8 from "../../assets/avatars/av8.png";
import av9 from "../../assets/avatars/av9.png";
import av10 from "../../assets/avatars/av10.png";
import av11 from "../../assets/avatars/av11.png";
import av12 from "../../assets/avatars/av12.png";
import av13 from "../../assets/avatars/av13.png";
import av14 from "../../assets/avatars/av14.png";
import av15 from "../../assets/avatars/av15.png";
import av16 from "../../assets/avatars/av16.png";
import av17 from "../../assets/avatars/av17.png";
import av18 from "../../assets/avatars/av18.png";
import av19 from "../../assets/avatars/av19.png";
import av20 from "../../assets/avatars/av20.png";
import av21 from "../../assets/avatars/av21.png";
import av22 from "../../assets/avatars/av22.png";
import av23 from "../../assets/avatars/av23.png";
import av24 from "../../assets/avatars/av24.png";
import av25 from "../../assets/avatars/av25.png";
import av26 from "../../assets/avatars/av26.png";
import av27 from "../../assets/avatars/av27.png";
import av28 from "../../assets/avatars/av28.png";
import av29 from "../../assets/avatars/av29.png";
import av30 from "../../assets/avatars/av30.png";
import av31 from "../../assets/avatars/av31.png";
import av32 from "../../assets/avatars/av32.png";
import av33 from "../../assets/avatars/av33.png";
import av34 from "../../assets/avatars/av34.png";
import av35 from "../../assets/avatars/av35.png";
import av36 from "../../assets/avatars/av36.png";
import av37 from "../../assets/avatars/av37.png";
import av38 from "../../assets/avatars/av38.png";
import av39 from "../../assets/avatars/av39.png";
import av40 from "../../assets/avatars/av40.png";
import av41 from "../../assets/avatars/av41.png";
import av42 from "../../assets/avatars/av42.png";
import av43 from "../../assets/avatars/av43.png";
import av44 from "../../assets/avatars/av44.png";
import av45 from "../../assets/avatars/av45.png";
import av46 from "../../assets/avatars/av46.png";
import av47 from "../../assets/avatars/av47.png";
import av48 from "../../assets/avatars/av48.png";
import av49 from "../../assets/avatars/av49.png";
import av50 from "../../assets/avatars/av50.png";
import av51 from "../../assets/avatars/av51.png";
import av52 from "../../assets/avatars/av52.png";
import av53 from "../../assets/avatars/av53.png";
import av54 from "../../assets/avatars/av54.png";
import av55 from "../../assets/avatars/av55.png";
import av56 from "../../assets/avatars/av56.png";
import av57 from "../../assets/avatars/av57.png";
import av58 from "../../assets/avatars/av58.png";
import av59 from "../../assets/avatars/av59.png";
import av60 from "../../assets/avatars/av60.png";

const Avatars = [
    av1,
    av2,
    av3,
    av4,
    av5,
    av6,
    av7,
    av8,
    av9,
    av10,
    av11,
    av12,
    av13,
    av14,
    av15,
    av16,
    av17,
    av18,
    av19,
    av20,
    av21,
    av22,
    av23,
    av24,
    av25,
    av26,
    av27,
    av28,
    av29,
    av30,
    av31,
    av32,
    av33,
    av34,
    av35,
    av36,
    av37,
    av38,
    av39,
    av40,
    av41,
    av42,
    av43,
    av44,
    av45,
    av46,
    av47,
    av48,
    av49,
    av50,
    av51,
    av52,
    av53,
    av54,
    av55,
    av56,
    av57,
    av58,
    av59,
    av60,
]

export default Avatars;