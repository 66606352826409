import Button from "react-bootstrap/Button";
import "./index.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { HandleError } from "../../errors/handler";
import Input from "../input/input";
import AuthApi from "../../api/auth-api";
type TChangePasswordModal = {
    show: boolean;
    onHide?: () => void;
    onPasswordChanged?: () => void;
};
export default function ChangePasswordModal(props: TChangePasswordModal) {
    const [loading, setLoading] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        reset();
    }, [props.show]);

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            if (validateForm()) {
                setLoading(true);
                await AuthApi.changePassword({
                    oldPassword,
                    password,
                });
                props.onPasswordChanged?.();
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const validateForm = () => {
        setFormErrors({});
        const errors: any = {};
        if (!oldPassword) {
            errors.oldPassword = "Please enter current password.";
        }
        if (!password || password.length < 6) {
            errors.password = "Password should be at least 6 character long.";
        }
        if (!confirmPassword) {
            errors.confirmPassword = "Please confirm password.";
        }
        if (confirmPassword !== password) {
            errors.confirmPassword = "Passwords does not match.";
        }
        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const reset = () => {
        setFormErrors({});
        setPassword("");
        setConfirmPassword("");
        setOldPassword("");
        setLoading(false);
    };

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide?.()}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <div>Change Password</div>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={submit} className="login-modal-form">
                    <fieldset disabled={loading}>
                        <div className="form-input-fields text-xs text-color-secondary">
                            <Form.Group className="mb-3">
                                <Input
                                    autoFocus
                                    autoComplete="new-password"
                                    value={oldPassword}
                                    label="Current Password"
                                    placeholder="Enter Your Current Password"
                                    maxLength={100}
                                    onChange={(e) => {
                                        if (formErrors["oldPassword"]) {
                                            delete formErrors?.oldPassword;
                                            setFormErrors(formErrors);
                                        }
                                        setOldPassword(e.target.value);
                                    }}
                                    focusOnError
                                    type="password"
                                    error={formErrors["oldPassword"]}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Input
                                    label="New Password"
                                    placeholder="Enter New Password"
                                    type="password"
                                    autoComplete="new-password"
                                    focusOnError
                                    value={password}
                                    error={formErrors.password}
                                    onChange={(e) => {
                                        if (formErrors["password"]) {
                                            delete formErrors.password;
                                            setFormErrors(formErrors);
                                        }
                                        setPassword(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="">
                                <Input
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    type="password"
                                    autoComplete=""
                                    focusOnError
                                    value={confirmPassword}
                                    error={formErrors.confirmPassword}
                                    onChange={(e) => {
                                        if (formErrors["confirmPassword"]) {
                                            delete formErrors.confirmPassword;
                                            setFormErrors(formErrors);
                                        }
                                        setConfirmPassword(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="my-2">
                                {formErrors[""] ? (
                                    <label className="text-danger">
                                        {formErrors[""]}
                                    </label>
                                ) : undefined}
                            </Form.Group>
                        </div>
                        <Button type="submit" className="mt-4 mx-auto rounded-pill">
                            {loading ? (
                                <>
                                    <FontAwesomeIcon spin className="me-2" icon={faSpinner} />{" "}
                                    Updating Password{" "}
                                </>
                            ) : (
                                "Change Password"
                            )}
                        </Button>
                    </fieldset>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
