import {useState} from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./index.scss";
import Avatars from './avatars';
import av1 from "../../assets/avatars/av1.png";
import Button from "react-bootstrap/Button";

export const AvatarCarousel = (props) => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
            slidesToSlide: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 2
        }
    };

    const makeImageAvatar = (avatar) => {
        fetch(avatar).then((response) => response.blob())
        .then((blob) => { 
            const file = new File([blob], 'AG_avatar.png', { type: 'image/png' });
            props.setImage(file);
        })
    }

    return (
        <div className={`${props.className || ""}`}>
            <Carousel 
                responsive={responsive}
                keyBoardControl={true}
                customTransition="all 0.75s"
                transitionDuration={500}
                containerClass=""
                removeArrowOnDeviceType={["tablet", "mobile"]}
                
                itemClass=""
            >   
                {Avatars.map((avatar, id) => 
                    <div key={"AG-avatar" + id} className='avatars-mini-cover'>
                        <div className='avatars-mini' onClick={() => makeImageAvatar(avatar)}
                            style={{
                                backgroundImage: `url(${avatar})`,
                            }}
                        >
                        </div>
                    </div>
                )}
            </Carousel>
        </div>
    )
}


export const AvatarSelector = (props) => {
    const [selected, setSelected] = useState(av1);

    const constructImageAvatar = () => {
        fetch(selected).then((response) => response.blob())
        .then((blob) => { 
            const file = new File([blob], 'AG_avatar.png', { type: 'image/png' });
            props.setImage(file);
            props.close();
        })        
    }
    
    return (
        <div className='d-flex flex-column align-items-center'>
            <div className="display-avatar"
                style={{
                    backgroundImage: `url(${selected})`,
                    borderRadius: 16,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}>    
            </div>
            <div className='form-input-fields mb-0'>
                <div className="maxim-avatar-container pb-sm-2">
                    {Avatars.map((avatar, id) => 
                        <div key={"AG-max-avatar" + id} className='avatars-mini-cover'>
                            <div className='avatar-tag' onClick={() => setSelected(avatar)}
                                style={{
                                    backgroundImage: `url(${avatar})`,
                                }}
                            >
                            </div>
                        </div>
                    )}
                </div>
            </div>
            
            <div className='d-flex mt-4 w-100'>
                <Button variant="primary" type="button" onClick={() => constructImageAvatar()} className="ms-auto w-fit me-3 w-100 rounded-pill">
                    Select
                </Button>
                <Button variant="secondary" type="button" onClick={() => props.close()} className="w-fit rounded-pill">
                    Cancel
                </Button>
            </div>
        </div>
    )
}