import { IOrganization } from "../../types/organization-types";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { timeElapsed } from "../../utils/helper-methods";
// import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
// import userTemplate from "../../assets/images/userTemplate.png";
// import dataset from "../../assets/icons/notebook.svg";
// import competition from "../../assets/icons/competitions.svg";
// import Dropdown from "react-bootstrap/Dropdown";
// import React from "react";
import "./index.scss";
import { OrganizationBanner } from "../../configs/api-config";
import AgLogo from "../../assets/logoMin.svg";
// import { useNavigate } from "react-router-dom";
// import { useAppSelector } from "../../store/store-hooks";

type TOrganizationListItemProps = {
    item: IOrganization;
    onClick?: () => void;
};
export default function OrganizationListItem(props: TOrganizationListItemProps) {
    return (
        <div className="slide_in_appear_top org-listing_row w-fit">
            <div className="org-item" 
                onClick={() => props?.onClick?.()}>
                <div className="listing-user-icon">
                    <img
                        src={OrganizationBanner(props.item._id)}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = AgLogo;
                        }}
                        className="listImage"
                        alt=""
                        width={92}
                        height={92}
                    />
                </div>
                <div className="d-flex flex-column">
                    <p className="listTitle">{props.item.title}</p>
                </div>
            </div>
        </div>
    );
}


        