import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DatasetApi from "../../api/dataset-api";
import { IStarItem } from "../../types/common-types";
import {
    ICreateDatasetPayload,
    IDatasetsData,
    IUpdateDatasetPayload,
    IFetchDatasetPayload,
    IDatasetFilterData,
    IDataset
} from "../../types/dataset-types";

interface IDatasetState {
    pageData: IDatasetsData;
    filterData: IDatasetFilterData;
    fetching: boolean;

    datasetInfo: IDataset | null;
}

const initialState: IDatasetState = {
    pageData: {
        data: [],
    },
    filterData: {},
    fetching: false,

    datasetInfo: null,
};

/*thunks*/
const createDataset = createAsyncThunk(
    "dataset/create",
    async (payload: ICreateDatasetPayload, thunkAPI) => {
        try {
            const response = await DatasetApi.create(payload);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const updateDataset = createAsyncThunk(
    "dataset/update",
    async (payload: IUpdateDatasetPayload, thunkAPI) => {
        try {
            const response = await DatasetApi.update(payload);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const loadDatasets = createAsyncThunk(
    "dataset/loadAll",
    async (payload: IFetchDatasetPayload, thunkAPI) => {
        try {
            const response = await DatasetApi.getAll(payload);
            return { ...response, page: payload.page, title: payload.title };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const starDataset = createAsyncThunk(
    "dataset/starDataset",
    async (data: IStarItem, thunkAPI) => {
        try {
            const response = await DatasetApi.star(data);
            return { response, ...data };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const deleteDataset = createAsyncThunk(
    "dataset/delete",
    async (id: string, thunkAPI) => {
        try {
            await DatasetApi.delete(id);
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const loadDatasetInfoBySlug = createAsyncThunk(
    'database/get/:slug',
    async ({ _slug }: { _slug: string }, thunkAPI) => {
        try {
            const response = await DatasetApi.get(_slug);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
)

export const datasetSlice = createSlice({
    name: "dataset",
    initialState,
    reducers: {
        setFetching: (s, a) => {
            s.fetching = a.payload;
        },
        setFilterData: (s, a) => {
            s.filterData = a.payload;
        },

        setDatasetsPageData: (s, a) => {
            s.pageData = a.payload;
        },
        setDatasetInfo: (s, a) => {
            s.datasetInfo = a.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createDataset.fulfilled, (s, a) => {
            s.pageData.data.push(a.payload);
        });
        builder.addCase(updateDataset.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex(
                (x) => x._id === a.payload._id
            );
            if (index > -1) {
                const ds = s.pageData.data[index];
                s.pageData.data[index] = { ...ds, ...a.payload };
            }
        });
        builder.addCase(loadDatasets.pending, (s, a) => {
            s.fetching = true;
        });
        builder.addCase(loadDatasets.fulfilled, (s, a) => {
            const { data, totalCount, page } = a.payload
            if (page === 1) {
                s.pageData.data = data;
            } else {
                s.pageData.data = [...s.pageData.data, ...data];
            }
            s.pageData.totalCount = totalCount;

            s.fetching = false;
        });
        builder.addCase(loadDatasets.rejected, (s, a) => {
            s.fetching = false;
        });
        builder.addCase(starDataset.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex(
                (x) => x._id === a.payload._id
            );
            if (index > -1) {
                const ds = s.pageData.data[index];
                s.pageData.data[index] = { ...ds, stared: a.payload.response };
            }
        });

        builder.addCase(deleteDataset.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex((x) => x._id === a.payload);
            if (index > -1) {
                s.pageData.data.splice(index, 1);
            }
        });

        builder.addCase(loadDatasetInfoBySlug.fulfilled, (s, a) => {
            s.datasetInfo = a.payload
        });
    },
});

export {
    createDataset,
    loadDatasets,
    updateDataset,
    starDataset,
    deleteDataset,

    loadDatasetInfoBySlug,
};
export const {
    setFetching,

    setDatasetsPageData,
    setDatasetInfo,
} = datasetSlice.actions;
export default datasetSlice.reducer;
