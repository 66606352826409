import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useAppDispatch } from "../../store/store-hooks";
import FileInput from "../../components/input/file-input";
import Input from "../../components/input/input";
import { HandleError } from "../../errors/handler";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import LoadingSpinner from "../../components/loading-spinner";
import { createOrganization } from "../../store/slices/organization-slice";
import Modal from "react-bootstrap/Modal";
import { ICreateOrganizationPayload } from "../../types/organization-types";

type IOrganizationCreateProps = {
    show: boolean;
    onHide: () => void;
};

export default function CreateOrganizationModal(props: IOrganizationCreateProps) {
    const dispatch = useAppDispatch();
    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<any>({});
    const [title, setTitle] = useState<string>("");
    const [logo, setLogo] = useState<File>();

    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();

            if (validateForm()) {
                setLoading(true);
                const obj = {
                    title,
                    logo
                } as ICreateOrganizationPayload;
                await dispatch(createOrganization(obj)).unwrap();
                dispatch(
                    showInfoModal({
                        title: "Created",
                        message: "Organization created succcessfully!",
                    })
                );
                resetForm();
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const validateForm: () => boolean = () => {
        setFormErrors({});
        const errors: any = {};
        if (!title) {
            errors["title"] = "Please write organization name.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const resetForm = () => {
        setTitle("");
        setLogo(undefined);
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={() => resetForm()}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header className="" closeButton>
                <p className="text-lg">Create Organization</p>
            </Modal.Header>
            <Modal.Body>

                <Card className="bg-primary">
                    <Card.Body>
                        <Form
                            onReset={resetForm}
                            ref={formRef}
                            noValidate
                            onSubmit={onSubmit}
                        >
                            <Form.Group>
                                <Input
                                    autoFocus
                                    focusOnError={true}
                                    label="Name"
                                    placeholder="Organization Name"
                                    value={title}
                                    onChange={(e) => {
                                        if (formErrors["title"]) {
                                            delete formErrors.title;
                                            setFormErrors(formErrors);
                                        }
                                        setTitle(e.target.value);
                                    }}
                                    error={formErrors["title"]}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3 is-invalid">
                                <FileInput
                                    selectedFiles={logo ? [logo] : []}
                                    accept={{
                                        "image/*": [],
                                    }}
                                    height={180}
                                    width={270}
                                    placeholder="Upload Organization Logo"
                                    error={formErrors["logo"]}
                                    onDrop={(files) => {
                                        if (formErrors["logo"]) {
                                            delete formErrors.logo;
                                            setFormErrors(formErrors);
                                        }
                                        if (files?.length) {
                                            setLogo(files[0]);
                                        } else {
                                            setLogo(undefined);
                                        }
                                    }}
                                    containerClass="text-white w-100"
                                />
                            </Form.Group>

                            {formErrors[""] ? (
                                <div className="text-invalid-feedback m-2">
                                    {formErrors[""]}
                                </div>
                            ) : undefined}

                            <Form.Group className="mt-5 d-flex gap-3 ms-auto w-fit">
                                <Button type="reset" className="rounded-pill" variant="secondary">Reset</Button>
                                <Button type="submit" className="rounded-pill">Create</Button>
                            </Form.Group>
                        </Form>
                    </Card.Body>

                    <LoadingSpinner show={loading} text={"Creating Organization"} />
                </Card>

            </Modal.Body>
        </Modal>
    );
}
