import "../intro-page/index.scss";
import { useRef, useEffect } from "react";

export default function CookieDeclaration() {
    const cookieRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if(cookieRef.current){
            if(!document.getElementById("CookieDeclaration")){
                const script = document.createElement("script");
                script.async = true;
                script.type = "text/javascript";
                script.id="CookieDeclaration";
                script.src = "https://consent.cookiebot.com/899d70b1-ff6d-4aa9-8c7a-2f568b4f92c3/cd.js";
                cookieRef.current.appendChild(script);
            }
        }
    }, [cookieRef])

    return (
        <div className="page-container sm-box">
            <div className="text-3xl">Cookie Declaration</div>
            <div className="text-color-tertiary text-sm static_page_content mt-4">
                <div className="cookieBotPage" ref={cookieRef}>

                </div>
            </div>
        </div>
    );
}