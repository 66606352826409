import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { clearInfoModal } from "../../store/slices/modal-states-slice";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import "../auth-modals/index.scss"

export default function InfoModal() {
    const dispatch = useAppDispatch();
    const infoModal = useAppSelector((s) => s.modalStates.infoModal);
    const onHide = () => {
        dispatch(clearInfoModal());
    };
    return (
        <Modal className="login-modal" show={infoModal.visible} centered onHide={() => onHide()}>
            <Modal.Header className="border-0 p-3" closeButton>
                <div>{infoModal.title}</div>
            </Modal.Header>
            <Modal.Body className="">
                <p className="text-center pb-21 mb-0">{infoModal.message}</p>

                <Button
                    onClick={() => onHide()}
                    className="rounded-pill mx-auto mt-4"
                >
                    {infoModal.buttonText ?? "Okay"}
                </Button>
            </Modal.Body>
        </Modal>
    );
}
