import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import InfoModal from "./components/info-modal";
import LoginModal from "./components/auth-modals/login-modal";
import { injectStore } from "./configs/api-config";
import { router } from "./router";
import { store } from "./store";
import { Helmet, HelmetProvider } from "react-helmet-async";
import RegisterModal from "./components/auth-modals/register-modal";
import AccountVerificationModal from "./components/auth-modals/verification-modal";
import ForgotPasswordModal from "./components/auth-modals/forgot-password-modal";
import ErrorModal from "./components/error-modal";

function App() {
    useEffect(() => {
        injectStore(store);
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Antigranular</title>
                <meta name="description" content="Antigranular by Oblivious" />
            </Helmet>
            <RouterProvider router={router} />
            <LoginModal />
            <InfoModal />
            <AccountVerificationModal />
            <RegisterModal />
            <ForgotPasswordModal />
            <ErrorModal />
        </HelmetProvider>
    );
}

export default App;
