import placeholder from "../../assets/images/list_item_placeholder.png";
import { IDataset } from "../../types/dataset-types";
import { DATASET_IMG_URL } from "../../configs/api-config";
import { useState } from "react";
import { Link } from "react-router-dom";

type TDatasetListItemProps = {
    item: IDataset;
    to: string;
    // setDatasetToDelete: Function;
};

export default function DatasetListItem(props: TDatasetListItemProps) {
    const [noImage, setNoImage] = useState<boolean>(false);

    return (
        <div className="list-item-cover glazed-card-cover">
            <Link to={props.to} className="list-item glazed-card unanchored">
                <div>
                    <p className="listTitle cursor-pointer">
                        {props.item.title}
                    </p>
                    {/* <p className="organizer text-color-tertiary text-xs">
                        {props.item.createdBy}
                    </p> */}
                </div>

                <div>
                    {noImage ? (
                        <img
                            src={placeholder}
                            className="listImage cursor-pointer"
                            alt=""
                            width={92}
                            height={92}
                        />
                    ) : (
                        <img
                            src={DATASET_IMG_URL(props.item?._id ?? "")}
                            className="listImage cursor-pointer"
                            alt=""
                            width={92}
                            height={92}
                            onError={() => setNoImage(true)}
                        />
                    )}

                    <p className="dataset-listData mb-2 text-xs">
                        {props.item?.subTitle || "Description"}
                    </p>
                </div>
            </Link>
        </div>
    );
}
