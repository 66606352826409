import {
    IOrganizationsData,
    IOrganization,
    IOrganizationDetails,
    ICreateOrganizationPayload,
    IUpdateOrganizationPayload,
    IFetchOrganizationListPayload,
    IUpdateOrganizationDatasetsPayload,
    IUpdateOrganizationCompetitionsPayload,
    IUpdateOrganizationUsersPayload
} from "../types/organization-types";
import { BaseApi } from "./base-api";

export default class OrganizationApi extends BaseApi {
    private static url = (action: string) => "organization/" + action;

    /**
     * Create new organization
     * @returns
     */
    public static async create( data: ICreateOrganizationPayload ): Promise<IOrganization> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._post(this.url("create"), fd);
    }

    /**
     * Get all organizations
     * @returns
     */
    public static async getAll( data: IFetchOrganizationListPayload ): Promise<IOrganizationsData> {
        return this._get(this.url("getall"), data);
    }

    /**
     * Get organization details
     * @param id organization id
     * @returns
     */
    public static async get(id: string): Promise<IOrganizationDetails> {
        return this._get(`${this.url("get")}/${id}`);
    }

    /**
     * Update organization
     * @param data
     * @returns
     */
    public static async update(
        data: IUpdateOrganizationPayload
    ): Promise<IOrganization> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._patch(this.url("update"), fd);
    }

    /**
     * Add datasets to organization
     * @param data
     * @returns
     */
    public static async addDatasets( data: IUpdateOrganizationDatasetsPayload ): Promise<void> {
        return this._patch(this.url("add-datasets"), data);
    }

    /**
     * Add datasets to organization
     * @param data
     * @returns
     */
    public static async addCompetitions( data: IUpdateOrganizationCompetitionsPayload ): Promise<void> {
        return this._patch(this.url("add-competitions"), data);
    }

    /**
     * Add members to organization
     * @param data
     * @returns
     */
    public static async addMembers( data: IUpdateOrganizationUsersPayload ): Promise<void> {
        return this._patch(this.url("add-members"), data);
    }

    /**
     * Remove datasets from organization
     * @param data
     * @returns
     */
    public static async removeDatasets( data: IUpdateOrganizationDatasetsPayload ): Promise<void> {
        return this._patch(this.url("remove-datasets"), data);
    }

    /**
     * Remove competitions from organization
     * @param data
     * @returns
     */
    public static async removeCompetitions( data: IUpdateOrganizationCompetitionsPayload ): Promise<void> {
        return this._patch(this.url("remove-competitions"), data);
    }

    /**
     * Remove members from organization
     * @param data
     * @returns
     */
    public static async removeMembers( data: IUpdateOrganizationUsersPayload ): Promise<void> {
        return this._patch(this.url("remove-members"), data);
    }


    public static async delete(id: string): Promise<void> {
        return this._delete(`${this.url("delete")}/${id}`);
    }
}
