import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// import: assets
import marker from "../../assets/icons/location_pin.png";
import briefcase from "../../assets/icons/briefcase.png";
import building from "../../assets/icons/building.png";

// import: styles
import "./index.scss";

// import: constants
import { BADGE_ID, COMPETITIONS, NOTEBOOKS, USER_ID } from "../../router/routes";
// import: enums
// import { AccomplishmentType } from "../../enums/accomplishment-type";

// import: types
import { IUser } from "../../types/user-type";
import { INotebook } from "../../types/notebook-types";
import { ICompetition } from "../../types/competition-types";
import { IAccomplishment } from "../../types/accomplishment-types";

// import: utils
import { HandleError } from "../../errors/handler";
import { timeElapsed } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { deleteNotebook } from "../../store/slices/notebook-slice";
import { refreshUser } from "../../store/slices/auth-slice";

// import: api
import ProfileApi from "../../api/profile-api";
import AccomplishmentApi from "../../api/accomplishment-api";
import NotebookApi from "../../api/notebook-api";
import CompetitionApi from "../../api/competition-api";

// import: config
// import: components
import ErrorAlert from "../../components/error-alert";
import LoadingSpinner from "../../components/loading-spinner";
import CongratulationsModal from "../../components/congratulations-modal";
import UserImg from "../../components/user-img";
import ProfileEditModal from "../../components/auth-modals/profile-edit-modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
// import RefferedUserItem from "../../components/list-items/reffered-user-item";
import ShareButton from "../../components/share-button";
import CompetitionListItem from "../../components/list-items/competition-list-item";
import BadgeListItem from "../../components/list-items/badge-list-item";
import NotebookListItem from "../../components/list-items/notebook-list-item";
import Button from "react-bootstrap/esm/Button";
import { Modal } from "react-bootstrap";

export default function UserProfilePage() {
    const user = useAppSelector((s) => s.auth.user);
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>();
    const { [USER_ID]: _userId, [BADGE_ID]: _badgeId } = useParams();

    const [profileUpdate, setProfileUpdate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [profile, setProfile] = useState<IUser>();
    const [myNotebooks, setMyNotebooks] = useState<INotebook[]>([]);
    const [joinedCompetitions, setJoinedCompetitions] =
        useState<ICompetition[]>();
    const [editModalView, setEditModalView] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [selectedAccomplishment, setSelectedAccomplishment] =
        useState<IAccomplishment>();

    const [submissions, setSubmissions] = useState<IAccomplishment[]>();
    const [showAccomplishmentModal, setShowAccomplishmentModal] =
        useState<boolean>(false);
    const [nbToDelete, setNbToDelete] = useState<INotebook>();
    const mailDescription = `Hey There!

Do you know about Antigranular?
Join me and find out more about the world of Eyes Off Data science!

Use this link to create a profile and become a part of this awesome community.

`;

    useEffect(() => {
        setLoading(true);
        ProfileApi.get(_userId)
            .then((profile) => {
                setProfile(profile);
            })
            .catch((err) => {
                setError(HandleError(err)[""]);
            });

        AccomplishmentApi.get(_userId)
            .then((submission) => {
                setSubmissions(submission);
                if (_badgeId) {
                    submission.forEach((data) => {
                        if (data._id === _badgeId)
                            setSelectedAccomplishment(data);
                        setShowAccomplishmentModal(true);
                    });
                }
            })
            .catch((err) => {
                setError(HandleError(err)[""]);
            })
            .finally(() => {
                setLoading(false);
            });

        NotebookApi.getUserNotebooks(_userId)
            .then((nbs) => setMyNotebooks(nbs.data))
            .catch((err) => setError(HandleError(err)[""]));

        CompetitionApi.getJoinedCompetitions({
            page: 1,
            size: 1000,
            userId: _userId,
        })
            .then((comps) => setJoinedCompetitions(comps.data))
            .catch((error) => setError(HandleError(error)[""]));
    }, [_userId]);

    useEffect(() => {
        if (profileUpdate) {
            setLoading(true);
            ProfileApi.get()
                .then((profile) => {
                    setProfile({ ...profile });
                })
                .catch((err) => {
                    setError(HandleError(err)[""]);
                })
                .finally(() => {
                    setLoading(false);
                    if (user)
                        dispatch(
                            refreshUser({ ...user, imgcode: Math.random() })
                        );
                    setProfileUpdate(false);
                });
        }
    }, [profileUpdate]);

    const getConfirmation = async (nb: INotebook) => {
        setNbToDelete(nb);
        setConfirmDelete(true);
    };

    const deleteNb = async () => {
        try {
            if (nbToDelete) {
                setConfirmDelete(false);
                setLoading(true);
                await dispatch(deleteNotebook(nbToDelete._id)).unwrap();
            }
        } catch (error) {
            setError(HandleError(error)[""]);
        } finally {
            setLoading(false);
            let holder = [...myNotebooks];
            myNotebooks.forEach((notebook, id) => {
                if (notebook._id === nbToDelete?._id) {
                    holder.splice(id, 1);
                }
            });
            setNbToDelete(undefined);
            setMyNotebooks(holder);
        }
    };
    // const star = () => {};

    return (
        <div className="profile-page page-container md-box">
            <ErrorAlert show={error ? true : false} errorMessage={error} />

            <div className="d-flex w-full flex-wrap">
                <div className="page-heading-container pb-1 mb-2 flex-row">
                    <div className="me-3">
                        <UserImg
                            userId={_userId ?? user?._id}
                            imgcode={user?.imgcode ? `${user?.imgcode}` : ""}
                            height={88}
                            width={88}
                            className="inactive-image"
                        />
                    </div>
                    <div className="">
                        <div className="joined-on text-color-tertiary text-xs">
                            <li>
                                Joined{" "}
                                {profile?.createdAt ? (
                                    timeElapsed(
                                        profile?.createdAt,
                                        new Date()
                                    ) + " ago"
                                ) : (
                                    <></>
                                )}
                            </li>
                        </div>
                        <div className="mb-1 text-3xl d-flex">
                            <span className="w-fit">{profile?.fullName}</span>
                            {user ? (
                                <ShareButton
                                    className="ms-3 mb-auto"
                                    modalHead="Share your profile"
                                    url={`https://www.antigranular.com/profile/${user._id}`}
                                    subject={`Invitation to the Eyes Off Data Science from ${user?.fullName}`}
                                    description={mailDescription}
                                    slim={true}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>

                {!_userId && profile ? (
                    <div className="d-flex flex-col mt-auto p-2">
                        <p className="mb-2 text-color-tertiary text-xs">
                            <span>Referrals</span>
                            <span className="ms-2 text-color-secondary">
                                {profile?.referralCount || 0}
                            </span>
                        </p>
                    </div>
                ) : (
                    <></>
                )}
            </div>

            <div className="d-flex flex-wrap justify-content-between">
                <div className="page-heading-container text-xs pe-3">
                    <div className="d-flex w-100 mt-2 flex-wrap">
                        {(profile?.location) || !_userId ? (
                            <p className="text-color-tertiary text-xs pe-3 d-flex">
                                <img
                                    alt=""
                                    className="me-1"
                                    height={16}
                                    width={16}
                                    src={marker}
                                />{" "}
                                <span>
                                    {profile?.location || "Add your location"}
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                        {(profile?.organization) || !_userId ? (
                            <p className="text-color-tertiary text-xs mx-auto pe-3 d-flex">
                                <img
                                    alt=""
                                    className="me-1"
                                    height={16}
                                    width={16}
                                    src={building}
                                />{" "}
                                <span>
                                    {profile?.organization ||
                                        "Add organization"}
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                        {(profile?.occupation) || !_userId ? (
                            <p className="text-color-tertiary text-xs d-flex pe-3">
                                <img
                                    alt=""
                                    className="me-1"
                                    height={16}
                                    width={16}
                                    src={briefcase}
                                />{" "}
                                <span>
                                    {profile?.occupation || "Add occupation"}
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>

                    {profile?.about ||
                        (!_userId
                            ? "Insert your inspiring and jaw-droppingly impressive bio here. Not in the mood to brag? Then just share a few words about yourself and your interest in eyes-off data science with the community."
                            : "")}
                </div>

                {!_userId && user ? (
                    <div className="d-flex flex-column my-2 my-lg-0">
                        <Button
                            variant="secondary"
                            className="rounded-pill py-1"
                            onClick={() => setEditModalView(!editModalView)}
                        >
                            Edit Profile
                            <svg
                                width="20"
                                height="20"
                                className="ms-2"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.0835 17.9167L6.70789 16.138C7.00367 16.0243 7.15156 15.9674 7.28993 15.8931C7.41283 15.8272 7.53 15.751 7.6402 15.6655C7.76426 15.5692 7.87631 15.4572 8.1004 15.2331L17.5002 5.83332C18.4207 4.91285 18.4207 3.42046 17.5002 2.49999C16.5797 1.57951 15.0873 1.57951 14.1669 2.49999L4.76706 11.8998C4.54298 12.1238 4.43093 12.2359 4.33466 12.36C4.24914 12.4702 4.173 12.5873 4.10702 12.7102C4.03275 12.8486 3.97587 12.9965 3.86211 13.2923L2.0835 17.9167ZM2.0835 17.9167L3.7986 13.4575C3.92133 13.1384 3.98269 12.9788 4.08795 12.9057C4.17993 12.8419 4.29375 12.8177 4.40374 12.8387C4.52961 12.8627 4.65048 12.9836 4.89223 13.2254L6.77481 15.1079C7.01656 15.3497 7.13743 15.4706 7.16147 15.5964C7.18247 15.7064 7.15832 15.8202 7.09445 15.9122C7.02137 16.0175 6.86182 16.0788 6.54272 16.2016L2.0835 17.9167Z"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Button>
                    </div>
                ) : (
                    <></>
                )}
            </div>

            <Tabs className="">
                {(submissions && submissions?.length > 0) || !_userId ? (
                    <Tab eventKey={"achievementsTab"} title="Achievements">
                        <div className="page-container">
                            <div className="listing-table-container">
                                {submissions && submissions?.length > 0 ? (
                                    submissions.map((submission, idx) => (
                                        <BadgeListItem
                                            user_id={
                                                _userId ?? user?._id
                                            }
                                            onClick={() => {
                                                setSelectedAccomplishment(
                                                    submission
                                                );
                                                setShowAccomplishmentModal(
                                                    true
                                                );
                                            }}
                                            submission={submission}
                                            key={"Accomplishment" + idx}
                                        />
                                    ))
                                ) : (
                                    <>
                                        {!(error || loading) && (
                                            <p className="text-sm text-center text-color-tertiary px-2 px-md-3">
                                                Seems like you haven't been actively
                                                participating. Join{" "}
                                                <Link
                                                    className="d-inline cursor-pointer link-ag-1"
                                                    to={`/${COMPETITIONS}`}
                                                >
                                                    competitions
                                                </Link>{" "}
                                                to get Accomplishments to showcase.
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </Tab>
                ) : (
                    <></>
                )}

                {(joinedCompetitions && joinedCompetitions?.length > 0) ||
                    !_userId ? (
                    <Tab eventKey={"competitionTab"} title="Competitions">
                        <div className="listing-table-container">
                            {joinedCompetitions &&
                                joinedCompetitions?.length > 0 ? (
                                joinedCompetitions?.map((item) => (
                                    <CompetitionListItem
                                        to={`/${COMPETITIONS}/` + (item.slug ?? item._id)}
                                        key={item._id}
                                        item={item}
                                    />
                                ))
                            ) : (
                                <>
                                    {!(error || loading) && (
                                        <p className="text-sm text-center text-color-tertiary px-2 px-md-3">
                                            You haven't participated in any competitions
                                            yet. Change that by going to the{" "}
                                            <Link
                                                className="d-inline unanchored cursor-pointer link-ag-1"
                                                to={`/${COMPETITIONS}`}
                                            >
                                                competitions
                                            </Link>{" "}
                                            section.
                                        </p>
                                    )}
                                </>
                            )}
                        </div>
                    </Tab>
                ) : (
                    <></>
                )}

                {(myNotebooks && myNotebooks.length > 0) || !_userId ? (
                    <Tab eventKey={"notebooksTab"} title="Notebooks">
                        <Container fluid className="profile-item-container">
                            {myNotebooks.map((item) => (
                                <NotebookListItem
                                    contextMenu={
                                        user?._id === item.createdBy._id
                                            ? [
                                                {
                                                    title: "Delete",
                                                    onClick: () => { getConfirmation(item) },
                                                },
                                            ]
                                            : []
                                    }
                                    to={`/${NOTEBOOKS}/` + (item.slug ?? item._id)}
                                    key={item._id}
                                    item={item}
                                    userNbItem
                                />
                            ))}
                        </Container>
                    </Tab>
                ) : (
                    <></>
                )}
            </Tabs>

            <LoadingSpinner show={loading} text="Getting user profile" />
            {profile && (
                <ProfileEditModal
                    profile={profile}
                    show={editModalView}
                    onHide={(update: boolean) => {
                        setProfileUpdate(update);
                        setEditModalView(false);
                    }}
                />
            )}

            {selectedAccomplishment ? (
                <CongratulationsModal
                    user_id={_userId ? _userId : user?._id}
                    accomplishment={selectedAccomplishment}
                    visible={showAccomplishmentModal}
                    onHide={() => setShowAccomplishmentModal(false)}
                />
            ) : (
                <></>
            )}

            <Modal
                show={confirmDelete}
                onHide={() => {
                    setConfirmDelete(false);
                    setNbToDelete(undefined);
                }}
                centered
                dialogClassName="login-modal"
            >
                <Modal.Header className="border-0" closeButton>
                    <p className="text-lg text-danger">Are you sure!</p>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="d-flex flex-column justify-content-center">
                        <p className="mb-5">{`Are you sure you want to delete the notebook ${nbToDelete?.title} ?`}</p>
                        <div className="w-100 d-flex">
                            <Button
                                onClick={deleteNb}
                                className="ms-auto rounded-pill"
                            >
                                Okay
                            </Button>
                            <Button
                                variant="secondary"
                                className="ms-4 rounded-pill"
                                onClick={() => {
                                    setConfirmDelete(false);
                                    setNbToDelete(undefined);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
