
import Badge from "../badge";
import Button from "react-bootstrap/Button";
import { IAccomplishment } from "../../types/accomplishment-types";
import { AccomplishmentType } from "../../enums/accomplishment-type";
import { useAppSelector } from "../../store/store-hooks";
import { useState } from "react";

type TBadgeListItemProps = {
    submission: IAccomplishment;
    user_id?: string;
    onClick: () => void;
    // setDatasetToDelete: Function;
};

export default function BadgeListItem(props: TBadgeListItemProps) {
    const user = useAppSelector((s) => s.auth.user);

    const getLink =() => {
        let link = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
            props.submission.type === AccomplishmentType.CompetitionBadge ? (props.submission.data.competition_name?.replace(" ", "%20") + "%20|%20Rank%20:%20" + props.submission.data.rank) : 
            props.submission.type === AccomplishmentType.BugBounty ? "Antigranular%20Bug%20Bounty" : "Antigranular%20Outstanding%20Notebook"}&organizationId=38143046&issueYear=${props.submission.date.getUTCFullYear()}&issueMonth=${props.submission.date.getUTCMonth() + 1}&certUrl=https%3A%2F%2Fantigranular.com${props.user_id ? `%2Fprofile%2F${props.user_id}%2Fbadge%2F${props.submission._id}` : ""}&certId=${
                props.submission.type === AccomplishmentType.CompetitionBadge ? props.submission.data.submission_sha : "-"}`
        return link
    }

    return (
        <div className="list-item-cover badge-item-cover glazed-card-cover w-fit">
            <div className="list-item badge-item p-0 p-md-3 d-flex justify-content-center align-items-center glazed-card cursor-pointer p-relative"
                onClick={() => props.onClick()}>
                <Badge listItem submission={props.submission}/>
                {user && user?._id === props.user_id ?  <>
                    <div className="item-shade"></div>
                    <div className="hover-btn">
                        <Button variant="secondary" className="rounded-pill mt-3 me-auto">
                            <a className="button-link" onClick={(e) => e.stopPropagation()} href={getLink()} target="_blank" rel='noreferrer'>
                                Add to LinkedIn
                            </a>
                        </Button>
                    </div>
                </> : <></>}
            </div>
        </div>
    );
}
