import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// import: assets
import logo from "../../assets/logo.svg";
import logoMin from "../../assets/logoMin.svg";
import { customDiscord } from "../../assets/icons";
// import resources from "../../assets/icons/resources.svg";
// import resourcesSelected from "../../assets/icons/resource-selected.png";
// import discord from "../../assets/icons/discord.svg";
// import discordSelected from "../../assets/icons/discord-selected.svg";
// import dropdownIcon from "../../assets/icons/dropdown-1.svg";
// import: styles
import "./index.scss";

// import: constants
import { DISCORD, ROOT } from "../../router/routes";

// import: enums
// import: types
import { ISidebarMenuItem } from "../../types/sidebar-menu-item-type";

// import: utils
// import: data
// import: store
// import: api
// import: config
// import: components
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type TSidebarProps = {
  xsVisible: boolean;
  onCloseSidebar?: () => void;
  menuList: ISidebarMenuItem[];
};

export default function Sidebar(props: TSidebarProps) {
  const navigate = useNavigate();
  const [showDropdownItems, setShowDropdownItems] = useState(false);
  const [activeDropdownItem, setActiveDropdownItem] = useState("");
  const [isDropdownItemActive, setIsDropdownItemActive] = useState(false);
  const location = useLocation();
  useEffect(() => {
    let isDropDownLink = false;
    let activeDropdown = ""
    props.menuList?.forEach((menu) =>
      menu.dropdownItems?.forEach((item) => {
        if ("/" + item.path === location.pathname) {
          isDropDownLink = true;
          activeDropdown = item.path
        }
      })
    );
    setActiveDropdownItem(activeDropdown)
    setIsDropdownItemActive(isDropDownLink)
    setShowDropdownItems(isDropDownLink);
  }, [location, props.menuList]);
  return (
    <div
      onClick={() => props.onCloseSidebar?.()}
      className={`sidebar-container ${props.xsVisible ? "w-100" : ""}`}
    >
      <div
        className={`sidebar h-100 sidebar-width ${props.xsVisible ? "" : "sidebar-width-0"
          }`}
      >
        <div className="sidebar-header">
          <img
            className="sidebar-logo-mini cursor-pointer"
            src={logoMin}
            alt=""
            onClick={() => {
              navigate(ROOT);
              setShowDropdownItems(false);
            }}
          />
          <img
            className="sidebar-logo cursor-pointer"
            src={logo}
            alt=""
            onClick={() => {
              navigate(ROOT);
              setShowDropdownItems(false);
            }}
          />
        </div>

        <div>
          {props.menuList?.map((menu, i) =>
            menu.external ? (
              <a
                key={i}
                rel="noreferrer"
                href={menu.path}
                target="_blank"
                className="sidebar-menu"
                onClick={() => setShowDropdownItems(false)}
              >
                <div className="sidebar-menu-content">
                  <img
                    className="sidebar-menu-icon"
                    src={menu.iconImg}
                    alt=""
                  />
                  <img
                    className="sidebar-menu-icon-selected"
                    src={menu.activeIconImg}
                    alt=""
                  />
                  <span className="sidebar-menu-text link">{menu.text}</span>
                </div>
              </a>
            ) : menu.dropdownItems ? (
              <div
                // to={menu.path}
                key={i}
                onClick={() => setShowDropdownItems(!showDropdownItems)}
                className={`${showDropdownItems || isDropdownItemActive ? "sidebar-menu selected" : "sidebar-menu"
                  } d-flex flex-column `}
              >
                <div className="sidebar-menu-content">
                  <img
                    className="sidebar-menu-icon"
                    src={menu.iconImg}
                    alt=""
                  />
                  <img
                    className="sidebar-menu-icon-selected"
                    src={menu.activeIconImg}
                    alt=""
                  />
                  <span className="sidebar-menu-text link">{menu.text}</span>
                  <img
                    src={menu.dropdownIconImg}
                    alt=""
                    width={20}
                    height={20}
                    className="mt-1 sidebar-menu-icon"
                    style={{ filter: "invert(1)" }}
                  />
                  <img
                    src={menu.activeDropDownIconImg}
                    alt=""
                    width={20}
                    height={20}
                    className="mt-1 sidebar-menu-icon-selected"
                  />
                </div>
                <div className="dropdownItems">
                  {showDropdownItems &&
                    menu.dropdownItems.map((dropdownItem, index) => (
                      <NavLink
                        key={index}
                        to={dropdownItem.path}
                        className={"items"}
                      >
                        <h6>
                          <span className={activeDropdownItem === dropdownItem.path ? " selected-link" : "link"}>
                            {dropdownItem.text.title}
                          </span>
                        </h6>
                      </NavLink>
                    ))}
                </div>
              </div>
            ) : (
              <NavLink
                key={i}
                to={menu.path}
                onClick={() => setShowDropdownItems(false)}
                className={({ isActive }) =>
                  isActive ? `sidebar-menu selected` : "sidebar-menu"
                }
              >
                <div className="sidebar-menu-content">
                  <img
                    className="sidebar-menu-icon"
                    src={menu.iconImg}
                    alt=""
                  />
                  <img
                    className="sidebar-menu-icon-selected"
                    src={menu.activeIconImg}
                    alt=""
                  />
                  <span className="sidebar-menu-text link">{menu.text}</span>
                </div>
              </NavLink>
            )
          )}

          <Button
            key={"discord"}
            className="py-0 mt-42 w-0 sidebar-menu-button"
            variant="link"
            onClick={() =>
              window
                .open(
                  DISCORD,
                  "_blank",
                  "noopener"
                )
                ?.focus()
            }
          >
            <span className="d-none d-lg-block">Discord</span>
            <FontAwesomeIcon
              size="xl"
              className="sidebar-menu-icon me-0 ms-lg-2"
              icon={customDiscord}
            />
          </Button>
          {/* <a key={"discord"} rel="noreferrer" href={DISCORD} target="_blank" className="sidebar-menu">
                        <div className="sidebar-menu-content">
                            <img
                                className="sidebar-menu-icon"
                                src={discord}
                                alt=""
                            />
                            <img
                                className="sidebar-menu-icon-selected"
                                src={discordSelected}
                                alt=""
                            />
                            <span className="sidebar-menu-text link">
                                {"Discord"}
                            </span>
                        </div>
                    </a> */}
        </div>
      </div>
    </div>
  );
}
