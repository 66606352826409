import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import: assets
import { faAdd, faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";

// import: styles
// import: constants
import { DATASET_ID } from "../../router/routes";

// import: enums
// import: types
import { IDataset, IUpdateDatasetPayload } from "../../types/dataset-types";

// import: utils
import { HandleError } from "../../errors/handler";
import { tryParseJSONObject } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppDispatch } from "../../store/store-hooks";
import { updateDataset } from "../../store/slices/dataset-slice";
import { showInfoModal } from "../../store/slices/modal-states-slice";

// import: api
import DatasetApi from "../../api/dataset-api";

// import: config
import { DATASET_IMG_URL } from "../../configs/api-config";

// import: components
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import RichTextEditor from "../../components/richtext-editor";
import InputSelect from "../../components/input/input-select";
import FileInput from "../../components/input/file-input";
import Input from "../../components/input/input";
import LoadingSpinner from "../../components/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorAlert from "../../components/error-alert";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import types from "../../components/category-icons/types.json";

export default function EditDatasetPage() {
    const { [DATASET_ID]: _id } = useParams();
    const navigate = useNavigate();
    const [dataset, setDataset] = useState<IDataset>();
    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);
    const titleInputRef = useRef<HTMLInputElement>(null);
    //form states
    const [formErrors, setFormErrors] = useState<any>({});
    const [title, setTitle] = useState<string>("");
    const [subTitle, setSubTitle] = useState<string>("");
    const [about, setAbout] = useState<string>("");
    const [tags, setTags] = useState<string>("");
    const [isPrivate, setIsPrivate] = useState<boolean>(true);
    const [coverImage, setCoverImage] = useState<File>();
    const [coverPreview, setCoverPreview] = useState<string>();
    const [sources, setSources] = useState<string>("");
    const [collaborators, setCollaborators] = useState<string>("");
    const [license, setLicense] = useState<string>("");
    const [columnData, setColumnData] = useState<
        { columnName: string; columnDescription: string; categories: string }[]
    >([]);
    const [pqEps, setPqEps] = useState<number>(0);
    const [pqDelta, setPqDelta] = useState<number>(0);
    const [maxEps, setMaxEps] = useState<number>(0);
    const [maxDelta, setMaxDelta] = useState<number>(0);
    const [type, setType] = useState<string>("");

    const visibilityOptions = [
        { value: "private", label: "Private" },
        { value: "public", label: "Public" },
    ];

    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string>();
    const [metadata, setMetadata] = useState<string>("");
    const [metrics, setMetrics] = useState<string>("");


    //#region  effects
    useEffect(() => {
        if (!dataset && _id) {
            setLoading(true);
            DatasetApi.get(_id)
                .then((ds) => {
                    setDataset(ds);
                })
                .catch((error) => {
                    setFetchError(HandleError(error)[""]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataset) {
            setTitle(dataset.title ?? "");
            setSubTitle(dataset.subTitle ?? "");
            setAbout(dataset.about ?? "");
            setTags(dataset.tags ?? "");
            setIsPrivate(dataset.isPrivate ?? false);
            setColumnData(dataset.columnData);
            setCollaborators(dataset.collaborators?.join("\n") ?? "");
            setSources(dataset.sources?.join("\n") ?? "");
            setLicense(dataset.license);
            setType(dataset.type);
            setPqDelta(dataset.pqDelta);
            setPqEps(dataset.pqEps);
            setMaxDelta(dataset.maxDelta);
            setMaxEps(dataset.maxEps);
            setMetrics(dataset.metrics ? JSON.stringify(dataset.metrics) : "");
            let newMeta = {
                x_meta: {},
                y_meta: {}
            }
            newMeta = { ...newMeta, ...dataset.metadata }
            setMetadata(JSON.stringify(newMeta, null, 2));
        }
    }, [dataset]);
    //#endregion

    //#region functions
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (validateForm() && dataset) {
                setLoading(true);
                const data = {
                    _id: dataset._id,
                    title,
                    subTitle,
                    about,
                    tags,
                    isPrivate,
                    columnData,
                    collaborators: collaborators.split("\n"),
                    license,
                    sources: sources.split("\n"),
                    type,
                    pqEps,
                    pqDelta,
                    maxDelta,
                    maxEps,
                    metrics,
                    metadata
                } as IUpdateDatasetPayload;
                if (coverImage) {
                    data.coverImage = coverImage;
                }
                await dispatch(updateDataset(data)).unwrap();
                dispatch(
                    showInfoModal({
                        title: "Updated",
                        message: "Dataset updated succcessfully.",
                    })
                );
                //resetForm();
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const validateForm: () => boolean = () => {
        setFormErrors({});
        const errors: any = {};

        if (!title) {
            errors["title"] = "Please write dataset title.";
        }

        if (!type) {
            errors["type"] = "please select type.";
        }

        if (isNaN(Number(pqEps))) {
            errors["pqEps"] = "Please enter pqEps.";
        }

        if (isNaN(Number(pqDelta))) {
            errors["pqDelta"] = "Please enter pqDelta.";
        }

        if (isNaN(Number(maxDelta))) {
            errors["maxDelta"] = "Please enter maxDelta.";
        }

        if (isNaN(Number(maxEps))) {
            errors["maxEps"] = "Please enter maxEps.";
        }

        if (!metrics || !tryParseJSONObject(metrics)) {
            errors["metrics"] = "Metrics needs tobe a valid json.";
        }

        if (!metadata || !tryParseJSONObject(metadata)) {
            errors["metadata"] = "Metadata needs tobe a valid json.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const resetForm = () => {
        setTitle("");
        setSubTitle("");
        setTags("");
        setType("");
        setPqDelta(0);
        setPqEps(0);
        setMaxDelta(0);
        setMaxEps(0);
        setAbout("");
        setFormErrors({});
        titleInputRef?.current?.focus();
    };
    //#endregion

    useEffect(() => {
        if (coverImage) {
            setCoverPreview(URL.createObjectURL(coverImage));
        } else {
            setCoverPreview(undefined);
        }
    }, [coverImage]);
    return (
        <div>
            <ErrorAlert
                show={fetchError ? true : false}
                errorMessage={fetchError}
            />
            {dataset ? (
                <>
                    <div className="d-flex flex-row align-content-center">
                        <FontAwesomeIcon
                            size="lg"
                            className="mx-3 cursor-pointer"
                            onClick={() => navigate(-1)}
                            icon={faArrowLeft}
                        />
                        <h4>Update Dataset</h4>
                    </div>

                    <Card className="bg-primary">
                        <Card.Body>
                            <Form
                                onReset={resetForm}
                                ref={formRef}
                                noValidate
                                onSubmit={onSubmit}
                            >
                                <Form.Group>
                                    <Input
                                        ref={titleInputRef}
                                        autoFocus
                                        focusOnError={true}
                                        label="Title"
                                        placeholder="Dataset Title"
                                        value={title}
                                        onChange={(e) => {
                                            if (formErrors["title"]) {
                                                delete formErrors.title;
                                                setFormErrors(formErrors);
                                            }
                                            setTitle(e.target.value);
                                        }}
                                        error={formErrors["title"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Input
                                        label="Subtitle"
                                        placeholder="Dataset Subtitle"
                                        value={subTitle}
                                        onChange={(e) => {
                                            if (formErrors["subTitle"]) {
                                                delete formErrors.subTitle;
                                                setFormErrors(formErrors);
                                            }
                                            setSubTitle(e.target.value);
                                        }}
                                        error={formErrors["subTitle"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Input
                                        label="Tags"
                                        placeholder="Search Tags"
                                        value={tags}
                                        onChange={(e) => {
                                            if (formErrors["tags"]) {
                                                delete formErrors.tags;
                                                setFormErrors(formErrors);
                                            }
                                            setTags(e.target.value);
                                        }}
                                        asTextArea
                                        error={formErrors["tags"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <InputSelect
                                        labelKey="label"
                                        valueKey="value"
                                        onChange={(e) =>
                                            setIsPrivate(
                                                e?.value === "private"
                                                    ? true
                                                    : false
                                            )
                                        }
                                        label="Visibility"
                                        placeholder="Set Visibility"
                                        value={
                                            isPrivate === true
                                                ? visibilityOptions[0]
                                                : visibilityOptions[1]
                                        }
                                        options={visibilityOptions}
                                        error={formErrors["visible"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Input
                                        label="Metadata"
                                        asTextArea
                                        focusOnError={true}
                                        placeholder="Enter metadata json data"
                                        value={metadata}
                                        onChange={(e) => {
                                            if (formErrors["metadata"]) {
                                                delete formErrors.metadata;
                                                setFormErrors(formErrors);
                                            }
                                            setMetadata(e.target.value);
                                        }}
                                        error={formErrors["metadata"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Input
                                        label="Metrices"
                                        asTextArea
                                        focusOnError={true}
                                        placeholder="Enter metrices json data"
                                        value={metrics}
                                        onChange={(e) => {
                                            if (formErrors["metrics"]) {
                                                delete formErrors.metrics;
                                                setFormErrors(formErrors);
                                            }
                                            setMetrics(e.target.value);
                                        }}
                                        error={formErrors["metrics"]}
                                    />
                                </Form.Group>


                                <div className="d-flex flex-row flex-wrap gap-3 mt-3">
                                    <div
                                        style={{
                                            backgroundImage: coverPreview
                                                ? `url(${coverPreview})`
                                                : `url(${DATASET_IMG_URL(
                                                    dataset._id
                                                )}`,
                                            borderRadius: 10,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                        }}
                                    >
                                        <Form.Group className="is-invalid">
                                            <FileInput
                                                selectedFiles={
                                                    coverImage
                                                        ? [coverImage]
                                                        : []
                                                }
                                                accept={{
                                                    "image/*": [
                                                        ".png",
                                                        ".gif",
                                                        ".jpeg",
                                                        ".jpg",
                                                    ],
                                                }}
                                                height={180}
                                                width={270}
                                                placeholder="Upload new cover image."
                                                error={formErrors["coverImage"]}
                                                onDrop={(files) => {
                                                    if (
                                                        formErrors["coverImage"]
                                                    ) {
                                                        delete formErrors.coverImage;
                                                        setFormErrors(
                                                            formErrors
                                                        );
                                                    }
                                                    if (files?.length) {
                                                        setCoverImage(files[0]);
                                                    } else {
                                                        setCoverImage(
                                                            undefined
                                                        );
                                                    }
                                                }}
                                                containerClass="text-white"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <Form.Label>Column Data</Form.Label>

                                    {columnData?.map((c, i) => (
                                        <Row className="mt-3" key={i}>
                                            <Col xs={12} md={4}>
                                                <Form.Group>
                                                    <Input
                                                        placeholder="Column Name"
                                                        value={c.columnName}
                                                        onChange={(e) => {
                                                            if (
                                                                formErrors?.[
                                                                "columnData"
                                                                ]?.[
                                                                i.toString()
                                                                ]?.[
                                                                "columnName"
                                                                ]
                                                            ) {
                                                                delete formErrors?.[
                                                                    "columnData"
                                                                ]?.[
                                                                    i.toString()
                                                                ]?.[
                                                                    "columnName"
                                                                ];
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                });
                                                            }
                                                            c.columnName =
                                                                e.target.value;
                                                            setColumnData([
                                                                ...columnData,
                                                            ]);
                                                        }}
                                                        error={
                                                            formErrors?.[
                                                            "columnData"
                                                            ]?.[i.toString()]?.[
                                                            "columnName"
                                                            ]
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={8}
                                                className="d-flex flex-row justify-content-center"
                                            >
                                                <Form.Group className="flex-fill">
                                                    <Input
                                                        placeholder="Column Description"
                                                        value={
                                                            c.columnDescription
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                formErrors?.[
                                                                "columnData"
                                                                ]?.[
                                                                i.toString()
                                                                ]?.[
                                                                "columnDescription"
                                                                ]
                                                            ) {
                                                                delete formErrors?.[
                                                                    "columnData"
                                                                ]?.[
                                                                    i.toString()
                                                                ]?.[
                                                                    "columnDescription"
                                                                ];
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                });
                                                            }
                                                            c.columnDescription =
                                                                e.target.value;
                                                            setColumnData([
                                                                ...columnData,
                                                            ]);
                                                        }}
                                                        error={
                                                            formErrors?.[
                                                            "columnData"
                                                            ]?.[i.toString()]?.[
                                                            "columnDescription"
                                                            ]
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={8}
                                                className="d-flex flex-row justify-content-center ms-auto my-2"
                                            >
                                                <Form.Group className="flex-fill">
                                                    <Input
                                                        placeholder="Catgories"
                                                        value={
                                                            c.categories
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                formErrors?.[
                                                                "columnData"
                                                                ]?.[
                                                                i.toString()
                                                                ]?.[
                                                                "categories"
                                                                ]
                                                            ) {
                                                                delete formErrors?.[
                                                                    "columnData"
                                                                ]?.[
                                                                    i.toString()
                                                                ]?.[
                                                                    "categories"
                                                                ];
                                                                setFormErrors({
                                                                    ...formErrors,
                                                                });
                                                            }
                                                            c.categories =
                                                                e.target.value;
                                                            setColumnData([
                                                                ...columnData,
                                                            ]);
                                                        }}
                                                        error={
                                                            formErrors?.[
                                                            "columnData"
                                                            ]?.[i.toString()]?.[
                                                            "categories"
                                                            ]
                                                        }
                                                    />
                                                </Form.Group>
                                                <Button
                                                    title="Remove Column"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        columnData.splice(i, 1);
                                                        setColumnData([
                                                            ...columnData,
                                                        ]);
                                                    }}
                                                    size="sm"
                                                >
                                                    <FontAwesomeIcon
                                                        color="red"
                                                        icon={faClose}
                                                    />
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Button
                                        onClick={() => {
                                            columnData.push({
                                                columnName: "",
                                                columnDescription: "",
                                                categories: ""
                                            });
                                            setColumnData([...columnData]);
                                        }}
                                        className="mt-3"
                                    >
                                        <FontAwesomeIcon icon={faAdd} /> Add
                                        Column
                                    </Button>
                                </div>

                                <Row className="mt-3">
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Input
                                                label="Sources"
                                                asTextArea
                                                placeholder="Separated by line"
                                                value={sources}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.["sources"]
                                                    ) {
                                                        delete formErrors?.[
                                                            "sources"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    setSources(e.target.value);
                                                }}
                                                error={formErrors?.["sources"]}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Input
                                                asTextArea
                                                label="Collaborators"
                                                placeholder="Separated by line"
                                                value={collaborators}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.[
                                                        "collaborators"
                                                        ]
                                                    ) {
                                                        delete formErrors?.[
                                                            "collaborators"
                                                        ];
                                                        setCollaborators({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    setCollaborators(
                                                        e.target.value
                                                    );
                                                }}
                                                error={
                                                    formErrors?.[
                                                    "collaborators"
                                                    ]
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Input
                                                label="License"
                                                asTextArea
                                                placeholder="License Info"
                                                value={license}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.["license"]
                                                    ) {
                                                        delete formErrors?.[
                                                            "license"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    setLicense(e.target.value);
                                                }}
                                                error={formErrors?.["license"]}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <InputSelect
                                                labelKey="label"
                                                valueKey="value"
                                                onChange={(e) =>
                                                    setType(e.value)
                                                }
                                                label="Type"
                                                placeholder="Select Type"
                                                value={types.find(
                                                    (x) => x.value === type
                                                )}
                                                options={types}
                                                error={formErrors["type"]}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Input
                                                label="PQ EPS"
                                                placeholder="pq_eps"
                                                type="number"
                                                value={pqEps?.toString()}
                                                onChange={(e) => {
                                                    if (formErrors?.["pqEps"]) {
                                                        delete formErrors?.[
                                                            "pqEps"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    if (
                                                        !isNaN(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    )
                                                        setPqEps(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                }}
                                                error={formErrors?.["pqEps"]}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Input
                                                label="PQ Delta"
                                                placeholder="pq_delta"
                                                type="number"
                                                value={pqDelta?.toString()}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.["pqDelta"]
                                                    ) {
                                                        delete formErrors?.[
                                                            "pqDelta"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    if (
                                                        !isNaN(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    )
                                                        setPqDelta(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                }}
                                                error={formErrors?.["pqDelta"]}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Input
                                                label="Max EPS"
                                                placeholder="max_eps"
                                                type="number"
                                                value={maxEps?.toString()}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.["maxEps"]
                                                    ) {
                                                        delete formErrors?.[
                                                            "maxEps"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    if (
                                                        !isNaN(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    )
                                                        setMaxEps(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                }}
                                                error={formErrors?.["maxEps"]}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group>
                                            <Input
                                                label="Max Delta"
                                                placeholder="max_delta"
                                                type="number"
                                                value={maxDelta?.toString()}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.["maxDelta"]
                                                    ) {
                                                        delete formErrors?.[
                                                            "maxDelta"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    if (
                                                        !isNaN(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    )
                                                        setMaxDelta(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                }}
                                                error={formErrors?.["maxDelta"]}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mt-3">
                                    <Form.Label>About</Form.Label>
                                    <RichTextEditor
                                        value={about}
                                        onChange={(val) => setAbout(val)}
                                    />
                                    {formErrors["about"] ? (
                                        <div className="text-invalid-feedback mt-1">
                                            {formErrors["dataFile"]}
                                        </div>
                                    ) : undefined}
                                </Form.Group>

                                {formErrors[""] ? (
                                    <div className="text-invalid-feedback m-2">
                                        {formErrors[""]}
                                    </div>
                                ) : undefined}

                                <Form.Group
                                    className="mt-5"
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <Button type="submit">Update</Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </>
            ) : undefined}
            <LoadingSpinner show={loading} text={"Updating Dataset"} />
        </div>
    );
}
