import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";

// import: assets
import trophy from "../../assets/images/list_item_placeholder.png";
import alert from "../../assets/icons/alert-circle.png";
import {
    faEdit,
    faAdd,
    faClose,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import maximize from "../../assets/icons/maximize.png";
import minimize from "../../assets/icons/minimize.png";

// import: styles
import "./index.scss";

// import: constants
import {
    COMPETITION_ID,
    COMPETITIONS,
    EDIT,
    ERROR,
    PROFILE,
} from "../../router/routes";

// import: enums
import { UserRole } from "../../enums/roles";
import {
    PrizeType as PrizeTypeEnum,
    CompetitionMembershipType,
    EditAccessType,
} from "../../enums/prize-type";

// import: types
import {
    ICompetitionLeaderboard,
    IAllowedUsers,
    ICompetition,
} from "../../types/competition-types";
import { IDataset } from "../../types/dataset-types";
import { IUserAuth0Creds } from "../../types/auth-types";

// import: utils
import {
    timeElapsed,
    HoursRemaining,
    isCompleted,
    convertDate,
    addDays,
    validateEmail,
    tryParseJSONObject,
} from "../../utils/helper-methods";

// import: data
import CompetitionDatasetInfo from "./competition-dataset-info";

// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    deleteCompetition,
    loadCompetitionInfoBySlug,
    setCompetitionInfo,
} from "../../store/slices/competition-slice";
import {
    showErrorModal,
    showInfoModal,
} from "../../store/slices/modal-states-slice";

// import: api
import CompetitionApi from "../../api/competition-api";
import CompetitionTeamsApi from "../../api/competition-teams-api";
import DatasetApi from "../../api/dataset-api";
import AuthApi from "../../api/auth-api";

// import: config
import { CompetitionBanner } from "../../configs/api-config";

// import: components
import { Form } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import LoadingSpinner from "../../components/loading-spinner";
import CodeBlock from "../../components/code-block";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserImg from "../../components/user-img";
import { HandleError } from "../../errors/handler";
import ErrorAlert from "../../components/error-alert";
import CategoryIcons from "../../components/category-icons";
import CustomAccordian from "../../components/custom-accordian";
import RankingBadge from "../../components/ranking-badge";
import CompetitionQuickstart from "./competition-quickstart";
import Input from "../../components/input/input";

export enum EmailType {
    RE_INVITE_ALL_MEMBERS = "Re-inviting all members",
    WAKE_UP_CALL = "Wake-up call",
}

// type LbUser = {
//     name: string;
//     id: string;
// };

// type LbEntry = {
//     user: LbUser[];
//     score: number;
// };

export default function CompetitionInfoPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { [COMPETITION_ID]: _slug } = useParams();

    const user = useAppSelector((s) => s.auth.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDataset, setLoadingDataset] = useState<boolean>(false);
    const [noImage, setNoImage] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const competitions = useAppSelector((s) => s.competition.pageData.data);
    const competitionInfo = useAppSelector(
        (store) => store.competition.competitionInfo
    );
    const competition = useMemo(() => {
        const filteredCompetitions = competitions.filter((c) => c.slug === _slug || c._id === _slug);
        if (!competitionInfo && filteredCompetitions.length > 0) {
            return filteredCompetitions[0];
        }
        return competitionInfo;
    }, [_slug, competitions, competitionInfo]);

    const [leaderboard, setLeaderboard] = useState<ICompetitionLeaderboard[]>();

    // const [leaderboardEntries, setLeaderboardEntries] = useState<LbEntry[]>();
    const [dataset, setDataset] = useState<IDataset[]>();
    const [maximized, setMaximized] = useState(false);
    const [authCreds, setAuthCreds] = useState<IUserAuth0Creds>();
    const [metadata, setMetadata] = useState<any[]>();
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const [createTeam, setCreateTeam] = useState<boolean>(false);
    const [teamName, setTeamName] = useState<string>("");
    const [membersToAdd, setMembersToAdd] = useState<string[]>([]);
    const [memberEMail, setMemberEMail] = useState<string>("");
    const [mailError, setMailError] = useState<string>("");
    const [teamError, setTeamError] = useState<string>("");

    const [updateMembersAccordian, setUpdateMembersAccordian] =
        useState<boolean>(false);

    const [deleteConfirmation, setDeleteConfirmation] =
        useState<boolean>(false);

    const [invitedMembers, setInvitedMembers] = useState<IAllowedUsers[]>([]);
    const [memberSearch, setMemberSearch] = useState<string>("");
    const [invitedMembersString, setInvitedMembersString] =
        useState<string>("");
    const [memberError, setMemberError] = useState<string>("");
    const [isTeamLeader, setIsTeamLeader] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);

    const [newMemberEmail, setNewMemberEmail] = useState<string>("");
    const [teamAddId, setTeamAddId] = useState<string>("");
    const addMemberFormRef = useRef<HTMLFormElement>(null);

    const [userRemoveId, setUserRemoveId] = useState<string>("");
    const [teamRemoveId, setTeamRemoveId] = useState<string>("");
    const removeMemberFormRef = useRef<HTMLFormElement>(null);

    const [showConfirmSendEmailsModal, setShowConfirmSendEmailsModal] = useState<boolean>(false);
    const [sendEmailsType, setSendEmailsType] = useState<string>();
    const [confirmSendEmailsInput, setConfirmSendEmailsInput] = useState<string>('');

    const competitionCode = `import antigranular as ag

session = ag.login(
    "********", "************",
    competition = "${competition ? competition.title : "competition_name"}")`;

    const competitionCopyCode = `import antigranular as ag
session = ag.login("${authCreds?.clientId || "ag_user_id"}", "${authCreds?.clientSecret || "ag_user_secret"
        }", competition = "${competition ? competition.title : "competition_name"
        }")`;

    const getAuthCreds = async () => {
        try {
            const holder = await AuthApi.getAuth0();
            setAuthCreds(holder);
        } catch (err) {
            // setError(HandleError(err)[""]);
        }
    };

    useEffect(() => {
        if (user && competition && isCompleted(competition.startDate)) {
            getAuthCreds();
        }
    }, [user, competition]);

    const fetchCompetition = (_slug: string) => {
        setLoading(true);
        dispatch(loadCompetitionInfoBySlug({ _slug }))
            .unwrap()
            .then((res) => {
                if (res.slug && _slug !== res.slug) {
                    window.history.replaceState(null, "", `/${COMPETITIONS}/${res.slug}`);
                }
            })
            .catch((error) => {
                setError(HandleError(error)[""]);
                navigate(`/${ERROR}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onMemberSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!teamAddId) {
            setMemberError("Enter Team Id");
            return;
        }
        if (!newMemberEmail || !validateEmail(newMemberEmail)) {
            setMemberError("Not a valid Email");
            return;
        }
        setLoading(true);
        CompetitionTeamsApi.addInvitedMember({
            teamId: teamAddId,
            email: newMemberEmail,
        })
            .then(() => {
                dispatch(
                    showInfoModal({
                        title: "Created",
                        message: "Team member invitation sent succcessfully.",
                    })
                );
            })
            .catch((error) => {
                setMemberError(HandleError(error)[""]);
            })
            .finally(() => {
                setLoading(false);
                setNewMemberEmail("");
                setTeamAddId("");
            });
    };

    const onMemberDelete = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!teamRemoveId) {
            setMemberError("Enter Team ID");
            return;
        }
        if (!userRemoveId) {
            setMemberError("Enter user ID");
            return;
        }
        setLoading(true);
        await CompetitionTeamsApi.removeMember({
            teamId: teamRemoveId,
            userId: userRemoveId,
        })
            .then(() => {
                dispatch(
                    showInfoModal({
                        title: "Updated",
                        message: "User removed from the team succcessfully.",
                    })
                );
            })
            .catch((error) => {
                setMemberError(HandleError(error)[""]);
            })
            .finally(() => {
                setLoading(false);
                setUserRemoveId("");
                setTeamRemoveId("");
            });
    };

    useEffect(() => {
        if (competition && competition.invitedMembersOnly) {
            if (memberSearch.trim()?.length > 3) {
                let delay = setTimeout(() => {
                    CompetitionApi.getAllowedMembers({
                        competitionId: competition._id,
                        page: 1,
                        size: 7,
                        fullName: memberSearch,
                    })
                        .then((data) => {
                            setInvitedMembers(data.data);
                        })
                        .catch((e) => console.log(e));
                }, 100);

                return () => clearTimeout(delay);
            } else if (invitedMembers?.length) {
                setInvitedMembers([]);
            }
        }
    }, [memberSearch]);

    useEffect(() => {
        if (_slug) {
            fetchCompetition(_slug);
        } else {
            dispatch(setCompetitionInfo(undefined));
            setError("Competition not found.");
        }
        return () => {
            dispatch(setCompetitionInfo(undefined));
        };
    }, [_slug, user]);

    useEffect(() => {
        if (user && competition && competition.joinedTeam) {
            if (
                competition.teamRules?.editAccessType ===
                EditAccessType.admin &&
                user.roles.includes(UserRole.Admin)
            ) {
                setIsTeamLeader(true);
                setEditable(true);
            } else if (
                competition.teamRules?.editAccessType === EditAccessType.creator
            ) {
                let creator = "";

                competition.joinedTeam.members.forEach((member) => {
                    if (
                        member.isLeader ||
                        member.user._id === competition.joinedTeam?.createdBy
                    )
                        creator = member.user._id;
                });

                if (
                    creator === user._id ||
                    user.roles.includes(UserRole.Admin)
                ) {
                    setIsTeamLeader(true);
                    if (
                        !isCompleted(
                            competition.registerByDate || competition.endDate
                        ) ||
                        user.roles.includes(UserRole.Admin)
                    )
                        setEditable(true);
                }
            } else if (
                competition.teamRules?.editAccessType ===
                EditAccessType.participant
            ) {
                setIsTeamLeader(true);
                if (
                    !isCompleted(
                        competition.registerByDate || competition.endDate
                    )
                )
                    setEditable(true);
            }
        }
    }, [competition]);

    const fetchDatasets = async (arrayId: string[]) => {
        setLoadingDataset(true);
        let dataHolder = dataset ? [...dataset] : [];
        let metaHolder = metadata ? [...metadata] : [];

        for (let item of arrayId) {
            try {
                const dataset = await DatasetApi.get(item);
                if (dataset) {
                    dataHolder.push(dataset);
                    metaHolder.push(dataset.metadata);
                }
            } catch (error) {
                setError(HandleError(error)[""]);
            } finally {
                setLoadingDataset(false);
            }
        }

        setDataset(dataHolder);
        setMetadata(metaHolder);
    };

    useEffect(() => {
        if (!competition?.externalDataset) {
            if (
                competition &&
                competition.dataset_id?.length !== 0 &&
                !dataset
            ) {
                fetchDatasets(competition.dataset_id);
            } else if (competition?.dataset && !dataset) {
                fetchDatasets([competition.dataset]);
            }
        }

        if (
            _slug &&
            competition &&
            isCompleted(competition.startDate) &&
            !competition.privateLeaderboard
        ) {
            CompetitionApi.getLeaderboard(_slug)
                .then((lb) => {
                    const updated = lb.sort((item1, item2) => {
                        if (item1.leaderboard && item2.leaderboard) {
                            if (item1.leaderboard > item2.leaderboard)
                                return -1;
                            else return 1;
                        } else if (item1.validation && item2.validation) {
                            if (item1.validation > item2.validation) return -1;
                            else return 1;
                        } else return 1;
                    });
                    setLeaderboard(updated);
                })
                .catch((error) => {
                    console.log(
                        "leaderboard not generated ",
                        error.data.errors
                    );
                });
        }
    }, [competition, _slug]);

    const deleteSelectedCompetition = async () => {
        try {
            setLoading(true);
            if (competition?._id) {
                await dispatch(deleteCompetition(competition?._id)).unwrap();
                navigate(`/${COMPETITIONS}`);
            } else {
                setError("Error deleting competition: unknown ID");
            }
            setError(HandleError(error)[""]);
        } finally {
            setLoading(false);
            setConfirmDelete(false);
        }
    };

    // Refreshing leaderboard after every 7 seconds
    useEffect(() => {
        if (
            _slug &&
            competition &&
            isCompleted(competition.startDate) &&
            !isCompleted(competition.endDate) &&
            !competition.privateLeaderboard
        ) {
            const interval = setInterval(() => {
                CompetitionApi.getLeaderboard(_slug)
                    .then((lb) => {
                        const updated = lb.sort((item1, item2) => {
                            if (item1.leaderboard && item2.leaderboard) {
                                if (item1.leaderboard > item2.leaderboard)
                                    return -1;
                                else return 1;
                            } else if (item1.validation && item2.validation) {
                                if (item1.validation > item2.validation)
                                    return -1;
                                else return 1;
                            } else return 1;
                        });
                        setLeaderboard(updated);
                    })
                    .catch((error) => {
                        console.log(
                            "leaderboard not generated ",
                            error.data.errors
                        );
                    });
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [competition, _slug]);

    // updating leaderboard entries
    // useEffect(() => {
    //     let rank = 0;
    //     const entryList: LbEntry[] = [];
    //     if (leaderboard && leaderboard?.length > 0) {
    //         leaderboard.forEach((lb, idx) => {
    //             if (idx > 0) {
    //                 if (lb.leaderboard) {
    //                     if (lb.leaderboard === leaderboard[idx - 1].leaderboard)
    //                         entryList[rank].user.push({
    //                             name: lb.user.fullName,
    //                             id: lb.user._id,
    //                         });
    //                     else {
    //                         rank += 1;
    //                         entryList.push({
    //                             user: [
    //                                 { name: lb.user.fullName, id: lb.user._id },
    //                             ],
    //                             score: lb.leaderboard,
    //                         });
    //                     }
    //                 } else if (lb.validation) {
    //                     if (lb.validation === leaderboard[idx - 1].validation)
    //                         entryList[rank].user.push({
    //                             name: lb.user.fullName,
    //                             id: lb.user._id,
    //                         });
    //                     else {
    //                         rank += 1;
    //                         entryList.push({
    //                             user: [
    //                                 { name: lb.user.fullName, id: lb.user._id },
    //                             ],
    //                             score: lb.validation,
    //                         });
    //                     }
    //                 }
    //             } else if (lb.leaderboard || lb.validation)
    //                 entryList.push({
    //                     user: [{ name: lb.user.fullName, id: lb.user._id }],
    //                     score: lb.leaderboard || lb.validation || NaN,
    //                 });
    //         });
    //         const sortedList = [...entryList].sort((item1, item2) =>
    //             item1.score > item2.score ? -1 : 1
    //         )
    //         setLeaderboardEntries(sortedList);
    //     }
    // }, [leaderboard]);

    useEffect(() => {
        const tabs = document.querySelector(".tab-content");

        if (maximized) tabs?.classList.add("tab-content-maximized");
        else tabs?.classList.remove("tab-content-maximized");
    });

    // Creating Team
    const createCompetitionTeam = async () => {
        setLoading(true);
        setCreateTeam(false);
        if (
            competition?.teamRules &&
            competition?.participantType === CompetitionMembershipType.TEAM
        ) {
            if (membersToAdd?.length + 1 < competition.teamRules.minMembers) {
                setTeamError(
                    `Minimum ${competition.teamRules.minMembers} members needed to create a team.`
                );
                return;
            } else if (
                membersToAdd?.length + 1 >
                competition.teamRules.maxMembers
            ) {
                setTeamError(
                    `Team can only have maximum ${competition.teamRules.maxMembers} members.`
                );
                return;
            } else if (teamName.trim() === "") {
                setTeamError("Please enter a valid name");
                return;
            }

            CompetitionTeamsApi.create({
                competition: competition._id,
                members: membersToAdd,
                name: teamName,
            })
                .then(() => {
                    dispatch(
                        showInfoModal({
                            title: "Created",
                            message: "Team created succcessfully.",
                        })
                    );
                })
                .catch((error) => {
                    dispatch(
                        showErrorModal({
                            title: "Error",
                            message: HandleError(error)[""],
                        })
                    );
                })
                .finally(() => {
                    setLoading(false);
                    resetTeamForm();
                    fetchCompetition(competition._id);
                });
        }
    };

    const resetTeamForm = () => {
        setMembersToAdd([]);
        setMemberEMail("");
        setTeamName("");
        setCreateTeam(false);
    };

    // Add Member to team list
    const addMember = (mail: string) => {
        if (competition && competition.teamRules) {
            if (membersToAdd?.length >= competition.teamRules?.maxMembers) {
                setMailError(
                    "Max team size reached, you can't add any more members"
                );
            } else {
                if (!validateEmail(mail)) setMailError("Not a valid email id");
                else {
                    if (!membersToAdd.includes(mail)) {
                        let holder = [...membersToAdd];
                        holder.push(mail);
                        setMembersToAdd(holder);
                        if (memberEMail) setMemberEMail("");
                        if (memberSearch) setMemberSearch("");
                    } else {
                        setMailError("User is already a part of your team");
                        if (memberEMail) setMemberEMail("");
                        if (memberSearch) setMemberSearch("");
                    }
                }
            }
        }
    };

    const addNewMember = (mail: string) => {
        if (competition?.joinedTeam && competition.teamRules) {
            setInvitedMembers([]);
            setLoading(true);
            if (
                competition.joinedTeam.members?.length >=
                competition.teamRules?.maxMembers
            ) {
                setTeamError(
                    "Max team size reached, you can't add any more members"
                );
                return;
            }
            if (!validateEmail(mail)) {
                setMailError("Not a valid email id");
                return;
            }

            if (competition && competition.joinedTeam) {
                CompetitionTeamsApi.addMember({
                    teamId: competition.joinedTeam._id,
                    email: mail,
                })
                    .then(() => {
                        fetchCompetition(competition._id);
                    })
                    .catch((error) => setTeamError(HandleError(error)[""]))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    // Add Member to team list in bulk
    const bulkAdd = () => {
        if (
            !invitedMembersString ||
            !tryParseJSONObject(invitedMembersString)
        ) {
            setMemberError("Invalid JSON object");
            return;
        }
        if (invitedMembersString && competition) {
            setLoading(true);
            CompetitionTeamsApi.createBulk({
                competition: competition._id,
                teams: JSON.parse(invitedMembersString),
            })
                .then(() => {
                    fetchCompetition(competition._id);
                    dispatch(
                        showInfoModal({
                            title: "Users added",
                            message: "Bulk users added Successfully!",
                        })
                    );
                })
                .catch((error) => setMemberError(HandleError(error)[""]))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const removeMember = (id: string) => {
        if (competition?.joinedTeam && competition.teamRules) {
            if (
                competition.joinedTeam.members?.length ===
                competition.teamRules?.minMembers
            ) {
                setTeamError(
                    "Team at minimum member capacity, can't delete any members."
                );
                return;
            }
            if (
                competition.joinedTeam.members?.length ===
                competition.teamRules?.minMembers
            ) {
                setTeamError(
                    "Team at minimum member capacity, can't delete any members."
                );
                return;
            }
            if (user?._id === id && isTeamLeader) {
                setTeamError("Team leader can't leave his team.");
                return;
            }
            setLoading(true);
            if (competition && competition.joinedTeam) {
                CompetitionTeamsApi.removeMember({
                    teamId: competition.joinedTeam._id,
                    userId: id,
                })
                    .then(() => {
                        let holder = { ...competition };
                        if (holder.joinedTeam)
                            holder.joinedTeam.members =
                                [
                                    ...holder.joinedTeam?.members.filter(
                                        (obj) => obj.user._id !== id
                                    ),
                                ];
                        // holder.joinedTeam?.members.splice(index, 1);
                        dispatch(setCompetitionInfo(holder));
                    })
                    .catch((error) => setTeamError(HandleError(error)[""]))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const resendInvite = (id: string, name: string) => {
        if (competition && competition.joinedTeam && competition.teamRules) {
            if (competition.joinedTeam.members?.length > 0) {
                setLoading(true);
                CompetitionTeamsApi.resendInvitation({
                    teamId: competition.joinedTeam._id,
                    userId: id,
                })
                    .then(() => {
                        dispatch(
                            showInfoModal({
                                title: "Invitation sent again!",
                                message: `Team invitation sent successfully to ${name}`,
                            })
                        );
                    })
                    .catch((error) => setTeamError(HandleError(error)[""]))
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const resendInviteAll = () => {
        if (competition && competition.teamRules) {
            setLoading(true);
            CompetitionTeamsApi.reInviteAll(competition._id)
                .then(() => {
                    dispatch(
                        showInfoModal({
                            title: "Invitation sent again!",
                            message: `Team invitation sent successfully to everyone in the competition`,
                        })
                    );
                })
                .catch((error) => setTeamError(HandleError(error)[""]))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const wakeUpCall = () => {
        if (competition && competition.teamRules) {
            setLoading(true);
            CompetitionTeamsApi.wakeUpCall(competition._id)
                .then(() => {
                    dispatch(
                        showInfoModal({
                            title: "Success!",
                            message: `Wake up call sent to the sleeping ones`,
                        })
                    );
                })
                .catch((error) => setTeamError(HandleError(error)[""]))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const deleteTeam = () => {
        setDeleteConfirmation(false);
        setLoading(true);
        if (competition && competition.joinedTeam) {
            CompetitionTeamsApi.delete(competition.joinedTeam._id)
                .then(() => {
                    let holder = { ...competition };
                    if (holder.joinedTeam) {
                        holder.joinedTeam = undefined;
                    }
                    dispatch(setCompetitionInfo(holder));
                })
                .catch((error) => {
                    setError(HandleError(error)[""]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const updateTeamName = () => {
        setLoading(true);
        if (competition && competition.joinedTeam) {
            CompetitionTeamsApi.update({
                teamId: competition.joinedTeam._id,
                name: teamName,
            })
                .then(() => {
                    let holder = { ...competition };
                    if (holder.joinedTeam) {
                        holder.joinedTeam.name = teamName;
                    }
                    dispatch(setCompetitionInfo(holder));

                    setTeamName("");
                })
                .catch((error) => setTeamError(HandleError(error)[""]))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const tabOptions = () => {
        return (
            <div className="screen-menu-cover">
                <div className="screen-menu">
                    <button
                        className={`screen-menu-item mb-md-2 me-2 me-md-0 mb-0 ${maximized ? "selected" : ""
                            }`}
                        onClick={() => setMaximized(true)}
                    >
                        <img alt="" src={maximize} />
                    </button>
                    <button
                        className={`screen-menu-item mb-md-2 me-2 me-md-0 mb-0 ${maximized ? "" : "selected"
                            }`}
                        onClick={() => setMaximized(false)}
                    >
                        <img alt="" src={minimize} />
                    </button>
                </div>
            </div>
        );
    };


    const hoursRemaining = (competition: any) => {
        return (HoursRemaining(
            competition.endDate,
            new Date()
        ) === "NA" ? (
            <span>
                Until{" "}
                {convertDate(
                    competition?.endDate?.toUTCString()
                )}{" "}
            </span>
        ) : (
            <span>
                {HoursRemaining(
                    competition.endDate,
                    new Date()
                )}{" "}
                remaining
            </span>
        ))
    }

    const getCompetitionDateComponent = () => {
        if (competition) {
            const formatStartDate = (comp: ICompetition) => (
                isCompleted(
                    comp.startDate
                ) ? (
                    <span className="text-xs endDate mb-2">
                        {hoursRemaining(comp)}
                    </span>
                ) : (
                    <span className="text-xs startDate mb-2">
                        <span className="me-1 selected-link">
                            Starting{" "}
                            {convertDate(
                                comp.startDate?.toUTCString()
                            )}
                        </span>
                    </span>
                )
            );

            const monetaryComp = (comp: ICompetition) => (
                isCompleted(comp.endDate) ? (
                    <li className="text-xs text-color-tertiary">
                        Finished{" "}
                        {timeElapsed(
                            comp.endDate,
                            new Date()
                        ) + " ago"}
                    </li>
                ) : (
                    formatStartDate(comp)
                )
            );

            return (
                competition.prizeDetail?.prizeType ===
                    PrizeTypeEnum.Monetary ? (
                    <div className="d-flex flex-column mt-2">
                        {monetaryComp(competition)}
                    </div>
                ) : ((!isCompleted(competition.startDate)) && (
                    <span className="text-xs startDate mb-2">
                        <span className="me-1 selected-link">
                            Starting{" "}
                            {convertDate(
                                competition.startDate?.toUTCString()
                            )}
                        </span>
                    </span>
                ))
            )
        } else {
            return undefined;
        }
    }

    const defaultActiveTab = (comp: ICompetition) => {
        const checkCompleted = (
            isCompleted(comp?.startDate)
                ? "tabOverview"
                : "tabDetails"
        )

        return (
            location.state?.leaderboard
                ? "tabLeaderboard"
                : checkCompleted
        )
    }
    return (
        <div className="page-container lg-box">
            <ErrorAlert errorMessage={error} show={error ? true : false} />

            <div className="d-flex flex-wrap">
                <div className="page-heading-container mb-0">
                    <div className="d-flex flex-sm-row flex-column-reverse">
                        {noImage ? (
                            <div className="page-heading-banner mb-3 mb-sm-0">
                                <img
                                    src={trophy}
                                    alt=""
                                    className="w-100 bg-light"
                                />
                            </div>
                        ) : undefined}
                        {competition && !noImage ? (
                            <div className="page-heading-banner mb-3 mb-sm-0">
                                <img
                                    src={
                                        CompetitionBanner(
                                            competition?._id ?? ""
                                        ) ?? trophy
                                    }
                                    className="w-100"
                                    alt=""
                                    onError={() => setNoImage(true)}
                                />
                            </div>
                        ) : undefined}

                        <div className="page-data-intro text-xs mb-4 d-sm-none d-block">
                            {competition && isCompleted(competition.startDate)
                                ? competition?.subTitle
                                : competition?.subTitle?.split("||BREAK||")[1]}
                        </div>

                        <div className="mb-21 mb-sm-0">
                            <div className="d-flex">
                                <span className="pb-1 text-3xl">
                                    {competition?.title}
                                </span>
                                {user?.roles.includes(UserRole.Admin)
                                    && competition
                                    && !isCompleted(competition?.endDate) ? (
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        className="ms-3 feature-icon"
                                        title="Edit"
                                        size="sm"
                                        onClick={() =>
                                            navigate(
                                                `/${COMPETITIONS}/${EDIT}/` + (competition?.slug ?? competition?._id)
                                            )
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            {getCompetitionDateComponent()}

                            <div>
                                {competition &&
                                    isCompleted(competition.startDate) &&
                                    !competition.externalDataset && (
                                        <span className="d-flex align-items-center pt-1">
                                            <img
                                                className="me-2"
                                                width={12}
                                                alt=""
                                                src={CategoryIcons(
                                                    competition?.type
                                                )}
                                            />
                                            <span className="text-capitalize text-xs text-color-tertiary">
                                                {competition?.type}
                                            </span>
                                        </span>
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="page-data-intro text-xs my-3 d-sm-block d-none">
                        {competition && isCompleted(competition.startDate)
                            ? competition?.subTitle
                            : competition?.subTitle?.split("||BREAK||")[1]}
                    </div>
                </div>
                <div className="d-none d-sm-block">
                    {competition && isCompleted(competition.startDate) ? (
                        <CodeBlock private copyText={competitionCopyCode}>
                            {competitionCode}
                        </CodeBlock>
                    ) : (
                        <></>
                    )}

                    {user?.roles?.includes(UserRole.Admin) ? (
                        <Button
                            variant="secondary"
                            className="mt-3 mb-3 rounded-pill"
                            onClick={() => setConfirmDelete(true)}
                        >
                            Delete
                        </Button>
                    ) : undefined}
                </div>
            </div>

            {competition ? (
                <div className="position-relative tabs-container-parent">
                    {user &&
                        (
                            (
                                competition.invited &&
                                competition.registerByDate &&
                                !isCompleted(competition.registerByDate)
                            ) ||
                            user.roles.includes(UserRole.Admin)
                        ) ? (
                        !isCompleted(competition.endDate) ? (
                            competition.joinedTeam ? (
                                (user?.roles.includes(UserRole.Admin) ||
                                    isTeamLeader) && (
                                    <Button
                                        className="rounded-pill team-btn"
                                        variant="link"
                                        onClick={() => setDeleteConfirmation(true)}
                                    >
                                        Delete Team
                                        <FontAwesomeIcon
                                            icon={faAdd}
                                            className="ms-2"
                                        />
                                    </Button>
                                )
                            ) : (
                                <Button
                                    className="rounded-pill team-btn"
                                    variant="link"
                                    onClick={() => {
                                        setMailError("");
                                        setTeamError("");
                                        setCreateTeam(true);
                                    }}
                                >
                                    Create Team
                                    <FontAwesomeIcon
                                        icon={faAdd}
                                        className="ms-2"
                                    />
                                </Button>
                            )
                        ) : (<></>)
                    ) : (
                        <></>
                    )}
                    <div className={maximized ? "back-cover" : " d-none"}></div>
                    <Tabs
                        className={`${maximized ? "info-page-tab-container" : ""
                            }`}
                        id="tab-competition"
                        defaultActiveKey={defaultActiveTab(competition)}
                    >
                        {!isCompleted(competition.startDate) ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"
                                    }`}
                                title={"Details"}
                                eventKey={"tabDetails"}
                            >
                                {tabOptions()}
                                <div className="info-page-tab-content text-color-secondary">
                                    <p className="text-lg w-75">
                                        About the competition
                                    </p>

                                    <div
                                        className="about-competition-content"
                                        dangerouslySetInnerHTML={{
                                            __html: competition?.overview ?? "",
                                        }}
                                    ></div>
                                </div>
                            </Tab>
                        ) : (
                            <></>
                        )}

                        {competition && isCompleted(competition.startDate) ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"
                                    }`}
                                title={"Overview"}
                                eventKey={"tabOverview"}
                            >
                                {tabOptions()}
                                <div className="info-page-tab-content text-color-secondary">
                                    <p className="text-lg w-75">
                                        About the competition
                                    </p>

                                    <div
                                        className="about-competition-content"
                                        dangerouslySetInnerHTML={{
                                            __html: competition?.overview ?? "",
                                        }}
                                    ></div>

                                    <div className="compWarning text-xs d-flex text-color-secondary">
                                        <img
                                            src={alert}
                                            width={16}
                                            height={16}
                                            alt=""
                                            className="me-2 mt-half"
                                        />
                                        <div>
                                            <p className="mb-1">
                                                Caution: Excessive epsilon spending may significantly impact your score. 
                                                To familiarize yourself with how the system works, 
                                                practice spending epsilon efficiently in{" "}
                                                {competition.prizeDetail
                                                    ?.prizeType === "Other"
                                                    ? "this "
                                                    : " our sandbox "}{" "}
                                                competition.
                                            </p>
                                            <p className="mb-0">
                                                Tip: Export important variables and save to a file for easy retrieval in case the kernel stops.
                                            </p>
                                        </div>
                                    </div>

                                    {/* ORGAINIZERS REMOVED AND REPLACED WITH ORGANIZATIONS */}
                                    {/* {competition.organizers && competition.organizers.length > 0 &&
                                    <CustomAccordian id="competitionOrganizers" heading="Organizers">
                                        <div>{competition.organizers.map((organizer, id) =>
                                            <p className="mb-1" key={"collaborator" + id}>{organizer}</p>)}
                                        </div>
                                    </CustomAccordian>
                                } */}
                                </div>
                            </Tab>
                        ) : (
                            <></>
                        )}

                        {competition && isCompleted(competition.startDate) ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"
                                    }`}
                                title={"Dataset"}
                                eventKey={"tabCompetitionDataset"}
                            >
                                {loadingDataset ? (
                                    <p className="text-center text-color-tertiary text-sm py-5 info-page-tab-content">
                                        Getting dataset information
                                    </p>
                                ) : dataset && metadata ? (
                                    <div
                                        className={`info-page-tab-content text-color-secondary ${dataset?.length > 1
                                            ? "pt-3 px-3"
                                            : ""
                                            }`}
                                    >
                                        {dataset?.length > 1 ? (
                                            <Tabs id="tab-competition-datasets">
                                                {dataset.map((item, id) => (
                                                    <Tab
                                                        className="px-2"
                                                        title={item.title}
                                                        key={
                                                            item
                                                                ? item._id
                                                                : "dataset" + id
                                                        }
                                                        eventKey={
                                                            "tabDataset" + id
                                                        }
                                                    >
                                                        <CompetitionDatasetInfo
                                                            item={item}
                                                            metadata={
                                                                metadata[id]
                                                            }
                                                        />
                                                    </Tab>
                                                ))}
                                            </Tabs>
                                        ) : (
                                            <CompetitionDatasetInfo
                                                item={dataset[0]}
                                                key={
                                                    dataset[0]
                                                        ? dataset[0]._id
                                                        : "dataset0"
                                                }
                                                metadata={
                                                    dataset[0]?.metadata || []
                                                }
                                            />
                                        )}
                                    </div>
                                ) : competition.externalDataset ? (
                                    <div className="info-page-tab-content text-color-secondary">
                                        <p className="text-lg w-75">
                                            Dataset details
                                        </p>

                                        <div
                                            className="about-competition-content"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    competition?.datasetDetails ??
                                                    "",
                                            }}
                                        ></div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Tab>
                        ) : (
                            <></>
                        )}

                        {competition &&
                            isCompleted(competition.startDate) &&
                            competition.prizeDetail?.prizeType ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"
                                    }`}
                                title={"Prize"}
                                eventKey={"tabPrize"}
                            >
                                {tabOptions()}
                                <div className="info-page-tab-content text-color-secondary">
                                    <p className="text-lg mb-3 w-75">
                                        About the prize
                                    </p>
                                    <div
                                        className="about-competition-content"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                competition.prizeDetail
                                                    ?.detail ??
                                                "Add details about Prizes",
                                        }}
                                    ></div>

                                    {competition.prizeDetail?.prizes?.length ? (
                                        <div className="glazed-card-cover d-flex flex-column radius-base text-sm">
                                            {competition.prizeDetail?.prizes?.map(
                                                (prize, idx) => (
                                                    <div
                                                        className={`prize-row ${idx === 0 &&
                                                            "first-prize"
                                                            } ${competition
                                                                .prizeDetail
                                                                ?.prizes
                                                                ?.length &&
                                                            idx ===
                                                            competition
                                                                .prizeDetail
                                                                ?.prizes
                                                                ?.length -
                                                            1 &&
                                                            "first-prize"
                                                            }`}
                                                        key={"prize" + idx}
                                                    >
                                                        <div className="prize-item badge-column">
                                                            <RankingBadge
                                                                miniText
                                                                rank={parseInt(
                                                                    prize.position
                                                                )}
                                                            />
                                                        </div>
                                                        {competition.prizeDetail
                                                            ?.prizeType ===
                                                            PrizeTypeEnum.Monetary ? (
                                                            <div className="prize-item amount-column">
                                                                {"€" +
                                                                    prize.amount}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <div className="prize-item description-column">
                                                            {prize.detail}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ) : undefined}

                                    {competition.prizeDetail
                                        ?.termsAndConditions ? (
                                        <>
                                            <p className="text-lg mt-4 mb-3 w-75">
                                                Terms and conditions
                                            </p>
                                            <div
                                                className="about-competition-content"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        competition.prizeDetail
                                                            ?.termsAndConditions ??
                                                        " ",
                                                }}
                                            ></div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </Tab>
                        ) : undefined}

                        {competition &&
                            isCompleted(competition.startDate) &&
                            competition.rules?.trim() !== "<p><br></p>" ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"
                                    }`}
                                title={"Rules"}
                                eventKey={"tabRules"}
                            >
                                {tabOptions()}
                                <div className="info-page-tab-content text-color-secondary">
                                    <p className="text-lg mb-3 w-75">
                                        Competition rules
                                    </p>
                                    <div
                                        className="about-competition-content"
                                        dangerouslySetInnerHTML={{
                                            __html: competition.rules ?? "",
                                        }}
                                    ></div>
                                </div>
                            </Tab>
                        ) : undefined}

                        {competition &&
                            isCompleted(competition.startDate) &&
                            !competition.privateLeaderboard ? (
                            <Tab
                                className={`info-page-tab space-top ${maximized ? "no-space" : "onFit"
                                    }`}
                                title={"Leaderboard"}
                                eventKey={"tabLeaderboard"}
                            >
                                {leaderboard && leaderboard?.length > 10
                                    ? tabOptions()
                                    : undefined}
                                {leaderboard && leaderboard?.length > 0 ? (
                                    <div className="info-page-tab-transparent text-color-secondary">
                                        <div
                                            className={`mb-3 ${maximized ? "d-none" : ""
                                                }`}
                                        >
                                            <p className="text-lg w-75">
                                                About Leaderboard
                                            </p>
                                            <div className="text-xs mb-21">
                                                Here's the leaderboard for this
                                                competition where you can get
                                                real-time insights into the top
                                                performers. Join the competition
                                                to track your progress and get
                                                motivated to improve your
                                                skills.
                                            </div>

                                            {isCompleted(competition.endDate) &&
                                                addDays(
                                                    competition?.endDate,
                                                    4
                                                ).getTime() > Date.now() ? (
                                                <div className="compWarning text-xs mb-3 d-flex text-color-secondary">
                                                    <img
                                                        src={alert}
                                                        width={16}
                                                        height={16}
                                                        alt=""
                                                        className="me-2 mt-half"
                                                    />
                                                    <div>
                                                        <p className="mb-1">
                                                            The competition has now concluded and is closed for new submissions. 
                                                            We greatly appreciate everyone's participation. 
                                                            We will reach out directly to the winners for further details regarding their prizes and next steps.
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {isCompleted(competition.startDate) && !isCompleted(competition.endDate) ? 
                                                <div className="glazed-card-cover mt-2 mb-3 rounded-2">
                                                    <div className="glazed-card text-xs d-flex text-color-secondary rounded-2 p-21 pt-2">
                                                        <div className="me-2 mt-half">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_7874_17819)">
                                                                    <path d="M8.00065 5.33331V7.99998M8.00065 10.6666H8.00732M14.6673 7.99998C14.6673 11.6819 11.6825 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6819 1.33398 7.99998C1.33398 4.31808 4.31875 1.33331 8.00065 1.33331C11.6825 1.33331 14.6673 4.31808 14.6673 7.99998Z" stroke="#D92D20" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_7874_17819">
                                                                        <rect width="16" height="16" fill="white"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <div className="warning-content">
                                                            <p className="mb-0 error-message heading">
                                                                Note: Entries with over 20 submissions are marked with a red asterisk*. 
                                                            </p>
                                                            <p className="mb-0">
                                                                High leaderboard rankings from frequent resubmissions may not hold—these users often have lower chances of winning. 
                                                            </p>
                                                            <p className="mb-0">
                                                                Only part of each user's submissions counts toward the live leaderboard, 
                                                                while the final ranking is based on a separate validation set.
                                                            </p>
                                                            <p className="mb-0">
                                                                Don't be discouraged by these participants; 
                                                                they have almost certainly overfit their submissions! 
                                                            </p>
                                                            <p className="mb-0">
                                                                Learn more about how the leaderboard works <a 
                                                                    href="https://docs.antigranular.com/antigranular/scoring-metrics/leaderboard/" 
                                                                    target="_blank" rel="noreferrer" className="inLine-link">here</a>.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> : <></>
                                            }

                                            <div className="position-relative ms-4 text-thick text-xs d-flex">
                                                <div className="rankHolder"></div>
                                                <span>Prize contenders</span>

                                                <div className="ms-5 me-2 error-message text-lg lh-sm">*</div>
                                                <span>Potential Overfit</span>
                                            </div>
                                        </div>
                                        <div
                                            className={`glazed-card-cover radius-base`}
                                        >
                                            <div className=" glazed-card competition-columns">
                                                <p className="ms-3 mb-21">
                                                    {competition?.title}
                                                    {addDays(
                                                        competition?.endDate,
                                                        4
                                                    ).getTime() < Date.now()
                                                        ? " final"
                                                        : " "}{" "}
                                                    Leaderboard
                                                </p>
                                                <div className="leaderboard-table">
                                                    <Table
                                                        bordered
                                                        className="mb-0 text-center"
                                                    >
                                                        <thead>
                                                            <tr className="sticky-row bg-glazed-card-1">
                                                                <th className="d-none d-md-block border-none">
                                                                    Position
                                                                </th>
                                                                <th className="d-md-none px-3 border-none">
                                                                    .
                                                                </th>
                                                                <th>Name</th>
                                                                <th>Score</th>
                                                                <th>Entries</th>
                                                                {/* <th>Last Submitted</th> */}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {leaderboard && leaderboard.length > 0 ? (
                                                                leaderboard.map((row, idx) => (
                                                                        <tr key={"entry " + idx}>
                                                                            <td className="text-center"
                                                                                key={"rank" + idx}
                                                                            >
                                                                                <span className="rankCover">
                                                                                    {idx + 1}{" "}
                                                                                    {idx < 3 ? (
                                                                                        <div className="rankHolder"></div>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </span>
                                                                            </td>
                                                                            <td key={ "name" + idx}>
                                                                                {user ? (
                                                                                    <a
                                                                                        href={`/${PROFILE}/` + row.user._id}
                                                                                        className={`link-ag-2 cursor-pointer`}
                                                                                    >
                                                                                        {row.user.fullName}
                                                                                    </a>
                                                                                ) : (
                                                                                    <span>{row.user.fullName}</span>
                                                                                )}
                                                                            </td>
                                                                            <td key={"score" + idx}>
                                                                                {isCompleted(competition.endDate) ? 
                                                                                    row.validation && parseFloat(row.validation.toFixed(4)) :
                                                                                    row.leaderboard && parseFloat(row.leaderboard.toFixed(4))
                                                                                }
                                                                            </td>
                                                                            <td className={row.submissionsCount > 20 ? "error-message" : ""}>
                                                                                {row.submissionsCount > 20 ? 
                                                                                    row.submissionsCount + "*" : row.submissionsCount
                                                                                }
                                                                            </td>
                                                                            {/* <td>{timeElapsed(row.lastSubmittedDate, new Date())}</td> */}
                                                                        </tr>
                                                                    )
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-center info-page-tab-content">
                                        No competition submissions received so
                                        far
                                    </div>
                                )}
                            </Tab>
                        ) : undefined}

                        {isCompleted(competition.startDate) ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"
                                    }`}
                                title={"Quickstart"}
                                eventKey={"tabQuickstart"}
                            >
                                {tabOptions()}
                                <CompetitionQuickstart
                                    isExternal={
                                        competition.externalDataset || false
                                    }
                                />
                            </Tab>
                        ) : (
                            <></>
                        )}

                        {competition &&
                            competition.participantType ===
                            CompetitionMembershipType.TEAM ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"}`}
                                title={"Team"}
                                eventKey={"tabTeam"}
                            >
                                {user ? (
                                    competition.invited ? (
                                        <>
                                            {tabOptions()}
                                            <div className="info-page-tab-content text-color-secondary">
                                                {competition.joinedTeam ? (
                                                    <div>
                                                        <p className="text-xl text-thick mb-2">
                                                            {
                                                                competition
                                                                    .joinedTeam
                                                                    .name
                                                            }
                                                        </p>
                                                        {isTeamLeader
                                                            && !isCompleted(competition.endDate)
                                                            && editable ? (
                                                            <>
                                                                <div className="name-input">
                                                                    <Input
                                                                        placeholder={
                                                                            competition
                                                                                .joinedTeam
                                                                                .name
                                                                        }
                                                                        value={
                                                                            teamName
                                                                        }
                                                                        className="border-0 ps-3"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                teamError
                                                                            ) {
                                                                                setTeamError(
                                                                                    ""
                                                                                );
                                                                            }
                                                                            setTeamName(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        onClick={() =>
                                                                            updateTeamName()
                                                                        }
                                                                        className={`rounded-pill ms-2 h-fit mt-auto ${teamName
                                                                            ? ""
                                                                            : "invisible-btn"
                                                                            }`}
                                                                        variant="secondary"
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </div>
                                                                {!competition.privateLeaderboard ? (
                                                                    <p className="text-xs text-color-tertiary ms-3 mb-0">
                                                                        This name will appear on your team's leaderboard position.
                                                                    </p>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                                <div className="team-members-update flex-column dropdown-parent mt-3">
                                                                    <CustomAccordian
                                                                        expanded={
                                                                            updateMembersAccordian
                                                                        }
                                                                        switch={() =>
                                                                            setUpdateMembersAccordian(
                                                                                !updateMembersAccordian
                                                                            )
                                                                        }
                                                                        className="p-21 w-100"
                                                                        id={
                                                                            "add-members"
                                                                        }
                                                                        heading="Add members"
                                                                        inline
                                                                        dark
                                                                    >
                                                                        <div className="ps-21">
                                                                            <p className="mb-2 w-100">
                                                                                Your can invite{" "}
                                                                                {competition?.teamRules
                                                                                    ? competition
                                                                                        .teamRules
                                                                                        ?.maxMembers -
                                                                                    membersToAdd?.length
                                                                                    : 0}{" "}
                                                                                more people to join your team.
                                                                            </p>
                                                                            {competition?.invitedMembersOnly ? (
                                                                                <div className="w-100">
                                                                                    <Input
                                                                                        autoFocus
                                                                                        focusOnError={
                                                                                            true
                                                                                        }
                                                                                        placeholder="Search Name"
                                                                                        className="w-100 xxs-box"
                                                                                        value={
                                                                                            memberSearch
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            if (
                                                                                                mailError
                                                                                            )
                                                                                                setMailError(
                                                                                                    ""
                                                                                                );
                                                                                            if (
                                                                                                teamError
                                                                                            )
                                                                                                setTeamError(
                                                                                                    ""
                                                                                                );

                                                                                            setMemberSearch(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="d-flex">
                                                                                    <Input
                                                                                        autoFocus
                                                                                        placeholder="Enter email of memebrs to add"
                                                                                        value={
                                                                                            memberEMail
                                                                                        }
                                                                                        className="py-3"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            if (
                                                                                                mailError
                                                                                            )
                                                                                                setMailError(
                                                                                                    ""
                                                                                                );
                                                                                            if (
                                                                                                teamError
                                                                                            )
                                                                                                setTeamError(
                                                                                                    ""
                                                                                                );

                                                                                            setMemberEMail(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            addNewMember(
                                                                                                memberEMail
                                                                                            )
                                                                                        }
                                                                                        className="rounded-pill ms-2 h-fit mt-auto"
                                                                                        variant="secondary"
                                                                                    >
                                                                                        Add
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </CustomAccordian>

                                                                    {invitedMembers &&
                                                                        updateMembersAccordian &&
                                                                        invitedMembers?.length >
                                                                        0 ? (
                                                                        <div className="users-dp-holder dropdown-holder glazed-card-cover radius-base">
                                                                            <div className="glazed-card radius-base py-2 text-capitalize">
                                                                                {invitedMembers.map(
                                                                                    (
                                                                                        member,
                                                                                        idx
                                                                                    ) => (
                                                                                        <p
                                                                                            key={
                                                                                                "ListItm" +
                                                                                                idx
                                                                                            }
                                                                                            className="dropdown-item text-color-tertiary text-xs cursor-pointer py-2 px-3 mb-0"
                                                                                            onClick={() => {
                                                                                                addNewMember(
                                                                                                    member.email
                                                                                                );
                                                                                                setMemberSearch(
                                                                                                    ""
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {`${member.fullName}`}
                                                                                        </p>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <div>
                                                            <p className="text-xl text-thick mb-half">
                                                                Team members
                                                            </p>
                                                            {isTeamLeader ? (
                                                                <span className="text-xs">
                                                                    Your team can have a minimum of {competition.teamRules?.minMembers} and a maximum of {competition.teamRules?.maxMembers} members.
                                                                </span>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div>
                                                                {competition.joinedTeam.members
                                                                    .filter(
                                                                        (m) =>
                                                                            m.accepted
                                                                    )
                                                                    .map(
                                                                        (
                                                                            member,
                                                                            idx
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    "acceptedMember" +
                                                                                    idx
                                                                                }
                                                                                className="team-members"
                                                                            >
                                                                                <UserImg
                                                                                    width={
                                                                                        56
                                                                                    }
                                                                                    height={
                                                                                        56
                                                                                    }
                                                                                    onClick={() => {
                                                                                        navigate(
                                                                                            `/${PROFILE}/` +
                                                                                            member
                                                                                                .user
                                                                                                ._id
                                                                                        );
                                                                                    }}
                                                                                    className="cursor-pointer"
                                                                                    userId={
                                                                                        member
                                                                                            .user
                                                                                            ._id
                                                                                    }
                                                                                    alt="user img"
                                                                                />
                                                                                <div className="ms-3">
                                                                                    <p className="text-xl text-thick mb-0">
                                                                                        {
                                                                                            member
                                                                                                .user
                                                                                                .fullName
                                                                                        }
                                                                                    </p>
                                                                                    <span className="text-xs text-color-tertiary">
                                                                                        {member
                                                                                            .user
                                                                                            ._id ===
                                                                                            competition
                                                                                                .joinedTeam
                                                                                                ?.createdBy ||
                                                                                            member.isLeader
                                                                                            ? "Team Leader"
                                                                                            : "Member"}
                                                                                    </span>
                                                                                </div>
                                                                                {isTeamLeader &&
                                                                                    editable ? (
                                                                                    <Button
                                                                                        variant="secondary"
                                                                                        className="rounded-pill ms-auto"
                                                                                        onClick={() =>
                                                                                            removeMember(
                                                                                                member
                                                                                                    .user
                                                                                                    ._id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Remove
                                                                                    </Button>
                                                                                ) : (
                                                                                    <>

                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    )}

                                                                {competition.joinedTeam.members.filter(
                                                                    (m) =>
                                                                        m.accepted
                                                                )?.length ===
                                                                    0 ? (
                                                                    <p className="text-xs mb-0 text-color-tertiary m-2">
                                                                        Seems
                                                                        like no
                                                                        one has
                                                                        accepted
                                                                        the
                                                                        invitation
                                                                        to join
                                                                        your
                                                                        team
                                                                        yet.
                                                                    </p>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {!isCompleted(competition.endDate)
                                                            && competition.joinedTeam.members.filter(
                                                                (m) => !m.accepted
                                                            )?.length > 0 ? (
                                                            <div className="mt-4 mb-4 pt-2">
                                                                <p className="text-xl text-thick mb-half">
                                                                    Pending
                                                                    Invitations
                                                                </p>

                                                                {user?.roles.includes(
                                                                    UserRole.Admin
                                                                ) ||
                                                                    isTeamLeader ? (
                                                                    <span className="text-xs">
                                                                        This
                                                                        member
                                                                        is
                                                                        pending
                                                                        to
                                                                        accept
                                                                        your
                                                                        invitation.
                                                                    </span>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                                <div>
                                                                    {competition.joinedTeam.members
                                                                        .filter(
                                                                            (
                                                                                m
                                                                            ) =>
                                                                                !m.accepted
                                                                        )
                                                                        .map(
                                                                            (
                                                                                member,
                                                                                idx
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        "acceptedMember" +
                                                                                        idx
                                                                                    }
                                                                                    className="team-members flex-wrap"
                                                                                >
                                                                                    <UserImg
                                                                                        width={
                                                                                            56
                                                                                        }
                                                                                        height={
                                                                                            56
                                                                                        }
                                                                                        onClick={() => { }}
                                                                                        userId={
                                                                                            member
                                                                                                .user
                                                                                                ._id
                                                                                        }
                                                                                        alt="user img"
                                                                                    />
                                                                                    <div className="ms-3">
                                                                                        <p className="text-xl text-thick mb-0">
                                                                                            {
                                                                                                member
                                                                                                    .user
                                                                                                    .fullName
                                                                                            }
                                                                                        </p>
                                                                                        <span className="text-xs text-color-tertiary">
                                                                                            {member
                                                                                                .user
                                                                                                ._id ===
                                                                                                competition
                                                                                                    .joinedTeam
                                                                                                    ?.createdBy ||
                                                                                                member.isLeader
                                                                                                ? "Team Leader"
                                                                                                : "Member"}
                                                                                        </span>
                                                                                    </div>

                                                                                    {isTeamLeader &&
                                                                                        editable ? (
                                                                                        <div className="d-flex ms-sm-auto mt-sm-0 mt-3">
                                                                                            <Button
                                                                                                variant="secondary"
                                                                                                className="text-xs rounded-pill me-2"
                                                                                                onClick={() =>
                                                                                                    resendInvite(
                                                                                                        member
                                                                                                            .user
                                                                                                            ._id,
                                                                                                        member
                                                                                                            .user
                                                                                                            .fullName
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Resend
                                                                                                invite
                                                                                            </Button>
                                                                                            <Button
                                                                                                variant="secondary"
                                                                                                className="text-xs rounded-pill"
                                                                                                onClick={() =>
                                                                                                    removeMember(
                                                                                                        member
                                                                                                            .user
                                                                                                            ._id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Remove
                                                                                            </Button>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {isTeamLeader &&
                                                            editable &&
                                                            !isCompleted(
                                                                competition.registerByDate ||
                                                                competition.endDate
                                                            ) ? (
                                                            <div className="d-flex justify-content-between w-100 mb-0">
                                                                <p className="text-sm mb-0">
                                                                    <span>
                                                                        Team
                                                                        member
                                                                        edits
                                                                        close on
                                                                    </span>
                                                                    <span className="ms-2 text-color-green-normal text-thick">
                                                                        {convertDate(
                                                                            (
                                                                                competition.registerByDate ||
                                                                                competition.endDate
                                                                            ).toUTCString()
                                                                        )}
                                                                    </span>
                                                                </p>
                                                                <p className="mb-0 text-danger text-xs">
                                                                    {mailError ||
                                                                        teamError}
                                                                </p>
                                                            </div>
                                                        ) : undefined}

                                                        {competition.teamRules
                                                            ?.minMembers ? (
                                                            <div className="team-status">
                                                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start">
                                                                    <p className="text-xl text-thick mb-half">
                                                                        Team
                                                                        Status
                                                                    </p>
                                                                    {competition.joinedTeam.members.filter(
                                                                        (m) =>
                                                                            m.accepted
                                                                    )?.length >=
                                                                        competition
                                                                            ?.teamRules
                                                                            ?.minMembers ? (
                                                                        <div className="qualified no-select text-xs my-1 my-md-0">
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faCheck
                                                                                }
                                                                                className="me-1"
                                                                                size="sm"
                                                                            />
                                                                            <span>
                                                                                Qualified
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="unqualified no-select text-xs my-1 my-md-0">
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faClose
                                                                                }
                                                                                className="me-1"
                                                                                size="sm"
                                                                            />
                                                                            <span>
                                                                                Unqualified
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <span className="text-xs">
                                                                    {competition.joinedTeam.members.filter(
                                                                        (m) =>
                                                                            m.accepted
                                                                    )?.length >=
                                                                        competition
                                                                            ?.teamRules
                                                                            ?.minMembers
                                                                        ? "Your team is qualified for the competition."
                                                                        : !isCompleted(
                                                                            competition.registerByDate ||
                                                                            competition.endDate
                                                                        )
                                                                            ? "Your team currently does not meet the minimum required number of members."
                                                                            : "Your team did not meet the minimum required number of members."}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <p className="w-100 text-center">
                                                        Create a team to join
                                                        the competition.
                                                    </p>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="info-page-tab-content text-color-secondary text-center">
                                            Sorry! This is an invitation only
                                            competition and it seems you aren't
                                            invited.
                                        </div>
                                    )
                                ) : (
                                    <div className="info-page-tab-content text-color-secondary text-center">
                                        Log in to create a team or check your
                                        team details.
                                    </div>
                                )}
                            </Tab>
                        ) : undefined}

                        {(competition
                            && competition.participantType === CompetitionMembershipType.TEAM
                            && user?.roles.includes(UserRole.Admin)
                            && !isCompleted(competition.endDate)) ? (
                            <Tab
                                className={`info-page-tab ${maximized ? "" : "onFit"
                                    }`}
                                title={"Manage members"}
                                eventKey={"tabAdmin"}
                            >
                                {tabOptions()}
                                <div className="info-page-tab-content text-color-secondary">
                                    <div className="mb-4 d-flex">
                                        <Button
                                            variant="primary"
                                            className="text-xs me-3 rounded-pill"
                                            onClick={() => {
                                                setShowConfirmSendEmailsModal(true);
                                                setSendEmailsType(EmailType.RE_INVITE_ALL_MEMBERS);
                                            }}
                                        >
                                            Re-Invite all members
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            className="text-xs rounded-pill"
                                            onClick={() => {
                                                setShowConfirmSendEmailsModal(true);
                                                setSendEmailsType(EmailType.WAKE_UP_CALL);
                                            }}
                                        >
                                            Wake Up call
                                        </Button>
                                    </div>
                                    <p className="text-lg mb-3">
                                        Bulk upload members
                                    </p>
                                    <div>
                                        <Input
                                            label="Invited Members"
                                            asTextArea
                                            focusOnError={true}
                                            placeholder="Enter invited members json array"
                                            value={invitedMembersString}
                                            onChange={(e) => {
                                                if (memberError) {
                                                    setMemberError("");
                                                }
                                                setInvitedMembersString(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <div className="mt-3 d-flex w-fit ms-auto">
                                            <Button
                                                variant="secondary"
                                                className="text-xs rounded-pill ms-auto me-3"
                                                onClick={bulkAdd}
                                            >
                                                Invite All
                                            </Button>
                                        </div>
                                    </div>

                                    <p className="my-3 text-color-red-normal text-sm">
                                        {memberError}
                                    </p>

                                    <p className="text-lg mb-0">
                                        Add and Invite Member to Team
                                    </p>
                                    <div className="p-2 radius-base mb-4 w-75">
                                        <Form
                                            ref={addMemberFormRef}
                                            noValidate
                                            onSubmit={onMemberSubmit}
                                        >
                                            <Form.Group>
                                                <Input
                                                    label="Member Email"
                                                    placeholder="Add new member email"
                                                    value={newMemberEmail}
                                                    onChange={(e) => {
                                                        if (memberError) {
                                                            setMemberError("");
                                                        }
                                                        setNewMemberEmail(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mt-3">
                                                <Input
                                                    label="Team Id"
                                                    focusOnError={true}
                                                    placeholder="Enter team id"
                                                    value={teamAddId}
                                                    onChange={(e) => {
                                                        if (memberError) {
                                                            setMemberError("");
                                                        }
                                                        setTeamAddId(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mt-4">
                                                <Button
                                                    variant="secondary"
                                                    className="rounded-pill ms-auto"
                                                    type="submit"
                                                >
                                                    Create
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </div>

                                    <p className="text-lg mb-0">
                                        Remove member from team
                                    </p>
                                    <div className="p-2 radius-base w-75">
                                        <Form
                                            ref={removeMemberFormRef}
                                            noValidate
                                            onSubmit={onMemberDelete}
                                        >
                                            <Form.Group>
                                                <Input
                                                    label="Member ID"
                                                    placeholder="Add member id to remove"
                                                    value={userRemoveId}
                                                    onChange={(e) => {
                                                        if (memberError) {
                                                            setMemberError("");
                                                        }
                                                        setUserRemoveId(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mt-3">
                                                <Input
                                                    label="Team Id"
                                                    focusOnError={true}
                                                    placeholder="Enter team id"
                                                    value={teamRemoveId}
                                                    onChange={(e) => {
                                                        if (memberError) {
                                                            setMemberError("");
                                                        }
                                                        setTeamRemoveId(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mt-4">
                                                <Button
                                                    variant="secondary"
                                                    className="rounded-pill ms-auto"
                                                    type="submit"
                                                >
                                                    Remove user
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            </Tab>
                        ) : undefined}
                    </Tabs>
                </div>
            ) : undefined}

            <Modal
                show={confirmDelete}
                className="login-modal"
                centered
                onHide={() => setConfirmDelete(false)}
            >
                <Modal.Header className="border-0 p-4" closeButton>
                    <p className="text-lg text-thick mb-0">Are you sure?</p>
                </Modal.Header>

                <Modal.Body className=" pt-0">
                    <p className=" mb-5">
                        Are you sure you want to delete{" "}
                        {competition?.title || "this competition"}?
                    </p>

                    <div className="d-flex">
                        <Button
                            onClick={() => setConfirmDelete(false)}
                            className="rounded-pill ms-auto me-4"
                            variant="secondary"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={deleteSelectedCompetition}
                            className="rounded-pill"
                            variant="primary"
                        >
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            {competition?.joinedTeam ? (
                <Modal
                    show={deleteConfirmation}
                    className="login-modal"
                    centered
                    onHide={() => setDeleteConfirmation(false)}
                >
                    <Modal.Header className="border-0 p-4" closeButton>
                        <p className="text-lg text-thick mb-0">Are you sure?</p>
                    </Modal.Header>

                    <Modal.Body className=" pt-0">
                        <p className=" mb-5">
                            Are you sure you want to delete{" "}
                            {competition.joinedTeam.name || "this Team"}?
                        </p>

                        <div className="d-flex">
                            <Button
                                onClick={() => setDeleteConfirmation(false)}
                                className="rounded-pill ms-auto me-4"
                                variant="secondary"
                            >
                                Close
                            </Button>
                            <Button
                                onClick={deleteTeam}
                                className="rounded-pill"
                                variant="primary"
                            >
                                Delete
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            ) : (
                <></>
            )}

            <Modal
                show={createTeam}
                className="login-modal"
                centered
                onHide={() => setCreateTeam(false)}
            >
                <Modal.Header className="border-0 p-4 pb-2" closeButton>
                    <p className="text-lg text-thick mb-0">Create a team</p>
                </Modal.Header>

                <Modal.Body className=" pt-0">
                    <p className="text-xs text-color-secondary mb-3">
                        This datathon requires teams with the minimum of{" "}
                        {competition?.teamRules?.minMembers} and maximum of{" "}
                        {competition?.teamRules?.maxMembers} members. The team
                        leader has to invite{" "}
                        {competition?.teamRules
                            ? competition.teamRules?.minMembers - 1
                            : 0}{" "}
                        more to start the team.
                    </p>
                    <div className="mb-3">
                        <Input
                            autoFocus
                            label="Team Name"
                            placeholder="Enter team name"
                            value={teamName}
                            className="py-3"
                            onChange={(e) => {
                                if (teamError) {
                                    setTeamError("");
                                }
                                setTeamName(e.target.value);
                            }}
                        />
                    </div>

                    <div className="">
                        {membersToAdd.map((member, id) => (
                            <div
                                className="d-flex bg-tag radius-base py-2 px-21 mb-3"
                                key={"member-list" + id}
                            >
                                <div className="d-flex w-100 flex-column">
                                    <p className="mb-half d-flex justify-content-between align-items-center">
                                        <span>{member}</span>
                                        <FontAwesomeIcon
                                            icon={faClose}
                                            className="feature-icon"
                                            size="sm"
                                            onClick={() => {
                                                let holder = [...membersToAdd];
                                                holder.splice(id, 1);
                                                setMembersToAdd(holder);
                                            }}
                                        />
                                    </p>
                                    <span className="text-xs text-color-tertiary">
                                        Member
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="team-members-add radius-base mb-3 dropdown-parent">
                        <CustomAccordian
                            expanded={updateMembersAccordian}
                            switch={() =>
                                setUpdateMembersAccordian(
                                    !updateMembersAccordian
                                )
                            }
                            className=""
                            id={"add-members"}
                            heading="Add members"
                            inline
                            dark
                        >
                            <div className="ps-21">
                                <p className="mb-2">
                                    Your can invite up to{" "}
                                    {competition && competition.teamRules
                                        ? competition.teamRules?.maxMembers -
                                        membersToAdd?.length
                                        : 0}{" "}
                                    more people to join your team.
                                </p>
                                <div className="d-flex">
                                    {competition?.invitedMembersOnly ? (
                                        <div className="w-100">
                                            <Input
                                                autoFocus
                                                focusOnError={true}
                                                label="Search Users by Name"
                                                placeholder="Search Name"
                                                className="w-100"
                                                value={memberSearch}
                                                onChange={(e) =>
                                                    setMemberSearch(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <Input
                                                autoFocus
                                                placeholder="Enter email of memebrs to add"
                                                value={memberEMail}
                                                className="py-3"
                                                onChange={(e) => {
                                                    if (mailError) {
                                                        setMailError("");
                                                    }
                                                    setMemberEMail(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <Button
                                                onClick={() =>
                                                    addMember(memberEMail)
                                                }
                                                className="rounded-pill ms-2 h-fit mt-auto"
                                                variant="secondary"
                                            >
                                                Add
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </CustomAccordian>

                        {invitedMembers &&
                            updateMembersAccordian &&
                            invitedMembers?.length > 0 ? (
                            <div className="users-dp-holder dropdown-holder glazed-card-cover radius-base">
                                <div className="glazed-card radius-base py-2">
                                    {invitedMembers.map((member, idx) => (
                                        <p
                                            key={"ListItm" + idx}
                                            className="dropdown-item text-color-tertiary text-xs cursor-pointer py-2 px-3 mb-0"
                                            onClick={() => {
                                                addMember(member.email);
                                            }}
                                        >
                                            {`${member.fullName}`}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <p className="mt-2 text-danger text-xs ms-5 ps-21 mb-0">
                            {mailError}
                        </p>
                    </div>

                    <div className="d-flex w-100 justify-content-between">
                        <p className="text-xs text-color-red-normal mb-0">
                            {teamError}
                        </p>
                        <Button
                            onClick={createCompetitionTeam}
                            className="rounded-pill"
                            variant="primary"
                        >
                            Create Team
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <LoadingSpinner show={loading} text="Loading..." />


            <Modal
                show={showConfirmSendEmailsModal}
                className="login-modal"
                centered
                onHide={() => {
                    setShowConfirmSendEmailsModal(false);
                    setSendEmailsType(undefined);
                    setConfirmSendEmailsInput('');
                }}
            >
                <Modal.Header className="border-0 p-4" closeButton>
                    <p className="text-lg text-thick mb-0">Are you sure?</p>
                </Modal.Header>

                <Modal.Body className=" pt-0">
                    <p className=" mb-5">
                        Are you sure you want to send emails {" "}
                        {sendEmailsType === EmailType.RE_INVITE_ALL_MEMBERS ? `for ${EmailType.RE_INVITE_ALL_MEMBERS}` : ''}
                        {sendEmailsType === EmailType.WAKE_UP_CALL ? `for ${EmailType.WAKE_UP_CALL}` : ''}
                        ?
                    </p>
                    <Input
                        id="confirm-send-emails-input"
                        label={<>
                            {"To confirm send, type"}
                            <i>&nbsp;confirm&nbsp;</i>
                            {"in the text input field."}
                        </>
                        }
                        placeholder="confirm"
                        value={confirmSendEmailsInput}
                        className=""
                        onChange={(e) => {
                            setConfirmSendEmailsInput(e.target.value);
                        }}
                    />

                    <div className="mt-5 d-flex">
                        <Button
                            onClick={() => {
                                setShowConfirmSendEmailsModal(false);
                                setSendEmailsType(undefined);
                                setConfirmSendEmailsInput('');
                            }}
                            className="rounded-pill ms-auto me-4"
                            variant="secondary"
                        >
                            Close
                        </Button>
                        <Button
                            disabled={confirmSendEmailsInput !== 'confirm'}
                            onClick={() => {
                                setShowConfirmSendEmailsModal(false);
                                setSendEmailsType(undefined);
                                setConfirmSendEmailsInput('');

                                if (sendEmailsType === EmailType.RE_INVITE_ALL_MEMBERS) {
                                    resendInviteAll();
                                } else if (sendEmailsType === EmailType.WAKE_UP_CALL) {
                                    wakeUpCall();
                                }
                            }}
                            className="rounded-pill"
                            variant="primary"
                        >
                            Send
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
