import { IStarItem } from "../types/common-types";
import { 
    INotebooksData, 
    ICreateNotebookPayload, 
    INotebook, 
    IUpdateNotebookStatusPayload, 
    IFetchNotebookListPayload 
} from "../types/notebook-types";
import { BaseApi } from "./base-api";

export default class NotebookApi extends BaseApi {
    private static url = (action: string) => 'notebook/' + action;

    /**
    * Create new notebook
    * @returns
    */
    public static async create(data: ICreateNotebookPayload): Promise<INotebook> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._post(this.url('create'), fd);
    }

    /**
* star dataset
* @returns
*/
    public static async star(data: IStarItem): Promise<boolean> {
        return this._post(this.url('star'), data);
    }

    /**
     * Get all notebooks
     * @returns 
     */
    public static async getAll( data: IFetchNotebookListPayload ): Promise<INotebooksData> {
        return this._get(this.url('getall'), data);
    }

    /**
     * Get all notebooks (approval pending) (Only admin)
     * @returns 
     */
    public static async getDisapproved(data: IFetchNotebookListPayload): Promise<INotebooksData> {
        return this._get(this.url('getdisapprovednotebooks'), data);
    }

    /**
     * Get notebook details
     * @param id notebook id
     * @returns 
     */
    public static async get(id: string): Promise<INotebook> {
        return this._get(`${this.url('get')}/${id}`);
    }

    /**
     * Update dataset
     * @param data 
     * @returns 
     */
    public static async updateStatus(data: IUpdateNotebookStatusPayload): Promise<INotebook> {
        return this._patch(this.url('updatestatus'), data);
    }

    /**
     * Get all notebooks linked to dataset
     * @param id Dataset id
     * @returns 
     */
    public static async getAllDatasetNotebooks(id: string, data: IFetchNotebookListPayload): Promise<INotebooksData> {
        return this._get(`${this.url('getbydataset')}/${id}`, data);
    }

    /**
    * Get my notebooks
    * @returns 
    */
    public static async getMyNotebooks(): Promise<INotebooksData> {
        return this._get(this.url('getmynotebooks'));
    }

    /**
   * Get my notebooks
   * @param id Dataset id
   * @returns 
   */
    public static async getMyNotebooksByDataset(id: string): Promise<INotebook[]> {
        return this._get(`${this.url('getmynotebooksbydataset')}/${id}`);
    }

    /**
 * Get my notebooks
 * @param id Dataset id
 * @returns 
 */
    public static async getUserNotebooks(userId?: string): Promise<INotebooksData> {
        if (userId)
            return this._get(`${this.url('getusernotebooks')}/${userId}`);
        else
            return this.getMyNotebooks();
    }

    /**
     * Delete notebook
     * @param id Notebook Id
     * @returns 
     */
    public static async delete(id: string): Promise<void> {
        return this._delete(`${this.url('delete')}/${id}`);
    }


    /**
    * Get notebook html
    * @returns 
    */
    public static async getNotebookHtml(_id: string): Promise<string> {
        return this._get(this.url('notebookhtml') + "/" + _id);
    }
}