/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from "react-bootstrap/Button";
import "./index.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { HandleError } from "../../errors/handler";
import Input from "../input/input";
import AuthApi from "../../api/auth-api";

type TResetPasswordModal = {
    token?: string | null;
    show: boolean;
    onHide?: () => void;
    onReset?: () => void;
};

export default function ResetPasswordModal(props: TResetPasswordModal) {
    const [formErrors, setFormErrors] = useState<any>({});
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reset();
    }, [props.show]);

    const reset = () => {
        setFormErrors({});
        setConfirmPassword("");
        setPassword("");
        setLoading(false);
    };

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            setFormErrors({});

            if (validateForm() && props.token) {
                setLoading(true);
                await AuthApi.resetPassword({
                    token: props.token,
                    password: password,
                });
                props.onReset?.();
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const validateForm = () => {
        setFormErrors({});
        const errors: any = {};
        if (!password || password.length < 6) {
            errors.password =
                "Please enter a minimum 6 character long password.";
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = "Password did not matched.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide?.()}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <div>
                    Reset Password
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={submit} className="login-modal-form">
                    <fieldset disabled={loading}>
                        <Form.Group className="">
                            <Input
                                value={password}
                                label="New Password"
                                placeholder="Enter your new password"
                                maxLength={100}
                                autoFocus
                                type="password"
                                autoComplete="new-password"
                                onChange={(e) => {
                                    if (formErrors["password"]) {
                                        delete formErrors?.password;
                                        setFormErrors(formErrors);
                                    }
                                    setPassword(e.target.value);
                                }}
                                error={formErrors["password"]}
                            />
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <Input
                                label="Confirm Password"
                                placeholder="Confirm your new password"
                                type="password"
                                value={confirmPassword}
                                autoComplete="new-password"
                                error={formErrors.confirmPassword}
                                onChange={(e) => {
                                    if (formErrors["confirmPassword"]) {
                                        delete formErrors.confirmPassword;
                                        setFormErrors(formErrors);
                                    }
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mt-4">
                            {formErrors[""] ? (
                                <label className="text-danger">
                                    {formErrors[""]}
                                </label>
                            ) : undefined}
                        </Form.Group>
                        <Button type="submit" className="rounded-pill w-fit mx-auto mt-5">
                            {loading ? (
                                <>
                                    <FontAwesomeIcon spin className="me-2" icon={faSpinner} />
                                    Please wait
                                </>
                            ) : (
                                "Save New Password"
                            )}
                        </Button>
                    </fieldset>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
