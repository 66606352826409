import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// import: assets
import trophy from "../../assets/icons/trophy.svg";

// import: styles
// import: constants
import { COMPETITIONS } from "../../router/routes";

// import: enums
// import: types
import { ICompetition } from "../../types/competition-types";

// import: utils
import {
    timeElapsed,
    isCompleted,
    convertDate,
} from "../../utils/helper-methods";

// import: data
// import: store
// import: api
// import: config
import { CompetitionBanner } from "../../configs/api-config";

// import: components
import { PrizeType as PrizeTypeEnum } from "../../enums/prize-type";

type TCompetitionListItemProps = {
    item: ICompetition;
    to?: string;
    className?: string;
};
export default function CompetitionListItem(props: TCompetitionListItemProps) {
    const upcoming = !isCompleted(props.item.startDate);
    const completed = isCompleted(props.item.endDate);
    const [totalPrize, setTotalPrize] = useState(0);

    useEffect(() => {
        if (props.item) {
            if (props.item.prizeDetail?.prizeType === "Monetary") {
                let total = 0;
                props.item.prizeDetail.prizes?.forEach((prize) => {
                    total = total + parseInt(prize?.amount ?? "0");
                });
                setTotalPrize(total);
            }
        }
    }, [props.item]);

    const prize = useMemo(() => {
        const otherPrize = props.item.prizeDetail?.prizeType === PrizeTypeEnum.Other
            ? ""
            : props.item.prizeDetail?.prizeType;

        const monetaryPrize = props.item.prizeDetail?.prizeType === PrizeTypeEnum.Monetary
            ? "€" + totalPrize
            : otherPrize;

        return (
            props.item.prizeDetail?.prizeType
                ? monetaryPrize
                : "SWAG"
        )
    }, [props.item.prizeDetail, totalPrize])

    return (
        <div className={`list-item-cover glazed-card-cover ${props.className}`}>
            <Link
                className="list-item glazed-card cursor-pointer unanchored"
                to={props.to ?? `/${COMPETITIONS}`}
            >
                <div>
                    {completed ? (
                        <p className="text-color-tertiary text-xs d-flex mb-0">
                            <li>
                                Finished{" "}
                                {timeElapsed(props.item?.endDate, new Date()) +
                                    " ago"}
                            </li>
                        </p>
                    ) : undefined}
                    <p className="listTitle">{props.item.title}</p>
                    <p className="organizer text-color-tertiary text-xs">
                        By Antigranular
                    </p>
                </div>

                <div className="d-flex flex-column">
                    <img
                        src={CompetitionBanner(props.item._id)}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = trophy;
                        }}
                        className="listImage"
                        alt=""
                        width={92}
                        height={92}
                    />
                    {/* {!upcoming && !props.item.externalDataset ? (
                        <span className="d-flex align-items-center my-2 pt-1">
                            <img
                                className="me-2"
                                width={12}
                                alt=""
                                src={CategoryIcons(props.item.type)}
                            />
                            <span className="text-capitalize text-xs text-color-tertiary">
                                {props.item.type}
                            </span>
                        </span>
                    ) : ( */}
                    <div className="mb-5" />
                    {/* )} */}
                    <p className="competition-listData text-color-tertiary text-xs">
                        {upcoming
                            ? props.item?.subTitle?.split("||BREAK||")[0] ?? ""
                            : props.item?.subTitle ?? ""}
                    </p>

                    {upcoming ? (
                        <div className="d-flex justify-content-start align-items-center">
                            <span className="text-xs startDate">
                                <span className="me-1 selected-link">
                                    Starting{" "}
                                    {convertDate(
                                        props.item.startDate?.toUTCString()
                                    )}
                                </span>
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}

                    {!completed && !upcoming ? (
                        <div className="d-flex justify-content-between align-items-center">
                            {props.item.prizeDetail?.prizeType &&
                                props.item.prizeDetail?.prizeType ===
                                PrizeTypeEnum.Monetary ? (
                                <span className="text-xs endDate">
                                    <span className="me-1">Until</span>
                                    {convertDate(
                                        props.item.endDate?.toUTCString()
                                    )}
                                </span>
                            ) : (
                                <></>
                            )}
                            <div className="prizes ms-auto">
                                {prize}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {completed && !props.item.externalDataset ? (
                        <Link
                            className="link text-end text-sm text-thick"
                            to={{ pathname: `/${COMPETITIONS}/${props.item?.slug ?? props.item?._id}` }}
                            state={{ leaderboard: true }}
                        >
                            Check Leaderboard
                            <svg
                                width="10"
                                height="10"
                                className="link-icon"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Link>
                    ) : (
                        <></>
                    )}
                </div>
            </Link>
        </div>
    );
}
