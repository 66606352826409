import {
    IBlogData,
    IBlog,
    ICreateBlogPayload,
    IUpdateBlogPayload,
    IFetchBlogListPayload,
} from "../types/blog-types";
import { BaseApi } from "./base-api";

export default class BlogApi extends BaseApi {
    private static url = (action: string) => "blog/" + action;

    /**
     * Create new blog
     * @returns
     */
    public static async create( data: ICreateBlogPayload ): Promise<IBlog> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._post(this.url("create"), fd);
    }

    /**
     * Get all blogs
     * @returns
     */
    public static async getAll(
        data: IFetchBlogListPayload
    ): Promise<IBlogData> {
        return this._get(this.url("getall"), data);
    }


    /**
     * Get blog details
     * @param id blog id
     * @returns
     */
    public static async get(id: string): Promise<IBlog> {
        return this._get(`${this.url("get")}/${id}`);
    }


    /**
     * Update blog
     * @param data
     * @returns
     */
    public static async update(
        data: IUpdateBlogPayload
    ): Promise<IBlog> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._patch(this.url("update"), fd);
    }


    public static async delete(id: string): Promise<void> {
        return this._delete(`${this.url("delete")}/${id}`);
    }
}
