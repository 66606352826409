import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import RichTextEditor from "../../components/richtext-editor";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import FileInput from "../../components/input/file-input";
import Input from "../../components/input/input";
import InputSelect from "../../components/input/input-select";
import { createDataset } from "../../store/slices/dataset-slice";
import { HandleError } from "../../errors/handler";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import LoadingSpinner from "../../components/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import types from "../../components/category-icons/types.json";
import { IOrganization } from "../../types/organization-types";
import { loadOrganizations } from "../../store/slices/organization-slice";
import { tryParseJSONObject } from "../../utils/helper-methods";

type CreateDatasetProps = {
    submitted?: Function;
};

export default function CreateDatasetPage(props: CreateDatasetProps) {
    const submitted = props?.submitted;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //control refs
    const formRef = useRef<HTMLFormElement>(null);
    const titleInputRef = useRef<HTMLInputElement>(null);
    //form states
    const [formErrors, setFormErrors] = useState<any>({});
    const [title, setTitle] = useState<string>("");
    const [subTitle, setSubTitle] = useState<string>("");
    const [about, setAbout] = useState<string>("");
    const [tags, setTags] = useState<string>("");
    const [coverImage, setCoverImage] = useState<File>();
    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    const [coverPreview, setCoverPreview] = useState<string>();
    const [sources, setSources] = useState<string>("");
    const [collaborators, setCollaborators] = useState<string>("");
    const [license, setLicense] = useState<string>("");
    const [columnData, setColumnData] = useState<
        { columnName: string; columnDescription: string; categories: string }[]
    >([]);
    const [pqEps, setPqEps] = useState<number>(0);
    const [pqDelta, setPqDelta] = useState<number>(0);
    const [maxEps, setMaxEps] = useState<number>(0);
    const [maxDelta, setMaxDelta] = useState<number>(0);
    const [type, setType] = useState<string>("");
    const [metadata, setMetadata] = useState<string>("");
    const [metrics, setMetrics] = useState<string>("");

    const organizations = useAppSelector((s) => s.organization.pageData.data);
    const [orgSearch, setOrgSearch] = useState<string>("");
    const [orgsToAdd, setOrgsToAdd] = useState<IOrganization[]>([]);

    const visibilityOptions = [
        { value: "private", label: "Private" },
        { value: "public", label: "Public" },
    ];
    const [loading, setLoading] = useState<boolean>(false);

    // FETCHING ORGANIZATIONS ON SEARCH STRING UPDATE
    useEffect(() => {
        if (orgSearch.trim().length > 3 ) {
            let delay = setTimeout(() => {
                dispatch(loadOrganizations({ page: 1, size: 7, title: orgSearch }))
            }, 1000);

            return () => clearTimeout(delay);
        }
    }, [orgSearch]);

    // ADDING DATASETS TO LIST OF SUBMISSION
    const addOrgToList = (org: IOrganization) => {
        let orgHolder = [...orgsToAdd];
        orgHolder.push(org);
        setOrgsToAdd(orgHolder);
        setOrgSearch("");
    };

    // REMOVING ORGANIZATIONS FROM LIST OF SUBMISSION
    const removeOrgsFromList = (i: number) => {
        let holder = [...orgsToAdd];
        holder.splice(i, 1);
        setOrgsToAdd(holder);
    }

    
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (validateForm()) {
                setLoading(true);
                await dispatch(
                    createDataset({
                        title,
                        subTitle,
                        tags,
                        about,
                        isPrivate,
                        coverImage,
                        columnData,
                        collaborators: collaborators.split("\n"),
                        license,
                        sources: sources.split("\n"),
                        type,
                        pqEps,
                        pqDelta,
                        maxDelta,
                        maxEps,
                        metadata,
                        metrics,
                        organizations: orgsToAdd.map((org) => org._id) ?? []
                    })
                ).unwrap();
                dispatch(
                    showInfoModal({
                        title: "Created",
                        message: "Dataset created succcessfully.",
                    })
                );
                if (submitted) submitted();
                resetForm();
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
            if (submitted) submitted();
        }
    };

    const validateForm: () => boolean = () => {
        setFormErrors({});
        const errors: any = {};

        if (!title) {
            errors["title"] = "Please write dataset title.";
        }

        if (!type) {
            errors["type"] = "please select type.";
        }

        if (!metrics || !tryParseJSONObject(metrics)) {
            errors["metrics"] = "Metrics needs tobe a valid json.";
        }

        if (isNaN(Number(pqEps))) {
            errors["pqEps"] = "Please enter pqEps.";
        }

        if (isNaN(Number(pqDelta))) {
            errors["pqDelta"] = "Please enter pqDelta.";
        }

        if (isNaN(Number(maxDelta))) {
            errors["maxDelta"] = "Please enter maxDelta.";
        }

        if (isNaN(Number(maxEps))) {
            errors["maxEps"] = "Please enter maxEps.";
        }

        if (!metadata || !tryParseJSONObject(metadata)) {
            errors["metadata"] = "Metadata needs tobe a valid json.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const resetForm = () => {
        setTitle("");
        setSubTitle("");
        setTags("");
        setType("");
        setPqDelta(0);
        setPqEps(0);
        setMaxDelta(0);
        setOrgsToAdd([]);
        setMaxEps(0);
        setAbout("");
        setFormErrors({});
        setMetadata("");
        setMetrics("");
        titleInputRef?.current?.focus();
    };

    useEffect(() => {
        if (coverImage) {
            setCoverPreview(URL.createObjectURL(coverImage));
        }
    }, [coverImage]);

    return (
        <div>
            <div className="d-flex flex-row align-content-center">
                <FontAwesomeIcon
                    size="lg"
                    className="mx-3 cursor-pointer"
                    onClick={() => navigate(-1)}
                    icon={faArrowLeft}
                />
                <h4>Create Dataset</h4>
            </div>

            <Card className="bg-primary">
                <Card.Body>
                    <Form
                        onReset={resetForm}
                        ref={formRef}
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <Form.Group>
                            <Input
                                ref={titleInputRef}
                                autoFocus
                                focusOnError={true}
                                label="Title"
                                placeholder="Dataset Title"
                                value={title}
                                onChange={(e) => {
                                    if (formErrors["title"]) {
                                        delete formErrors.title;
                                        setFormErrors(formErrors);
                                    }
                                    setTitle(e.target.value);
                                }}
                                error={formErrors["title"]}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Input
                                label="Subtitle"
                                placeholder="Dataset Subtitle"
                                value={subTitle}
                                onChange={(e) => {
                                    if (formErrors["subTitle"]) {
                                        delete formErrors.subTitle;
                                        setFormErrors(formErrors);
                                    }
                                    setSubTitle(e.target.value);
                                }}
                                error={formErrors["subTitle"]}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Input
                                label="Tags"
                                placeholder="Search Tags"
                                value={tags}
                                onChange={(e) => {
                                    if (formErrors["tags"]) {
                                        delete formErrors.tags;
                                        setFormErrors(formErrors);
                                    }
                                    setTags(e.target.value);
                                }}
                                asTextArea
                                error={formErrors["tags"]}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <InputSelect
                                labelKey="label"
                                valueKey="value"
                                onChange={(e) =>
                                    setIsPrivate(
                                        e?.value === "private" ? true : false
                                    )
                                }
                                label="Visibility"
                                placeholder="Set Visibility"
                                value={
                                    isPrivate === true
                                        ? visibilityOptions[0]
                                        : visibilityOptions[1]
                                }
                                options={visibilityOptions}
                                error={formErrors["visible"]}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Input
                                label="Metadata"
                                asTextArea
                                focusOnError={true}
                                placeholder="Enter metadata json data"
                                value={metadata}
                                onChange={(e) => {
                                    if (formErrors["metadata"]) {
                                        delete formErrors.metadata;
                                        setFormErrors(formErrors);
                                    }
                                    setMetadata(e.target.value);
                                }}
                                error={formErrors["metadata"]}
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Input
                                label="Metrices"
                                asTextArea
                                focusOnError={true}
                                placeholder="Enter metrices json data"
                                value={metrics}
                                onChange={(e) => {
                                    if (formErrors["metrics"]) {
                                        delete formErrors.metrics;
                                        setFormErrors(formErrors);
                                    }
                                    setMetrics(e.target.value);
                                }}
                                error={formErrors["metrics"]}
                            />
                        </Form.Group>

                        <div className="d-flex flex-row flex-wrap gap-3 mt-3">
                            <div
                                style={{
                                    backgroundImage: `url(${coverPreview})`,
                                    borderRadius: 10,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                }}
                            >
                                <Form.Group className="is-invalid">
                                    <FileInput
                                        selectedFiles={
                                            coverImage ? [coverImage] : []
                                        }
                                        accept={{
                                            "image/*": [
                                                ".png",
                                                ".gif",
                                                ".jpeg",
                                                ".jpg",
                                            ],
                                        }}
                                        height={180}
                                        width={270}
                                        placeholder="Upload dataset cover image."
                                        error={formErrors["coverImage"]}
                                        onDrop={(files) => {
                                            if (formErrors["coverImage"]) {
                                                delete formErrors.coverImage;
                                                setFormErrors(formErrors);
                                            }
                                            if (files?.length) {
                                                setCoverImage(files[0]);
                                            } else {
                                                setCoverImage(undefined);
                                            }
                                        }}
                                        containerClass="text-white"
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <Form.Group className="dropdown-parent my-4">
                            <Input
                                autoFocus
                                focusOnError={true}
                                label="Search organizations by name"
                                placeholder="Organization Name"
                                className=""
                                value={orgSearch}
                                onChange={(e) =>  setOrgSearch(e.target.value)}
                            />
                            {orgSearch && organizations.length > 0 ? <div className="dropdown-holder glazed-card-cover radius-base">
                                <div className="glazed-card radius-base py-2">
                                    {organizations.map((org, idx) => 
                                        <p key={"ListItm" + idx} className="dropdown-item text-color-tertiary text-xs cursor-pointer py-2 px-3 mb-0" onClick={() => addOrgToList(org)}>
                                            {org.title}
                                        </p>
                                    )}
                                </div>
                            </div>: <></>}
                        </Form.Group>

                        <div className="org-container">
                            {orgsToAdd.length > 0 ? orgsToAdd.map((org, id) => 
                                <p key={"selected" + id} 
                                    className="d-flex justify-content-between align-items-center text-color-tertiary text-sm px-2 w-100">
                                    <span>{org.title}</span>
                                    <FontAwesomeIcon icon={faClose} className="feature-icon"
                                        title="Remove" size="sm"
                                        onClick={() => removeOrgsFromList(id)}
                                    />
                                </p>
                            ) : <></>}
                        </div>

                        <div className="mt-3">
                            <Form.Label>Column Data</Form.Label>

                            {columnData?.map((c, i) => (
                                <Row className="mt-3" key={i}>
                                    <Col xs={12} md={4}>
                                        <Form.Group>
                                            <Input
                                                placeholder="Column Name"
                                                value={c.columnName}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.[
                                                            "columnData"
                                                        ]?.[i.toString()]?.[
                                                            "columnName"
                                                        ]
                                                    ) {
                                                        delete formErrors?.[
                                                            "columnData"
                                                        ]?.[i.toString()]?.[
                                                            "columnName"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    c.columnName =
                                                        e.target.value;
                                                    setColumnData([
                                                        ...columnData,
                                                    ]);
                                                }}
                                                error={
                                                    formErrors?.[
                                                        "columnData"
                                                    ]?.[i.toString()]?.[
                                                        "columnName"
                                                    ]
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={8}
                                        className="d-flex flex-row justify-content-center"
                                    >
                                        <Form.Group className="flex-fill">
                                            <Input
                                                placeholder="Column Description"
                                                value={c.columnDescription}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.[
                                                            "columnData"
                                                        ]?.[i.toString()]?.[
                                                            "columnDescription"
                                                        ]
                                                    ) {
                                                        delete formErrors?.[
                                                            "columnData"
                                                        ]?.[i.toString()]?.[
                                                            "columnDescription"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    c.columnDescription =
                                                        e.target.value;
                                                    setColumnData([
                                                        ...columnData,
                                                    ]);
                                                }}
                                                error={
                                                    formErrors?.[
                                                        "columnData"
                                                    ]?.[i.toString()]?.[
                                                        "columnDescription"
                                                    ]
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={8}
                                        className="d-flex flex-row justify-content-center ms-auto my-2"
                                    >
                                        <Form.Group className="flex-fill">
                                            <Input
                                                placeholder="Categories"
                                                value={c.categories}
                                                onChange={(e) => {
                                                    if (
                                                        formErrors?.[
                                                            "columnData"
                                                        ]?.[i.toString()]?.[
                                                            "categories"
                                                        ]
                                                    ) {
                                                        delete formErrors?.[
                                                            "columnData"
                                                        ]?.[i.toString()]?.[
                                                            "categories"
                                                        ];
                                                        setFormErrors({
                                                            ...formErrors,
                                                        });
                                                    }
                                                    c.categories =
                                                        e.target.value;
                                                    setColumnData([
                                                        ...columnData,
                                                    ]);
                                                }}
                                                error={
                                                    formErrors?.[
                                                        "columnData"
                                                    ]?.[i.toString()]?.[
                                                        "categories"
                                                    ]
                                                }
                                            />
                                        </Form.Group>
                                        <Button
                                            title="Remove Column"
                                            variant="outlined"
                                            onClick={() => {
                                                columnData.splice(i, 1);
                                                setColumnData([...columnData]);
                                            }}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon
                                                color="red"
                                                icon={faClose}
                                            />
                                        </Button>
                                    </Col>
                                </Row>
                            ))}

                            <Button
                                onClick={() => {
                                    columnData.push({
                                        columnName: "",
                                        columnDescription: "",
                                        categories: ""
                                    });
                                    setColumnData([...columnData]);
                                }}
                                className="mt-3"
                            >
                                <FontAwesomeIcon icon={faAdd} /> Add Column
                            </Button>
                        </div>

                        <Row className="mt-3">
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Input
                                        label="Sources"
                                        asTextArea
                                        placeholder="Separated by line"
                                        value={sources}
                                        onChange={(e) => {
                                            if (formErrors?.["sources"]) {
                                                delete formErrors?.["sources"];
                                                setFormErrors({
                                                    ...formErrors,
                                                });
                                            }
                                            setSources(e.target.value);
                                        }}
                                        error={formErrors?.["sources"]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Input
                                        asTextArea
                                        label="Collaborators"
                                        placeholder="Separated by line"
                                        value={collaborators}
                                        onChange={(e) => {
                                            if (formErrors?.["collaborators"]) {
                                                delete formErrors?.[
                                                    "collaborators"
                                                ];
                                                setCollaborators({
                                                    ...formErrors,
                                                });
                                            }
                                            setCollaborators(e.target.value);
                                        }}
                                        error={formErrors?.["collaborators"]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Input
                                        label="License"
                                        asTextArea
                                        placeholder="License Info"
                                        value={license}
                                        onChange={(e) => {
                                            if (formErrors?.["license"]) {
                                                delete formErrors?.["license"];
                                                setFormErrors({
                                                    ...formErrors,
                                                });
                                            }
                                            setLicense(e.target.value);
                                        }}
                                        error={formErrors?.["license"]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <InputSelect
                                        labelKey="label"
                                        valueKey="value"
                                        onChange={(e) => setType(e.value)}
                                        label="Type"
                                        placeholder="Select Type"
                                        value={types.find(
                                            (x) => x.value === type
                                        )}
                                        options={types}
                                        error={formErrors["type"]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Input
                                        label="PQ EPS"
                                        placeholder="pq_eps"
                                        type="number"
                                        value={pqEps?.toString()}
                                        onChange={(e) => {
                                            if (formErrors?.["pqEps"]) {
                                                delete formErrors?.["pqEps"];
                                                setFormErrors({
                                                    ...formErrors,
                                                });
                                            }
                                            if (!isNaN(Number(e.target.value)))
                                                setPqEps(
                                                    Number(e.target.value)
                                                );
                                        }}
                                        error={formErrors?.["pqEps"]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Input
                                        label="PQ Delta"
                                        placeholder="pq_delta"
                                        type="number"
                                        value={pqDelta?.toString()}
                                        onChange={(e) => {
                                            if (formErrors?.["pqDelta"]) {
                                                delete formErrors?.["pqDelta"];
                                                setFormErrors({
                                                    ...formErrors,
                                                });
                                            }
                                            if (!isNaN(Number(e.target.value)))
                                                setPqDelta(
                                                    Number(e.target.value)
                                                );
                                        }}
                                        error={formErrors?.["pqDelta"]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Input
                                        label="Max EPS"
                                        placeholder="max_eps"
                                        type="number"
                                        value={maxEps?.toString()}
                                        onChange={(e) => {
                                            if (formErrors?.["maxEps"]) {
                                                delete formErrors?.["maxEps"];
                                                setFormErrors({
                                                    ...formErrors,
                                                });
                                            }
                                            if (!isNaN(Number(e.target.value)))
                                                setMaxEps(
                                                    Number(e.target.value)
                                                );
                                        }}
                                        error={formErrors?.["maxEps"]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group>
                                    <Input
                                        label="Max Delta"
                                        placeholder="max_delta"
                                        type="number"
                                        value={maxDelta?.toString()}
                                        onChange={(e) => {
                                            if (formErrors?.["maxDelta"]) {
                                                delete formErrors?.["maxDelta"];
                                                setFormErrors({
                                                    ...formErrors,
                                                });
                                            }
                                            if (!isNaN(Number(e.target.value)))
                                                setMaxDelta(
                                                    Number(e.target.value)
                                                );
                                        }}
                                        error={formErrors?.["maxDelta"]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mt-3">
                            <Form.Label>About</Form.Label>
                            <RichTextEditor
                                value={about}
                                onChange={(val) => setAbout(val)}
                            />
                            {formErrors["about"] ? (
                                <div className="text-invalid-feedback mt-1">
                                    {formErrors["dataFile"]}
                                </div>
                            ) : undefined}
                        </Form.Group>

                        {formErrors[""] ? (
                            <div className="text-invalid-feedback m-2">
                                {formErrors[""]}
                            </div>
                        ) : undefined}

                        <Form.Group
                            className="mt-5 d-flex"
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button type="submit" className="ms-auto">
                                Create
                            </Button>
                            <Button
                                type="reset"
                                className="ms-3"
                                variant="danger"
                            >
                                Reset
                            </Button>
                        </Form.Group>
                    </Form>
                </Card.Body>
                <LoadingSpinner show={loading} text={"Creating dataset"} />
            </Card>
        </div>
    );
}
