import step_1 from "../../assets/images/quickstart/Step 1.gif";
import step_2 from "../../assets/images/quickstart/Step 2.png";
import step_3 from "../../assets/images/quickstart/Step 3.png";
import step_4 from "../../assets/images/quickstart/Step 4.gif";
import step_6 from "../../assets/images/quickstart/Step 6.jpg";
import step_8 from "../../assets/images/quickstart/Step 8.png";
import step_9 from "../../assets/images/quickstart/Step 9.png";
import step_10 from "../../assets/images/quickstart/Step 10.png";
import "./index.scss";
import { NOTEBOOKS } from "../../router/routes";

type QuickstartProps = {
    isExternal: boolean;
};

export default function CompetitionQuickstart(props: QuickstartProps) {
    return (
        <div className="info-page-tab-content text-color-secondary text-xs">
            <p className="text-lg mb-3 w-75">Getting started</p>
            <div className="quickstart-info mb-4">
                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">1.</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Sign Up and Log In!</p>
                        <p className="mb-3">
                            First things first, create an account on the platform. You
                            can register via email or just use your Google or GitHub credentials.
                        </p>
                        <img src={step_1} alt="Step 1" />
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">2.</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Explore the competitions</p>
                        <p>
                            Dive into the available competitions and choose the one you're
                            interested in. Seems you have already picked this one!
                        </p>
                        <img src={step_2} alt="Step 2" />
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">3.</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Get your Jupyter Notebook ready</p>
                        <p>
                            You can use any available option, including Google Colab or your local system.
                        </p>
                        <img src={step_3} alt="Step 3" />
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">4.</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Install the Antigranular package</p>
                        <p>
                            Type in a "pip install antigranular" command to connect to Antigranular.
                        </p>
                        <img src={step_4} alt="Step 4" />
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">5.</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Secure enclave access</p>
                        <p>
                            Connect to the secure enclave by copying the code block at
                            the top right corner of the competition page which includes
                            your custom credentials. Paste them into your Jupyter Notebook
                            to connect to the secure enclave. Check the
                            sample <a href={`/${NOTEBOOKS}`}
                                className="link-ag-1 cursor-pointer">notebooks</a> if in doubt.
                        </p>
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">6.</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">You're in!</p>
                        <p>
                            Upon successful login, you'll see the session ID and the
                            %%ag cell magic registered to your system.
                        </p>
                        <img src={step_6} alt="Step 6" />
                    </div>
                </div>

                {props.isExternal ? <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">7.</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Load the dataset</p>
                        <p>
                            The datasets need to be loaded using load_dataset method.
                            There are a total of 19 data slices relevant to various
                            features. Please refer to <a target="_blank" rel='noreferrer' className="link-ag-1"
                                href="https://docs.google.com/spreadsheets/d/1KVO5y3Xcda7FkkAk34ajnQ_zDDfR0cRASr8PCfgVS4k/edit#gid=797273506">this sheet</a> to
                            access the relevant code to load the data slice. You can load all data slices or select ones you need for your exploration.
                        </p>
                    </div>
                </div> : <></>}

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">{props.isExternal ? "8." : "7."}</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Now the fun begins</p>
                        <p>
                            You are ready to analyse the data, make predictions, and flex
                            your skills using %%ag magic remote execution. Check the
                            sample <a href={`/${NOTEBOOKS}`}
                                className="link-ag-1 cursor-pointer">notebooks</a> and details of supported <a target="_blank" rel='noreferrer'
                                    className="link-ag-1" href="https://docs.antigranular.com/category/packages">packages</a> for more examples.
                        </p>
                        <img src={step_8} alt="Step 8" />
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">{props.isExternal ? "9." : "8."}</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Ready to make a submission?</p>
                        <p>
                            Submit a prediction by simply typing “session.submit_predictions (your_prediction_dataframe)”
                            to find out how you rank on the leaderboard.
                        </p>
                        <img src={step_9} alt="Step 9" />
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <span className="lineNumber text-thick text-lg">{props.isExternal ? "10." : "9."}</span>
                    <div>
                        <p className="mb-0 text-thick text-lg">Spend wisely</p>
                        <p>
                            Antigranular is not just about accuracy but also about using
                            the least amount of privacy budget. Navigate the trade-off
                            like a boss to come out on top. Head to the <a target="_blank"
                                rel='noreferrer' className="link-ag-1" href="https://docs.antigranular.com">
                                Antigranular Docs</a> for details of the scoring system and epsilon best practice.
                        </p>
                        <img src={step_10} alt="Step 10" />
                    </div>
                </div>
            </div>

            <div>
                <p className="mb-0 text-bold text-lg">Got questions?</p>
                <p>
                    Join our <a target="_blank" rel='noreferrer' className="link-ag-1"
                        href="https://discord.com/channels/904342660339359744/1131201540665909291">Discord channel</a>, where
                    our team is ready to give you guidance and troubleshoot problems.
                </p>
            </div>
        </div>
    )
}
