export const USR_LOCALSTORAGE_KEY = "_USER";
export const PWD_RESET_URL_PARAM = "res_pwd";
export const REF_CODE_URL_PARAM = "ref_code";
export const UNLINK_AP_URL_PARAM = "unlink";
export const ACCOUNT_VERIFY_URL_PARAM = "ac_vf";
export const JOIN_TEAM_URL_PARAM = "join_team";
export const GIT_CLIENT_ID =
    process.env.REACT_APP_GIT_CLIENT_ID || "ffc4889b06f5b6d305f4";
export const GIT_AUTH_REDIRECT_URL = `${window.location.protocol}//${window.location.host}/signin/github`;
export const GIT_AUTH_URL = `https://github.com/login/oauth/authorize?scope=read:user user:email&client_id=${GIT_CLIENT_ID}&redirect_uri=${GIT_AUTH_REDIRECT_URL}`;
export const GOOGLE_CLIENT_ID =
    process.env.REACT_APP_GOOGLE_CLIENT_ID ||
    `783003709749-i35on8q2f9ivoi7k4gav0fm09k6bg1ea.apps.googleusercontent.com`;
export const GOOGLE_AUTH_REDIRECT_URL = `${window.location.protocol}//${window.location.host}/signin/google`;
export const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/auth?client_id=${GOOGLE_CLIENT_ID}&scope=openid profile email&redirect_uri=${GOOGLE_AUTH_REDIRECT_URL}&response_type=code`;
export const RECAPTCHA_SITE_ID_V2 = "6LcxIJAmAAAAAP8T4E-cjTkHo3tU34q93Q_w7btL";
export const RECAPTCHA_SITE_ID_V3 = process.env.REACT_APP_RECAPTCHA_SITE_ID;
