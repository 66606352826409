import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    customDiscordLink,
    customGithub,
    customTwitterLink,
} from "../../assets/icons";
import { COOKIE_DECLARATION, DISCORD, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../../router/routes";

type FooterProps = {
    className?: string;
};

export default function Footer(props: FooterProps) {
    return (
        <section
            className={`section-container lg-box footer mb-0 ${props.className}`}
        >
            <div className="d-flex flex-column me-4 mb-5 footer-col-1">
                <p
                    className="text-xs mb-3"
                    onClick={() =>
                        document
                            ?.getElementById("Antigranular")
                            ?.scrollIntoView(false)
                    }
                >
                    Antigranular by Oblivious
                </p>
                <p className="text-xs text-color-quinary mb-3">
                    {new Date().getUTCFullYear()} Oblivious Software Ltd. All
                    rights reserved.
                </p>
                <span className="text-xs text-color-tertiary">
                    This site is protected by reCAPTCHA, and the Google Privacy
                    Policy and Terms Of Service apply.
                </span>
            </div>
            <div className="footer-links">
                <div className="d-flex flex-column text-xs me-4 mb-5">
                    <p className="text-xs mb-3">Help</p>
                    <a
                        className="link mb-2 text-color-quinary"
                        href={`/${COOKIE_DECLARATION}`}
                    >
                        Cookie Declaration
                    </a>
                    <a
                        className="link mb-2 text-color-quinary"
                        href={`/${PRIVACY_POLICY}`}
                    >
                        Privacy Policy
                    </a>
                    <a
                        className="link text-color-quinary"
                        href={`/${TERMS_AND_CONDITIONS}`}
                    >
                        Terms & Conditions
                    </a>
                </div>
                <div className="d-flex flex-column me-4">
                    <p className="text-xs mb-3 text-xs">Contact</p>
                    <a
                        className="text-xs mb-5 text-color-quinary agAnchor ms-0"
                        href="mailto:hello@antigranular.com"
                    >
                        hello@antigranular.com
                    </a>
                </div>
                <div className="d-flex flex-column text-xs mb-5 me-1">
                    {/* <img src={git} alt="GitHub"/>
                    <img src={discord} alt="Discord"/> */}
                    <p className="text-xs mb-3">Socials</p>
                    <div className="d-flex flex-wrap">
                        <a
                            href={DISCORD}
                            className="agAnchor ms-0 me-3"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                size="xl"
                                icon={customDiscordLink}
                            />
                        </a>
                        <a
                            href="https://github.com/ObliviousAI"
                            className="agAnchor me-0 me-3"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon size="xl" icon={customGithub} />
                        </a>
                        <a
                            href="https://twitter.com/antigranular"
                            className="agAnchor me-0 me-3"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                size="xl"
                                icon={customTwitterLink}
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/showcase/antigranular/"
                            className="agAnchor me-0"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="1.5"
                                    y="1.5"
                                    width="21"
                                    height="21"
                                    rx="10.5"
                                    fill="#98A2B3"
                                />
                                <path
                                    d="M9.46392 7.26911C9.46392 7.97002 8.85639 8.53822 8.10696 8.53822C7.35753 8.53822 6.75 7.97002 6.75 7.26911C6.75 6.5682 7.35753 6 8.10696 6C8.85639 6 9.46392 6.5682 9.46392 7.26911Z"
                                    fill="white"
                                />
                                <path
                                    d="M6.93557 9.47107H9.25515V16.5H6.93557V9.47107Z"
                                    fill="white"
                                />
                                <path
                                    d="M12.9897 9.47107H10.6701V16.5H12.9897C12.9897 16.5 12.9897 14.2872 12.9897 12.9036C12.9897 12.0732 13.2732 11.2392 14.4046 11.2392C15.6833 11.2392 15.6756 12.3259 15.6696 13.1678C15.6618 14.2683 15.6804 15.3914 15.6804 16.5H18V12.7903C17.9804 10.4215 17.3631 9.33006 15.3325 9.33006C14.1265 9.33006 13.379 9.87754 12.9897 10.3729V9.47107Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
