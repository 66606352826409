import { IUpdateUserProfilePayload, IFetchUserProfilesPayload, IOrganizationUserProfileData, IUser } from "../types/user-type";
import { BaseApi } from "./base-api";

export default class ProfileApi extends BaseApi {
    private static url = (action: string) => 'profile/' + action;
    /**
    * Get profile
    * @param userId //Only for admin use
    * @returns
    */
    public static async get(userId?: string): Promise<IUser> {
        const path = this.url('get') + (userId ? '/' + userId : "");
        return this._get(path);
    }

    /**
    * Get User Referral Code
    * @param userId //Only for admin use
    * @returns
    */
    public static async getReferralCode(): Promise<string> {
        const path = this.url('getMyReferralCode');
        return this._get(path);
    }

    /**
    * Get User Referral Code
    * @param data
    * @returns
    */
    public static async getUserProfiles(data: IFetchUserProfilesPayload): Promise<IOrganizationUserProfileData> {
        const path = this.url('get-profiles');
        return this._get(path, data);
    }

    /**
     * Update user profile
     * @param profile
     * @returns
     */
    public static async update(profile: IUpdateUserProfilePayload): Promise<IUser> {
        let fd = new FormData();
        for (let key in (profile as any)) {
            fd.append(key, (profile as any)[key])
        }
        return this._patch(this.url('update'), fd);
    }

    public static async updateNotficiationPreference(data: { type: string, allowed: boolean }): Promise<IUser> {
        return this._patch(this.url('updateemailpreference'), data);
    }
}
