import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";

// import: assets
// import: styles
import "./index.scss";

// import: constants
import { ROOT } from "../../router/routes";

// import: enums
// import: types
// import: utils
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import { useAppDispatch } from "../../store/store-hooks";
import {
    showErrorModal,
    showInfoModal,
} from "../../store/slices/modal-states-slice";

// import: api
import AuthApi from "../../api/auth-api";
import CompetitionTeamsApi from "../../api/competition-teams-api";

// import: config
import {
    ACCOUNT_VERIFY_URL_PARAM,
    REF_CODE_URL_PARAM,
    PWD_RESET_URL_PARAM,
    JOIN_TEAM_URL_PARAM,
} from "../../configs/constants";

// import: components
import Banner from "./homeBanner";
import InfoColumns from "./infoColumns";
import JoinCompetition from "./joinCompetition";
import DetailsCard from "./detailsCard";
import Header from "./header";
import Footer from "./footer";
import BaseCard from "./baseCard";
import JoinCommunity from "./joinCommunity";
import ResetPasswordModal from "../../components/auth-modals/reset-password-modal";
import AcceptInvitationModal from "../../components/auth-modals/accept-invitation-modal";

export default function IntroPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [inviteToken, setInviteToken] = useState<string>("");
    const [invitationType, setInvitationType] = useState<string>("");
    const [teamId, setTeamId] = useState<string>("");
    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
    const [searchParam, setSearchParam] = useSearchParams();
    const [pwdResToken, setPwdResToken] = useState<string | null>();

    useEffect(() => {
        if (searchParam.get(PWD_RESET_URL_PARAM)) {
            setPwdResToken(searchParam.get(PWD_RESET_URL_PARAM));
            searchParam.delete(PWD_RESET_URL_PARAM);
            setSearchParam(searchParam);
        }
        else if (searchParam.get(ACCOUNT_VERIFY_URL_PARAM)) {
            const token = searchParam.get(ACCOUNT_VERIFY_URL_PARAM);
            if (token) {
                const decoded: any = jwt_decode(token);
                if (decoded.requirePasswordChange) {
                    setInviteToken(token);
                    setInvitationType("SITE");
                    setShowInviteModal(true);
                } else {
                    AuthApi.verifyAccount({ token: searchParam.get(ACCOUNT_VERIFY_URL_PARAM) ?? "" })
                        .then(() => {
                            dispatch(
                                showInfoModal({
                                    title: "Verified",
                                    message:
                                        "Your account verified successfully. You can login now with your credentials.",
                                })
                            );
                        })
                        .catch((error) => {
                            dispatch(
                                showErrorModal({
                                    title: "Verification Failed",
                                    message: HandleError(error)[""],
                                })
                            );
                        })
                        .finally(() => setShowInviteModal(false))
                }
            }
            searchParam.delete(ACCOUNT_VERIFY_URL_PARAM);
            setSearchParam(searchParam);
        }
        else if (searchParam.get(REF_CODE_URL_PARAM)) {
            localStorage.setItem("AG_REFERRAL", searchParam.get(REF_CODE_URL_PARAM) || "");
            searchParam.delete(REF_CODE_URL_PARAM);
            setSearchParam(searchParam);
        }
        else if (searchParam.get(JOIN_TEAM_URL_PARAM)) {
            const token = searchParam.get(JOIN_TEAM_URL_PARAM);
            if (token) {
                const teamDecoded: any = jwt_decode(token);
                if (teamDecoded.verifiedUser) {
                    CompetitionTeamsApi.acceptInvitation({
                        token
                    })
                        .then(() => {
                            setShowInviteModal(false);
                            dispatch(
                                showInfoModal({
                                    title: "Success!",
                                    message: `You have successfully joined the team!`
                                })
                            );
                        }).catch((error) => {
                            dispatch(
                                showInfoModal({
                                    title: "",
                                    message: HandleError(error)[""],
                                })
                            );
                        });

                } else {
                    setInviteToken(token);
                    setInvitationType("TEAM");
                    setShowInviteModal(true);
                    setTeamId(teamDecoded.teamId);
                }

                searchParam.delete(JOIN_TEAM_URL_PARAM);
                setSearchParam(JOIN_TEAM_URL_PARAM);
            }
        }
    }, []);

    return (
        <div className="intro-page-holder">
            <div className="page-holder">
                <Header />
                <Banner />
                <InfoColumns />
                <JoinCompetition />
                <JoinCommunity />
                <DetailsCard />
                <BaseCard />
                <Footer />
            </div>

            {//*******reset password modal */
                <ResetPasswordModal
                    token={pwdResToken}
                    onHide={() => {
                        navigate(ROOT);
                        setPwdResToken(null);
                    }}
                    onReset={() => {
                        dispatch(
                            showInfoModal({
                                title: "Password Changed",
                                message:
                                    "Your password has been reset successfully. Please login with your new password.",
                            })
                        );
                        navigate(ROOT);
                        setPwdResToken(null);
                    }}
                    show={pwdResToken ? true : false}
                />
            }

            {//*******accept site/team invitation modal */
                <AcceptInvitationModal
                    token={inviteToken}
                    onHide={() => setShowInviteModal(false)}
                    show={showInviteModal}
                    invitationType={invitationType}
                    teamId={teamId}
                />
            }
        </div>
    );
}
