import "./index.scss";
import { useState } from "react";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../intro-page/footer";
import { Outlet } from "react-router-dom";
import { 
    SidebarMenus,
    // SidebarFooterMenus
} from "../../router/sidebar-menu";
// import Container from "react-bootstrap/Container";

export default function MasterLayout() {
    const [sidebarXsVisible, setSidebarXsVisible] = useState<boolean>(false);
    return (
        <div className="parentHolder">
            <Sidebar
                menuList={SidebarMenus}
                // footerMenuList={SidebarFooterMenus}
                onCloseSidebar={() => setSidebarXsVisible(false)}
                xsVisible={sidebarXsVisible}
            />

            <div className="pageHolder">
                <Navbar onToggleClick={() => setSidebarXsVisible(!sidebarXsVisible)} />
                <div className="content-container">
                    <Outlet />
                </div>
                <div className="content-container pages-footer pb-0">
                   <Footer className="pb-0 ms-0"/>
                </div>
            </div>
        </div>
    );
}
