import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISharedState {
    navbarSearchText?: string;
    searchPlaceholder?: string;
}

const initialState: ISharedState = {
    navbarSearchText: ""
};

export const sharedStatesSlice = createSlice({
    name: 'shared-state',
    initialState,
    reducers: {
        onChangeSearchText: (s, { payload }: PayloadAction<string>) => {
            s.navbarSearchText = payload;
        },
        setSearchPlaceholder: (s, { payload }: PayloadAction<string>) => {
            s.searchPlaceholder = payload;
        }
    }
})

export const { onChangeSearchText, setSearchPlaceholder } = sharedStatesSlice.actions;
export default sharedStatesSlice.reducer;