import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Input from "../input/input";
import OffenseApi from "../../api/offense-api";
import { useAppDispatch } from "../../store/store-hooks";
import InputSelect from "../../components/input/input-select";
import { HandleError } from "../../errors/handler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import "../auth-modals/index.scss"
import { useEffect, useState } from "react";

type AlertNotebookModal = {
    notebookID: string;
    show: boolean;
    setShow: Function;
};

type alertTypeProps = {
    name: string;
    description: string;
};

export default function AlertModal(props: AlertNotebookModal) {
    const dispatch = useAppDispatch();
    const [detail, setDetail] = useState("");
    const [type, setType] = useState("");
    const [category, setCategory] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any>({});
    // alert = "alert",
    // warning = "warning",
    // info = "info",
    const alertCategories = [
        {name: "rendering issue", description: "Notebook is not rendering", category : "alert"},
        {name: "spam", description: "Notebook is spammy", category : "warning"}, 
        {name: "abusive language", description: "Notebook contains abusive content", category : "alert"}, 
        {name: "plagiarism", description: "Plagiarism / copied content", category : "warning"}, 
        {name: "not working", description: "Broken notebook, code not working as expected", category : "alert"}, 
        {name: "other", description: "other", category : "warning"}
    ]

    useEffect(() => {
        if(type !== ""){
            const cat = alertCategories.find( (x) => x.name === type )?.category;
            if(cat) setCategory(cat);
        }
    }, [type])

    const validateForm = () => {
        setFormErrors({});
        const errors: any = {};
        if (type === "") {
            errors["type"] = "Please select an alert type";
        }
        if (detail.trim().length <= 10) {
            errors["detail"] = "Please describe the issue a little";
        }
        else if (detail.trim().length > 300) {
            errors["detail"] = "Please give a brief description, we will contact you for more details if needed.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        } else {
            return true;
        }
    };

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try{
            e.preventDefault();
            if (validateForm() && props.notebookID) {
                setLoading(true);
                await OffenseApi.report({
                    notebook: props.notebookID,
                    type,
                    category,
                    detail,
                });
                dispatch(
                    showInfoModal({
                        title: "Alerted",
                        message: "Notebook alert sucessfully submitted, necessary action will be taken.",
                    })
                );
                props.setShow(false);
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    }

    const reset = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDetail("");
        setType("");
        props.setShow(false);
    }

    return (
        <Modal className="login-modal" show={props.show} centered onHide={() => props.setShow(!props.show)}>
            <Modal.Header className="border-0 p-4 pb-0" closeButton>
                <p className="m-0">Send Alert</p>
            </Modal.Header>
            <Modal.Body className="">
                <p className="text-start text-sm text-color-secondary">Select the alert category and give a brief description of the issue.</p>
                <Form onSubmit={submit} onReset={reset} noValidate>
                    <fieldset disabled={loading}>
                        <div className="form-input-fields text-xs text-color-secondary">
                            
                            <Form.Group className="">
                                <InputSelect
                                    labelKey="description"
                                    valueKey="name"
                                    focusOnError={true}
                                    onChange={(val: alertTypeProps) => {
                                        if (formErrors["type"]) {
                                            delete formErrors.type;
                                            setFormErrors(formErrors);
                                        }
                                        setType(val ? val.name : "");   
                                    }}
                                    label="Issue Type"
                                    placeholder="Your Issue"
                                    value={alertCategories.find(
                                        (x) => x.name === type
                                    )}
                                    options={alertCategories}
                                    error={formErrors["alert"]}
                                />
                            </Form.Group>

                            <Form.Group className="mt-4 ">
                                <Input
                                    asTextArea
                                    focusOnError={true}
                                    className="form-control-textarea radius-base border edit-bio-textarea py-2"
                                    rows={20}
                                    placeholder="Enter a description"
                                    onChange={(e) => {
                                        if (formErrors["detail"]) {
                                            delete formErrors.detail;
                                            setFormErrors(formErrors);
                                        }
                                        setDetail(e.target.value)
                                    }}
                                    value={detail}
                                    error={formErrors["detail"]}
                                />
                            </Form.Group>
                        </div>  

                        <Form.Group className="mt-3 d-flex text-end">
                            <Button variant="primary" disabled={loading} type="submit" className="ms-auto rounded-pill">
                                {loading ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            spin
                                            className="me-2"
                                        />{" "}
                                        Submitting issue
                                    </>
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                            <Button variant="secondary" disabled={loading} type="reset" className="ms-3 rounded-pill">
                                Cancel
                            </Button>
                        </Form.Group>
                    </fieldset>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
