import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// import: assets
// import: styles
import "./index.scss";

// import: constants
import { ORGANISATIONS } from "../../router/routes";
// import: enums
import { UserRole } from "../../enums/roles";

// import: types
// import: utils
// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { loadOrganizations } from "../../store/slices/organization-slice";

// import: api
// import: config
// import: components
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LoadingSpinner from "../../components/loading-spinner";
import OrganizationListItem from "../../components/list-items/organization-list-item";
import CreateOrganizationModal from "./create-organization";
import Button from "react-bootstrap/Button";

export default function OrganizationPage() {
    const dispatch = useAppDispatch();
    const pageSize = parseInt(process.env.React_APP_ORGANIZATIONS_DATA_LENGTH || "10000");
    const user = useAppSelector((s) => s.auth.user);
    const organizations = useAppSelector((s) => s.organization.pageData.data);
    const fetchingOrganizations = useAppSelector((s) => s.organization.fetching);
    const [createModal, showCreateModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!organizations?.length) {
            dispatch(loadOrganizations({ page: 1, size: pageSize }));
        }
    }, [dispatch]);

    return (
        <div className="page-container lg-box">
            <div>
                <div className="page-heading-container">
                    <span className="text-3xl mb-21">Organizations</span>
                </div>
                {user?.roles?.includes(UserRole.Admin) ? (
                    <Button
                        className="mb-3 rounded-pill"
                        onClick={() => showCreateModal(true)}
                    >
                        Create
                    </Button>
                ) : undefined}
            </div>

            <Tabs className="pb-1">
                <Tab title={"All"} eventKey={"tabAll"}>
                    <div className="listing-table-container">
                        {organizations ? organizations?.map((org, idx) => (
                            <OrganizationListItem
                                onClick={() => navigate(`/${ORGANISATIONS}/` + (org.slug ?? org._id))}
                                item={org}
                                key={org._id}
                            />
                        )) : <></>}

                        {organizations?.length === 0 ?
                            <div className="py-5 text-center text-color-tertiary w-100 my-0">
                                No Organizations to list
                            </div> : <></>
                        }
                    </div>
                </Tab>
            </Tabs>

            <CreateOrganizationModal show={createModal} onHide={() => showCreateModal(false)} />

            <LoadingSpinner
                show={fetchingOrganizations}
                text={"Getting Organizations"}
            />
        </div>
    );
}
