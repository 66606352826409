import "./index.scss";
import award from "../../assets/icons/award.png";

type RankingBadgeProps = {
    rank: number;
    className?: string;
    miniText?: boolean;
};

export default function RankingBadge(props: RankingBadgeProps) {
    const addon = props.miniText ? "" : " place"

    const getRankClass = () => {
        if(props.rank === 1) return "goldBadge";
        else if(props.rank === 2) return "silverBadge";
        else if(props.rank === 3) return "bronzeBadge";
        else return "congratulationsBadge"
    }

    const getRank = () => {
        if(props.rank === 1) return <span>1st {addon}</span>;
        else if(props.rank === 2) return <span>2nd {addon}</span>;
        else if(props.rank === 3) return <span>3rd {addon}</span>;
        else return <img alt="" className="badge-icon" src={award} />
    }

    return (
        <div className={`customBadge ${getRankClass()} ${ props.className}`}>
            {getRank() as any}
        </div>
    );
}
