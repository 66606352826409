import laptop from "../../assets/icons/laptop-bw.png";
import magic from "../../assets/icons/magic-wand.png";
import browser from "../../assets/icons/code-browser.png";
import shield from "../../assets/icons/shield-tick.png";
import company_logos from "../../assets/logos/company_logos.png";
import Carousel from 'react-bootstrap/Carousel';


export default function DetailsCard() {

    return (
        <section className="section-container text-md-center lg-box details-card-section">
            <div className="text-heading text-md-center glazed-bw-text2 mb-43">
                Antigranular in a nutshell: what's inside? 
            </div>
            {window.innerWidth >= 780 ?
                <div className="details-card-holder">
                    <div className="d-flex">
                        <Item1/>
                        <Item2/>
                    </div>
                    <div className="d-flex">
                        <Item3 />
                        <Item4 />
                    </div>
                </div> : 

                <Carousel controls={false} interval={null}>
                    <Carousel.Item className="info-carousel-item px-2">
                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <Item1 />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="info-carousel-item px-2">
                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <Item2 />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="info-carousel-item px-2">
                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <Item3 />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="info-carousel-item px-2">
                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <Item4 />
                        </div>
                    </Carousel.Item>
                </Carousel>
            }
        </section>
    );
}

function Item1() {
    return (
        <div className="glazed-card-cover radius-base details-card">
            <div className="glazed-card radius-base p-42 h-100 d-flex flex-column">
                <img src={magic} alt="" className="mx-auto" width={53} height={53}/>
                <h2 className="mt-4 mb-31 text-color-quaternary text-lg text-thick">
                    Make privacy become     %%Magic
                </h2>
                <p className="text-xs text-color-quinary">
                    Toggle between confidential compute and regular code blocks 
                    with only four characters. Available from all notebooks:
                </p>
                <img alt="" src={company_logos} className="w-100 mt-2" />
                <a className="link text-xs text-bold mt-auto pt-3 text-color-secondary" 
                    href="https://docs.antigranular.com/core-concepts/trusted-execution-environments/" target="_blank" rel='noreferrer'>
                        Learn about confidential compute
                    <svg width="10" height="10" className="link-icon me-0" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" 
                            stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
    )
}

function Item2() {
    return (
        <div className="glazed-card-cover radius-base details-card">
            <div className="glazed-card radius-base p-42 h-100 d-flex flex-column">
                <img src={shield} alt="" className="mx-auto" width={53} height={53}/>
                <h2 className="mt-4 mb-31 text-color-quaternary text-lg text-thick">
                    From privacy violations to native errors
                </h2>
                <p className="text-xs text-color-quinary">
                    It may look and feel like standard Python, but any 
                    attempt to reveal sensitive data will raise a firm exception.
                </p>
                <a className="link text-xs text-bold mt-auto pt-3 text-color-secondary" 
                    href="https://docs.antigranular.com/private-python/intro" target="_blank" rel='noreferrer'>
                    Learn about Private Python
                    <svg width="10" height="10" className="link-icon" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" 
                            stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
    )
}

function Item3() {
    return (
        <div className="glazed-card-cover radius-base details-card">
            <div className="glazed-card radius-base p-42 h-100 d-flex flex-column">
                <img src={browser} alt="" className="mx-auto" width={53} height={53}/>
                <h2 className="mt-4 mb-3 text-color-quaternary text-lg text-thick">
                    Supporting the best in class disclosure control frameworks
                </h2>
                <p className="text-xs text-color-quinary">
                    Only differentially private results can be printed or exported back 
                    to your local session. Get started by using any of the most popular packages:
                </p>
                <p className="text-sm text-color-tertiary text-thick d-flex w-100">
                    <span>OpenDP</span>
                    <span className="mx-auto px-2">SmartNoise</span>
                    <span>DiffPrivLib</span>
                </p>
                <a className="link text-xs text-bold mt-auto break-normal pt-3 text-color-secondary" 
                    href="https://docs.antigranular.com/core-concepts/differential-privacy" target="_blank" rel='noreferrer'>
                        Learn more about differential privacy
                    <svg width="10" height="10" className="link-icon" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" 
                            stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
    )
}

function Item4() {
    return (
        <div className="glazed-card-cover radius-base details-card">
            <div className="glazed-card radius-base p-42 h-100 d-flex flex-column">
                <img src={laptop} alt="" className="mx-auto" width={53} height={53}/>
                <h2 className="mt-4 mb-31 text-color-quaternary text-lg text-thick">The quickest of quick Starts</h2>
                <p className="text-xs text-color-quinary">
                    Quick and easy to install from within Jupyter notebooks.<br/>
                    <br/>Tested on all major operating systems.
                </p>
                <a className="link text-xs text-bold mt-auto break-normal pt-3 text-color-secondary" 
                    href="https://docs.antigranular.com/private-python/quickstart-guides/qs_error_free" target="_blank" rel='noreferrer'>
                        Learn about OS support & troubleshooting
                    <svg width="10" height="10" className="link-icon" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" 
                            stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
    )
}