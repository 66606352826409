import {
    IChangePasswordPayload,
    IRegisterPayload,
    IResetPasswordPayload,
    ISignInPayload,
    IUserAuth0Creds,
    IAcceptSiteInvitationBodyPayload
} from "../types/auth-types";
import { IJwtUser } from "../types/user-type";
import { BaseApi } from "./base-api";

export default class AuthApi extends BaseApi {
    private static url = (action: string) => "auth/" + action;

    /**
     * get Auth0 credentials
     * @returns
     */
    public static async getAuth0(): Promise<IUserAuth0Creds> {
        return this._get(this.url("getclientcredential"));
    }

    /**
     * rotate Auth0 credentials
     * @returns
     */
    public static async rotateAuth0(): Promise<IUserAuth0Creds> {
        return this._get(this.url("rotatecredential"));
    }

    /**
     * Login user
     * @param email
     * @param password
     * @returns
     */
    public static async login(data: ISignInPayload): Promise<IJwtUser> {
        return this._post(this.url("login"), data);
    }

    /**
     * Login user with github
     * @param code
     * @returns
     */
    public static async gitLogin(code: string, referralCode?: string): Promise<IJwtUser> {
        return this._post(this.url("gitauth"), {
            code,
            referralCode
        });
    }

    /**
     * Login user with google
     * @param code
     * @returns
     */
    public static async googleLogin(code: string, referralCode?: string): Promise<IJwtUser> {
        return this._post(this.url("googleauth"), {
            code,
            referralCode
        });
    }

    /**
     * Send Verification Link on Email
     * @param email
     * @param password
     * @returns
     */
    public static async sendVerificationLink(): Promise<void> {
        return this._post(this.url("sendverficationlink"), {});
    }

    /**
     * Register user
     * @returns
     */
    public static async register(data: IRegisterPayload): Promise<IJwtUser> {
        return this._post(this.url("register"), data);
    }

    /**
     * Change Password
     * @returns
     */
    public static async changePassword(
        data: IChangePasswordPayload
    ): Promise<void> {
        return this._post(this.url("changepassword"), data);
    }

    /**
     * Forgot Password
     * @returns
     */
    public static async forgotPassword(
        email: string,
        captcha: string
    ): Promise<void> {
        return this._post(this.url("forgotpassword"), {
            email,
            captcha,
        });
    }

    /**
     * Reset Password
     * @returns
     */
    public static async resetPassword(
        data: IResetPasswordPayload
    ): Promise<void> {
        return this._post(this.url("resetpassword"), data);
    }

    /**
     * Verify Account
     * @returns
     */
    public static async verifyAccount(data: IAcceptSiteInvitationBodyPayload): Promise<void> {
        return this._post(this.url("verifyaccount"), data);
    }

    /**
     * Unlink Auth Provider Request
     * @returns
     */
    // public static async unlinkAuthProviderRequest(): Promise<void> {
    //     return this._get(this.url("unlinkaccountrequest"));
    // }

    /**
     * Unlink Auth Provider Verify unlinkAuthProviderVerify
     * @returns
     */
    public static async unlinkAuthProvider(
        provider: string,
    ): Promise<void> {
        return this._post(this.url("unlinkaccount"), { provider });
    }

    /**
     * Set Password
     * @returns
     */
    public static async setUnlinkPassword(
        password: string,
    ): Promise<void> {
        return this._post(this.url("setpassword"), { password });
    }
}
