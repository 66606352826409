import { useAppSelector } from "../store/store-hooks";
import { Navigate } from "react-router-dom";
import { ErrorType } from "../enums/error";

type TAuthGuardProps = {
    roles: string[];
    children: JSX.Element;
};

export default function AuthGuard(props: TAuthGuardProps) {
    const user = useAppSelector((s) => s.auth.user);
    if (!user) {
        return (
            <Navigate
                state={{
                    errorType: ErrorType.Unauthorized,
                }}
                to={"/error"}
            />
        );
    } else {
        if (props.roles?.length) {
            if (user?.roles?.some((r) => props.roles.includes(r))) {
                return <>{props.children}</>;
            } else {
                return (
                    <Navigate
                        state={{
                            errorType: ErrorType.Unauthorized,
                        }}
                        to={"/error"}
                    />
                );
            }
        } else {
            return <>{props.children}</>;
        }
    }
}
