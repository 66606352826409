import { IconDefinition, IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
  
export const customDiscord: IconDefinition = {
  icon: [ 20, 20, [], "",
    "M15.172 6.26667C14.2259 5.46667 13.0906 5.06667 11.8922 5L11.703 5.2C12.7752 5.46667 13.7213 6 14.6044 6.73333C13.5321 6.13333 12.3337 5.73333 11.0722 5.6C10.6938 5.53333 10.3784 5.53333 10 5.53333C9.62156 5.53333 9.30619 5.53333 8.92775 5.6C7.66628 5.73333 6.46789 6.13333 5.39564 6.73333C6.27867 6 7.22477 5.46667 8.29702 5.2L8.1078 5C6.9094 5.06667 5.77408 5.46667 4.82798 6.26667C3.75573 8.4 3.18807 10.8 3.125 13.2667C4.0711 14.3333 5.39564 15 6.78326 15C6.78326 15 7.22477 14.4667 7.54014 14C6.72018 13.8 5.9633 13.3333 5.45872 12.6C5.90023 12.8667 6.34174 13.1333 6.78326 13.3333C7.35092 13.6 7.91858 13.7333 8.48624 13.8667C8.99083 13.9333 9.49541 14 10 14C10.5046 14 11.0092 13.9333 11.5138 13.8667C12.0814 13.7333 12.6491 13.6 13.2167 13.3333C13.6583 13.1333 14.0998 12.8667 14.5413 12.6C14.0367 13.3333 13.2798 13.8 12.4599 14C12.7752 14.4667 13.2167 15 13.2167 15C14.6044 15 15.9289 14.3333 16.875 13.2667C16.8119 10.8 16.2443 8.4 15.172 6.26667ZM7.91858 12.0667C7.28784 12.0667 6.72018 11.4667 6.72018 10.7333C6.72018 10 7.28784 9.4 7.91858 9.4C8.54931 9.4 9.11697 10 9.11697 10.7333C9.11697 11.4667 8.54931 12.0667 7.91858 12.0667ZM12.0814 12.0667C11.4507 12.0667 10.883 11.4667 10.883 10.7333C10.883 10 11.4507 9.4 12.0814 9.4C12.7122 9.4 13.2798 10 13.2798 10.7333C13.2798 11.4667 12.7122 12.0667 12.0814 12.0667Z"
  ],
  iconName: "custom-discord" as IconName,
  prefix: "custom-icons" as IconPrefix
};

export const customClose: IconDefinition = {
  icon: [ 42, 42, [], "",
    "M34.5079 32.1836L23.3188 21.0006L34.5079 9.89999C35.0686 9.25055 35.0383 8.27594 34.4382 7.66315C33.8381 7.05036 32.8707 7.00597 32.2177 7.56128L20.9959 18.5796L9.97035 7.47893C9.33231 6.84036 8.30186 6.84036 7.66382 7.47893C7.35414 7.78818 7.17994 8.20914 7.17994 8.64829C7.17994 9.08743 7.35414 9.50839 7.66382 9.81764L18.673 20.9018L7.48388 31.986C7.17419 32.2952 7 32.7162 7 33.1554C7 33.5945 7.17419 34.0155 7.48388 34.3247C7.79259 34.633 8.21051 34.8049 8.64532 34.8023C9.07216 34.8049 9.48309 34.6394 9.79041 34.3412L20.9959 23.2241L32.2177 34.5223C32.5264 34.8306 32.9443 35.0025 33.3792 35C33.8083 34.9982 34.2196 34.8266 34.5242 34.5223C34.8318 34.2109 35.003 33.7887 35 33.3496C34.9969 32.9104 34.8197 32.4907 34.5079 32.1836Z"
  ],
  iconName: "custom-close" as IconName,
  prefix: "custom-icons" as IconPrefix
};

export const customDiscordLink: IconDefinition = {
  icon: [ 24, 24, [], "",
  ["M9.88806 10.0679C9.20406 10.0679 8.66406 10.6679 8.66406 11.3999C8.66406 12.1319 9.21606 12.7319 9.88806 12.7319C10.5721 12.7319 11.1121 12.1319 11.1121 11.3999C11.1241 10.6679 10.5721 10.0679 9.88806 10.0679ZM14.2681 10.0679C13.5841 10.0679 13.0441 10.6679 13.0441 11.3999C13.0441 12.1319 13.5961 12.7319 14.2681 12.7319C14.9521 12.7319 15.4921 12.1319 15.4921 11.3999C15.4921 10.6679 14.9521 10.0679 14.2681 10.0679Z",
    "M20.1005 0H4.02055C2.66455 0 1.56055 1.104 1.56055 2.472V18.696C1.56055 20.064 2.66455 21.168 4.02055 21.168H17.6285L16.9925 18.948L18.5285 20.376L19.9805 21.72L22.5605 24V2.472C22.5605 1.104 21.4565 0 20.1005 0ZM15.4685 15.672C15.4685 15.672 15.0365 15.156 14.6765 14.7C16.2485 14.256 16.8485 13.272 16.8485 13.272C16.3565 13.596 15.8885 13.824 15.4685 13.98C14.8685 14.232 14.2925 14.4 13.7285 14.496C12.5765 14.712 11.5205 14.652 10.6205 14.484C9.93655 14.352 9.34855 14.16 8.85655 13.968C8.58055 13.86 8.28055 13.728 7.98055 13.56C7.94455 13.536 7.90855 13.524 7.87255 13.5C7.84855 13.488 7.83655 13.476 7.82455 13.464C7.60855 13.344 7.48855 13.26 7.48855 13.26C7.48855 13.26 8.06455 14.22 9.58855 14.676C9.22855 15.132 8.78455 15.672 8.78455 15.672C6.13255 15.588 5.12455 13.848 5.12455 13.848C5.12455 9.984 6.85255 6.852 6.85255 6.852C8.58055 5.556 10.2245 5.592 10.2245 5.592L10.3445 5.736C8.18455 6.36 7.18855 7.308 7.18855 7.308C7.18855 7.308 7.45255 7.164 7.89655 6.96C9.18055 6.396 10.2005 6.24 10.6205 6.204C10.6925 6.192 10.7525 6.18 10.8245 6.18C11.5565 6.084 12.3845 6.06 13.2485 6.156C14.3885 6.288 15.6125 6.624 16.8605 7.308C16.8605 7.308 15.9125 6.408 13.8725 5.784L14.0405 5.592C14.0405 5.592 15.6845 5.556 17.4125 6.852C17.4125 6.852 19.1405 9.984 19.1405 13.848C19.1405 13.848 18.1205 15.588 15.4685 15.672Z"]
  ],
  iconName: "custom-discord-link" as IconName,
  prefix: "custom-icons" as IconPrefix
};

export const customTwitterLink: IconDefinition = {
  icon: [ 24, 24, [], "",
    "M8.64991 20C6.57026 20 4.63168 19.3864 3 18.3276C4.38535 18.4185 6.83019 18.2007 8.35087 16.7292C6.06326 16.6227 5.0316 14.8428 4.89703 14.0822C5.0914 14.1582 6.01841 14.2495 6.54172 14.0365C3.91022 13.3671 3.50652 11.0243 3.59624 10.3093C4.08964 10.6592 4.92694 10.7809 5.25587 10.7505C2.8038 8.97059 3.68595 6.2931 4.11954 5.71501C5.87924 8.18826 8.51649 9.57733 11.7791 9.65459C11.7176 9.38089 11.6851 9.09593 11.6851 8.80325C11.6851 6.70277 13.3586 5 15.423 5C16.5016 5 17.4735 5.46484 18.1558 6.20837C18.8766 6.03703 19.9613 5.63591 20.4916 5.28905C20.2243 6.26268 19.3922 7.07488 18.8888 7.37592C18.8847 7.36563 18.893 7.38616 18.8888 7.37592C19.331 7.30807 20.5274 7.07481 21 6.74949C20.7663 7.29647 19.884 8.20591 19.16 8.71506C19.2947 14.7422 14.7492 20 8.64991 20Z"
  ],
  iconName: "custom-twitter-link" as IconName,
  prefix: "custom-icons" as IconPrefix
};


export const customGithub: IconDefinition = {
  icon: [ 24, 24, [], "",
    "M12 0C5.3724 0 0 5.3808 0 12.0204C0 17.3304 3.438 21.8364 8.2068 23.4252C8.8068 23.5356 9.0252 23.1648 9.0252 22.8456C9.0252 22.5612 9.0156 21.804 9.0096 20.802C5.6712 21.528 4.9668 19.1904 4.9668 19.1904C4.422 17.8008 3.6348 17.4312 3.6348 17.4312C2.5452 16.6872 3.7176 16.7016 3.7176 16.7016C4.9212 16.7856 5.5548 17.94 5.5548 17.94C6.6252 19.776 8.364 19.2456 9.0468 18.9384C9.1572 18.162 9.4668 17.6328 9.81 17.3328C7.146 17.0292 4.344 15.9972 4.344 11.3916C4.344 10.08 4.812 9.006 5.5788 8.166C5.4552 7.8624 5.0436 6.6396 5.6964 4.986C5.6964 4.986 6.7044 4.662 8.9964 6.2172C9.97532 5.95022 10.9853 5.81423 12 5.8128C13.02 5.8176 14.046 5.9508 15.0048 6.2172C17.2956 4.662 18.3012 4.9848 18.3012 4.9848C18.9564 6.6396 18.5436 7.8624 18.4212 8.166C19.1892 9.006 19.6548 10.08 19.6548 11.3916C19.6548 16.0092 16.848 17.0256 14.1756 17.3232C14.6064 17.694 14.9892 18.4272 14.9892 19.5492C14.9892 21.1548 14.9748 22.452 14.9748 22.8456C14.9748 23.1672 15.1908 23.5416 15.8004 23.424C18.19 22.6225 20.2672 21.0904 21.7386 19.0441C23.2099 16.9977 24.001 14.5408 24 12.0204C24 5.3808 18.6264 0 12 0Z"
  ],
  iconName: "custom-github-link" as IconName,
  prefix: "custom-icons" as IconPrefix
};
