
const process3D = (cards, scale, contentHeight, animate) => {
    const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");
    const THRESHOLD = scale;

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    const handleHover = (e) => {
        const { layerX, layerY, currentTarget } = e;
        const { clientWidth, clientHeight } = currentTarget;
        const horizontal = (layerX) / clientWidth;
        const vertical = (layerY) / clientHeight;
        const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
        const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

        currentTarget.style.transform = `perspective(1000px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
        currentTarget.style.setProperty("--x", layerX);
        currentTarget.style.setProperty("--y", layerY);
        currentTarget.style.setProperty("--a", rotateX);
        currentTarget.style.setProperty("--b", rotateY);
    }

    const resetStyles = (e) => {
        const { currentTarget } = e;
        currentTarget.style.transform = `perspective(1000px) rotateX(0deg) rotateY(-25deg) scale3d(1, 1, 1)`;
        currentTarget.style.setProperty("--a", "-25");
        currentTarget.style.setProperty("--b", "0");
    }
    
    if (!motionMatchMedia.matches && cards != null && !isMobileDevice && animate) {
        cards.forEach(card => {
            card.addEventListener("mousemove", handleHover);
            card.addEventListener("mouseleave", resetStyles);
            card.style.setProperty("--h", contentHeight);
        })
    }

    // ------------------------------------------------------------------------------------------------------------    

    const controlMobileLayout = (card) => {
        let touchStartX = 0;
        let touchStartY = 0;

        // Function to handle touch move and perform 3D rotation
        function handleTouchMove(event) {
            event.preventDefault();

            const touch = event.touches[0];
            const deltaX = touch.clientX - touchStartX;
            const deltaY = touch.clientY - touchStartY;

            // Adjust the rotation based on touch movement
            const rotateX = deltaY / 3; // Adjust the values for the desired effect
            const rotateY = deltaX / 3; // Adjust the values for the desired effect

            // Apply the 3D rotation
            card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(1, 1, 1)`;

            card.style.setProperty("--x", event.layerX);
            card.style.setProperty("--y", event.layerY);
            card.style.setProperty("--a", rotateY);
            card.style.setProperty("--b", rotateX);
        }

        // Function to handle touch end and reset the rotation
        function handleTouchEnd() {
            // Reset the rotation when the touch ends
            card.style.transform = 'perspective(1000px) rotateX(0deg) rotateY(-25deg) scale3d(1, 1, 1)';
            card.style.setProperty("--a", "-25");
            card.style.setProperty("--b", "0");
        }

        // Add touch event listeners to the element
        card.addEventListener('touchstart', (event) => {
            touchStartX = event.touches[0].clientX;
            touchStartY = event.touches[0].clientY;
        });

        card.addEventListener('touchmove', handleTouchMove);

        card.addEventListener('touchend', handleTouchEnd);

        // card.addEventListener("touchmove", handleTouchMove);
        // card.addEventListener("touchend", handleTouchEnd);
    }

    
    if (!motionMatchMedia.matches && cards != null && isMobileDevice && animate) {
        cards.forEach(card => {
            controlMobileLayout(card);
            card.style.setProperty("--h", contentHeight);
            if(!animate) card.style.pointerEvents = "none";
        })
    }

    // const handleTouchMove = (e) => {
    //     const { layerX, layerY, target } = e.touches[0];
    //     const { clientWidth, clientHeight } = target;
    //     const horizontal = (layerX) / clientWidth;
    //     const vertical = (layerY) / clientHeight;
    //     const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
    //     const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

    //     target.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
    //     target.style.setProperty("--x", layerX);
    //     target.style.setProperty("--y", layerY);
    //     target.style.setProperty("--a", rotateX);
    //     target.style.setProperty("--b", rotateY);
    //     target.style.setProperty("--h", contentHeight);
    // }

    // const handleTouchEnd = (e) => {
    //     const { target } = e.touches[0];
    //     target.style.transform = `perspective(${target.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
    // }

}

export default process3D;