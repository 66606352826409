import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import: assets
import {
    faAdd,
    faArrowLeft,
    faTimes,
    faSubtract,
} from "@fortawesome/free-solid-svg-icons";

// import: styles
// import: constants
import { COMPETITION_ID } from "../../router/routes";

// import: enums
import { PrizeType, CompetitionMembershipType, EditAccessType } from "../../enums/prize-type";

// import: types
import { IDataset } from "../../types/dataset-types";
import {
    ICompetition,
    IPrizeDetail,
    IUpdateCompetitionPayload,
    TMembers,
    ITeamRules
} from "../../types/competition-types";
// import { IOrganization } from "../../types/organization-types";

// import: utils
import { HandleError } from "../../errors/handler";
import { tryParseJSONObject } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { loadDatasets } from "../../store/slices/dataset-slice";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import { updateCompetition } from "../../store/slices/competition-slice";

// import: api
import CompetitionApi from "../../api/competition-api";

// import: config
// import: components
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RichTextEditor from "../../components/richtext-editor";
import FileInput from "../../components/input/file-input";
import Input from "../../components/input/input";
import LoadingSpinner from "../../components/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputSelect from "../../components/input/input-select";
import DateInput from "../../components/input/date-input";
import ErrorAlert from "../../components/error-alert";
import competitionTypes from "../../components/category-icons/types.json";
import Checkbox from "../../components/checkbox";

export default function EditCompetitionPage() {
    const { [COMPETITION_ID]: _id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [competition, setCompetition] = useState<ICompetition>();
    const datasets = useAppSelector((s) => s.dataset.pageData.data);
    const loadingDatasets = useAppSelector((s) => s.dataset.fetching);
    //control refs
    const formRef = useRef<HTMLFormElement>(null);
    const titleInputRef = useRef<HTMLInputElement>(null);
    //form states
    const [formErrors, setFormErrors] = useState<any>({});
    const [title, setTitle] = useState<string>("");
    const [subTitle, setSubTitle] = useState<string>("");
    const [overview, setOverview] = useState<string>("");
    const [dataset_id, setDataset_id] = useState<string[]>([""]);
    const [dataset_dependencies, setDataset_dependencies] = useState<string>("");

    const [banner, setBanner] = useState<File>();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [rules, setRules] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string>("");


    const [registerByDate, setRegisterByDate] = useState<Date>();
    const [pqDelta, setPqDelta] = useState<number>(0);
    const [pqEps, setPqEps] = useState<number>(0);
    const [maxDelta, setMaxDelta] = useState<number>(0);
    const [maxEps, setMaxEps] = useState<number>(0);
    const [metrics, setMetrics] = useState<string>("");


    const [externalDataset, setExternalDtaset] = useState<boolean>(false);
    const [datasetDetails, setDatasetDetails] = useState<string>("");

    const [prizeDetail, setPrizeDetail] = useState<IPrizeDetail>({
        prizeType: PrizeType.None,
        detail: "",
        prizes: [],
        termsAndConditions: "",
    });

    const [participantType, setParticipantType] = useState<string>(CompetitionMembershipType.INDIVIDUAL);
    const [teamRules, setTeamRules] = useState<ITeamRules>();
    const [invitedMembersOnly, setInvitedMembersOnly] = useState<boolean>(false);
    const [invitedMembersString, setInvitedMembersString] = useState<string>("");
    const [privateLeaderboard, setPrivateLeaderboard] = useState<boolean>(false);

    useEffect(() => {
        if (!datasets?.length) {
            dispatch(loadDatasets({ page: 1, size: 10000 }));
        }

        if (_id) {
            setLoading(true);
            CompetitionApi.get(_id)
                .then((comp) => setCompetition(comp))
                .catch((error) => setFetchError(HandleError(error)[""]))
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setFetchError("Competition not found.");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (competition) {
            setTitle(competition.title);
            setSubTitle(competition.subTitle ?? "");
            setStartDate(competition.startDate);
            setEndDate(competition.endDate);
            setOverview(competition.overview ?? "");
            setDataset_id(competition.dataset_id);
            setPrizeDetail(
                competition.prizeDetail ?? {
                    prizeType: PrizeType.None,
                    detail: "",
                    prizes: [],
                }
            );
            setType(competition.type ?? "");
            setRules(competition.rules ?? "");
            setPqEps(competition.pqEps || 0);
            setPqDelta(competition.pqDelta || 0);
            setMaxEps(competition.maxEps || 0);
            setMaxDelta(competition.maxDelta || 0);
            setMetrics(competition.metrics ? JSON.stringify(competition.metrics, null, 2) : "{}");
            setDataset_dependencies(competition.dataset_dependencies ? JSON.stringify(competition.dataset_dependencies, null, 2) : "{}")

            if (competition.externalDataset) {
                setExternalDtaset(competition.externalDataset);
            }
            if (competition.datasetDetails) {
                setDatasetDetails(competition.datasetDetails);
            }

            if (competition.participantType) {
                setParticipantType(competition.participantType);

                if (competition.participantType === CompetitionMembershipType.TEAM) {
                    setInvitedMembersOnly(competition.invitedMembersOnly || false);
                    setTeamRules(competition.teamRules);
                    setRegisterByDate(competition.registerByDate);

                    if (competition.invitedMembersOnly === true) {
                        CompetitionApi.getInvitedMembers(competition._id).then((members) => {
                            setInvitedMembersString(members ? JSON.stringify(members, null, 2) : "{}")
                        }).catch(err => console.log(err));
                    }
                }
            }
            if (competition.privateLeaderboard) setPrivateLeaderboard(competition.privateLeaderboard);
        }
    }, [competition]);

    const addNewDataset = () => {
        if (dataset_id) {
            dataset_id.push("");
            setDataset_id([...dataset_id]);
        }
    };
    const removeDataset = () => {
        if (dataset_id && dataset_id.length > 1) {
            setDataset_id(dataset_id.slice(0, -1));
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (validateForm()) {
                setLoading(true);

                const obj = {
                    title,
                    subTitle,
                    overview,
                    banner,
                    startDate: startDate!.toUTCString(),
                    endDate: endDate!.toUTCString(),
                    dataset_id,
                    _id: competition?._id,
                    type,
                    rules,
                    pqDelta: Number(pqDelta),
                    pqEps: Number(pqEps),
                    maxDelta: Number(maxDelta),
                    maxEps: Number(maxEps),
                    metrics,
                    participantType,
                    privateLeaderboard,
                    teamRules,
                    dataset_dependencies,
                    registerByDate: registerByDate ? registerByDate.toUTCString() : undefined,
                    invitedMembersOnly,
                    invitedMembers: JSON.parse(invitedMembersString || "[]"),
                    externalDataset,
                    datasetDetails: externalDataset ? datasetDetails : undefined,
                } as IUpdateCompetitionPayload;

                if (
                    prizeDetail?.prizeType &&
                    prizeDetail?.prizeType !== PrizeType.None
                ) {
                    obj.prizeDetail = prizeDetail;
                }

                await dispatch(updateCompetition(obj)).unwrap();
                dispatch(
                    showInfoModal({
                        title: "Updated",
                        message: "Competition updated succcessfully.",
                    })
                );
                //resetForm();
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const validateForm: () => boolean = () => {
        setFormErrors({});
        const errors: any = {};
        if (!title) {
            errors["title"] = "Please write dataset title.";
        }

        if (!subTitle) {
            errors["subTitle"] = "Please write subtitle.";
        }

        if (dataset_id) {
            dataset_id.length > 0 &&
                dataset_id.forEach((dat, id) => {
                    if (dataset_id[id] === "" || dataset_id[id] === undefined) {
                        errors["dataset_id"] ??= {};
                        errors["dataset_id"][id.toString()] =
                            "Please select dataset.";
                    }
                });
        }

        if (!startDate) {
            errors["startDate"] = "Please select start date.";
        }

        if (!endDate) {
            errors["endDate"] = "Please select end date.";
        }

        if (!type) {
            errors["type"] = "Please select competition type.";
        }

        if (!pqDelta || isNaN(Number(pqDelta))) {
            errors["pqDelta"] = "Per query delta needs to be a number.";
        }

        if (!maxDelta || isNaN(Number(maxDelta))) {
            errors["maxDelta"] = "Max delta needs to be a number.";
        }

        if (!pqEps || isNaN(Number(pqEps))) {
            errors["pqEps"] = "Per query epsilon needs to be a number.";
        }

        if (!maxEps || isNaN(Number(maxEps))) {
            errors["maxEps"] = "Max epsilon needs to be a number.";
        }

        if (!metrics || !tryParseJSONObject(metrics)) {
            errors["metrics"] = "Metrics needs to be a valid json.";
        }

        if (participantType === CompetitionMembershipType.TEAM) {
            if (!registerByDate) {
                setRegisterByDate(startDate);
            }

            if (teamRules) {
                if (teamRules.minMembers > teamRules?.maxMembers) {
                    errors["maxMembers"] = "Max members can't be lesser than min members.";
                }
                if (teamRules.minMembers < 0) {
                    errors["minMembers"] = "Min members can't be lesser than 0.";
                }
                if (!teamRules.editAccessType) {
                    const holder = { ...teamRules };
                    holder.editAccessType = EditAccessType.admin;
                    setTeamRules(holder);
                }
            } else {
                errors["minMembers"] = "Enter min team members.";
                errors["maxMembers"] = "Enter max team members.";
            }

            if (invitedMembersOnly === true) {
                if (!invitedMembersString || !tryParseJSONObject(invitedMembersString)) {
                    errors["invitedMembersString"] = "invited Members array is not a valid json.";
                } else {
                    const members = JSON.parse(invitedMembersString);
                    members.forEach((member: TMembers, id: number) => {
                        if (!member.email && !errors["invitedMembersString"]) {
                            errors["invitedMembersString"] = `member ${id + 1} is not valid, please give an email`;
                        }
                    })
                }
            }
        }

        if (
            prizeDetail?.prizeType &&
            prizeDetail.prizeType !== PrizeType.None
        ) {
            if (prizeDetail?.prizes?.length) {
                prizeDetail.prizes.forEach((p, i) => {
                    if (!p.amount) {
                        errors["prizeDetail"] ??= {};
                        errors["prizeDetail"]["prizes"] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()][
                            "amount"
                        ] = "Please enter amount.";
                    }
                    if (!p.position) {
                        errors["prizeDetail"] ??= {};
                        errors["prizeDetail"]["prizes"] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()] ??= {};
                        errors["prizeDetail"]["prizes"][i.toString()][
                            "position"
                        ] = "Please enter position.";
                    }
                });
            }
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return false;
        } else return true;
    };

    const resetForm = () => {
        setTitle("");
        setSubTitle("");
        setOverview("");
        setDataset_id([""]);
        setDataset_dependencies("");
        setBanner(undefined);
        setStartDate(undefined);
        setEndDate(undefined);
        setFormErrors({});
        setType("");
        setRules("");
        setPrizeDetail({
            prizeType: PrizeType.None,
            detail: "",
            prizes: [],
            termsAndConditions: "",
        });
        titleInputRef?.current?.focus();
    };

    const addNewPrize = () => {
        if (prizeDetail?.prizes?.length) {
            prizeDetail.prizes.push({
                amount: "",
                detail: "",
                position: "",
            });
            setPrizeDetail({ ...prizeDetail });
        } else if (prizeDetail) {
            prizeDetail.prizes = [
                {
                    amount: "",
                    detail: "",
                    position: "",
                },
            ];
            setPrizeDetail({ ...prizeDetail });
        }
    };

    return (
        <div>
            <ErrorAlert
                show={fetchError ? true : false}
                errorMessage={fetchError}
            />
            {competition ? (
                <>
                    <div className="d-flex flex-row align-content-center">
                        <FontAwesomeIcon
                            size="lg"
                            className="mx-3 cursor-pointer"
                            onClick={() => navigate(-1)}
                            icon={faArrowLeft}
                        />
                        <h4>Edit Competition</h4>
                    </div>

                    <Card className="bg-primary">
                        <Card.Body>
                            <Form
                                onReset={resetForm}
                                ref={formRef}
                                noValidate
                                onSubmit={onSubmit}
                            >
                                <Form.Group>
                                    <Input
                                        ref={titleInputRef}
                                        autoFocus
                                        focusOnError={true}
                                        label="Title"
                                        placeholder="Competition Title"
                                        value={title}
                                        onChange={(e) => {
                                            if (formErrors["title"]) {
                                                delete formErrors.title;
                                                setFormErrors(formErrors);
                                            }
                                            setTitle(e.target.value);
                                        }}
                                        error={formErrors["title"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Input
                                        label="Subtitle"
                                        focusOnError={true}
                                        placeholder="Competition Subtitle"
                                        value={subTitle}
                                        onChange={(e) => {
                                            if (formErrors["subTitle"]) {
                                                delete formErrors.subTitle;
                                                setFormErrors(formErrors);
                                            }
                                            setSubTitle(e.target.value);
                                        }}
                                        error={formErrors["subTitle"]}
                                    />
                                </Form.Group>

                                <div className="d-flex flex-wrap">
                                    <Form.Group className="mt-3 col-5 me-4">
                                        <Input
                                            label="Per querry delta"
                                            focusOnError={true}
                                            placeholder="Per querry delta"
                                            value={pqDelta?.toString()}
                                            onChange={(e) => {
                                                if (formErrors["pqDelta"]) {
                                                    delete formErrors.pqDelta;
                                                    setFormErrors(formErrors);
                                                }

                                                if (
                                                    !isNaN(
                                                        Number(e.target.value)
                                                    )
                                                )
                                                    setPqDelta(
                                                        Number(e.target.value)
                                                    );
                                            }}
                                            error={formErrors["pqDelta"]}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mt-3 col-5">
                                        <Input
                                            label="Max delta"
                                            focusOnError={true}
                                            placeholder="Max delta"
                                            value={maxDelta + ""}
                                            onChange={(e) => {
                                                if (formErrors["maxDelta"]) {
                                                    delete formErrors.maxDelta;
                                                    setFormErrors(formErrors);
                                                }
                                                if (
                                                    !isNaN(
                                                        Number(e.target.value)
                                                    )
                                                )
                                                    setMaxDelta(
                                                        Number(e.target.value)
                                                    );
                                            }}
                                            error={formErrors["maxDelta"]}
                                        />
                                    </Form.Group>
                                </div>

                                <div className="d-flex flex-wrap">
                                    <Form.Group className="mt-3 col-5 me-4">
                                        <Input
                                            label="Per querry Epsilon"
                                            focusOnError={true}
                                            placeholder="Per querry Epsilon"
                                            value={pqEps + ""}
                                            onChange={(e) => {
                                                if (formErrors["pqEps"]) {
                                                    delete formErrors.pqEps;
                                                    setFormErrors(formErrors);
                                                }
                                                if (
                                                    !isNaN(
                                                        Number(e.target.value)
                                                    )
                                                )
                                                    setPqEps(
                                                        Number(e.target.value)
                                                    );
                                            }}
                                            error={formErrors["pqEps"]}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mt-3 col-5">
                                        <Input
                                            label="Max Epsilon"
                                            focusOnError={true}
                                            placeholder="Max Epsilon"
                                            value={maxEps + ""}
                                            onChange={(e) => {
                                                if (formErrors["maxEps"]) {
                                                    delete formErrors.maxEps;
                                                    setFormErrors(formErrors);
                                                }
                                                if (
                                                    !isNaN(
                                                        Number(e.target.value)
                                                    )
                                                )
                                                    setMaxEps(
                                                        Number(e.target.value)
                                                    );
                                            }}
                                            error={formErrors["maxEps"]}
                                        />
                                    </Form.Group>
                                </div>

                                <Form.Group className="mt-3">
                                    <Input
                                        label="Metrices"
                                        asTextArea
                                        focusOnError={true}
                                        placeholder="Enter metrices json data"
                                        value={metrics}
                                        onChange={(e) => {
                                            if (formErrors["metrics"]) {
                                                delete formErrors.metrics;
                                                setFormErrors(formErrors);
                                            }
                                            setMetrics(e.target.value);
                                        }}
                                        error={formErrors["metrics"]}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    {dataset_id.map((ds, i) => (
                                        <Col
                                            className="col-12 mb-3"
                                            lg={10}
                                            key={"dataset" + i}
                                        >
                                            <InputSelect
                                                isLoading={loadingDatasets}
                                                loadingMessage="Loading datasets"
                                                labelKey="title"
                                                valueKey="_id"
                                                focusOnError={true}
                                                onChange={(val: IDataset) => {
                                                    if (
                                                        formErrors["dataset_id"]?.[i.toString()]
                                                    ) {
                                                        delete formErrors.dataset_id?.[i.toString()];
                                                        setFormErrors(
                                                            formErrors
                                                        );
                                                    }
                                                    let holder = [...dataset_id];
                                                    holder[i] = val?._id;
                                                    setDataset_id(holder);
                                                }}
                                                label="Dataset"
                                                placeholder="Select Dataset"
                                                value={datasets.find(
                                                    (x) => x._id === ds
                                                )}
                                                options={datasets}
                                                error={
                                                    formErrors["dataset_id"]?.[i]
                                                }
                                            />
                                        </Col>
                                    ))}
                                </Form.Group>

                                <Form.Group className="d-flex gap-4">
                                    <Button onClick={() => addNewDataset()}>
                                        <FontAwesomeIcon
                                            icon={faAdd}
                                            className="me-1"
                                        />{" "}
                                        Add Dataset
                                    </Button>

                                    {dataset_id && dataset_id.length > 1 && (
                                        <Button onClick={() => removeDataset()}>
                                            <FontAwesomeIcon
                                                icon={faSubtract}
                                                className="me-1"
                                            />{" "}
                                            Delete Dataset
                                        </Button>
                                    )}
                                </Form.Group>

                                <Form.Group className="mt-4">
                                    <Checkbox
                                        placeholder="External Dataset"
                                        onChange={setExternalDtaset}
                                        value={externalDataset}
                                        className={`${externalDataset ? "mb-3" : ""}`}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Input
                                        label="Dataset Dependencies"
                                        asTextArea
                                        focusOnError={true}
                                        placeholder="Enter Dataset dependencies in json format eg: { dataset_id: ['ds1_id', 'ds2_id'], ... } "
                                        value={dataset_dependencies}
                                        onChange={(e) => {
                                            if (formErrors["dt_dep"]) {
                                                delete formErrors.dt_dep;
                                                setFormErrors(formErrors);
                                            }
                                            setDataset_dependencies(e.target.value);
                                        }}
                                        error={formErrors["dt_dep"]}
                                    />
                                </Form.Group>

                                {externalDataset ? <Form.Group>
                                    <Form.Label>Dataset Details</Form.Label>
                                    <RichTextEditor
                                        value={datasetDetails}
                                        onChange={(val) => {
                                            setDatasetDetails(val);
                                        }}
                                    />
                                </Form.Group> : <></>}

                                <Form.Group className="mt-3">
                                    <Row>
                                        <Col sm={6} lg={4}>
                                            <InputSelect
                                                loadingMessage="Loading types"
                                                focusOnError={true}
                                                onChange={(val: any) => {
                                                    if (formErrors["type"]) {
                                                        delete formErrors.type;
                                                        setFormErrors(
                                                            formErrors
                                                        );
                                                    }
                                                    if (val?.value)
                                                        setType(val.value);
                                                    else setType("");
                                                }}
                                                labelKey="label"
                                                valueKey="value"
                                                label="Competition Type"
                                                placeholder="Select Type"
                                                value={competitionTypes.find(
                                                    (x) => x.value === type
                                                )}
                                                options={competitionTypes}
                                                error={formErrors["dataset"]}
                                            />
                                        </Col>
                                        <Col sm={6} lg={4}>
                                            <DateInput
                                                focusOnError={true}
                                                value={startDate}
                                                onChange={(d) => {
                                                    if (
                                                        formErrors["startDate"]
                                                    ) {
                                                        delete formErrors.startDate;
                                                        setFormErrors(
                                                            formErrors
                                                        );
                                                    }
                                                    setStartDate(
                                                        d ?? undefined
                                                    );
                                                }}
                                                label="Start Date"
                                                placeholder="Select Start Date"
                                            />
                                            <span className="text-xs text-color-tertiary pt-2">Selected date time is {startDate ? startDate.toUTCString() : ""}</span>
                                        </Col>
                                        <Col sm={6} lg={4}>
                                            <DateInput
                                                focusOnError={true}
                                                value={endDate}
                                                onChange={(d) => {
                                                    if (formErrors["endDate"]) {
                                                        delete formErrors.endDate;
                                                        setFormErrors(
                                                            formErrors
                                                        );
                                                    }
                                                    setEndDate(d ?? undefined);
                                                }}
                                                label="End Date"
                                                placeholder="Select End Date"
                                            />

                                            <span className="text-xs text-color-tertiary pt-2">Selected date time is {endDate ? endDate.toUTCString() : ""}</span>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="my-4">
                                    <Row className="flex-wrap-reverse">
                                        <Col sm={12} lg={6}>
                                            <InputSelect
                                                labelKey="label"
                                                valueKey="value"
                                                focusOnError={true}
                                                onChange={(val: any) => {
                                                    if (formErrors["participantType"]) {
                                                        delete formErrors.participantType;
                                                        setFormErrors(formErrors);
                                                    }
                                                    setParticipantType(val?.value ?? CompetitionMembershipType.INDIVIDUAL);
                                                }}
                                                value={
                                                    Object.keys(CompetitionMembershipType)
                                                        .map((x) => ({
                                                            label: x,
                                                            value: (
                                                                CompetitionMembershipType as any
                                                            )[x],
                                                        }))
                                                        ?.find(
                                                            (x) => x.value === participantType
                                                        ) ?? CompetitionMembershipType.INDIVIDUAL
                                                }
                                                label="Participant Type"
                                                placeholder="Select Participant Type"
                                                options={Object.keys(CompetitionMembershipType).map(
                                                    (x) => ({
                                                        label: x,
                                                        value: (CompetitionMembershipType as any)[x],
                                                    })
                                                )}
                                                error={formErrors["participantType"]}
                                            />
                                        </Col>
                                        <Col sm={12} lg={6} className="d-flex mb-lg-0 mb-3 align-items-end">
                                            <Checkbox
                                                placeholder="Private Leaderboard"
                                                onChange={setPrivateLeaderboard}
                                                value={privateLeaderboard}
                                            />
                                        </Col>
                                    </Row>


                                    {participantType === CompetitionMembershipType.TEAM ?
                                        <Row className="mt-3">
                                            <Col sm={12} lg={6} className="mb-2">
                                                <InputSelect
                                                    label="Edit access type"
                                                    placeholder="Select edit access type"

                                                    labelKey="label"
                                                    valueKey="value"
                                                    focusOnError={true}
                                                    onChange={(val: any) => {
                                                        setTeamRules({
                                                            editAccessType: val?.value || EditAccessType.admin,
                                                            maxMembers: teamRules?.maxMembers || 0,
                                                            minMembers: teamRules?.minMembers || 0
                                                        });
                                                    }}
                                                    value={
                                                        Object.keys(EditAccessType)
                                                            .map((x) => ({
                                                                label: x,
                                                                value: (
                                                                    EditAccessType as any
                                                                )[x],
                                                            }))
                                                            ?.find(
                                                                (x) => x.value === teamRules?.editAccessType
                                                            ) ?? EditAccessType.admin
                                                    }
                                                    options={Object.keys(EditAccessType).map(
                                                        (x) => ({
                                                            label: x,
                                                            value: (EditAccessType as any)[x],
                                                        })
                                                    )}
                                                    error={formErrors["participantType"]}
                                                />
                                            </Col>

                                            <Col sm={12} lg={6} className="mb-2">
                                                <DateInput
                                                    focusOnError={true}
                                                    value={registerByDate}
                                                    onChange={(d) => {
                                                        if (formErrors["registerByDate"]) {
                                                            delete formErrors.registerByDate;
                                                            setFormErrors(formErrors);
                                                        }
                                                        setRegisterByDate(d ?? undefined);
                                                    }}
                                                    label="Register by Date"
                                                    placeholder="Select Register by Date"
                                                    error={formErrors["registerByDate"]}
                                                />

                                                <span className="text-xs text-color-tertiary pt-2">Selected date time is {registerByDate ? registerByDate.toUTCString() : ""}</span>
                                            </Col>

                                            <Col sm={12} lg={6} className="mb-2">
                                                <Input
                                                    label="Min Members"
                                                    focusOnError={true}
                                                    placeholder="Min Members"
                                                    value={(teamRules?.minMembers || 0) + ""}
                                                    onChange={(e) => {
                                                        if (formErrors["minMembers"]) {
                                                            delete formErrors.minMembers;
                                                            setFormErrors(formErrors);
                                                        }
                                                        if (!isNaN(Number(e.target.value))) setTeamRules({
                                                            editAccessType: teamRules?.editAccessType || EditAccessType.admin,
                                                            maxMembers: teamRules?.maxMembers || 0,
                                                            minMembers: (Number(e.target.value))
                                                        });
                                                    }}
                                                    error={formErrors["minMembers"]}
                                                />
                                            </Col>

                                            <Col sm={12} lg={6} className="mb-2">
                                                <Input
                                                    label="Max Members"
                                                    focusOnError={true}
                                                    placeholder="Max Members"
                                                    value={(teamRules?.maxMembers || 0) + ""}
                                                    onChange={(e) => {
                                                        if (formErrors["maxMembers"]) {
                                                            delete formErrors.maxMembers;
                                                            setFormErrors(formErrors);
                                                        }
                                                        if (!isNaN(Number(e.target.value)))
                                                            setTeamRules({
                                                                editAccessType: teamRules?.editAccessType || EditAccessType.admin,
                                                                minMembers: teamRules?.minMembers || 0,
                                                                maxMembers: (Number(e.target.value))
                                                            });
                                                    }}
                                                    error={formErrors["maxMembers"]}
                                                />
                                            </Col>

                                            <div className="mt-4">
                                                <Checkbox
                                                    placeholder="Invited members Only"
                                                    onChange={setInvitedMembersOnly}
                                                    value={invitedMembersOnly}
                                                    className={`${invitedMembersOnly ? "mb-3" : ""}`}
                                                />

                                                {invitedMembersOnly ? <Input
                                                    label="Invited Members"
                                                    asTextArea
                                                    focusOnError={true}
                                                    placeholder="Enter invited members json array"
                                                    value={invitedMembersString}
                                                    onChange={(e) => {
                                                        if (formErrors["invitedMembersString"]) {
                                                            delete formErrors.invitedMembersString;
                                                            setFormErrors(formErrors);
                                                        }
                                                        setInvitedMembersString(e.target.value);
                                                    }}
                                                    error={formErrors["invitedMembersString"]}
                                                /> : <></>}
                                            </div>

                                        </Row> : <></>
                                    }
                                </Form.Group>

                                <Form.Group className="mt-3 is-invalid d-flex flex-row">
                                    <FileInput
                                        selectedFiles={banner ? [banner] : []}
                                        accept={{
                                            "image/*": [],
                                        }}
                                        height={180}
                                        width={270}
                                        placeholder="Upload Banner Image"
                                        error={formErrors["banner"]}
                                        onDrop={(files) => {
                                            if (formErrors["banner"]) {
                                                delete formErrors.banner;
                                                setFormErrors(formErrors);
                                            }
                                            if (files?.length) {
                                                setBanner(files[0]);
                                            } else {
                                                setBanner(undefined);
                                            }
                                        }}
                                        containerClass="text-white"
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>Overview</Form.Label>
                                    <RichTextEditor
                                        value={overview}
                                        onChange={(val) => {
                                            if (formErrors["overview"]) {
                                                delete formErrors.overview;
                                                setFormErrors(formErrors);
                                            }
                                            setOverview(val);
                                        }}
                                    />
                                    {formErrors["overview"] ? (
                                        <div className="text-invalid-feedback mt-1">
                                            {formErrors["overview"]}
                                        </div>
                                    ) : undefined}
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>Rules</Form.Label>
                                    <RichTextEditor
                                        value={rules}
                                        onChange={(val) => {
                                            if (formErrors["rules"]) {
                                                delete formErrors.rules;
                                                setFormErrors(formErrors);
                                            }
                                            setRules(val);
                                        }}
                                    />
                                    {formErrors["rules"] ? (
                                        <div className="text-invalid-feedback mt-1">
                                            {formErrors["rules"]}
                                        </div>
                                    ) : undefined}
                                </Form.Group>

                                {/* ORGANIZERS REMOVED AND REPLACED WITH ORGANIZATIONS */}
                                {/* <fieldset className="mt-4">
                                    <legend>Organizers</legend>
                                    <Row className="g-3">
                                        {organizers?.map((o, i) => (
                                            <Col
                                                key={i}
                                                xs={12}
                                                md={4}
                                                className="d-flex flex-row"
                                            >
                                                <Input
                                                    autoFocus
                                                    focusOnError={true}
                                                    placeholder=""
                                                    value={o}
                                                    onChange={(e) => {
                                                        organizers[i] =
                                                            e.target.value;
                                                        setOrganizers([
                                                            ...organizers,
                                                        ]);
                                                    }}
                                                />
                                                <Button
                                                    onClick={() => {
                                                        organizers.splice(i, 1);
                                                        setOrganizers([
                                                            ...organizers,
                                                        ]);
                                                    }}
                                                    variant="outlined"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faClose}
                                                        color="red"
                                                    />
                                                </Button>
                                            </Col>
                                        ))}
                                        <Col
                                            key={"_2"}
                                            xs={1}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    if (!organizers) {
                                                        setOrganizers([""]);
                                                    } else {
                                                        organizers.push("");
                                                        setOrganizers([
                                                            ...organizers,
                                                        ]);
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faAdd} />{" "}
                                                Add
                                            </Button>
                                        </Col>
                                    </Row>
                                </fieldset> */}

                                <fieldset className="mt-3">
                                    <legend>Prize</legend>
                                    <Row>
                                        <Col sm={6} lg={4}>
                                            <Form.Group>
                                                <InputSelect
                                                    labelKey="label"
                                                    valueKey="value"
                                                    focusOnError={true}
                                                    onChange={(val: any) => {
                                                        if (
                                                            formErrors[
                                                            "dataset"
                                                            ]
                                                        ) {
                                                            delete formErrors.dataset;
                                                            setFormErrors(
                                                                formErrors
                                                            );
                                                        }
                                                        setPrizeDetail({
                                                            ...prizeDetail,
                                                            prizeType:
                                                                val?.value ??
                                                                PrizeType.None,
                                                        });
                                                    }}
                                                    value={
                                                        Object.keys(PrizeType)
                                                            .map((x) => ({
                                                                label: x,
                                                                value: (
                                                                    PrizeType as any
                                                                )[x],
                                                            }))
                                                            ?.find(
                                                                (x) =>
                                                                    x.value ===
                                                                    prizeDetail?.prizeType
                                                            ) ?? ""
                                                    }
                                                    label="Prize Type"
                                                    placeholder="Select Prize Type"
                                                    options={Object.keys(
                                                        PrizeType
                                                    ).map((x) => ({
                                                        label: x,
                                                        value: (
                                                            PrizeType as any
                                                        )[x],
                                                    }))}
                                                    error={
                                                        formErrors[
                                                            "prizeDetail"
                                                        ]?.children?.find(
                                                            (x: any) =>
                                                                x.field ===
                                                                "type"
                                                        )?.errors[0]
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {prizeDetail?.prizeType &&
                                        prizeDetail?.prizeType !==
                                        PrizeType.None ? (
                                        <>
                                            <Form.Group className="mt-3">
                                                <Form.Label>
                                                    Prize Detail
                                                </Form.Label>
                                                <RichTextEditor
                                                    value={prizeDetail?.detail}
                                                    onChange={(val) => {
                                                        if (
                                                            formErrors[
                                                            "overview"
                                                            ]
                                                        ) {
                                                            delete formErrors.overview;
                                                            setFormErrors(
                                                                formErrors
                                                            );
                                                        }
                                                        prizeDetail.detail =
                                                            val;
                                                        setPrizeDetail({
                                                            ...prizeDetail,
                                                        });
                                                    }}
                                                />
                                                {formErrors["prizeDetail"]?.[
                                                    "detail"
                                                ] ? (
                                                    <div className="text-invalid-feedback mt-1">
                                                        {
                                                            formErrors[
                                                            "prizeDetail"
                                                            ]?.["detail"]
                                                        }
                                                    </div>
                                                ) : undefined}
                                            </Form.Group>

                                            <Form.Group className="mt-3">
                                                <Form.Label>
                                                    Terms & Conditions
                                                </Form.Label>
                                                <RichTextEditor
                                                    value={
                                                        prizeDetail?.termsAndConditions
                                                    }
                                                    onChange={(val) => {
                                                        if (
                                                            formErrors["prizeDetail"]?.[
                                                            "termsAndConditions"
                                                            ]
                                                        ) {
                                                            delete formErrors[
                                                                "prizeDetail"
                                                            ]?.termsAndConditions;
                                                            setFormErrors(formErrors);
                                                        }
                                                        prizeDetail.termsAndConditions =
                                                            val;
                                                        setPrizeDetail({
                                                            ...prizeDetail,
                                                        });
                                                    }}
                                                />
                                                {formErrors["prizeDetail"]?.[
                                                    "termsAndConditions"
                                                ] ? (
                                                    <div className="text-invalid-feedback mt-1">
                                                        {
                                                            formErrors["prizeDetail"]?.[
                                                            "termsAndConditions"
                                                            ]
                                                        }
                                                    </div>
                                                ) : undefined}
                                            </Form.Group>


                                            {prizeDetail?.prizes?.map(
                                                (p, i) => (
                                                    <Row
                                                        key={i}
                                                        className="mt-3"
                                                    >
                                                        <Col sm={6} lg={4}>
                                                            <Form.Group>
                                                                <Input
                                                                    label="Position"
                                                                    focusOnError={
                                                                        true
                                                                    }
                                                                    placeholder="Position"
                                                                    value={
                                                                        p.position
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            formErrors[
                                                                                "prizeDetail"
                                                                            ]
                                                                                ?.prizes?.[
                                                                                i
                                                                            ]
                                                                                ?.position
                                                                        ) {
                                                                            delete formErrors[
                                                                                "prizeDetail"
                                                                            ]
                                                                                ?.prizes?.[
                                                                                i
                                                                            ]
                                                                                ?.position;
                                                                            setFormErrors(
                                                                                {
                                                                                    ...formErrors,
                                                                                }
                                                                            );
                                                                        }
                                                                        p.position =
                                                                            e.target.value;
                                                                        setPrizeDetail(
                                                                            {
                                                                                ...prizeDetail,
                                                                            }
                                                                        );
                                                                    }}
                                                                    error={
                                                                        formErrors[
                                                                            "prizeDetail"
                                                                        ]
                                                                            ?.prizes?.[
                                                                            i
                                                                        ]
                                                                            ?.position
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={5} lg={3}>
                                                            <Form.Group>
                                                                <Input
                                                                    label="Amount"
                                                                    focusOnError={
                                                                        true
                                                                    }
                                                                    placeholder="Prize Amount"
                                                                    value={
                                                                        p.amount
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            formErrors[
                                                                                "prizeDetail"
                                                                            ]
                                                                                ?.prizes?.[
                                                                                i
                                                                            ]
                                                                                ?.amount
                                                                        ) {
                                                                            delete formErrors[
                                                                                "prizeDetail"
                                                                            ]
                                                                                ?.prizes?.[
                                                                                i
                                                                            ]
                                                                                ?.amount;
                                                                            setFormErrors(
                                                                                {
                                                                                    ...formErrors,
                                                                                }
                                                                            );
                                                                        }
                                                                        p.amount =
                                                                            e.target.value;
                                                                        setPrizeDetail(
                                                                            {
                                                                                ...prizeDetail,
                                                                            }
                                                                        );
                                                                    }}
                                                                    error={
                                                                        formErrors[
                                                                            "prizeDetail"
                                                                        ]
                                                                            ?.prizes?.[
                                                                            i
                                                                        ]
                                                                            ?.amount
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={6} lg={4}>
                                                            <Form.Group>
                                                                <Input
                                                                    label="Detail/Perks"
                                                                    focusOnError={
                                                                        true
                                                                    }
                                                                    placeholder="Details"
                                                                    value={
                                                                        p.detail
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            formErrors[
                                                                                "prizeDetail"
                                                                            ]
                                                                                ?.prizes?.[
                                                                                i
                                                                            ]
                                                                                ?.detail
                                                                        ) {
                                                                            delete formErrors[
                                                                                "prizeDetail"
                                                                            ]
                                                                                ?.prizes?.[
                                                                                i
                                                                            ]
                                                                                ?.detail;
                                                                            setFormErrors(
                                                                                {
                                                                                    ...formErrors,
                                                                                }
                                                                            );
                                                                        }
                                                                        p.detail =
                                                                            e.target.value;
                                                                        setPrizeDetail(
                                                                            {
                                                                                ...prizeDetail,
                                                                            }
                                                                        );
                                                                    }}
                                                                    error={
                                                                        formErrors[
                                                                            "prizeDetail"
                                                                        ]
                                                                            ?.prizes?.[
                                                                            i
                                                                        ]
                                                                            ?.detail
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col
                                                            sm={1}
                                                            lg={1}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                alignItems:
                                                                    "end",
                                                            }}
                                                        >
                                                            <Button
                                                                title="Remove"
                                                                onClick={() => {
                                                                    prizeDetail?.prizes?.splice(
                                                                        i,
                                                                        1
                                                                    );
                                                                    setPrizeDetail(
                                                                        {
                                                                            ...prizeDetail,
                                                                        }
                                                                    );
                                                                }}
                                                                variant="outlined"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faTimes
                                                                    }
                                                                    color="red"
                                                                />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )
                                            )}
                                            <Form.Group className="mt-3">
                                                <Button
                                                    onClick={() => {
                                                        addNewPrize();
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faAdd}
                                                    />{" "}
                                                    Add Prize
                                                </Button>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </fieldset>

                                {formErrors[""] ? (
                                    <div className="text-invalid-feedback m-2">
                                        {formErrors[""]}
                                    </div>
                                ) : undefined}

                                <Form.Group
                                    className="mt-5"
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <Button type="submit">Update</Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </>
            ) : undefined}
            <LoadingSpinner show={loading} text={"Updating Competition"} />
        </div>
    );
}
