
var moment = require('moment');

// export function toRelativeTime(date: Date) {
//     const today = new Date();
//     // To calculate the time difference of two dates
//     var Difference_In_Time = date.getTime() - today.getTime();

//     // To calculate the no. of days between two dates
//     var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

//     return parseInt(Difference_In_Days.toString());
// }
export function isCompleted(date: Date): boolean {
    const inputDate = moment(date?.toUTCString());
    const current = moment(new Date().toUTCString());
    const difference = current.diff(inputDate);
    if(difference > 0) return true;
    else return false;
}

export function competitionStatus(start: Date, end : Date){
    let initial = moment(start);
    let final = moment(end);
    let current = moment(new Date());
    let finalDifference = final.diff(current, "minutes");
    let initialDifference = initial.diff(current, "minutes");

    if(finalDifference < 0 ) return "completed";
    else if(finalDifference >= 0 && initialDifference < 0){
        return "ongoing";
    }
    else return "upcoming";
}

export function convertDate(inputFormat : string){
    const pad = (s : number) => (s < 10) ? '0' + s : s;
    const monthList = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec" ];
    const d = new Date(inputFormat)
    return [pad(d.getUTCDate()), monthList[d.getUTCMonth()], d.getUTCFullYear()].join('.')
}

export function BadgeFormatDate(inputFormat : string){
    const pad = (s : number) => (s < 10) ? '0' + s : s;
    const d = new Date(inputFormat)
    return [pad(d.getUTCDate()), pad(d.getUTCMonth() + 1), d.getUTCFullYear()].join('.')
}

const isMultiple = (value: number) => {
    if(value > 1) return true;
    return false;
}

export function timeElapsed(date1: Date, date2: Date){
    const initial = moment(date1?.toUTCString());
    const current = moment(date2?.toUTCString());
    const toFirst = moment.duration(current.diff(initial));
    const years = toFirst.years();
    const months = toFirst.months();
    const days = toFirst.days();
    const hours = toFirst.hours();

    let timeTaken = ""
    if(years > 0){
        timeTaken = years + (isMultiple(years) ? " years ": " year ") + months + (isMultiple(months) ? " months": " month");
    }
    else if(months > 0){
        timeTaken = months + (isMultiple(months) ? " months ": " month ") + days + (isMultiple(days) ? " days": " day");
    }
    else if(days > 0){
        timeTaken = days + (isMultiple(days) ? " days ": " day ") + hours + (isMultiple(hours) ? " hours": " hour");
    }
    else timeTaken = hours + (isMultiple(hours) ? " hours": " hour");

    return timeTaken;
}

export function HoursRemaining(date1: Date, date2: Date){
    const initial = new Date(date1?.toUTCString());
    const current = new Date(date2?.toUTCString());
    
    var diffMs: any = initial.getTime() - current.getTime();
    if(diffMs < 0) return "NA";
    var diffDays = Math.floor(diffMs / 86400000); // days
    if(diffDays > 0) return "NA";

    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return  diffHrs + (isMultiple(diffHrs) ? " hours ": " hour ") + diffMins + (isMultiple(diffMins) ? " minutes": " minute");
}

export function validateEmail(mail: string) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}

export function addDays(point: Date, days : number) {
    var result = new Date(point);
    result.setDate(result.getDate() + days);
    return result;
}

export function tryParseJSONObject(jsonString: string) {
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return true;
        }
    } catch (e) {}
    return false;
}