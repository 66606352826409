import { useEffect } from "react";
import "./index.scss";

type TCheckboxProps = {
    value: boolean;
    onClick: Function;
    disabled?: boolean;
};

export default function ToggleSwitch(props: TCheckboxProps) {
    
    return (
        <div onClick={() => {!props.disabled && props.onClick()}} className={`toggle-container  ${props.value ? "bg-on" : "" }`}>
            <div className={`toggle-switch ${props.value ? "switch-on" : "" }`}>
            </div>
        </div>
    );
}
