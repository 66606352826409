import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// import: styles
import "./index.scss";

// import: constants
import { CREATE, DATASETS } from "../../router/routes";
// import: enums
import { UserRole } from "../../enums/roles";

// import: store
import { loadDatasets, setDatasetsPageData } from "../../store/slices/dataset-slice";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    onChangeSearchText,
    setSearchPlaceholder,
} from "../../store/slices/shared-states-slice";

// import: components
import DatasetListItem from "../../components/list-items/dataset-list-item";
import LoadingSpinner from "../../components/loading-spinner";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/error-alert";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

export default function DatasetPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const pageEnd = useRef<HTMLDivElement>(null);

    const user = useAppSelector((s) => s.auth.user);

    const searchText = useAppSelector((s) => s.sharedStates.navbarSearchText);

    const fetchingDatasets = useAppSelector((s) => s.dataset.fetching);
    const datasets = useAppSelector((s) => s.dataset.pageData.data);
    const [error, setError] = useState("");

    const pageSize = parseInt(process.env.React_APP_DATASETS_DATA_LENGTH || "24")
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    // const [messageVisibility, setMessageVisibility] = useState(false);

    useEffect(() => {

        dispatch(onChangeSearchText(""));
        dispatch(setSearchPlaceholder("Search Datasets"));
        return () => {
            dispatch(setSearchPlaceholder(""));

            /* reset data to reset height of container
             * so the observer can trigger fresh data fetch
             */
            dispatch(setDatasetsPageData({
                data: [],
                totalCount: pageSize,
            }))
        };
    }, []);

    const fetchData = useCallback(({ newPage }: { newPage?: number }) => {
        if (newPage === 0) {
            setPage(0)
        }

        const pageToFetch = newPage ?? page;
        if (pageToFetch < totalPages && !fetchingDatasets) {
            dispatch(loadDatasets({
                page: pageToFetch + 1,
                size: pageSize,
                title: searchText && searchText.length >= 3 ? searchText : ''
            }))
                .unwrap()
                .then((res) => {
                    setPage(res.page);
                    setTotalPages(Math.ceil((res.totalCount || pageSize) / pageSize));
                })
        }
    }, [
        dispatch,
        page,
        totalPages,
        pageSize,
        fetchingDatasets,
        searchText,
    ]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !fetchingDatasets) {
                    fetchData({});
                }
            });
        });

        if (pageEnd.current) observer.observe(pageEnd.current);

        return () => {
            if (pageEnd.current) observer.unobserve(pageEnd.current);
        };
    }, [fetchData, fetchingDatasets]);

    const [hasMounted, setHasMounted] = useState<boolean>(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            // to avoid duplicate API call on mount & subsequent searchText initialisation
            if (hasMounted) {
                fetchData({ newPage: 0 });
            } else {
                setHasMounted(true);
            }
        }, 1000)

        return () => clearTimeout(timer);
    }, [searchText]);

    // useEffect(() => {
    //     let welcome = localStorage.getItem("DS_WLC");
    //     if(!welcome || isNaN(parseInt(welcome))) {
    //         setMessageVisibility(true);
    //         let now = new Date().getTime();
    //         localStorage.setItem( "DS_WLC", now + "");
    //     }
    //     else{
    //         let now = new Date().getTime();
    //         let setupTime = parseInt(welcome);
    //         if(now - setupTime > 30*60*1000 && messageVisibility === false){
    //             localStorage.setItem( "DS_WLC", now + "");
    //             setMessageVisibility(true);
    //         }
    //     }
    // }, [])

    // const sendTags = (tagString: string | undefined, row: number) => {
    //     if(tagString){
    //         let tagArr = tagString.split(",");
    //         return(
    //             <div className="d-flex flex-wrap">
    //                 {tagArr.map((tag, id) => <Badge pill bg="secondary" text="dark" className="mx-1 px-2 p-1 fw-normal text-sm" key={"tag" + row + id}>{tag}</Badge>)}
    //             </div>
    //         )
    //     }else return tagString;
    // }

    return (
        <div className="page-container position-relative lg-box">
            <ErrorAlert show={error ? true : false} errorMessage={error} />
            <div>
                <div className="page-heading-container">
                    <h1 className="text-3xl mb-21">Datasets</h1>
                    <div className="text-xs">
                        <p>
                            New to Antigranular datasets? Head over to the{" "}
                            <a
                                className="link-ag-1"
                                href="https://docs.antigranular.com/category/quickstart-guides"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Quickstart guide
                            </a>{" "}
                            to learn about datasets and how to use them.
                        </p>
                        <p className="mb-0">
                            Search through our open datasets and take advantage
                            of practice opportunities to enhance your skills to
                            participate in one of our data science hackathons.
                        </p>
                    </div>
                </div >
                {user?.roles?.includes(UserRole.Admin) ? (
                    <Button
                        className="mb-3 rounded-pill"
                        onClick={() => navigate(`/${DATASETS}/${CREATE}`)}
                    >
                        Create
                    </Button>
                ) : undefined
                }
                {/* <MessageCard onClick={() => window.open("https://docs.antigranular.com/antigranular/quickstart-guides/datasets", "_blank", 'noopener')}
                    heading="New to using Datasets?" linkText="Quick Start" page="datasets" visibility={messageVisibility}>
                    Head over to the Quickstart guide to learn about datasets and how to use them.
                </MessageCard> */}
            </div >

            <Tabs className="pb-1">
                <Tab title={"All Datasets"} eventKey={"tabOverview"}>
                    <div className="listing-table-container">
                        {datasets && datasets?.length > 0 ? (
                            datasets.map((dset, idx) => (
                                <DatasetListItem
                                    key={dset._id}
                                    to={`/${DATASETS}/` + (dset.slug ?? dset._id)}
                                    item={dset}
                                />
                            ))
                        ) : <></>}
                    </div>

                    <div id="datasets-page-end" ref={pageEnd} className="" />
                </Tab>
            </Tabs>

            <LoadingSpinner
                show={fetchingDatasets}
                text={"Getting Datasets"}
            />
        </div >
    );
}
