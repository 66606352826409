// import { faPlus, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import upload from "../../assets/icons/upload.svg"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone, Accept } from "react-dropzone";
import "./index.scss";

export type TFileInputProps = {
    onDrop: (files: File[]) => void;
    placeholder?: string;
    fileType?: string;
    noIcon?: boolean;
    error?: string;
    height?: number;
    width?: number;
    accept?: Accept;
    multiple?: boolean;
    containerClass?: string;
    selectedFiles?: File[];
    loading?: boolean;
};

export default function FileInput(props: TFileInputProps) {
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        onDrop: props.onDrop,
    });

    return (
        <>
            <div
                className={`file-input text-center d-flex flex-column justify-content-center cursor-pointer ${
                    props.error ? "has-error" : ""
                } ${props.containerClass}`}
                style={{
                    height: props?.height ?? "auto",
                    width: props?.width ?? "100%",
                }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <div>
                    {!props.noIcon && !props.loading && (props?.selectedFiles && props?.selectedFiles?.length === 0) ? <img className="mb-31" width={24} height={24}  alt="" src={upload} /> : <></>}
                    {/* <FontAwesomeIcon className="m-2" icon={faFileUpload} /> */}
                    <div className="text-color-secondary text-xs">
                        {props.selectedFiles?.length ? (
                            <ul className="list-unstyled text-xs">
                                {acceptedFiles?.map((f, i) => (
                                    <li key={i}>
                                        <p className="w-100 text-overflow mb-1">{f.name}</p>
                                        {"(" + (f.size / 1000).toFixed(2) + " Kb)"}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div>
                                <p className="mb-21">{props.placeholder}</p>
                                <span className="text-color-tertiary">{props.fileType}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {props.error ? (
                <div className="text-invalid-feedback mt-1">{props.error}</div>
            ) : undefined}
        </>
    );
}
