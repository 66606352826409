
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customDiscord } from "../../assets/icons";
import Button from "react-bootstrap/Button";
import { DISCORD } from "../../router/routes";

export default function JoinCommunity() {

    return (
        <section className="section-container md-box text-md-center join-community-section">
            <span className="text-heading glazed-bw-text2 mb-43 w-100 text-md-center">
                Be part of our community
            </span>
            <span className="text-lg text-thick mb-3 text-color-secondary">
                Join our rapidly expanding global
                network, spanning across 33 countries worldwide.
            </span>
            <span className="text-color-tertiary text-sm mb-42">
                Connect with other privacy-minded data scientists,
                ask questions, exchange knowledge, and play an
                active role in the growth of our community.
            </span>
            <Button variant="secondary" className="rounded-pill text-nowrap"
                onClick={() => window.open(DISCORD, "_blank", 'noopener')}>
                Join Discord <FontAwesomeIcon size="xl" icon={customDiscord} className="ms-1" />
            </Button>
        </section>
    );
}
