import ReactMarkdown from 'react-markdown';
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import RemarkMathPlugin from 'remark-math';

function MarkdownRender(props) {

      return (
        <ReactMarkdown
        className='text-color-secondary'
          children={props.children}
          remarkPlugins={[RemarkMathPlugin]}
          rehypePlugins={[rehypeKatex]}
        />
      );
}

export default MarkdownRender