import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { clearErrorModal } from "../../store/slices/modal-states-slice";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";

export default function ErrorModal() {
    const dispatch = useAppDispatch();
    const infoModal = useAppSelector((s) => s.modalStates.errorModal);
    const onHide = () => {
        dispatch(clearErrorModal());
    };
    return (
        <Modal className="login-modal" show={infoModal.visible} centered onHide={() => onHide()}>
            <Modal.Header className="border-0 p-3" closeButton>
                <span> </span>
            </Modal.Header>
            <Modal.Body className=" p-3 pt-0 text-center">
                <FontAwesomeIcon color="red" size="3x" icon={faCircleXmark} />
                <p className="lead mt-2">{infoModal.title}</p>
                <p className="">{infoModal.message}</p>
            </Modal.Body>
            <Modal.Footer className=" p-3 pt-0 border-0 d-flex justify-content-center">
                <Button
                    onClick={() => onHide()}
                    className="align-self-center"
                >
                    {infoModal.buttonText || "Okay"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
