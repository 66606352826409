
// import Header from "../intro-page/header";
// import Footer from "../intro-page/footer";
import "../intro-page/index.scss";

export default function PrivacyPolicy() {

    return (
        <div className="page-container sm-box">
            <div className="text-3xl">Privacy Policy</div>
            <div className="text-color-tertiary text-sm static_page_content">

                <div className="spHeading text-xxl">1. INTRODUCTION</div>

                <div className="spSubHeading text-xl">1.1. Important information and who we are</div>
                <p><span className="number">1.1.1.</span> Welcome to Oblivious Software Limited's Privacy and Data Protection Policy (“<b>Privacy Policy</b>”).</p>
                <p><span className="number">1.1.2.</span> At Oblivious Software Limited (“<b>we</b>”, “<b>us</b>”, or “<b>our</b>”) we are committed to protecting and  respecting your privacy and Personal Data in compliance with the General Data Protection  Regulation (“<b>GDPR</b>”) laws.</p>
                <p><span className="number">1.1.3.</span> This Privacy Policy explains how we collect, process and keep your data safe. The Privacy Policy will tell you about your privacy rights, how the law protects you, and inform our employees  and staff members of all their obligations and protocols when processing data.</p>
                <p><span className="number">1.1.4.</span> The individuals from which we may gather and use data can include:</p>
                <div className="bullets">
                    <p>a) Customers</p>
                    <p>b) Our business contacts</p>
                    <p>c) Users</p>
                    <p>d) and any other people that we have a relationship with or may need to contact.</p>
                </div>
                <p><span className="number">1.1.5.</span> This Privacy Policy applies to all our employees, staff members, subcontractor and partners and all Personal Data  processed at any time by us.</p>

                <div className="spSubHeading text-xl">1.2. Who is Your Data Controller and Data Protection Officer</div>
                <p><span className="number">1.2.1.</span> Oblivious Software Limited is your Data Controller and responsible for your Personal Data.</p>
                <p><span className="number">1.2.2.</span> We have appointed a data protection officer (“<b>DPO</b>”) who is responsible for overseeing  questions in relation to this Privacy Policy. If you have any questions about this Privacy Policy,  including any requests to exercise your legal rights surrounding your Personal Data please  contact the DPO at privacy@oblivious.ai or postal address: 6 JAMES' TERRACE, Malahide, Co Dublin, K36Y972, Ireland.</p>

                <div className="spSubHeading text-xl">1.3. Processing Data on Behalf of a Controller and Processors' responsibility to you</div>
                <p><span className="number">1.3.1.</span> In discharging our responsibilities as a Data Controller we cooperate the following partners who will deal with  your data on our behalf (known as “<b>Processors</b>”).Therefore, if it is necessary, the responsibilities described  below may be assigned to them. Our Processors have the following responsibilities imposed on them by us:</p>
                <div className="bullets">
                    <p>a) Ensure that all processing of Personal Data is governed by one of the legal bases laid  out in the GDPR (see 2.2 below for more information on those bases);</p>
                    <p>b) Ensure that Processors authorised to process Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of  confidentiality;</p>
                    <p>c) Implement appropriate technical and organisational measures to ensure a level of  security appropriate to the risk associated with the processing of Personal Data;</p>
                    <p>d) Obtain the prior specific or general authorisation of the Controller before engaging  its sub processor;</p>
                    <p>e) Assist the Controller in the fulfilment of the Controller's obligation to respond to  requests for exercising the data subject's rights;</p>
                    <p>f) Make available to the Controller all information necessary to demonstrate compliance  with the obligations laid down in the GDPR and allow for and contribute to audits,  including inspections, conducted by the Controller or another auditor mandated by the  Controller;</p>
                    <p>g) Maintain a record of all categories of processing activities carried out on behalf of a Controller;</p>
                    <p>h) Cooperate, on request, with the supervisory authority in the performance of its tasks;</p>
                    <p>i) Ensure that any person acting under the authority of the Processor who has access to Personal Data does not process Personal Data except on instructions from the Controller and under applicable law;</p>
                    <p>j) Notify the Controller without undue delay after becoming aware of a Personal Data Breach;</p>
                    <p>k) Designate a data protection officer where required by the GDPR, publish their details  and communicate them to the supervisory authority;</p>
                    <p>l) Support the data protection officer in performing their tasks by providing resources  necessary to carry out those tasks and access to Personal Data and processing  operations, and to maintain their expert knowledge.</p>
                </div>




                <div className="spHeading text-xxl">2. LEGAL BASIS FOR DATA COLLECTION</div>

                <div className="spSubHeading text-xl">2.1. Types of Data / Privacy Policy Scope</div>
                <p><span className="number">2.1.1.</span> We have appointed a data protection officer (“<b>DPO</b>”) who is responsible for overseeing  questions in relation to this Privacy Policy. If you have any questions about this Privacy Policy,  including any requests to exercise your legal rights surrounding your Personal Data please  contact the DPO at privacy@oblivious.ai or postal address: 6 JAMES' TERRACE, Malahide, Co Dublin, K36Y972, Ireland.</p>
                <div className="bullets">
                    <p>a) <b>Identification Data:</b> in particular name, last name, user name, pseudonym, other names that help to establish the identity of a person, title, date of birth, gender.</p>
                    <p>b) <b>Contact Data:</b> in particular data relating to your addresses, e-mail addresses, phone numbers.</p>
                    <p>c) <b>Marketing Data:</b> in particular your preferences in receiving marketing information and other communication from us or our partners.</p>
                    <p>d) <b>Technical Data:</b> in particular your IP address, browser type and version, time zone setting and location, operating system and platform, and other technology on the devices you use to engage with us.</p>
                    <p>e) <b>Transaction Data:</b> in particular data about your payments, data about products and services you have purchased from us, data about cancelled transactions, bank account numbers.</p>
                    <p>f) <b>Usage Data:</b> in particular information about how you use our website, products and services.</p>
                    <p>g) <b>Job Application Data:</b> by submitting via the website your CV that contains Personal Data, in particular name, last name, contact details, data on your job and education history, fields of interest.</p>
                </div>
                <p><span className="number">2.1.2.</span> We also collect Aggregated Data (that means high-level data which is acquired by combining individual data) for page visits related analytics such as but not restricted to country of a visitor, how a search engine was used to access our website link, time and duration for which the page was visited, what users click on. Aggregated Data could be derived from your Personal Data but is not considered Personal Data in law as this data will  not directly or indirectly reveal your identity. However, if we combine or connect Aggregated  Data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as Personal Data which will be used in accordance with this Privacy Policy.</p>
                <p><span className="number">2.1.3.</span> We may also Aggregate Data to enable research or analysis so that we can better understand  and serve you and others. For example, we may conduct research on your demographics and usage. Although this Aggregated Data may be based in part on Personal Data, it does not  identify you personally. We may share this type of anonymous data with others, including  service providers, our affiliates, agents and current and prospective business partners.</p>
                <p><span className="number">2.1.4.</span> We do not collect any Special Categories of Personal Data about you that means in particular details  about your race or ethnicity, religious beliefs, sexual orientation,  political opinions, party or trade union membership, information about your health, and genetic and  biometric data, criminal convictions and offences. </p>


                <div className="spSubHeading text-xl">2.2. The Legal Basis for Collecting That Data</div>
                <p><span className="number">2.2.1.</span> There are a number of justifiable reasons under the GDPR that allow collection and processing  of Personal Data. The main avenues we rely on are:</p>
                <div className="bullets">
                    <p>a) "<b>Consent</b>": Certain situations allow us to collect your Personal Data, such as when you  tick a box that confirms you are happy to receive e-mail newsletters from us, or 'opt in' to  a service.</p>
                    <p>b) "<b>Contractual Obligations</b>": We may require certain information from you in order to  fulfil our contractual obligations and provide you with the promised service.</p>
                    <p>c) "<b>Legal Compliance</b>": We're required by law to collect and process certain types of data,  such as fraudulent activity or other illegal actions.</p>
                    <p>d) "<b>Legitimate Interest</b>": We might need to collect certain information from you to be able  to meet our legitimate interests – this covers aspects that can be reasonably expected as  part of running our business, that will not have a material impact on your rights, freedom or interests. Examples could be your address, so that we know where to  deliver something to, or your name, so that we have a record of who to contact moving  forwards.</p>
                </div>






                <div className="spHeading text-xxl">3. HOW IS YOUR PERSONAL DATA COLLECTED</div>
                <div className="spSubHeading text-xl">3.1. Direct Interactions</div>
                <p><span className="number">3.1.1.</span> You may give us your identity and contact data by filling in forms or by corresponding with us by phone, email or otherwise. This includes Personal Data you provide when you:</p>
                <div className="bullets">
                    <p>a) Inquire about or purchase our products or services and go on to use them,</p>
                    <p>b) Speak to our support team,</p>
                    <p>c) Request marketing to be sent to you,</p>
                    <p>d) Give us feedback or contact us.</p>
                </div>

                <div className="spSubHeading text-xl">3.2. Automated Technologies or Interactions</div>
                <p><span className="number">3.2.1.</span> As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this Personal Data by using cookies and other similar technologies. Please see the Cookie Declaration  for more information.</p>

                <div className="spSubHeading text-xl">3.3. Third-parties or Publicly Available Sources</div>
                <p><span className="number">3.3.1.</span> You may give us your identity and contact data by filling in forms or by corresponding with us by phone, email or otherwise. This includes Personal Data you provide when you:</p>
                <p>a) Analytics providers such as Google and other third parties,</p>
                <p>b) Advertising networks such as LinkedIn and Google.</p>





                <div className="spHeading text-xxl">4. HOW WE USE YOUR PERSONAL DATA</div>
                <div className="spSubHeading text-xl">4.1. Our Uses</div>
                <p><span className="number">4.1.1.</span> We will only use your Personal Data when the law allows us to. Set out below is a table  containing the different types of Personal Data we collect and the lawful basis for processing  that data. Please refer to section 2.2 for more information on the lawful basis listed in the  table below.</p>
                <p><span className="number">4.1.2.</span> Examples provided in the table below are indicative in nature and the purposes for which we  use your data may be broader than described but we will never process your data without a  legal basis for doing so and it is for a related purpose. For further inquiries please contact our  Data Protection Officer.</p>
                <table className="static-page-table">
                    <thead>
                        <tr>
                            <th>Activity</th>
                            <th>Type of data</th>
                            <th>Lawful basis for processing data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Communication with you, Response to your request</td>
                            <td>Identification Data, Contact Data, Usage Data</td>
                            <td>(d) Legitimate Interest</td>
                        </tr>
                        <tr>
                            <td>Provision of Services, Transaction</td>
                            <td>Identification Data, Contact Data, Transaction Data, Usage Data, Technical Data</td>
                            <td>(b) Contractual Obligations</td>
                        </tr>
                        <tr>
                            <td>Provision of the support, Updates of software</td>
                            <td>Identification Data, Contact Data, Usage Data, Technical Data</td>
                            <td>(d) Legitimate Interest</td>
                        </tr>
                        <tr>
                            <td>Data Analytics for improvement of the website, products, services</td>
                            <td>Usage Data, Aggregated Data</td>
                            <td>(d) Legitimate Interest</td>
                        </tr>
                        <tr>
                            <td>Marketing, Customer relationships</td>
                            <td>Identification Data, Contact Data</td>
                            <td>(a) Consent</td>
                        </tr>
                        <tr>
                            <td>Carrying out legal obligations</td>
                            <td>Identification Data, Contact Data, Transaction Data, Usage Data, Technical Data</td>
                            <td>(c) Legal Compliance</td>
                        </tr>
                        <tr>
                            <td>Job Application</td>
                            <td>Job Application Data</td>
                            <td>(d) Legitimate Interest</td>
                        </tr>
                        <tr>
                            <td>Other, not specified activities</td>
                            <td>Identification Data, Contact Data, Transaction Data, Usage Data, Technical Data, Marketing Data</td>
                            <td>Depending on the activity</td>
                        </tr>
                    </tbody>

                </table>

                <div className="spSubHeading text-xl">4.2. Marketing and Content Updates</div>
                <p><span className="number">4.2.1.</span> You will receive marketing and new content communications from us unless you specifically request that you would not like to receive these communications. From time to time we may  make suggestions and recommendations to you about goods or services that may be of  interest to you.</p>

                <div className="spSubHeading text-xl">4.3. Change of Purpose</div>
                <p><span className="number">4.3.1.</span> We will only use your Personal Data for the purposes for which we collected it, unless we  reasonably consider that we need to use it for another reason and that reason is compatible  with the original purpose. If you wish to get an explanation as to how the processing for the  new purpose is compatible with the original purpose, please contact our Data Protection Officer.</p>
                <p><span className="number">4.3.2.</span> If we need to use your Personal Data for an unrelated purpose, we will notify you and we will  explain the legal basis which allows us to do so.</p>
                <p><span className="number">4.3.3.</span> Please note that we may process your Personal Data without your knowledge or consent, in  compliance with the above rules, where this is required or permitted by law.</p>





                <div className="spHeading text-xxl">5. YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US</div>
                <div className="spSubHeading text-xl">5.1. How Does Oblivious Software Limited Protect Customers' Personal Data?</div>
                <p><span className="number">5.1.1.</span> We are concerned with keeping your data secure and protecting it from inappropriate  disclosure. Any Personal Data collected by us is only accessible by a limited number of employees who have special access rights to such systems and are bound by obligations of  confidentiality. If and when we use subcontractors to store your data, we will not relinquish  control of your Personal Data or expose it to security risks that would not have arisen had the  data remained in our possession. However, unfortunately no transmission of data over the  internet is guaranteed to be completely secure. It may be possible for third parties not under  the control of Oblivious Software Limited to intercept or access transmissions or private  communications unlawfully. While we strive to protect your Personal Data, we cannot ensure  or warrant the security of any Personal Data you transmit to us. Any such transmission is done  at your own risk. If you believe that your interaction with us is no longer secure, please contact  us at privacy@oblivious.ai.</p>

                <div className="spSubHeading text-xl">5.2. Opting Out Of Marketing Promotions</div>
                <p><span className="number">5.2.1.</span> You can ask us to stop sending you marketing messages at any time by You can ask us to stop  sending you marketing messages at any time by writing to us at privacy@oblivious.ai.</p>
                <p><span className="number">5.2.1.</span> Where you opt out of receiving these marketing messages, we will continue to retain other  Personal Data provided to us as a result of interactions with us not related to your marketing preferences.</p>

                <div className="spSubHeading text-xl">5.3. How to Request your Data and the Process for Obtaining it</div>
                <p><span className="number">5.3.1.</span> You will not have to pay a fee to access your Personal Data (or to exercise any of the other  rights). However, if your request is clearly unfounded, we could refuse to comply with your request.</p>
                <p><span className="number">5.3.1.</span> We may need to request specific information from you to help us confirm your identity and  ensure you have the right to access your Personal Data (or to exercise any of your other  rights). This is a security measure to ensure that Personal Data is not disclosed to any person  who has no right to receive it. We may also contact you to ask you for further information in  relation to your request to speed up our response.</p>

                <div className="spSubHeading text-xl">5.4. Right to object</div>
                <p><span className="number">5.4.1.</span> To the extent that we process your data on the basis of our legitimate interest, you have the right to object to data processing due to your particular situation.</p>

                <div className="spSubHeading text-xl">5.5. Other rights</div>
                <p><span className="number">5.5.1.</span> You have the following rights related to the processing of your Personal Data:</p>
                <p>a) the right to access your Personal Data,</p>
                <p>b) the right to request the rectification of your Personal Data,</p>
                <p>c) the right to request the deletion of your Personal Data,</p>
                <p>d) the right to request the restriction of the processing of your Personal Data.</p>





                <div className="spHeading text-xxl">6. YOUR DATA AND THIRD PARTIES</div>
                <div className="spSubHeading text-xl">6.1. Will We Share Your Data With Third Parties?</div>
                <p><span className="number">6.1.1.</span> You have the following rights related to the processing of your Personal Data:<br />For performing the activities specified in par. 4.1 above, we share Personal Data with service providers and partners whose services and products are necessary to provide you with our services and products, in particular the following entities:</p>
                <p><span className="number">6.1.2.</span> We may also share Personal Data with interested parties in the event that Oblivious Software  Limited anticipates a change in control or the acquisition of all or part of our business or  assets or with interested parties in connection with the licensing of our technology.</p>
                <p><span className="number">6.1.3.</span> If Oblivious Software Limited is sold or makes a sale or transfer, we may, in our sole discretion,  transfer, sell or assign your Personal Data to a third party as part of or in connection with that  transaction. Upon such transfer, the Privacy Policy of the acquiring entity may govern the  further use of your Personal Data. In all other situations your data will still remain protected in  accordance with this Privacy Policy (as amended from time to time).</p>
                <p><span className="number">6.1.4.</span> We may share your Personal Data at any time if required for legal reasons or in order to  enforce our terms or this Privacy Policy.</p>





                <div className="spHeading text-xxl">7. HOW LONG WILL WE RETAIN YOUR DATA FOR?</div>
                <p className="ms-md-5">We will only retain your Personal Data for as long as reasonably necessary to fulfil the purposes we  collected it for. We may retain your Personal Data for a longer period than usual in the event of a  complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship  with you.</p>



                <div className="spHeading text-xxl">8. INTERNATIONAL TRANSFER OF DATA</div>
                <p className="ms-md-5">Your information may be stored and processed in the US or other countries or jurisdictions outside the US where Oblivious Software Limited has facilities. We are currently storing data in the EU and so, by using Oblivious Software Limited, you are permitting and consenting to the transfer of information, including Personal Data, outside of the US.</p>




                <div className="spHeading text-xxl">9. NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY</div>
                <p className="ms-md-5">Your information may be stored and processed in the European Union (EU) or other countries or jurisdictions outside  the EU where Oblivious Software Limited and its partners have facilities. <b>NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY</b></p>
                <p className="ms-md-5"><span className="number">9.1.</span> We keep our Privacy Policy under review and will place any updates on this website. This version is dated 26th January 2022.</p>
                <p className="ms-md-5"><span className="number">9.2.</span> By using Oblivious Software Limited, you consent to the collection and use of  data by us as set out in this Privacy Policy. Continued access or use of Oblivious Software Limited will  constitute your express acceptance of any modifications to this Privacy Policy.</p>




                <div className="spHeading text-xxl">10. INTERPRETATION</div>
                <p className="ms-md-5"><span className="number">10.1.</span> All uses of the word "including" mean "including but not limited to" and the enumerated examples are not intended to in any way limit the term which they serve to illustrate. Any email addresses set out in  this policy may be used solely for the purpose for which they are stated to be provided, and any unrelated correspondence will be ignored. Unless otherwise required by law, we reserve the right to  not respond to emails, even if they relate to a legitimate subject matter for which we have provided  an email address. As a matter of common sense, you are more likely to get a reply if your request or  question is polite, reasonable and there is no relatively obvious other way to deal with or answer your  concern or question (e.g. FAQs, other areas of our website etc.).</p>
                <p className="ms-md-5"><span className="number">10.2.</span> Our staff are not authorised to contract on behalf of Oblivious Software Limited, waive rights or make  representations (whether contractual or otherwise). If anything contained in an email from a Oblivious  Software Limited address contradicts anything in this policy, our terms or any official public announcement on our website, or is inconsistent with or amounts to a waiver of any Oblivious  Software Limited rights, the email content will be read down to grant precedence to the latter. The only exception to this is genuine correspondence expressed to be from the Oblivious Software Limited legal department.</p>
                <p className="ms-md-5"><span className="number">10.3.</span> In case of any questions on this Privacy Policy or any complaint regarding it, you should contact us at privacy@oblivious.ai.</p>

            </div>
        </div>
    );
}