import {
    ICreateCompetitionTeamsPayload,
    ICompetitionTeams,
    IAcceptTeamInvitationBodyPayload,
    IAddTeamMemberPayload,
    ICreateBulkTeamPayload,
    IUpdateMemberPayload,
    IUpdateCompetitionTeamsPayload
} from "../types/competition-teams-types";
import { BaseApi } from "./base-api";

export default class CompetitionTeamsApi extends BaseApi {
    private static url = (action: string) => "competition_teams/" + action;

    /**
     * Create new competition Team
     * @returns
     */
    public static async create( data: ICreateCompetitionTeamsPayload ): Promise<void> {
        return this._post(this.url("createTeam"), data);
    }

    /**
     * Create multiple new competition Teams
     * @returns
     */
    public static async createBulk( data: ICreateBulkTeamPayload ): Promise<void> {
        return this._post(this.url("createBulkTeam"), data);
    }

    /**
     * Get competition teams
     * @param id competition id
     * @returns
     */
    public static async get(id: string): Promise<ICompetitionTeams> {
        return this._get(`${this.url("getcompetitionteams")}/${id}`);
    }

    /**
     * Update competition Team
     * @param data
     * @returns
     */
    public static async update( data: IUpdateCompetitionTeamsPayload ): Promise<void> {
        return this._post(this.url("updateTeam"), data);
    }

    /**
     * Add competition team member
     * @param data
     * @returns
     */
    public static async addMember( data: IAddTeamMemberPayload ): Promise<void> {
        return this._post(this.url("addTeamMember"), data);
    }

    /**
     * Add competition team member
     * @param data
     * @returns
     */
    public static async addInvitedMember( data: IAddTeamMemberPayload ): Promise<void> {
        return this._post(this.url("addInvitedTeamMember"), data);
    }

    /**
     * Remove competition team member
     * @param data
     * @returns
     */
    public static async removeMember( data: IUpdateMemberPayload ): Promise<void> {
        return this._post(this.url("removeTeamMember"), data);
    }

    /**
     * Resend competition team invitation
     * @param data
     * @returns
     */
    public static async resendInvitation( data: IUpdateMemberPayload ): Promise<void> {
        return this._post(this.url("resendInvitation"), data);
    }

    /**
     * Resend competition team invitation
     * @param id
     * @returns
     */
    public static async reInviteAll(id: string): Promise<void> {
        return this._post(`${this.url("resendInvitationToAll")}/${id}`, {});
    }

    /**
     * Resend competition team invitation to those who haven't joined yet
     * @param id
     * @returns
     */
    public static async wakeUpCall(id: string): Promise<void> {
        return this._post(`${this.url("reInviteAllMissing")}/${id}`, {});
    }

    /**
     * Delete competition Team
     * @returns
     */
    public static async delete(id: string): Promise<void> {
        return this._delete(`${this.url("deleteTeam")}/${id}`);
    }


    /**
     * Accept team invitation
     * @returns
     */
    public static async acceptInvitation( data: IAcceptTeamInvitationBodyPayload ): Promise<void> {
        return this._post(this.url("acceptTeamInvitation"), data);
    }
}
