import { useEffect, useState } from "react";

// import: assets
// import: styles
// import: constants
import { COMPETITIONS } from "../../router/routes";

// import: enums
// import: types
import { ICompetition } from "../../types/competition-types";

// import: utils
import { competitionStatus } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { loadCompetitions } from "../../store/slices/competition-slice";

// import: api
// import: config
// import: components
import CompetitionListItem from "../../components/list-items/competition-list-item";

export default function JoinCompetition() {
    const dispatch = useAppDispatch();
    const pageSize = parseInt(
        process.env.React_APP_COMPETITIONS_DATA_LENGTH || "100"
    );
    const competitions = useAppSelector((s) => s.competition.pageData.data);
    const [comp1, setComp1] = useState<ICompetition>();
    const [comp2, setComp2] = useState<ICompetition>();

    useEffect(() => {
        if (competitions.length < 1) {
            dispatch(loadCompetitions({ page: 1, size: pageSize }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (competitions?.length > 0) {
            const updated = competitions.filter((x) =>
                competitionStatus(x.startDate, x.endDate) === "upcoming" ||
                competitionStatus(x.startDate, x.endDate) === "ongoing"
            );
            setComp1(updated[0]);
            if (updated?.length > 1) {
                setComp2(updated[1]);
            }
        }
    }, [competitions]);

    return comp1 ? (
        <section className="section-container md-box join-competitions-icon">
            <div className="text-heading text-md-center w-100 glazed-bw-text2 mb-31">
                Join a data science competition today
            </div>
            <span className=" text-color-secondary text-sm mb-5 text-md-center">
                Master privacy-enhancing technologies, showcase your skills in
                balancing accuracy and privacy by participating in community
                competitions.
            </span>

            <div className="glazed-card-cover radius-base">
                <div className="glazed-card radius-base current-competition">
                    {comp1 && (
                        <CompetitionListItem
                            to={`/${COMPETITIONS}/` + (comp1?.slug ?? comp1._id)}
                            item={comp1}
                            key={"Competition_Completed" + comp1._id}
                        />
                    )}
                    {comp2 && (
                        <CompetitionListItem
                            to={`/${COMPETITIONS}/` + (comp2?.slug ?? comp2._id)}
                            item={comp2}
                            key={"Competition_Completed" + comp2._id}
                        />
                    )}
                </div>
            </div>
        </section>
    ) : (
        <></>
    );
}
