// import { useState } from 'react';
import { IAccomplishment } from "../../types/accomplishment-types";
import { AccomplishmentType } from "../../enums/accomplishment-type";
import Card from "react-bootstrap/Card";
// import copy from "../../assets/icons/copy-icon.svg";
// import alert from "../../assets/icons/alert-circle-glazed.png";
// import { useAppSelector } from "../../store/store-hooks";

import { BadgeFormatDate } from "../../utils/helper-methods";

import bug_logo from "../../assets/logos/bug_logo.png";
import first_logo from "../../assets/logos/first_logo.png";
import second_logo from "../../assets/logos/second_logo.png";
import third_logo from "../../assets/logos/third_logo.png";
import participant_logo from "../../assets/logos/participant_logo.png";
import rob_signature from "../../assets/logos/Rob_signature.png";
import submission_hash from "../../assets/images/submission_hash.png";

import bug from "../../assets/images/bug_bg.png";
import first from "../../assets/images/first_bg.png";
import second from "../../assets/images/second_bg.png";
import third from "../../assets/images/third_bg.png";
// import participant from "../../assets/images/participant_bg.png";

import "./index.scss";
import '../../animations/animate.scss';
import process3D from '../../animations/animate';
import { useState, useEffect } from 'react';

type TCodeBlockProps = {
    submission: IAccomplishment;
    className?: string;
    animate?: boolean;
    listItem?: boolean;
    id?: string;
};

export default function Badge(props: TCodeBlockProps) {
    const submissionDetails = props.submission.data;
    // const [initiated, setInitiated] = useState(false);
    const [background, setBackground] = useState<string>("bg-participant");
    const [bgImage, setBgImage] = useState<string>();
    const [icon, setIcon] = useState<string>(participant_logo);
    const [position, setPosition] = useState("Hackthon Challenger");

    useEffect(() => {
        if ( props.submission.type === AccomplishmentType.CompetitionBadge ){
            switch(submissionDetails.rank){
                case 1:
                    setPosition("1ST \nPOSITION");
                    setBackground("bg-first");
                    setBgImage(first);
                    setIcon(first_logo);
                    break;
                case 2:
                    setPosition("2ND \nPOSITION");
                    setBackground("bg-second");
                    setBgImage(second);
                    setIcon(second_logo);
                    break;
                case 3:
                    setPosition("3RD \nPOSITION");
                    setBackground("bg-third");
                    setBgImage(third);
                    setIcon(third_logo);
                    break;
                default:
                    setPosition("Hackthon \nChallenger");
                    setBackground("bg-participant");
                    // setBgImage(participant);
                    setIcon(participant_logo);              
            }
        } else if( props.submission.type === AccomplishmentType.BugBounty ) {
            setPosition("BUG \nBOUNTY");
            setBackground("bg-bug");
            setBgImage(bug);
            setIcon(bug_logo);

        } else if( props.submission.type === AccomplishmentType.OutstandingNotebook ) {
            setPosition("OUTSTANDING \nNOTEBOOK");
            setBackground("bg-bug");
            setBgImage(bug);
            setIcon(bug_logo);
        }
    }, []);

    useEffect(() => {
        const components = document.querySelectorAll('.card3D.animating');
        if(components && components.length > 0) 
            process3D( components, 40, 80, props.animate ? true : false );
    }, []);

    const createRank = (rank: number) => {
        let rankString = "" + parseInt("" + rank);
        while(rankString.length < 5){
            rankString = "0" + rankString;
        }
        return rankString;
    };

    const getCardDetails = () => {
        switch(props.submission.type){
            case AccomplishmentType.BugBounty:
                return bugBountyBadgeBody();
            case AccomplishmentType.CompetitionBadge:
                return competitionBadgeBody();
            case AccomplishmentType.OutstandingNotebook:
                return notebookBadgeBody();
            default:
                return <p>!Error!</p>            
        }
    }

    const competitionBadgeBody = () => {
        return (
            <>
                <p className='mb-0 badge-rank'>{position}</p>
                <img src={icon} alt={position} className='badge-logo' />
                <div className='marg-t-2 pad-t-1'>
                    <p className='badge-detail marg-b-1'>RANK<span className='marg-s-2'>{createRank(submissionDetails.rank)}</span></p>
                    {submissionDetails.leaderboard ? 
                        <p className='badge-detail marg-b-1'>SCORE<span className='marg-s-2'>{parseFloat(submissionDetails.leaderboard.toFixed(4))}</span></p>
                        : <></>
                    }
                    <p className='badge-detail marg-b-1'>EPSILON<span className='marg-s-2'>{submissionDetails.eps ? parseFloat(submissionDetails.eps.toFixed(4)) : 0}</span></p>    
                    <p className='badge-detail marg-b-2 pad-b-1'>DELTA<span className='marg-s-2'>{submissionDetails.delta ? parseFloat(submissionDetails.delta.toFixed(4)) : 0}</span></p>
                        
                    <p className='badge-detail marg-b-1'>DATE OF ISSUE<span className='marg-s-2'>
                        {props.submission.date ? BadgeFormatDate(props.submission.date?.toUTCString()) : ""}</span></p>
                    <p className='badge-detail marg-b-1'>HOLDER'S NAME</p>
                    <p className='badge-detail marg-b-1'>{props.submission.user.fullName}</p>
                    <div className='badge-sign-holder'>
                        <p className='badge-detail marg-b-1'>SIGNATURE OF ISSUER</p>
                        <div className="badge-sign">
                            <img src={rob_signature} alt="Signature" className='' />
                        </div>
                        <p className='badge-detail marg-b-1'>Robert Pisarczyk</p>
                        <p className='badge-detail marg-t-3 marg-b-3'>{submissionDetails.user_df}</p>
                    </div>
                    <p className='marg-t-3 mb-0 badge-validate'>validate at www.antigranular.com</p>
                </div>
                <div className='badge-hash'>
                    <img src={submission_hash} alt="Signature" />
                    <p className='hash-hex'>{submissionDetails.submission_sha}</p>
                </div>
                {submissionDetails.competition_name ? <div className='badge-competition'>
                    {submissionDetails.competition_name}
                </div> : <></>}
            </>
        )
    }

    const bugBountyBadgeBody = () => {
        return (
            <>
                <p className='mb-0 badge-rank'>{position}</p>
                <img src={icon} alt={position} className='badge-logo' />
                <div className='marg-t-2 pad-t-1'>
                    <p className='badge-detail marg-b-2'>DATE SUBMITTED<span className='marg-s-2'>
                        {submissionDetails.submissionDate ? BadgeFormatDate(submissionDetails.submissionDate.toUTCString()) : ""}</span></p>
                    <p className='badge-detail marg-b-1'>BUG TYPE</p>
                    <p className='badge-detail marg-b-2'>{submissionDetails.bountyType}</p>

                    <p className='badge-detail marg-b-2'>DATE OF ISSUE<span className='marg-s-2'>
                        {props.submission.date ? BadgeFormatDate(props.submission.date.toUTCString()) : ""}</span></p>
                    <p className='badge-detail marg-b-1'>HOLDER'S NAME</p>
                    <p className='badge-detail mb-0'>{props.submission.user.fullName}</p>
                    
                    <div className='badge-sign-holder'>
                        <p className='badge-detail marg-b-1'>SIGNATURE OF ISSUER</p>
                        <div className="badge-sign">
                            <img src={rob_signature} alt="Signature" className='' />
                        </div>
                        <p className='badge-detail marg-y-1'>Robert Pisarczyk</p>
                    </div>
                    <p className='marg-t-6 mb-0 badge-validate'>validate at www.antigranular.com</p>
                </div>
                <div className='badge-competition'>www.antigranular.com</div>
            </>
        )
    }

    const notebookBadgeBody = () => {
        return (
            <>
                <p className='mb-0 badge-rank'>{position}</p>
                <img src={icon} alt={position} className='badge-logo' />
                <div className='marg-t-2 pad-t-1'>
                    <p className='badge-detail marg-b-3'>DATE SUBMITTED<span className='marg-s-2'>
                        {submissionDetails.submissionDate ? BadgeFormatDate(submissionDetails.submissionDate.toUTCString()) : ""}</span></p>

                    <p className='badge-detail marg-b-3'>DATE OF ISSUE<span className='marg-s-2'>
                        {props.submission.date ? BadgeFormatDate(props.submission.date.toUTCString()) : ""}</span></p>

                    <p className='badge-detail marg-b-1'>HOLDER'S NAME</p>
                    <p className='badge-detail mb-0'>{props.submission.user.fullName}</p>
                    
                    <div className='badge-sign-holder'>
                        <p className='badge-detail marg-b-1'>SIGNATURE OF ISSUER</p>
                        <div className="badge-sign">
                            <img src={rob_signature} alt="Signature" className='' />
                        </div>
                        <p className='badge-detail marg-y-1'>Robert Pisarczyk</p>
                    </div>
                    <p className='marg-t-9 mb-0 badge-validate'>validate at www.antigranular.com</p>
                </div>
                <div className='badge-competition'>
                    { submissionDetails.competition_name ? 
                        submissionDetails.competition_name : "www.antigranular.com"
                    }
                </div>
                
            </>
        )
    }

    return (
        <div id={props.id} className={`cardCover ${props.listItem ? "p-4 miniItemCardCover" : "modalItemCardCover"} ${props.className}`}>
            <Card className={`card3D ${props.listItem ? "miniItemCard" : ""} ${props.animate ? "animating" : ""} ${background} `}>
                {bgImage ? <img alt="" className='card-bg-image' src={bgImage} /> : <></>}
                <Card.Body className={`content3D badge-card ${props.listItem ? "badge-content-mini miniItem" : "badge-content"}`}>
                    {getCardDetails()}
                </Card.Body>
            </Card>
        </div>
    );


}
