export enum SectionType {
    Para = "Para",
    Quote = "Quote",
    Columns = "Columns",
    ImageColumn = "ImageColumn",
    ColumnImage = "ColumnImage",
    Image = "Image",
}


export interface PageRefType {
    title: string;
    url: string;
    openInNewTab: boolean;
};