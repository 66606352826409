// import { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import vscDarkPlus from "./vsc-dark-plus";
import Card from "react-bootstrap/Card";
import copy from "../../assets/icons/copy-icon.svg";
import alert from "../../assets/icons/alert-circle-glazed.png";
import { useAppSelector } from "../../store/store-hooks";
import "./index.scss";
import { useState } from 'react';


type TCodeBlockProps = {
    children?: string;
    className?: string;
    copyText?: string;
    noBg?: boolean;
    noCopy?: boolean;
    private?: boolean;
};
export default function CodeBlock(props: TCodeBlockProps) {
    const user = useAppSelector((s) => s.auth.user);
    const [copied, setCopied] = useState(false);
    const testCode = `Add code here`;

    return (
        <div className={`code-block-cover position-relative glazed-card-cover mb-3 radius-base slide_in_appear ${props.className}`}>
            <div className={`d-flex justify-content-center align-items-center ${props.private && !user ? "security-cover glass-card" : "d-none"}`}>
                <div>
                    <p className='mb-1 text-thick text-sm selected-link w-fit'>
                        <img src={alert} alt="" width={16} height={16} className='me-2'/>Just a sec!</p>
                    <p className='mb-0 text-xs ms-4'>Please log in to access your credentials.</p>
                </div>
            </div>
            <Card className={`window-container ${props.noBg ? "" : "glazed-card"} ${props.private && !user ? "inactive" : ""}`}>
                <Card.Body className='p-0'>
                    {!props.noCopy && 
                        <>
                            <img src={copy} alt="" className={`copy-icon ${copied ? "d-none" : ""}`} 
                                onClick={() => {
                                    navigator.clipboard.writeText(props.copyText || "");
                                    setCopied(true);
                                    setTimeout(() => {
                                        setCopied(false)
                                    }, 2000)
                                }}
                            />
                            <span className={`text-xs text-color-tertiary copy-icon ${!copied ? "d-none" : ""}`}>copied</span>
                        </>
                    }
                    <SyntaxHighlighter
                        language='javascript'
                        wrapLongLines
                        // showLineNumbers
                        customStyle={{margin: 0, padding: 0, border: 0,}}
                        style={vscDarkPlus as any}
                    >
                        {props.children ?? testCode }
                    </SyntaxHighlighter>
                </Card.Body>
            </Card>
        </div>
    );
}
