/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from "react-bootstrap/Button";
import "./index.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppDispatch } from "../../store/store-hooks";
import { useEffect, useState } from "react";
import CompetitionTeamsApi from "../../api/competition-teams-api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { HandleError } from "../../errors/handler";
import AuthApi from "../../api/auth-api";
import Input from "../input/input";
import { showInfoModal, showErrorModal } from "../../store/slices/modal-states-slice";
import {ICompetitionTeams} from "../../types/competition-teams-types";


type TAcceptInvitationModalProps = {
    token: string;
    teamId?: string;
    show: boolean;
    onHide: () => void;
    invitationType: string;
};

export default function AcceptInvitationModal(props : TAcceptInvitationModalProps) {
    const dispatch = useAppDispatch();
    const [formErrors, setFormErrors] = useState<any>({});
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [loading, setLoading] = useState<boolean>(false);


    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
    
        if (validateForm()) {
            setLoading(true);

            if(props.invitationType === "TEAM"){
                CompetitionTeamsApi.acceptInvitation({
                    token : props.token,
                    fullName,
                    password
                })
                    .then(() => {
                        dispatch(
                            showInfoModal({
                                title: "Success!",
                                message: `You should now be able to see the team details on Teams section of Competition page.`
                            })
                        );
                        reset();
                        
                    }).catch((error) => setFormErrors(HandleError(error)))
                    .finally(() => setLoading(false));

            } else if(props.invitationType === "SITE") {
                AuthApi.verifyAccount({
                    token : props.token,
                    fullName,
                    password
                })
                    .then(() => {
                        dispatch(
                            showInfoModal({
                                title: "Verified",
                                message:
                                    "Your account verified successfully. You can login now with your credentials.",
                            })
                        );
                    })
                    .catch(() => {
                        dispatch(
                            showErrorModal({
                                title: "Verification Failed",
                                message: "There was an error while verifying your account. Please resend verification link and try again.",
                            })
                        );
                    })
                    .finally(() => setLoading(false));

            }
        } 
    };

    const validateForm = () => {
        setFormErrors({});
        let errors: any = {};
        if (!password) {
            errors.password = "Please enter password.";
        }
        if (!confirmPassword) {
            errors.confirmPassword = "Please confirm password.";
        }
        if (confirmPassword !== password) {
            errors.confirmPassword = "Passwords does not match.";
        }
        if (password && password.length < 6) {
            errors.password = "Password should be at least 6 charater long.";
        }
        if (!fullName) {
            errors.fullName = "Please enter full name.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const reset = () => {
        setFormErrors({});
        setPassword("");
        setConfirmPassword("");
        setFullName("");
        setLoading(false);
        props.onHide();
    };

    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide()}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <div>{props.invitationType === "TEAM" ? "Join Team" : "Join Antigranular"}</div>
            </Modal.Header>
            <Modal.Body className="login-modal-form">
                <Form
                    noValidate
                    onSubmit={submit}
                    className={`appear`}
                >
                    <fieldset disabled={loading}>
                        <div className="form-input-fields text-xs text-color-secondary">
                            <Form.Group className="">
                                <Input
                                    autoComplete="off"
                                    value={fullName}
                                    label="Name"
                                    placeholder="Full Name"
                                    maxLength={100}
                                    onChange={(e) => {
                                        if (formErrors["fullName"]) {
                                            delete formErrors?.fullName;
                                            setFormErrors(formErrors);
                                        }
                                        setFullName(e.target.value);
                                    }}
                                    error={formErrors["fullName"]}
                                />
                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Input
                                    autoComplete="new-password"
                                    label="Password"
                                    placeholder="Create a password"
                                    type="password"
                                    value={password}
                                    error={formErrors.password}
                                    onChange={(e) => {
                                        if (formErrors["password"]) {
                                            delete formErrors.password;
                                            setFormErrors(formErrors);
                                        }
                                        setPassword(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Input
                                    autoComplete=""
                                    label="Confirm Password"
                                    placeholder="Confirm password"
                                    type="password"
                                    value={confirmPassword}
                                    error={formErrors.confirmPassword}
                                    onChange={(e) => {
                                        if (formErrors["confirmPassword"]) {
                                            delete formErrors.confirmPassword;
                                            setFormErrors(formErrors);
                                        }
                                        setConfirmPassword(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className={formErrors[""] ? "mt-4" : "mt-2"}>
                                {formErrors[""] ? (
                                    <label className="text-danger">
                                        {formErrors[""]}
                                    </label>
                                ) : undefined}
                            </Form.Group>
                        </div>

                        <Button type="submit" variant="primary" className="rounded-pill mx-auto">
                            {loading ? (
                                <>
                                    <FontAwesomeIcon spin className="me-2" icon={faSpinner} /> Please wait
                                </>
                            ) : (
                                "Join"
                            )}
                        </Button>
                    </fieldset>
                </Form>
            </Modal.Body>
        </Modal>
    );
}