import Table from 'react-bootstrap/Table';
import CodeBlock from "../../components/code-block";
import { IDataset } from "../../types/dataset-types";
import CustomAccordian from "../../components/custom-accordian";
import "./index.scss";
// import CustomTooltip from "../../components/custom-tooltip";
// import { setRegistrationModalVisible } from "../../store/slices/auth-slice";

type DatasetProps = {
    item: IDataset;
    metadata: any;
};

export default function CompetitionDatasetInfo(props: DatasetProps) {
    const dataset = props.item;
    const metadata = props.metadata;
    

    return ( !props.item ? <span className='text-sm text-color-red-normal'>Dataset is private or not accessible, please check with the Antigranular team</span> :
        <>
            <p className="text-xxl mb-2">{dataset.title}</p>
            <p className="text-xs mb-2">
                {dataset.subTitle}
            </p>
            <p className="text-lg w-75 mb-2">About the dataset</p>
            <div className="about-competition-content"
                dangerouslySetInnerHTML={{
                    __html: dataset.about ?? "",
                }}>
            </div>

            {dataset.columnData && dataset.columnData.length > 0 ?
                <div className="glazed-card-cover radius-base mt-3">
                    <div className="competition-dataset-columns">
                        <p className="text-base text-thick ps-3 me-21 ps-md-4 column-heading mb-0 pb-21">{dataset.title}</p>
                        
                        <div className="columns-table">
                            <Table bordered>
                                <thead className="">
                                    <tr className="sticky-row bg-primary">
                                        <td className="sticky-cell" key="columnName">
                                            <div>Name</div>
                                        </td>
                                        {dataset.columnData.map((column, id) => 
                                            <td key={"columnName" + id} className="text-nowrap relaxed-width">
                                                <span className="me-1">{column.columnName.split(" ")[0]}</span>
                                                <span className="text-xxs text-color-tertiary">{column.columnName.split(" ").slice(1).join(" ")}</span>
                                            </td>
                                        )}
                                    </tr>
                                </thead>

                                <tbody className="">
                                    <tr>
                                        <td className="sticky-cell" key="columnDesc">
                                            <div>Description</div>
                                        </td>
                                        {dataset.columnData.map((column, idx) => 
                                            <td key={"columnDesc" + idx} className="relaxed-width">
                                                {column.columnDescription}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className="sticky-cell" key="columnMetaColumn">
                                            <div>{metadata ? "Metadata" : "Categories"}</div>
                                        </td>
                                        {dataset.columnData.map((column, idx) => 
                                            <td key={"columnMeta" + idx} className="relaxed-width">
                                                {metadata ? <div className="d-flex flex-column">{
                                                    metadata["x_meta"] && metadata["x_meta"]?.[column.columnName.split(" ")[0]] ? 
                                                        metadata["x_meta"]?.[column.columnName.split(" ")[0]]?.join(" - ") :
                                                        metadata["y_meta"] && metadata["y_meta"]?.[column.columnName.split(" ")[0]] ? 
                                                            metadata["y_meta"]?.[column.columnName.split(" ")[0]]?.join(" - ") :

                                                            column?.categories?.length > 0 ? column.categories.split(",").map((category, id) => 
                                                                <span key={column.columnName + "Cat" + id}>{category}</span>
                                                            ) : "-"
                                                }</div> : 
                                                    <div className="d-flex flex-column">{
                                                        column?.categories?.length > 0 ? column.categories.split(",").map((category, id) => 
                                                            <span key={column.columnName + "Cat" + id}>{category}</span>
                                                        ) : "-"
                                                    }</div>
                                                }
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div> : undefined
            }

            {/* <CustomAccordian className="mt-31" id={"loadingDataset"+ dataset._id} heading="Loading Dataset">
                <div className="mb-3">
                    <p className="text-xs text-color-secondary mb-2">
                        By copying and running the code snippet given below
                        you can connect to the competition dataset.
                    </p>
                
                    <CodeBlock noBg noCopy className="w-100">{`%%ag
from ag_utils import load_dataset
response = load_dataset("${dataset.title}")
train_x = response["train_x"]
train_y = response["train_y"]
test_x = response["test_x"]`} 
                    </CodeBlock>
                </div>
            </CustomAccordian> */}

            <CustomAccordian className="mt-31" id={"submission"+ dataset._id} heading="Submission">
                <div className="mb-3">
                    <p className="text-xs text-color-secondary mb-2">
                        <span className="me-2">1)</span>Using session object to submit and print the result
                    </p>
                    <CodeBlock noBg noCopy className="w-100">{`result = session.submit_predictions(df_pred)
print(result)`} 
                    </CodeBlock>
                    <p className="text-xs text-color-secondary mb-2 ms-3">
                        NOTE: session here is the session object assigned against ag.login
                    </p>
                    <p className="text-xs text-color-secondary mb-2 mt-4">
                    <span className="me-2">2)</span>Using the ag_utils' submit_predictions function
                    </p>
                    <CodeBlock noBg noCopy className="w-100">{`submit_predictions(pd.DataFrame(y_pred))`} 
                    </CodeBlock>
                    <p className="text-xs text-color-secondary mb-2 ms-3">
                        NOTE: here y_pred is the predicted (Private) DataFrame object
                    </p>
                </div>
            </CustomAccordian>

            {dataset.sources && dataset.sources.length > 0 ?
                <CustomAccordian className="mt-31" id={"DatasetSources"+ dataset._id} heading="Sources">
                    <div>{dataset.sources.map((source, id) => 
                        <p className="mb-1" key={"source" + id}>{source}</p>)}
                    </div>
                </CustomAccordian> : undefined
            }

            {dataset.collaborators && dataset.collaborators.length > 0 && dataset.collaborators[0].trim().length !== 0 ?
                <CustomAccordian id={"DatasetCollaborators"+ dataset._id} heading="Collaborators">
                    <div>{dataset.collaborators.map((collaborator, id) => 
                        <p className="mb-1" key={"collaborator" + id}>{collaborator}</p>)}
                    </div>
                </CustomAccordian> : undefined
            }
            
            {dataset.license ?
                <CustomAccordian id={"DatasetLicense"+ dataset._id} heading="License">
                    <div>{dataset.license}</div>
                </CustomAccordian> : undefined
            }
        </>
    )
}
