import card1 from "../../assets/images/card1.png";
import card2 from "../../assets/images/card2.png";
import card3 from "../../assets/images/card3.png";
import Carousel from 'react-bootstrap/Carousel';


export default function InfoColumns() {
    
    const InfoCard1 = () => {
        return (
            <div className="glazed-card-cover radius-base">
                <div className="glazed-card p-4 radius-base">
                    <div className="d-flex mx-auto mb-3">
                        <img src={card1} height={90} alt="" className="mx-auto"/>
                    </div>
                    <p className="text-xxl mb-2">
                        Say goodbye to being hindered by sensitive data.
                    </p>
                    <p className="text-xs text-color-tertiary mb-0">
                        Antigranular is your platform to learn, explore, and 
                        sharpen your data privacy skills. Unlock the full 
                        potential of world's most impactful data, such as personal, 
                        financial, and trade secrets, while maintaining utmost privacy.
                    </p>
                </div>
            </div>
        )
    } 

    const InfoCard2 = () => {
        return (
            <div className="glazed-card-cover radius-base">
                <div className="glazed-card p-4 radius-base">
                    <div className="d-flex mx-auto mb-4 pb-2">
                        <img src={card2} width={110} alt="" className="mx-auto"/>
                    </div>
                    <p className="text-xxl mb-2">
                        PETs: Empowering you with unbreakable privacy.
                    </p>
                    <p className="text-xs text-color-tertiary mb-0">
                        Antigranular is a community-driven, open-source platform 
                        that merges confidential computing and differential privacy. 
                        This creates a secure environment for handling unseen confidential data.
                    </p>
                </div>
            </div>
        )
    } 

    const InfoCard3 = () => {
        return (
            <div className="glazed-card-cover radius-base">
                <div className="glazed-card p-4 radius-base">
                    <div className="d-flex mx-auto mb-3">
                        <img src={card3} height={90} alt="" className="mx-auto"/>
                    </div>
                    <p className="text-xxl mb-2">
                        Prove you can be a data science master, even with eyes closed.
                    </p>
                    <p className="text-xs text-color-tertiary mb-0">
                        Start leveraging the Antigranular library now, with seamless 
                        integration into any Jupyter Notebook environment. Showcase your 
                        abilities and compete for the best accuracy models without compromising privacy. 
                    </p>
                </div>
            </div>
        )
    } 


    return (
        <section className="section-container md-box">
            {window.innerWidth >= 780 ?
            <div className="info-columns">
                {InfoCard1()}
                {InfoCard2()}
                {InfoCard3()}
            </div> : 
            <Carousel controls={false}  interval={null} className="info-box-carousel">
                <Carousel.Item className="info-carousel-item px-2">
                    <div className="d-flex w-100 justify-content-center align-items-center">
                        {InfoCard1()} 
                    </div>
                </Carousel.Item>
                <Carousel.Item className="info-carousel-item px-2">
                    <div className="d-flex w-100 justify-content-center align-items-center">
                        {InfoCard2()}
                    </div>
                </Carousel.Item>
                <Carousel.Item className="info-carousel-item px-2">
                    <div className="d-flex w-100 justify-content-center align-items-center">
                        {InfoCard3()}
                    </div>
                </Carousel.Item>
            </Carousel>
            }
        </section>
    );
}
