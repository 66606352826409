import {
    // IOffense,
    IReportOffensePayload,
} from "../types/offense-types";

import { BaseApi } from "./base-api";

export default class OffenseApi extends BaseApi {
    private static url = (action: string) => "offense/" + action;

    /**
     * Report offense
     * @param offenseData 
     * @returns
     */
    public static async report(data: IReportOffensePayload): Promise<void> {
        return this._post(this.url("report"), data);
    }
}
