
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "../intro-page/index.scss";
import { COMPETITIONS, COOKIE_DECLARATION, PRIVACY_POLICY } from "../../router/routes";

export default function PrivacyPolicy() {
    return (
        <div className="page-container sm-box" id="TNCPage">
            <Tabs id="tab-tnc" defaultActiveKey={"tabT&C"} className="">
                <Tab eventKey={"tabT&C"} title="T&C">
                    <div className="text-3xl mt-2">Terms and Conditions</div>
                    <div className="text-color-quinary text-sm static_page_content mt-4">
                        These Terms and Conditions apply to the Website located at oblivious.ai and all associated websites linked to it and Services provided through it. The Website is owned and operated by Oblivious Software Limited. <b>Please read these Terms and Conditions carefully before using the Website and Services.</b><br />
                        Oblivious, on the basis of these Terms and Conditions, <b>PROVIDES ONLY BUSINESS SERVICES to entrepreneurs, companies or organisations.</b><br />
                        <b>The Services ARE NOT intended for use by persons acting as consumers.</b><br />
                        By accessing or using the Website, Services or any of the Content provided by it You agree to be legally bound by these Terms and Conditions, You represent and warrant that You are eligible to use the Services and are authorised to perform the obligation required by these Terms and Conditions.<br />
                        If you do not accept these Terms and Conditions, do not use the Website, Service and Content provided by it.<br />

                        <div className="spHeading text-xxl">1. DEFINITION</div>
                        <p><span className="number">1.1.</span> <b>Account</b> – a collection of resources kept for You by Oblivious, by means of which You can use the Services.</p>
                        <p><span className="number">1.2.</span> <b>Agreement </b> – all documents and information presented to You, in particular these Terms and Conditions, setting out the rules for the provision of the Services by Oblivious.</p>
                        <p><span className="number">1.3.</span> <b>Content</b> – content displayed by the Website, in particular software, source code, data, images, graphics, design and other materials available on the Website while using the Services.</p>
                        <p><span className="number">1.4.</span> <b>Credits</b> – a sum of money paid for in advance to use a specific Service indicated in the Account (in particular hosting services). Oblivious may, as part of the Services provided, offer a certain number of Credits without the need to incur additional costs. Details can be found at</p>
                        <p><span className="number">1.5.</span> <b>On-Premise Elements</b> – computer programmes installed on Your devices in order to use the Services.</p>
                        <p><span className="number">1.6.</span> <b>Oblivious</b> – Oblivious Software Limited, address: St. James Court, 6, James Terrace, Dublin, K36Y972, Ireland, represented by its affiliates, directors, employees, officers or agents.</p>
                        <p><span className="number">1.7.</span> <b>Services</b> – software, application, products and services provided by Oblivious.</p>
                        <p><span className="number">1.8.</span> <b>User</b> or <b>You</b> or <b>Your</b> – an individual person acting as an entrepreneur, company or organisation that uses the Account and the Services through the Website.</p>
                        <p><span className="number">1.9.</span> <b>User's Content</b> – Content created, generated or uploaded by the User in the course of using the Services.</p>
                        <p><span className="number">1.10.</span> <b>Website </b> – website located at oblivious.ai, including its Content and Services provided by Oblivious through it.</p>





                        <div className="spHeading text-xxl">2. REQUIREMENT TO USE THE SERVICES</div>
                        <p><span className="number">2.1.</span> You register an Account by filling out the registration form available on the Website and accepting these Terms and Conditions.</p>
                        <p><span className="number">2.2.</span> In the registration process taking place via the registration form, You should provide the required data indicated in the registration form</p>
                        <p><span className="number">2.3.</span> For registration, You may also use a valid account of external authentication services (e.g. offered on other websites) in order to complete the registration process. You shall confirm the registration using the confirmation mechanisms provided by these external authentication services.</p>
                        <p><span className="number">2.4.</span> Upon confirmation of registration, an Agreement is concluded between You and Oblivious, the subject of which are Services, under the conditions specified in these Terms and Conditions.</p>
                        <p><span className="number">2.5.</span> When registering an Account on behalf of the User who is a legal person, it is required to be authorised to do so. Oblivious may require You to submit a relevant confirmation that You are acting on behalf of a legal person.</p>
                        <p><span className="number">2.6.</span> You are entitled to register only one Account and it is prohibited to have more than one Account. By meeting the additional requirements specified for a specific Service, You may create sub-accounts in the amount appropriate for a given Service as part of Your Account.</p>
                        <p><span className="number">2.7.</span> Accounts registered by software running automated tasks (bots) or other automated methods shall not be permitted.</p>
                        <p><span className="number">2.8.</span> You gain access to the Account (logging in) after entering the e-mail address and password or logging in via external authentication services. The use of these external services, in particular the software that causes logging in, is at Your risk using such software.</p>




                        <div className="spHeading text-xxl">3. USE OF THE SERVICES</div>
                        <p><b>Direct Interactions</b></p>
                        <p><span className="number">3.1.</span> By using the Services, You shall use it in accordance with applicable law and for the purposes for which it is intended, as set out in these Terms and Conditions.</p>
                        <p><span className="number">3.2.</span> Oblivious offers Services in three variants:</p>
                        <div className="bullets">
                            <div className="d-flex">a) <div className="ms-2">Free</div></div>
                            <div className="d-flex">b) <div className="ms-2">Professional</div></div>
                            <div className="d-flex">c) <div className="ms-2">Enterprise.</div></div>
                        </div>
                        <p><span className="number">3.3.</span> <b>Free variant</b> is intended for an unlimited number of Users, and the using its functionalities is free of charge, unless it is clearly indicated as part of the Services provided that certain functionalities or Services are additional payable. Prices of these certain functionalities or Services will be precisely indicated on the Website. As part of the Services provided within Free variant, Oblivious provides the number of Credits indicated on the Website that You are entitled to in each month of the Agreement without the need to incur additional costs. The number of those Credits is renewed every month and if unused they do not carry over to the next period.</p>
                        <p><span className="number">3.4.</span> <b>Professional and Enterprise variants</b> are intended for Users and their use might require separate arrangements between Oblivious and You. In the event of a conflict between these Terms and Conditions and separate arrangements, the latter will prevail, unless otherwise stated in the separate arrangements.</p>
                        <p><span className="number">3.5.</span> You shall provide Oblivious with all required cooperation (including necessary information, products or data) and carry out all other responsibilities set out in the Agreement to enable Oblivious to perform the Services.</p>

                        <p><b>Prohibited use of the Services</b></p>
                        <p><span className="number">3.6.</span> You shall, under no circumstances, upload, host, transmit any Content that is unlawful, defamatory, fraudulent, discriminatory or abusive to any individual or group; infringes copyright of third parties; facilitates illegal activity; contains false or deceptive information or interferes with the normal operation of the Services; uploads or transmits or any other type of malicious code that may be used in any way that will affect the operation of the Services or of any other websites.</p>
                        <p><span className="number">3.7.</span> You must not use the name of another person or entity for what is not lawfully authorised. You must not use a trademark or any other protected designation that is subject to any rights of another person or entity with its authorisation.</p>
                        <p><span className="number">3.8.</span> If Oblivious knows or suspects that the Services have been used for illegal purposes, Oblivious may share information about such activity with the relevant authorities.</p>
                        <p><span className="number">3.9.</span> You shall maintain the security of the Account by keeping the login and password confidential. You are responsible for the User's Content uploaded and processed as part of the Services (including the User's Content of people who do it on Your behalf). If a vulnerability is noticed, You are obliged to inform Oblivious about it by contacting at vulnerabilities@oblivious.ai.</p>
                        <p><span className="number">3.10.</span> You shall inform Oblivious if You become aware of any unauthorised access of use to Your Account by contacting at support@oblivious.ai.</p>
                        <p><span className="number">3.11.</span> Oblivious reserves the right to suspend and – in case of gross or repeating violation – terminate the Agreement for violating any of the prohibited uses, in particular these specified above and in case for delay in payments over 14 days.</p>
                        <p><span className="number">3.12.</span> You acknowledge that provision of the Services requires the use of services provided from third parties and that You do so solely at Your own risk. Oblivious makes no representation or warranty and shall has no liability or obligation in relation to the use of, or correspondence with, any such third-party services unless agreed otherwise.</p>
                        <p><span className="number">3.13.</span> Oblivious provides Services by using systems that could be located outside the Ireland or outside other countries within the European Union, You are obliged to inform Your clients/customers/partners that their personal data may be transferred, processed and stored outside of Ireland and, as set forth in Privacy Policy, may be subject to disclosure as required by applicable law, and to obtain from Your clients/customers/partners all necessary consents under applicable law.</p>
                        <p><span className="number">3.14.</span> You acknowledge that the Services may be subject to limitations, delays and other problems inherent in the use of Services due to the way third party services are performed, errors and other unforeseeable events.</p>
                        <p><span className="number">3.15.</span> Oblivious provides You with support to resolve issues relating to the Account and use of the Services. This support consists of the access to documentation and other data that are available to You through the Account and other pages on the Website.</p>





                        <div className="spHeading text-xxl">4. PRICES AND PAYMENTS</div>
                        <p><span className="number">4.1.</span> Oblivious will provide You with information about the fees that You are required to pay in order to use the Services. This information will be provided via the Website or any other way that will allow You to know the fee for the Services before using them.</p>
                        <p><span className="number">4.2.</span> Oblivious reserves the right to modify fees from time to time. The change in the amount of fees applies to those Services that will be provided to You after the change is made. The change does not affect the amount of fees for services purchased before the change. Oblivious will inform You thereof in accordance with the provisions of par. 11 below.</p>
                        <p><span className="number">4.3.</span> Unless otherwise agreed or clearly indicated on the Website, all fees (including Credits) must be paid in advance before using the Services. The payment shall be made using the available payment method You have chosen. If the primary payment method cannot be made for any reason, You may use the alternate payment method if it is available to You. Oblivious reserves the right to take further steps to collect the fees due and may suspend or revoke the access to the Services to pending payment.</p>
                        <p><span className="number">4.4.</span> All fees for providing the Services are exclusive of any taxes or other charges imposed by any authority that may apply and You shall be solely responsible for payment of any such taxes or charges, unless applicable law provides otherwise.</p>





                        <div className="spHeading text-xxl">5. PROPRIETARY RIGHTS</div>
                        <p><span className="number">5.1.</span> Subject to Your compliance with these Terms and Conditions, Oblivious grants You a personal, worldwide, non-exclusive, non-transferable, revocable, non-sublicensable license to use the Services, including Content (except for other User’s Content), and install and use On-Premise Elements on Your device exclusively for Your access to and use of the Services and information shared with You.</p>
                        <p><span className="number">5.2.</span> The license granted herein does not permit You to modify, alter, translate, decompile, reverse engineer, derive the source code, disassemble or create derivative works of the Services or allow a third party doing thereof. You shall not be permitted to transfer, distribute, sell, offer for sale, copy, reproduce, display, publicly perform, rent or provide access to the Services to any third party. In the event that the use of the Services becomes impossible in accordance with the intended purpose due to the occurring software error, You shall immediately inform Oblivious about it.</p>
                        <p><span className="number">5.3.</span> Unless otherwise stated, Oblivious does not grant to You any rights to any third party's intellectual property. All rights not expressly granted herein are reserved by Oblivious and licensors of Oblivious.</p>




                        <div className="spHeading text-xxl">6. USER'S CONENT</div>
                        <p><span className="number">6.1.</span> You retain ownership and responsibility of User's Content.</p>
                        <p><span className="number">6.2.</span> For hosting, publishing and sharing of the User's Content, Oblivious shall be granted the right to store, analyse and display the User's Content and make its temporary copies as required to provide the Services. It includes the right to show and share it with other Users. The above does not apply to encrypted Content sent between Users. Oblivious shall not be granted with the right to the User's Content in the greater extent than to provide the Services to the Users.</p>
                        <p><span className="number">6.3.</span> Oblivious does not analyse the User's Content before uploading it, but Oblivious retains the right to remove User's Content that, in its opinion, violates the generally applicable law or the Terms and Conditions, suspend Your Account and/or the performance of Services and/or terminate the Agreement.</p>





                        <div className="spHeading text-xxl">7. USER'S LIABILITY FOR THIRD PARTY CLAIMS AGAINST OBLIVIOUS</div>
                        <p><span className="number">7.1.</span> You agree to defend and indemnify Oblivious and its respective employees, contractors, agents, officers and directors from and against any and all claims, damages, losses, liabilities, costs or expenses (including without limitation attorneys' fees) arising out of or related to any claim, suit, action or proceeding by a third party arising out of or relating to Your use of the Services, breach of these Terms and Conditions (as well as any Oblivious policy referenced in these Terms and Conditions), violation of law, or any Content that You provide, upload or which cause to interface with the Services, or otherwise You transfer, use or process in connection with the Services.</p>
                        <p><span className="number">7.2.</span> You are responsible for violation of these Terms and Conditions by anyone using the Services with Your consent or using Your Account without authorisation if due to improper care the Account has become available to persons not duly authorised by You to act on Your behalf.</p>
                        <p><span className="number">7.3.</span> You shall obtain and maintain all necessary licences and consents necessary to perform obligations under this Agreement by Oblivious, in particular providing the Services. Additionally, You shall ensure that systems and programmes used by it comply with the relevant specifications provided by Oblivious.</p>





                        <div className="spHeading text-xxl">8. LIMITATION OF LIABILITY</div>
                        <p><span className="number">8.1.</span> ALL SERVICES AND PRODUCTS SHALL BE PROVIDED BY OBLIVIOUS "AS IS", WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SECURITY, CONFIDENTIALITY OR NON-INFRINGEMENT. IN NO EVENT SHALL OBLIVIOUS BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY CLAIM, DAMAGES, LOSSES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES, PRODUCTS OR ANY SOFTWARE COVERED BY THE AGREEMENT. YOU EXPRESSLY AGREE TO USE THE SERVICES AT YOUR OWN RISK AND SOLELY AS PERMITTED BY THE AGREEMENT.</p>
                        <p><span className="number">8.2.</span> Except as expressly provided in the Agreement, You are solely responsible for using the Services and for results obtained from the use thereof. Oblivious shall not be liable for any damages caused by errors or omissions in any information or instruction provided to Oblivious by You in connection with the Services or any actions taken by Oblivious toward You.</p>
                        <p><span className="number">8.3.</span> Oblivious shall not be liable under or in connection with the Agreement, including, but not limited to, contractual, tort (including negligence, misrepresentation), refund or otherwise for any of the following activities related to the use of the Services or the Website, in particular, loss of profit, loss of sales or market share by the undertaking, loss of data, damage of software or information or any other indirect, punitive, incidental, special, consequential, or exemplary damages.</p>
                        <p><span className="number">8.4.</span> Unless otherwise provided by mandatory law or to the extent that any statutory rights apply that cannot be excluded, limited or waived, Oblivious does not make any other warranties or representations of any kind, whether express, implied, statutory or otherwise regarding the Services including, without limitation, in relation to the quality of the Services, their use being on an uninterrupted or error free basis, that the Services will meet Your requirements.</p>
                        <p><span className="number">8.5.</span> Oblivious shall not be liable for any damages caused by:</p>
                        <div className="bullets">
                            <div className="d-flex">a) <div className="ms-2">Your access and use of the Services contrary to the Agreement;</div></div>
                            <div className="d-flex">b) <div className="ms-2">Unauthorised access to infrastructure, any modification, suspension or discontinuation of the Services,</div></div>
                            <div className="d-flex">c) <div className="ms-2">The use or alterations of the Content or other data used in connection with the Services;</div></div>
                            <div className="d-flex">d) <div className="ms-2">Content provided by other Users;</div></div>
                            <div className="d-flex">e) <div className="ms-2">Any harmful code (bugs, viruses).</div></div>
                        </div>

                        <p><span className="number">8.6.</span> To the fullest extent allowed by applicable law, under no circumstances, Oblivious shall be liable to You for any amount, exceeding in the aggregate the amount of fees paid by You to Oblivious during the one-month period immediately preceding the event that gave rise to Your claim for damages.</p>
                        <p><span className="number">8.7.</span> Oblivious shall have no liability to You under the Agreement if the performance of its obligations is impossible or limited by acts, omissions or accidents beyond reasonable control of Oblivious, including, without limitation, acts of God, war, pandemic, riot, malicious damage, blackout, fire, flood, strikes provided that You are notified of such an event as soon as reasonably possible.</p>





                        <div className="spHeading text-xxl">9. TERMINATION OF THE AGREEMENT</div>
                        <p><span className="number">9.1.</span> The provision of Services by Oblivious is indefinite, subject to the provisions set out below in this article</p>
                        <p><span className="number">9.2.</span> You may at any time terminate the Agreement concluded with Oblivious regarding Your Account, by submitting a statement at <a href="mailto:cancellations@oblivious.ai" target="_blank" rel='noreferrer'>cancellations@oblivious.ai</a>. The delivery of the statement will be confirmed by Oblivious. If You have Credits in Your Account purchased by You at the time of termination, Oblivious will return the Credits within 60 days from the termination of the Agreement unless agreed separately otherwise.</p>
                        <p><span className="number">9.3.</span> The termination of the Agreement regarding Your Account shall take effect at the end of the next calendar month You terminated the Agreement unless the Agreement is concluded for a specific accounting period. In this case, the Agreement will be terminated at the end of this period. After the indicated deadline, logging in to the Account and using the Services available on the Account will not be possible.</p>
                        <p><span className="number">9.4.</span> In the event of Your gross or repeated violations of the Agreement (including failure to pay the fees due), the Agreement may be terminated by Oblivious with immediate effect by sending an email with a statement of termination.</p>
                        <p><span className="number">9.5.</span> If the Agreement has been terminated on the basis of Oblivious’ decision due to Your fault, You are not entitled to re-register the Account without the prior consent of Oblivious.</p>





                        <div className="spHeading text-xxl">10. PRIVACY POLICY</div>
                        <p><span className="number">10.1.</span> Oblivious collects, uses and discloses information You provide or other information obtained about You in accordance with these Terms and Conditions as described in Privacy Policy.</p>
                        <p><span className="number">10.2.</span> Using the Website and the Services, You agree to the terms of Privacy Policy, which may be updated from time to time.</p>
                        <p><span className="number">10.3.</span> You warrant that You obtained all necessary consents under applicable law to disclose and use any data You provide Oblivious with.</p>





                        <div className="spHeading text-xxl">11. AMENDMENTS</div>
                        <p><span className="number">11.1.</span> Oblivious is entitled to change these Terms and Conditions at any time with effect for the future.</p>
                        <p><span className="number">11.2.</span> Oblivious will inform You about the planned change in advance and provide You with the date on which the change to these Terms and Conditions will apply. Oblivious may amend these Terms and Conditions upon notice to You, which may be provided through e-mail, your Account and/or the Website.</p>
                        <p><span className="number">11.3.</span> If You do not agree with the presented changes, it is able to terminate the Agreement and delete the Account. If You do not terminate the Agreement and cease using the Account prior to the changes of the Terms and Conditions becoming binding, by continuing to use the Services You agree that You are consenting to any such changes.</p>




                        <div className="spHeading text-xxl">12. MISCELLANEOUS</div>
                        <p><span className="number">12.1.</span> <b>Severability.</b> In case any provision in these Terms and Conditions are invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby and such provision shall be ineffective only to the extent of such invalidity, illegality or unenforceability. The remaining provisions of these Terms and Conditions shall remain in full force.</p>
                        <p><span className="number">12.2.</span> <b>Assignment.</b> Oblivious may transfer or assign its rights and obligations (in whole or in part) under these Terms and Conditions without Your prior written consent, including licenses granted by You. You may not transfer or assign Your rights and obligations (in whole or in part) under these Terms and Conditions without the prior written consent of Oblivious under pain of nullity.</p>
                        <p><span className="number">12.3.</span> <b>Entire Agreement.</b> These Terms and Conditions together with any additional individual arrangements for the provision of Services constitute the entire and complete content of the agreement concluded between You and Oblivious. In the event of a conflict between these Terms and Conditions and a separate agreement, the latter will prevail, unless otherwise stated in the separate agreement.</p>
                        <p><span className="number">12.4.</span> <b>No partnership.</b> Nothing in the Agreement shall be recognised as a partnership between the parties or authorise either party to act as an agent for the other or act in the name or on behalf.</p>
                        <p><span className="number">12.5.</span> <b>Governing Law.</b> The Agreement is governed by and construed in accordance with the laws of the Republic of Ireland, unless otherwise provided by mandatory law.</p>
                        <p><span className="number">12.6.</span> <b>Jurisdiction.</b> Any disputes arising from the performance of Services and the application of these Terms and Conditions will be settled by the court competent for the seat of the Oblivious unless otherwise provided by mandatory law.</p>
                        <p><span className="number">12.7.</span> In case of any questions on these Terms and Conditions or any complaint regarding provided Services, You should contact us at <a href="mailto:support@oblivious.ai" target="_blank" rel='noreferrer'>support@oblivious.ai</a>.</p>
                    </div>
                </Tab>

                <Tab eventKey={"tabCompetitionT&C"} title="Competition T&C">
                    <div className="text-3xl mt-2">Competition Terms and Conditions</div>
                    <div className="text-color-quinary text-sm static_page_content">

                        <div className="spHeading mt-4 text-xxl">1. GENERAL</div>
                        <p><span className="number">1.1.</span> These terms and conditions apply to competitions operated by Oblivious Software Limited ("<b>Oblivious</b>", “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”), and supplement the Terms and Conditions which apply to the website located at antigranular.com ("<b>Website</b>"). </p>
                        <p><span className="number">1.2.</span> By entering into an online skills-based coding competition or challenge on the Website ("<b>Competition</b>"), you agree to comply with these Terms and Conditions. <b>Please read these Terms and Conditions, along with our Website Terms and Conditions carefully before participating in a Competition</b>. These Terms and Conditions should also be read in conjunction with our <b><a href={`/${COOKIE_DECLARATION}`} target="_blank" rel='noreferrer'>Cookies Declaration</a> and <a href={`/${PRIVACY_POLICY}`} target="_blank" rel='noreferrer'>Privacy Policy</a></b>.</p>


                        <div className="spHeading text-xxl">2. COMPETITION RULES</div>
                        <p><span className="number">2.1.</span> Entry to Competitions is open to entrants aged 18 years and over resident worldwide (unless you are a resident of a country subject to a UN, US or EU sanction regime, or you are personally subject to any such sanction) ("<b>Eligible Entrants</b>"). Oblivious' directors, management and employees (and their immediate families), its suppliers and service providers that are directly associated with the conduct of the Competitions are ineligible to enter the Competitions.</p>
                        <p><span className="number">2.2.</span> To enter a Competition, Eligible Entrants may post, upload, share, store or otherwise provide their code as a submission through the submission form set out on the Website ("<b>User Submission</b>") to a Competition accessible at the following link: <a href={`/${COMPETITIONS}`} rel='noreferrer'>https://www.antigranular.com/competitions</a> ("<b>Entry Mechanism</b>"). Your User Submission may be viewed or downloaded by other users. All code submitted in a User Submission is executed on our servers and must use the differential privacy frameworks specified for that Competition.</p>
                        <p><span className="number">2.3.</span> You are responsible for ensuring that your User Submission does not contain any personal data, or any malware, adware, spyware, Trojan, malicious code or any other form of code which is designed to execute any operation other than being a genuine entry to the relevant Competition.</p>
                        <p><span className="number">2.4.</span> Eligible Entrants are permitted to enter as many User Submissions per Competition as they choose. Eligible Entrants are permitted to collaborate and enter as a team, provided that each Eligible Entrant participating in the team confirms team membership when entering the competition via the Entry Mechanism and does not participate on more than one team for a particular Competition. Teams may also merge with each other, in which case the merged team will take on responsibility for all past entries of each constituent team.</p>
                        <p><span className="number">2.5.</span> Competitions will be hosted by Oblivious, and Oblivious is responsible for establishing the Competition rules applicable to each Competition. The Competition rules will set out guidelines in relation to participation in the Competition, the data set(s) to be used by Eligible Entrants, the selection criteria which Oblivious will use to select a winner of the Competition (the "<b>Criteria</b>"), the prize awarded to the winner, and when the prize will be awarded (the "<b>Competition Rules</b>"). The Criteria will assign scores to each Eligible Entrant which combines accuracy and the privacy budget consumed.</p>
                        <p><span className="number">2.6.</span> Entry to a Competition closes at the close time and date specified in the Competition Rules ("<b>Close Time and Date</b>").</p>
                        <p><span className="number">2.7.</span> Oblivious accepts no responsibility for late, lost or misdirected entries. Incomplete, ineligible or incomprehensible entries will be deemed invalid.  Entries must be submitted in the manner required and received by Oblivious. Entries received will be considered final by Oblivious. Late entries submitted after the Close Time and Date will not be accepted.</p>
                        <p><span className="number">2.8.</span> Eligible Entrants must only enter in their own name or on behalf of a company or other body corporate with which they are associated. Oblivious reserves the right to request the winner(s) to produce (within a nominated time period) appropriate photo identification or other documentation (to Oblivious' satisfaction, at its sole discretion) in order to confirm their identity, age, residency and/or eligibility to enter and/or claim a prize and any information submitted by the entrant in entering the Competition before issuing a prize. If the documentation required by Oblivious is not received by Oblivious (or its nominated agent) or the entrant has not been verified or validated to Oblivious' satisfaction within the time requested, that entrant’s entry will be invalid. Proof of identification, residency and entry considered suitable for verification is at the sole discretion of Oblivious. The prize(s) will only be awarded following any validation and verification of the entrant that Oblivious requires in its sole discretion. In the event that an entrant cannot provide suitable proof, the relevant prize will be forfeited and no substitute will be offered as the entrant will be disqualified.</p>
                        <p><span className="number">2.9.</span> Eligible Entrants may not use any automated means to enter a Competition, or enter into any Competition using bulk entries (unless expressly provided for in the Competition Rules). In this context, “automated means” includes any means which in the reasonable opinion of Oblivious gives you an unfair advantage in entering or participating in a Competition.</p>



                        <div className="spHeading text-xxl">3. PRIZE ELIGIBILITY</div>
                        <p><span className="number">3.1.</span> Oblivious will provide you with information about the prizes via the <a href="https://www.antigranular.com" rel='noreferrer'>Website</a>.</p>
                        <p><span className="number">3.2.</span> Where a team's entry is selected as the winning competition entry, the prize will be distributed equally among members of the team, unless each member of the team submits an identical request for the prize to be split unequally among team members.</p>
                        <p><span className="number">3.3.</span> The winner(s) will be notified via email.</p>
                        <p><span className="number">3.4.</span> The prize must be taken as offered and cannot be varied by the winner(s). Prizes are not transferable or exchangeable and cannot be taken as cash. Any change in the value of a prize between the advertising date and the date the prize is claimed is not the responsibility of Oblivious. Oblivious accepts no responsibility for any tax implications that may arise from the prize winnings. Independent financial advice should be sought. </p>
                        <p><span className="number">3.5.</span> Prizes will be delivered to winner(s) by bank transfer and only in euro within 28 days of winning. Oblivious and its agents associated with a Competition take no responsibility for a prize (or part of a prize) damaged or lost in transit (if relevant).</p>
                        <p><span className="number">3.6.</span> As a condition of accepting the prize, the winner (and his/her team member(s), if applicable) may be required to sign any legal documentation as and in the form required by Oblivious and/or prize suppliers in their absolute discretion, including but not limited to a legal release and indemnity form, indemnifying Oblivious from any legal claims related to the receipt or use of the prize.</p>
                        <p><span className="number">3.7.</span> Oblivious encourages the responsible use of prize(s), in accordance with applicable legislation.</p>
                        <p><span className="number">3.8.</span> If for any reason a prize, or any part of a prize becomes unavailable for any reason beyond Oblivious' reasonable control, Oblivious reserves the right to substitute the prize (or part thereof) for an alternative prize to the same or greater value of the original prize value, subject to applicable law.</p>
                        <p><span className="number">3.9.</span> Where applicable, Oblivious reserves the right to refuse to allow the prize winner (or any of their team members, if applicable) to take part in any or all aspects of the prize if Oblivious determines, in its absolute discretion, that the prize winner (or any of their team members, if applicable) are not in the mental or physical condition necessary to be able to safely participate in or use the prize.</p>
                        <p><span className="number">3.10.</span> No compensation will be payable if a winner (or their team members if applicable) are unable to use any element of a prize for whatever reason, including refusal of entry or ejection from the location(s) or participation in certain activities for health, age, behaviour or safety reasons.  Any part of a prize that is not taken for any reason is forfeited and will not be substituted.</p>
                        <p><span className="number">3.11.</span> Oblivious will make all reasonable efforts to contact the winner. If the winner cannot be contacted or is not available, or has not claimed their prize within 7 of days of the announcement of the winner, Oblivious may instead offer the prize to the next Eligible Entrant selected from the valid User Submissions that were received before the Closing Time and Date.</p>
                        <p><span className="number">3.12.</span> All prize awards are subject to the winner's compliance with the Competition Rules and with these Terms and Conditions, and are ultimately awarded at Oblivious' discretion.</p>




                        <div className="spHeading text-xxl">4. INTELLECTUAL PROPERTY RIGHTS</div>
                        <p><span className="number">4.1.</span> Your User Submission will be subject to the MIT No Attribution License, such that permission will be granted, free of charge, to any person obtaining a copy of your User Submission and associated documentation (the “<b>Software</b>”), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so.  For the avoidance of doubt, Oblivious may use your User Submission for the purposes of maintaining the Competition and other Eligible Entrants may incorporate all or part of your User Submission in their own User Submissions.</p>
                        <p><span className="number">4.2.</span> By submitting your User Submission, you thereby agree that all your intellectual property rights will be licensed to any third party via the MIT No Attribution License, and you thereby waive all moral rights in and to that User Submission which you may now or at any time in the future be entitled to under applicable intellectual property law in force anywhere in the world.</p>
                        <p><span className="number">4.3.</span> If necessary, you will take such steps and enter into such additional documents as Oblivious may request in order to give effect to this clause 4.</p>
                        <p><span className="number">4.4.</span> Oblivious retains all legal and beneficial ownership of all intellectual property rights in the Website and the Competitions, and you are granted a limited right to access the Website solely for the purpose of viewing and entering any Competition.</p>




                        <div className="spHeading text-xxl">5. OUR LIABILITY</div>
                        <p><span className="number">5.1.</span> Oblivious is not obliged to hold a Competition entry in confidence, or otherwise limit its activities based on a Competition entry.</p>
                        <p><span className="number">5.2.</span> Oblivious does not endorse any content submitted by Eligible Entrants.</p>
                        <p><span className="number">5.3.</span> When you participate in a Competition, you release Oblivious from claims, damages, losses, liabilities, costs, expenses or demands of all types, whether known or unknown, arising out of or related to any dispute, claim, suit, action or proceeding by a third party arising out of or relating to your use of the Competition, breach of these Terms and Conditions or the Website Terms and Conditions, or violation of law.</p>
                        <p><span className="number">5.4.</span> You access or submit all content at your own risk. You alone are responsible for any damage or loss to any party that may result from participating in a Competition.</p>
                        <p><span className="number">5.5.</span> Oblivious will have no liability for the actions or content of an Eligible Entrant.</p>
                        <p><span className="number">5.6.</span> Unless otherwise provided by mandatory law or to the extent that any statutory rights apply that cannot be excluded, limited or waived, Oblivious does not make any other warranties or representations of any kind, whether express, implied, statutory or otherwise regarding the Competitions.</p>
                        <p><span className="number">5.7.</span> Oblivious may, without any liability, remove or disqualify an Eligible Entrant, if it believes that it is in violation of these Terms and Conditions or otherwise poses a risk to the Website, Oblivious, its reputation or business, or the services provided by it.</p>
                        <p><span className="number">5.8.</span> Except for any liability that cannot be excluded by law, Oblivious (including its officers, employees and agents) excludes all liability (including negligence), for any personal injury; or any loss or damage (including loss of opportunity, loss of profit, loss of sales or market share by the undertaking, loss of data, damage of software or information or any other indirect, punitive, incidental, special, consequential, or exemplary damages); whether direct, indirect, special or consequential, arising in any way out of a Competition, including, but not limited to, where arising out of the following:</p>
                        <div className="bullets">
                            <div className="ms-4 d-flex">a) <div className="ms-2">any technical difficulties or equipment malfunction (whether or not under Oblivious' control);</div></div>
                            <div className="ms-4 d-flex">b) <div className="ms-2">any theft, unauthorised access or third party interference, including any harmful code (bugs, viruses);</div></div>
                            <div className="ms-4 d-flex">c) <div className="ms-2">any entry or prize claim that is late, lost, altered, damaged or misdirected (whether or not after their receipt by Oblivious) due to any reason beyond the reasonable control of Oblivious;</div></div>
                            <div className="ms-4 d-flex">d) <div className="ms-2">any variation in prize value to that stated in these Terms and Conditions or in the Competition Rules;</div></div>
                            <div className="ms-4 d-flex">e) <div className="ms-2">any tax liability incurred by a winner; or</div></div>
                            <div className="ms-4 d-flex">f) <div className="ms-2">use of a prize.</div></div>
                        </div>

                        <p><span className="number">5.9.</span> If a Competition is interfered with in any way or is not capable of being conducted as reasonably anticipated due to any reason beyond the reasonable control of Oblivious, including because of war, terrorism, state of emergency or disaster (including natural disaster), infection by computer virus, bugs, tampering, unauthorised intervention, technical failures or any which corrupt or affect the administration, security, fairness, integrity or proper conduct of a Competition, Oblivious reserves the right, in its sole discretion, to the fullest extent permitted by law </p>
                        <div className="bullets">
                            <div className="ms-4 d-flex">a) <div className="ms-2">to disqualify any Eligible Entrant; or</div></div>
                            <div className="ms-4 d-flex">b) <div className="ms-2">subject to any written directions from a regulatory authority, to modify, suspend, terminate or cancel the Competition, as appropriate.</div></div>
                        </div>
                        <p><span className="number">5.10.</span> Oblivious and its associated agencies and companies are not responsible for any problems, delays or technical malfunction of any telephone or network or lines, servers or providers, computer equipment, software, technical problems or traffic congestion on a network or a mobile network or any combination thereof, or any other technical failures including any damage to Eligible Entrants', or any other person's, computer or peripherals related to, or resulting from, participation in Competitions or the downloading of any materials related to a Competition.</p>



                        <div className="spHeading text-xxl">6. OUR RIGHTS</div>
                        <p><span className="number">6.1.</span> Oblivious does not analyse the User Submission before it is entered, but Oblivious retains the right to remove User Submissions that, in its opinion, violate the generally applicable law or the Terms and Conditions, or are otherwise in Oblivious' view not submitted in keeping with the spirit of the applicable Competition.</p>
                        <p><span className="number">6.2.</span> Oblivious, in its total discretion, reserves the right to cancel, suspend, or amend any Competition where it becomes necessary to do so for any reason. Any prizes awarded prior to such cancellation, suspension or amendment of a Competition shall be honoured, provided that these Terms and Conditions have been complied with.</p>
                        <p><span className="number">6.3.</span> Oblivious reserves the right to verify the validity of User Submissions and reserves the right to disqualify any Eligible Entrant where it has reasonable grounds to do so, including for tampering with the entry process or for submitting an entry which is not in accordance with these Terms and Conditions of entry, or if the Eligible Entrant is engaged in any unlawful or other improper misconduct calculated to jeopardise the fair and proper conduct of a Competition. Oblivious' legal rights to recover damages or other compensation from such an offender are reserved.</p>
                        <p><span className="number">6.4.</span> Oblivious will not be responsible for any incorrect, inaccurate or incomplete information communicated in the course of, or in connection with, a Competition if the deficiency is occasioned by any cause outside the reasonable control of Oblivious.</p>
                        <p><span className="number">6.5.</span> Any attempt to deliberately undermine the legitimate operation of a Competition may be a violation of criminal and civil laws and should such an attempt be made, whether successful or not, Oblivious reserves the right to seek damages to the fullest extent permitted by law. If Oblivious suffers loss or incurs any costs in connection with any breach of these conditions of entry or any other legal obligation by an entrant, the entrant agrees to indemnify Oblivious for those losses, damages and costs.</p>



                        <div className="spHeading text-xxl">7. DISPUTE RESOLUTION</div>
                        <p><span className="number">7.1.</span> Oblivious is not obliged to become involved in disputes in relation to a Competition between Eligible Entrants, or between Eligible Entrants and third parties.</p>
                        <p><span className="number">7.2.</span> If you wish to make a complaint in relation to a Competition, please contact <a href="mailto:competitions@oblivious.ai" target="_blank" rel='noreferrer'>competitions@oblivious.ai</a> and we will do our best to resolve any problems you may have.</p>



                        <div className="spHeading text-xxl">8. GOVERNING LAW</div>
                        <p><span className="number">8.1.</span> These Terms and Conditions are governed by the laws of the Republic of Ireland and the Irish courts shall have exclusive jurisdiction over any dispute or difference arising in connection with the Competitions or these Terms and Conditions.</p>



                        <div className="spHeading text-xxl">9. AMENDMENTS</div>
                        <p><span className="number">9.1.</span> Oblivious is entitled to change these Terms and Conditions at any time with effect for the future.</p>
                        <p><span className="number">9.2.</span> Oblivious will inform you about the planned change by updating the Website and in doing so will provide you with the date on which the change to these Terms and Conditions will apply. Notice to you may alternatively be provided through e-mail or other means.</p>
                        <p><span className="number">9.3.</span> If you do not agree with the presented changes, you may withdraw from current or future Competitions but this will not affect any previous User Submissions. By continuing to enter Competitions you agree that you are consenting to any such changes.</p>



                        <div className="spHeading text-xxl">10. MISCELLANEOUS</div>
                        <p><span className="number">10.1.</span> <b>Severability.</b>  In case any provision in these Terms and Conditions is invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby and such provision shall be ineffective only to the extent of such invalidity, illegality or unenforceability. The remaining provisions of these Terms and Conditions shall remain in full force.</p>
                        <p><span className="number">10.2.</span> <b>Assignment.</b>  Oblivious may transfer or assign its rights and obligations (in whole or in part) under these Terms and Conditions without your prior written consent, including licenses granted by you. You may not transfer or assign your rights and obligations (in whole or in part) under these Terms and Conditions without the prior written consent of Oblivious under pain of nullity.</p>
                        <p><span className="number">10.3.</span> <b>Entire Agreement.</b>  These Terms and Conditions together with our Website <a href="###" onClick={() => document?.getElementById('TNCPage')?.scrollIntoView({ behavior: "smooth" })}>Terms and Conditions</a>, our <a href={`/${COOKIE_DECLARATION}`} target="_blank" rel='noreferrer'>Cookies Declaration</a>, our <a href={`/${PRIVACY_POLICY}`} target="_blank" rel='noreferrer'>Privacy Policy</a> and any additional individual arrangements for the provision of Oblivious services constitute the entire and complete content of the agreement concluded between you and Oblivious. In the event of a conflict between these Terms and Conditions and a separate agreement, the latter will prevail, unless otherwise stated in the separate agreement.</p>
                        <p><span className="number">10.4.</span> <b>No partnership.</b>  Nothing in the Terms and Conditions shall be recognised as a partnership between the parties or authorise either party to act as an agent for the other or act in the name or on behalf.</p>
                    </div>
                </Tab>

                <Tab eventKey={"tabOxfordDPBootcampT&C"} title="Oxford DP Bootcamp T&C">
                    <div className="text-3xl mt-2">Oxford Differential Privacy Bootcamp Terms and Conditions</div>




                    <div className="ms-4 text-color-quinary text-sm static_page_content">
                        <div className="spHeading mt-4 text-xxl">1. SELECTED COMPETITION WINNERS</div>
                        <p><span className="number ">1.1</span> Selected competition winners, subject to these terms and conditions and their eligibility, may win a prize of a paid trip to attend the Differential Privacy Bootcamp at the University of Oxford, which takes place on 17-22 March 2024. Winners will be selected and announced by the Antigranular team after the competition ends.</p>
                        <p><span className="number ">1.2</span> The prize includes a paid trip to the United Kingdom, including accommodation, transportation, and additional activities as detailed in the events page on the Antigranular website. The details of additional activities are subject to change. Meal provisions, such as dinners, will be detailed in the email sent to the winners. However, Oblivious will not be providing out of pocket expenses or "pocket money".</p>
                        <p><span className="number ">1.3</span> Winners will be notified of their eligibility for the bootcamp via email. Winners must accept the prize within 7 days of notification, otherwise, they will forfeit their eligibility.</p>
                        <p><span className="number ">1.4</span> Oblivious bears no medical liability for any health-related issues that participants may encounter before, during, or after the trip. Participants are strongly advised to obtain comprehensive travel insurance covering health-related expenses.</p>
                        <p><span className="number ">1.5</span> Oblivious will not provide financial reimbursement for missed flights, regardless of the circumstances.</p>
                        <p><span className="number ">1.6</span> The trip is subject to the participant successfully obtaining a visa, having a valid passport and any other required travel documentation for entry into the United Kingdom. Oblivious will not be held responsible for any visa denials, delays, or refusal of entry into the United Kingdom. Participants are advised to start the visa application process well in advance of the trip.</p>
                        <p><span className="number ">1.7</span> It is the participant's responsibility to secure appropriate travel insurance to include at a minimum, covering medical emergencies, trip cancellation, and unforeseen eventualities. Oblivious is not liable for any financial losses or other losses borne by the participants pertaining to such circumstances.</p>
                        <p><span className="number ">1.8</span> The trip prize is strictly non-transferable to another person and cannot be redeemed for cash.</p>
                        <p><span className="number ">1.9</span> Oblivious takes no responsibility for any taxes, charges or any other costs incurred by the winner(s) in receipt of their prizes.</p>
                        <p><span className="number ">1.10</span> Oblivious reserves the right to make changes to the trip itinerary, including accommodations, transportation, and activities, due to unforeseen circumstances. Participants will be notified of any changes as soon as possible.</p>
                        <p><span className="number ">1.11</span> In the event a participant is unable to undertake the trip, Oblivious shall not be liable for any costs incurred, and the prize cannot be redeemed for cash or any other alternative.</p>
                        <p><span className="number ">1.12</span> Participants are expected to adhere to a high standard of behaviour during the trip. Oblivious reserves the right to terminate the trip for any participant who engages in behaviour deemed inappropriate or disruptive.</p>
                        <p><span className="number ">1.13</span> By attending the event, participants agree to allow Oblivious to use their name and likeness for promotional purposes without additional compensation.</p>
                        <p><span className="number ">1.14</span> By participating in the event and competition the participant (and on behalf of any dependents, family or agents representing the participant) agrees to hold Oblivious harmless from any and all claims, including inter alia, medical, financial, personal, force majeure, injury or death resulting from the participants' attendance or participation in the event or the competition.</p>
                        <p><span className="number ">1.15</span> By attending the event, participants indicate their understanding and acceptance of these terms and conditions. Oblivious reserves the right to amend these terms at any time and at their absolute discretion and will notify participants of any changes.</p>
                        <p><span className="number ">1.16</span> Family members' exclusion, age limit, and sanctioned countries' conditions mentioned in the full T&Cs apply. The governing law remains as stated in the full T&Cs.</p>
                    </div>


                    <div className="ms-4 text-color-quinary text-sm static_page_content">
                        <div className="spHeading text-xxl">2. GENERAL ATTENDEES</div>

                        <p><span className="number ">2.1</span> <b>Event Registration Fee:</b>  The fee covers entry to the event, access to all scheduled activities, and amenities as described in the event brochure. Any additional costs (e.g. flights, alternative accommodation, supplemental meals etc…) are not included unless explicitly stated.</p>
                        <p><span className="number ">2.2</span> <b>Refund Policy:</b>  </p>
                        <div className="bullets">
                            <div className="ms-4 d-flex">a) <div className="ms-2">Cancellations made within 7 days of booking will receive a full refund less a 5% administrative fee. This is applicable if there are at least 14 days remaining till the start of the event.</div></div>
                            <div className="ms-4 d-flex">b) <div className="ms-2">Cancellations made 14 days or more before the event start date will receive a 50% refund.</div></div>
                            <div className="ms-4 d-flex">c) <div className="ms-2">No refunds will be issued for cancellations made less than 14 days before the event start date.</div></div>
                            <div className="ms-4 d-flex">d) <div className="ms-2">In case the event is cancelled by the organisers, a full refund will be issued to all registrants.</div></div>
                        </div>

                        <p><span className="number ">2.3</span> <b>Event Cancellation or Postponement:</b>  The organisers reserve the right to cancel or postpone the event due to unforeseen circumstances. In the event of cancellation, registrants will receive a full refund. In the case of postponement, registrants will have the option to retain their registration for the new date or request a full refund.</p>
                        <p><span className="number ">2.4</span> <b>Insurance:</b>  Participants are encouraged to have personal insurance. The event organisers are not responsible for personal injuries or loss of/damage to private property of attendees.</p>
                        <p><span className="number ">2.5</span> <b>Liability Waiver:</b>  By registering for this event, participants agree to waive and release the event organisers, sponsors, and affiliates from any claims, demands, and damages in connection with the event.</p>
                        <p><span className="number ">2.6</span> <b>Photography and Filming:</b>  Participants consent to be photographed and filmed during the event. The organisers reserve the right to use such material for promotional purposes. Participants who wish to opt out should notify the organisers in writing in advance.</p>
                        <p><span className="number ">2.7</span> <b>Code of Conduct:</b>  All participants are expected to behave in a professional and respectful manner. The organisers reserve the right to remove any attendee not adhering to this code, without refund.</p>
                        <p><span className="number ">2.8</span> <b>Alterations to the Program:</b>  The event organisers reserve the right to make alterations to the event schedule or program as necessary.</p>
                        <p><span className="number ">2.9</span> <b>Personal Data:</b>  Participant’s personal data will be used in accordance with our Privacy Policy and as in accordance with our T&Cs.</p>
                        <p><span className="number ">2.10</span> <b>No Reselling:</b>  Strictly no-reselling of tickets. Tickets are non-transferable unless approved by the organisers at their sole discretion.</p>
                        <p><span className="number ">2.11</span> <b>Special Access or Requirements:</b>  You are responsible for advising us at the time of booking of any special access requirements you may have for the event. Please also advise of any dietary requirements or other special requirements you may have.</p>
                        <p><span className="number ">2.12</span> <b>Visa or Travel Requirements:</b>  The trip is subject to the participant successfully obtaining a visa, having a valid passport and any other required travel documentation for entry into the United Kingdom. Oblivious will not be held responsible for any visa denials, delays, or refusal of entry into the United Kingdom. Participants are advised to start the visa application process well in advance of the trip.</p>
                        <p><span className="number ">2.13</span> Unless otherwise stated all of the other Oblivious T&Cs apply.</p>
                    </div>


                </Tab>
            </Tabs>
            <div className="mt-5 text-sm text-color-secondary">
                In case of any questions on these Terms and Conditions or any complaint
                regarding provided Oblivious services, you should contact us
                at <a className="link-ag-1" href="mailto:support@oblivious.ai" target="_blank" rel='noreferrer'>support@oblivious.ai</a>, or
                in writing at Oblivious AI, Belfield Innovation Park, Dublin 18, Ireland.
            </div>

        </div>
    );
}
