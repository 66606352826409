/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
// import { useLocation } from "react-router-dom";

// import: assets
import {
    faArrowLeft,
    faSpinner,
    // faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
// import { faGoogle, faGithub } from "@fortawesome/free-brands-svg-icons";
import googleIcon from "../../assets/logos/google.png";
import gitIcon from "../../assets/logos/github.png";
import mail from "../../assets/logos/mail.png";
import check from "../../assets/icons/check.png";

// import: styles
import "./index.scss";

// import: constants
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../../router/routes";
// import: enums
import { AuthProvider } from "../../enums/auth-provider";

// import: types
import { IJwtUser } from "../../types/user-type";

// import: utils
import { HandleError } from "../../errors/handler";
import { validateEmail } from "../../utils/helper-methods";
import { executeV3Captcha } from "../../utils/captcha-methods";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    registerWithEmail,
    setRegistrationModalVisible,
    setForgotPasswordModalVisible,
    showLoginModal,
} from "../../store/slices/auth-slice";

// import: api
// import: config
import { GIT_AUTH_URL, GOOGLE_AUTH_URL } from "../../configs/constants";

// import: components
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../input/input";
import LoadingSpinner from "../loading-spinner";


export default function RegisterModal() {
    // const location = useLocation();
    const dispatch = useAppDispatch();
    const show = useAppSelector((s) => s.auth.showRegistrationModal);
    const [selectedMedium, setSelectedMedium] = useState<AuthProvider | null>(
        null
    );
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setSelectedMedium(null);

        // if(show === true){
        //     localStorage.removeItem( "DS_WLC");
        //     localStorage.removeItem( "NB_WLC");
        //     localStorage.removeItem( "COMP_WLC");
        // }
    }, [show]);

    const onMediumSelect = (provider: AuthProvider) => {
        if (provider === AuthProvider.Github) {
            // localStorage.setItem("ROUT_TO_PATH", window.location.pathname);
            setLoading(true);
            // console.log(GIT_AUTH_URL);
            window.location.href = GIT_AUTH_URL;
        } else if (provider === AuthProvider.Google) {
            // localStorage.setItem("ROUT_TO_PATH", window.location.pathname);
            setLoading(true);
            // console.log(GIT_AUTH_URL);
            window.location.href = GOOGLE_AUTH_URL;
        } else {
            setSelectedMedium(provider);
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => dispatch(setRegistrationModalVisible(false))}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header className="border-0" closeButton>
                {selectedMedium ? (
                    <FontAwesomeIcon
                        className="cursor-pointer me-2"
                        icon={faArrowLeft}
                        onClick={() => setSelectedMedium(null)}
                        size="sm"
                    />
                ) : undefined}
                <div>Join Antigranular</div>
            </Modal.Header>
            <Modal.Body className="login-modal-form">
                {loading ? (
                    <div className="text-center">
                        <LoadingSpinner inLine show={loading} />
                        <p className="mt-4"> Redirecting to authentication page </p>
                    </div>
                ) : (
                    <>
                        <RegistrationWithEmail
                            hidden={selectedMedium !== AuthProvider.Email}
                        />

                        <RegisterMediums
                            hidden={selectedMedium ? true : false}
                            onSelect={onMediumSelect}
                        />
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
}

/**
 * Registeration mediums
 */
type TRegisterMediumProps = {
    onSelect: (provider: AuthProvider) => void;
    hidden: boolean;
};

function RegisterMediums(props: TRegisterMediumProps) {
    const dispatch = useAppDispatch();

    return (
        <div className={`text-color-secondary appear ${props.hidden ? "d-none" : "d-block"}`}>
            <p className="text-xs mb-4 text-center">Sign up with</p>
            <div className="form-external-brand-links">
                <div className="me-4">
                    <div className="brand-image-cover">
                        <img alt="" className="feature-icon" src={googleIcon}
                            onClick={() => props.onSelect(AuthProvider.Google)}
                        />
                    </div>
                    <p className="mt-2 mb-0 text-xs">Google</p>
                </div>
                <div className="me-4">
                    <div className="brand-image-cover">
                        <img alt="" className="feature-icon" src={gitIcon}
                            onClick={() => props.onSelect(AuthProvider.Github)}
                        />
                    </div>
                    <p className="mt-2 mb-0 text-xs">Github</p>
                </div>
                <div>
                    <div className="brand-image-cover">
                        <img alt="" className="feature-icon" src={mail}
                            onClick={() => props.onSelect(AuthProvider.Email)}
                        />
                    </div>
                    <p className="mt-2 mb-0 text-xs">Email</p>
                </div>
            </div>

            <p className="mt-4 pt-3 mb-1 text-xs text-center text-color-secondary">
                Forgot your password?
                <a href="#" className="actions-link"
                    onClick={() => {
                        dispatch(setRegistrationModalVisible(false));
                        setTimeout(() => {
                            dispatch(setForgotPasswordModalVisible(true));
                        }, 200);
                    }}

                >Recover</a>
            </p>

            <p className="text-xs text-center text-color-quinary mt-4">
                By signing up with Google or Github, I agree to Oblivious{' '}
                <a href={`/${TERMS_AND_CONDITIONS}`} className="me-half ms-half link-ag-1">Terms and Conditions</a>{' '}and{' '}
                <a href={`/${PRIVACY_POLICY}`} className="ms-half link-ag-1">Privacy Policy</a>.
            </p>
        </div>
    );
}

/**Registration Form  */
type TRegistrationFormProps = {
    hidden: boolean;
    onSuccess?: (login: IJwtUser) => void;
};

function RegistrationWithEmail(props: TRegistrationFormProps) {
    const dispatch = useAppDispatch();
    const [formErrors, setFormErrors] = useState<any>({});
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [userConfirmation, setUserConfirmation] = useState<boolean>(false);
    const emailInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        reset();
        emailInputRef?.current?.focus();
    }, [props.hidden]);

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            if (validateForm()) {
                setLoading(true);
                let refCode = localStorage.getItem("AG_REFERRAL") || "";

                const token = await executeV3Captcha("register");
                const response = await dispatch(
                    registerWithEmail({
                        email,
                        password,
                        fullName,
                        referralCode: refCode,
                        captcha: token,
                    })
                ).unwrap();
                reset();
                props.onSuccess?.(response);
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const validateForm = () => {
        setFormErrors({});
        let errors: any = {};
        if (!validateEmail(email)) {
            errors.email = "Please enter valid email address.";
        }
        if (!password) {
            errors.password = "Please enter password.";
        }
        if (!confirmPassword) {
            errors.confirmPassword = "Please confirm password.";
        }
        if (confirmPassword !== password) {
            errors.confirmPassword = "Passwords does not match.";
        }
        if (password && password.length < 6) {
            errors.password = "Password should be at least 6 charater long.";
        }

        if (!fullName) {
            errors.fullName = "Please enter full name.";
        }

        if (!userConfirmation) {
            errors.agreement = "Please agree to Terms and Conditions and Privacy Policy.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const reset = () => {
        setFormErrors({});
        setEmail("");
        setPassword("");
        setUserConfirmation(false);
        setConfirmPassword("");
        setFullName("");
        setLoading(false);
    };

    return (
        <>
            <Form
                noValidate
                onSubmit={submit}
                className={`${props.hidden ? "d-none" : "d-block"} appear`}
            >
                <fieldset disabled={loading}>
                    <div className="form-input-fields text-xs text-color-secondary">
                        <Form.Group>
                            <Input
                                ref={emailInputRef}
                                autoFocus
                                autoComplete="off"
                                value={email}
                                label="Email"
                                placeholder="Your email"
                                maxLength={100}
                                onChange={(e) => {
                                    if (formErrors["email"]) {
                                        delete formErrors?.email;
                                        setFormErrors(formErrors);
                                    }
                                    setEmail(e.target.value);
                                }}
                                error={formErrors["email"]}
                            />
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <Input
                                autoComplete="new-password"
                                label="Password"
                                placeholder="Create a password"
                                type="password"
                                value={password}
                                error={formErrors.password}
                                onChange={(e) => {
                                    if (formErrors["password"]) {
                                        delete formErrors.password;
                                        setFormErrors(formErrors);
                                    }
                                    setPassword(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <Input
                                autoComplete=""
                                label="Confirm Password"
                                placeholder="Confirm password"
                                type="password"
                                value={confirmPassword}
                                error={formErrors.confirmPassword}
                                onChange={(e) => {
                                    if (formErrors["confirmPassword"]) {
                                        delete formErrors.confirmPassword;
                                        setFormErrors(formErrors);
                                    }
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <Input
                                autoComplete="off"
                                value={fullName}
                                label="Name"
                                placeholder="Full Name"
                                maxLength={100}
                                onChange={(e) => {
                                    if (formErrors["fullName"]) {
                                        delete formErrors?.fullName;
                                        setFormErrors(formErrors);
                                    }
                                    setFullName(e.target.value);
                                }}
                                error={formErrors["fullName"]}
                            />
                        </Form.Group>
                        <div className="text-xs d-flex text-color-quinary mt-4 mb-0">
                            <div className="custom-radio me-2" onClick={() => {
                                setUserConfirmation(!userConfirmation);
                                if (formErrors["agreement"]) {
                                    delete formErrors?.agreement;
                                    setFormErrors(formErrors);
                                }
                            }}>
                                <img src={check} alt="" className={` ${userConfirmation ? "agree" : "disagree"}`} />
                            </div>
                            <p className="mb-0">I agree to Oblivious{' '}
                                <a href={`/${TERMS_AND_CONDITIONS}`} className="ms-1 me-1 link-ag-1">Terms and conditions</a>{' '}and{' '}
                                <a href={`/${PRIVACY_POLICY}`} className="ms-1 link-ag-1">Privacy Policy</a>.
                            </p>
                        </div>

                        <Form.Group className={formErrors[""] ? "mt-4" : "mt-2"}>
                            {formErrors[""] ? (
                                <label className="text-danger">
                                    {formErrors[""]}
                                </label>
                            ) : undefined}

                            {formErrors["captcha"] ? (
                                <label className="text-danger">
                                    {formErrors["captcha"]}
                                </label>
                            ) : undefined}

                            {formErrors["agreement"] ? (
                                <label className="text-danger">
                                    {formErrors["agreement"]}
                                </label>
                            ) : undefined}
                        </Form.Group>

                    </div>
                    <Button type="submit" variant="primary" className="rounded-pill mx-auto">
                        {loading ? (
                            <>
                                <FontAwesomeIcon spin className="me-2" icon={faSpinner} /> Please
                                wait
                            </>
                        ) : (
                            "Join"
                        )}
                    </Button>
                </fieldset>

                <p className="text-xs text-center text-color-secondary mt-4">
                    Forgot your password?
                    <a href="#" className="actions-link"
                        onClick={() => {
                            dispatch(setRegistrationModalVisible(false));
                            setTimeout(() => {
                                dispatch(setForgotPasswordModalVisible(true));
                            }, 200);
                        }}
                    >Recover</a>
                </p>
                <p className="text-xs text-center text-color-secondary mb-0">
                    Already have an account?
                    <a href="#" className="actions-link"
                        onClick={() => {
                            dispatch(setRegistrationModalVisible(false));
                            setTimeout(() => {
                                dispatch(showLoginModal());
                            }, 200);
                        }}
                    >Sing In</a>
                </p>
            </Form>
        </>
    );
}
