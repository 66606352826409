import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth-slice";
import modalStates from "./slices/modal-states-slice";
import dataset from "./slices/dataset-slice";
import notebook from "./slices/notebook-slice";
import sharedStates from "./slices/shared-states-slice";
import competition from "./slices/competition-slice";
import organization from "./slices/organization-slice";
import blog from "./slices/blog-slice";
export const store = configureStore({
    reducer: {
        auth,
        dataset,
        modalStates,
        sharedStates,
        notebook,
        competition,
        organization,
        blog
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch