import { IStarItem } from "../types/common-types";
import {
    ICreateDatasetPayload,
    IDatasetsData,
    IDataset,
    IUpdateDatasetPayload,
    IFetchDatasetPayload,
    IDatasetChunkedFileUploadProps
} from "../types/dataset-types";
import { BaseApi } from "./base-api";

export default class DatasetApi extends BaseApi {
    private static url = (action: string) => "dataset/" + action;

    /**
     * Create new dataset
     * @returns
     */
    public static async create(data: ICreateDatasetPayload): Promise<IDataset> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._post(this.url("create"), fd);
    }

    /**
     * star dataset
     * @returns
     */
    public static async star(data: IStarItem): Promise<boolean> {
        return this._post(this.url("star"), data);
    }

    /**
     * Get all datasets
     * @returns
     */
    public static async getAll(
        data: IFetchDatasetPayload
    ): Promise<IDatasetsData> {
        return this._get(this.url("getall"), data);
    }

    /**
     * Get dataset details
     * @param id dataset id
     * @returns
     */
    public static async get(id: string): Promise<IDataset> {
        return this._get(`${this.url("get")}/${id}`);
    }

    /**
     * Update dataset
     * @param data
     * @returns
     */
    public static async update(data: IUpdateDatasetPayload): Promise<IDataset> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._patch(this.url("update"), fd);
    }


    /**
     * Upload chunked files
     * @param data
     * @returns
     */
    public static async UploadFileChunks(data: IDatasetChunkedFileUploadProps): Promise<void> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key, (data as any)[key]
                );
            }
        }
        return this._post(this.url("uploadDataFile"), fd);
    }

    public static async delete(id: string): Promise<void> {
        return this._delete(`${this.url("delete")}/${id}`);
    }
}
