export enum PrizeType {
    None = "None",
    Monetary = "Monetary",
    Knowledge = "Knowledge",
    Other = "Other",
}

export enum CompetitionMembershipType {
    INDIVIDUAL = "INDIVIDUAL",
    TEAM = "TEAM",
}


export enum EditAccessType {
    admin = "admin",
    participant = "participant",
    creator = "creator",
}