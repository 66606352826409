import { useLocation } from "react-router-dom";
import { ErrorType } from "../../enums/error";
import Navbar from "../intro-page/header";
import errorBanner from "../../assets/images/error-banner.png";
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { ROOT } from "../../router/routes";

type TState = {
    errorType: ErrorType;
};
export default function ErrorPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as TState;
    return (
        <div className="d-flex flex-column justify-content-start align-items-start w-100 h-100 position-relative">
            <Navbar minNav />
            <div className="error-dialogue-container glazed-card-cover appear">
                <div className="error-dialogue glazed-card">
                    <div className="pe-3 me-3 d-flex flex-column w-100">
                        <p className="d-flex mb-2 text-3xl vertical-glaze-text">Ooops!</p>
                        <p className="mb-2 text-lg">You've taken the wrong turn</p>
                        <p className="text-color-secondary text-xs mb-5">
                            The page you tried to access could not be found.
                            The safest thing to do is to go back and try again. Good luck!
                        </p>

                        {/* <div className="text-xs text-color-tertiary mb-4">
                            {ErrorType[state?.errorType]}
                        </div> */}
                        {/* <Link to={"/"}>Back To Home</Link> */}
                        <Button type="button" size='sm' variant="primary"
                            onClick={() => navigate(ROOT)} className="rounded-pill w-fit">
                            Go Home
                        </Button>
                    </div>

                    <div className="image-container">
                        {/* <img src={errorBanner} alt="" className="w-100"/> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
