// import MathPlugin from "remark-math";
// import TeX from "@matejmazur/react-katex";
// import Highlighter from "react-syntax-highlighter";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import vscDarkPlus from "./vsc-dark-plus";
import "./nbviewer/index.css";
import "./index.scss";
import NbViewer from "./nbviewer";


const DarkHighlighter = (props: any) => {
    return (
        <SyntaxHighlighter
            {...props}
            style={vscDarkPlus}
            language="python"
        />
    );
};

type TNotebookViewProps = {
    source: any;
    className?: string;
};


function NotebookView(
    props: TNotebookViewProps = { source: {}, className: "" }
) {
    return (
        <div className={`notebookView text-sm ${props.className}`}>
            <NbViewer
                source={props.source}
                code={DarkHighlighter}
            />
        </div>
    );
}
export default NotebookView;