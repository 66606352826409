// import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRef } from "react";

// import: assets
import { faDownload } from "@fortawesome/free-solid-svg-icons";

// import: styles
import "./index.scss";
import "../auth-modals/index.scss";

// import: constants
import { COMPETITIONS } from "../../router/routes";

// import: enums
import { AccomplishmentType } from "../../enums/accomplishment-type";

// import: types
import { IAccomplishment } from "../../types/accomplishment-types";

// import: utils
import { convertDate } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppSelector } from "../../store/store-hooks";

// import: api
// import: config
// import: components
import Button from "react-bootstrap/Button";
import Badge from "../badge";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";

type CongratulationsModalProps = {
    visible: boolean;
    user_id?: string;
    accomplishment: IAccomplishment;
    onHide: () => void;
};

export default function CongratulationsModal(props: CongratulationsModalProps) {
    const user = useAppSelector((s) => s.auth.user);
    const navigate = useNavigate();
    const exportRef = useRef<HTMLDivElement>(null);

    const openLeaderboard = () => {
        navigate(`/${COMPETITIONS}/` + (props.accomplishment.data.competition_slug ?? props.accomplishment.data.competition_id),
            {
                state: {
                    leaderboard: true
                }
            }
        )
    }

    const getLink = () => {
        let link = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${props.accomplishment.type === AccomplishmentType.CompetitionBadge ? (props.accomplishment.data.competition_name?.replace(" ", "%20") + "%20|%20Rank%20:%20" + props.accomplishment.data.rank)
            : "Antigranular%20Bug%20Bounty"}&organizationId=38143046&issueYear=${props.accomplishment.date.getUTCFullYear()}&issueMonth=${props.accomplishment.date.getUTCMonth() + 1}&certUrl=https%3A%2F%2Fantigranular.com${props.user_id ? `%2Fprofile%2F${props.user_id}%2Fbadge%2F${props.accomplishment._id}` : ""}&certId=${props.accomplishment.type === AccomplishmentType.CompetitionBadge ? props.accomplishment.data.submission_sha : "-"}`
        return link
    }

    const getRankmessage = () => {
        if (props.accomplishment.type === AccomplishmentType.BugBounty) {
            return "You won the Bug Bounty badge!"
        }
        else if (props.accomplishment.type === AccomplishmentType.CompetitionBadge) {
            let rankString = "";
            switch (props.accomplishment.data.rank) {
                case 1:
                    rankString = "You won the First Place on the ";
                    break;
                case 2:
                    rankString = "You won the Second Place on the ";
                    break;
                case 3:
                    rankString = "You won the third Place on the ";
                    break;
                default:
                    rankString = "You have participated on the ";
            }
            rankString = rankString + props.accomplishment.data.competition_name + " competition"

            return rankString
        }
    }

    const downloadBadge = async (imageFileName: string) => {
        const element = document.getElementById("user-badge");
        if (element) {
            let child = element.querySelector(".card3D") as HTMLDivElement;
            child.style.transform = "rotateX(0deg) rotateY(0deg)";
            let grandChild = child.querySelector(".content3D") as HTMLDivElement;
            grandChild.classList.add("badge-card-lighter");
            grandChild.style.transform = "translateZ(0px) translateX(0px) translateY(0px)";

            const canvas = await html2canvas(element, { backgroundColor: null, scale: 1.8 });
            const image = canvas.toDataURL("image/png", 1.0);

            // element.style.background = "black";
            // let child = element.children[0] as HTMLDivElement;
            // child.style.transform = "rotateY(-25deg)";

            downloadImage(image, imageFileName);
        }
    };


    const downloadImage = (blob: any, fileName: string) => {
        const fakeLink = window.document.createElement("a");
        // fakeLink.style = "display:none;";
        fakeLink.setAttribute("style", "display:none;");
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();

        const element = document.getElementById("user-badge");

        if (element) {
            let child = element.querySelector(".card3D") as HTMLDivElement;
            child.style.transform = "perspective(1000px) rotateX(0deg) rotateY(-25deg) scale3d(1, 1, 1)";
            let grandChild = child.querySelector(".content3D") as HTMLDivElement;
            grandChild.classList.remove("badge-card-lighter");
            grandChild.style.transform = "translateZ(calc(var(--h) * 0.15px)) translateX(-15px) translateY(-25px) perspective(1000px) scale3d(1, 1, 1)";

            // element.style.background = "transparent";
            // let child = element.children[0] as HTMLDivElement;
            // child.style.transform = "";
        }
    };

    const getBadgeDetails = () => {
        if (props.accomplishment.type === AccomplishmentType.CompetitionBadge) {
            return (
                <div className="d-flex flex-row flex-md-column flex-wrap w-100">
                    <div className="">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">SERIAL NUMBER</p>
                        <span className="text-sm text-thick">{props.accomplishment.data.user_df || "-"}</span>
                    </div>

                    <div className="px-4 px-md-0">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">SCORE</p>
                        <span className="text-sm text-thick">
                            {
                                props.accomplishment.data.validation ?
                                    parseFloat(props.accomplishment.data.validation.toFixed(4)) : "-"
                            }
                        </span>
                    </div>

                    <div className="">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">EPSILON</p>
                        <span className="text-sm text-thick">
                            {
                                props.accomplishment.data.eps ?
                                    parseFloat(props.accomplishment.data.eps.toFixed(4)) : "-"
                            }
                        </span>
                    </div>

                    <div className="ps-4 ps-md-0">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">DELTA</p>
                        <span className="text-sm text-thick">
                            {
                                props.accomplishment.data.delta ?
                                    parseFloat(props.accomplishment.data.delta.toFixed(4)) : "-"
                            }
                        </span>
                    </div>
                </div>
            )
        } else if (props.accomplishment.type === AccomplishmentType.BugBounty) {
            return (
                <div className="d-flex flex-row flex-md-column flex-wrap w-100">
                    <div className="">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">DATE SUBMITTED</p>
                        <span className="text-sm text-thick">
                            {convertDate(props.accomplishment.data.submissionDate?.toUTCString())}
                        </span>
                    </div>

                    {/* <div className="px-4 px-md-0">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">DATE SUBMITTED</p>
                        <span className="text-sm text-thick">
                            {convertDate(props.accomplishment.date.toUTCString())}
                        </span>
                    </div> */}

                    <div className="">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">BUG TYPE</p>
                        <span className="text-sm text-thick">{props.accomplishment.data.bountyType || "-"}</span>
                    </div>

                    {/* <div className="ps-4 ps-md-0">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">DELTA</p>
                        <span className="text-sm text-thick">
                            {
                                props.accomplishment.data.delta ?
                                parseFloat(props.accomplishment.data.delta.toFixed(4)) : "-"
                            }
                        </span>
                    </div> */}
                </div>
            )
        } else if (props.accomplishment.type === AccomplishmentType.OutstandingNotebook) {
            return (
                <div className="d-flex flex-row flex-md-column flex-wrap w-100">
                    <div className="">
                        <p className="mb-0 text-color-tertiary text-xs pt-2 mt-1">DATE SUBMITTED</p>
                        <span className="text-sm text-thick">
                            {convertDate(props.accomplishment.data.submissionDate?.toUTCString())}
                        </span>
                    </div>
                </div>
            )
        }
    }

    return (
        <Modal dialogClassName="login-modal congrats-modal" show={props.visible} centered onHide={() => props.onHide()}>
            <Modal.Header className="border-0 p-3" closeButton>
                <p className="text-xs mb-0">Achievement</p>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center p-md-4 p-3 pt-0 pt-md-0">
                <p className="text-xl mb-2">Congratulations!</p>
                <p className="text-xs py-1 mb-2 text-color-secondary">
                    {getRankmessage()}
                </p>
                <div className="d-flex flex-column flex-md-row">
                    <div ref={exportRef} className="">
                        <Badge submission={props.accomplishment} animate id="user-badge" className="" />
                    </div>
                    <div className="pt-2 pt-md-0 pb-md-0 mb-md-0 pb-2 mb-2 w-100 mt-md-5 pe-md-5 me-md-2">
                        <p className="mb-0 text-sm text-thick text-center text-md-start">Badge information</p>

                        {getBadgeDetails()}
                    </div>
                </div>

                <p className="text-xs text-color-secondary mb-0">
                    Balancing accuracy and privacy in eyes-off data science is not
                    easy but you rocked it with your mad skills! Now it's time to
                    download your certificate and show off your achievement to your network on LinkedIn.
                </p>

                <div className="d-flex me-auto">
                    {user && user._id === props.user_id ? <Button variant="primary" className="rounded-pill mt-3 me-auto">
                        <a className="button-link" href={getLink()} target="_blank" rel='noreferrer'>
                            Add to LinkedIn
                        </a>
                    </Button> : <div className="mt-4 pt-4"></div>}

                    {user && user._id === props.user_id ?
                        <Button variant="secondary" className="rounded-pill mt-3 ms-3" onClick={() => downloadBadge("Badge_" + props.accomplishment.data?.competition_name?.replace(" ", "_"))}>
                            <FontAwesomeIcon className="me-2" icon={faDownload} />Download
                        </Button> :
                        <div className="mt-4 pt-4"></div>
                    }
                </div>

                <div className="link mt-2 pt-1 text-sm text-thick ms-auto" onClick={(e) => { e.preventDefault(); e.stopPropagation(); openLeaderboard() }}>
                    Check Leaderboard
                    <svg width="10" height="10" className="link-icon" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.833008 9.16671L9.16634 0.833374M9.16634 0.833374H0.833008M9.16634 0.833374V9.16671" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </Modal.Body>
        </Modal>
    );
}
