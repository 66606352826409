export const ROOT = '/';

export const CREATE = 'create';
export const EDIT = 'edit';

export const DATASETS = 'datasets';
export const DATASET_ID = '_id';

export const NOTEBOOKS = 'notebooks';
export const NOTEBOOK_ID = '_id';

export const PROFILE = 'profile';
export const USER_ID = '_userId';
export const BADGE = 'badge';
export const BADGE_ID = '_badgeId';

export const SETTINGS = 'settings';

export const COMPETITIONS = 'competitions';
export const COMPETITION_ID = '_id';

export const ORGANISATIONS = 'organizations';
export const ORGANISATION_ID = '_id';

export const BLOG = 'blog';
export const BLOG_ID = '_id';

export const EVENTS = 'events';
export const PRIVACY_POLICY = 'privacy_policy';
export const COOKIE_DECLARATION = 'cookie_declaration';
export const TERMS_AND_CONDITIONS = 'terms_and_conditions';
export const ABOUT_US = 'about_us';

export const SIGN_IN = 'signin';
export const PROVIDER = 'provider';

export const ERROR = 'error';

export const DISCORD = 'https://discord.com/invite/KJwApgXs4s';
