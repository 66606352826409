import "./index.scss";
import Modal from "react-bootstrap/Modal";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { setVerificationModalVisible } from "../../store/slices/auth-slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Button from "react-bootstrap/Button";
import AuthApi from "../../api/auth-api";
import { useState, useEffect } from "react";
import { HandleError } from "../../errors/handler";

export default function AccountVerificationModal() {
    const dispatch = useAppDispatch();
    const show = useAppSelector((s) => s.auth.showVerificationModal);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMessage] = useState<string>("");
    const [codeSent, setCodeSent] = useState<boolean>(false);

    const resendEmail = async () => {
        try {
            setLoading(true);
            await AuthApi.sendVerificationLink();
            setCodeSent(true);
        } catch (error) {
            setErrorMessage(HandleError(error)[""]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(codeSent){
            const timer = setTimeout(() => {
                setCodeSent(false);
            }, 3000)
        }
    }, [codeSent])

    const onHide = () => {
        dispatch(setVerificationModalVisible(false));
    };
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <div className="text-3xl mb-3">
                    Welcome!
                </div>
            </Modal.Header>
            <Modal.Body className="text-center">
                <FontAwesomeIcon size="3x" icon={faCheckCircle} className="text-success"/>
                <p className="mt-4 mb-4 text-lg">
                    Thank you for joining us. Please verify your email address to
                    activate your account.
                </p>
                <p className="text-sm text-color-secondary">
                    Please check your inbox, we have sent you an email with
                    verification link.
                </p>

                {codeSent ? (
                    <div className="text-success text-thick mt-5">
                        Email sent successfully.
                    </div>
                ) : (
                    <>
                        {loading ? (
                            <div className="text-color-tertiary mt-5">
                                Sending link
                            </div>
                        ) : (
                            <div className="mt-5">
                                Not received email?{" "}
                                <span
                                    className="cursor-pointer selected-link text-base ms-1"
                                    onClick={() => resendEmail()}
                                >
                                    Resend Link
                                </span>
                            </div>
                        )}
                    </>
                )}

                {errorMsg ? (
                    <p className="m-0 text-invalid-feedback">{errorMsg}</p>
                ) : undefined}
                <div>
                    {" "}
                    <Button onClick={() => onHide()} className="mt-5 mx-auto rounded-pill">
                        Okay
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
