import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import: assets
import maximize from "../../assets/icons/maximize.png";
import minimize from "../../assets/icons/minimize.png";
import trophy from "../../assets/images/list_item_placeholder.png";

// import: styles
import "./index.scss";

// import: constants
import { DATASET_ID, DATASETS, EDIT, ERROR, NOTEBOOKS } from "../../router/routes";
// import: enums
import { UserRole } from "../../enums/roles";

// import: types
import { INotebook } from "../../types/notebook-types";
import { IUserAuth0Creds } from "../../types/auth-types";

// import: utils
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { deleteDataset, loadDatasetInfoBySlug, setDatasetInfo } from "../../store/slices/dataset-slice";

// import: api
import NotebookApi from "../../api/notebook-api";
import AuthApi from "../../api/auth-api";

// import: config
import { DATASET_IMG_URL } from "../../configs/api-config";

// import: components
import Table from 'react-bootstrap/Table';
import LoadingSpinner from "../../components/loading-spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import ChunkedFileUploader from "../../components/input/chunked-file-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import CustomAccordian from "../../components/custom-accordian";
import NotebookListItem from "../../components/list-items/notebook-list-item";
import ErrorAlert from "../../components/error-alert";
import CodeBlock from "../../components/code-block";
import ReactPaginate from 'react-paginate';
import CategoryIcons from "../../components/category-icons";


export default function DatasetInfoPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const location = useLocation();

    const user = useAppSelector((s) => s.auth.user);

    const { [DATASET_ID]: _slug } = useParams();
    const [loading, setLoading] = useState<boolean>(true);

    const datasets = useAppSelector((s) => s.dataset.pageData.data);
    const datasetInfo = useAppSelector((store) => store.dataset.datasetInfo);
    const dataset = useMemo(() => {
        const filteredDatasets = datasets.filter((d) => d.slug === _slug || d._id === _slug);
        if (!datasetInfo && filteredDatasets.length > 0) return filteredDatasets[0];
        return datasetInfo;
    }, [_slug, datasets, datasetInfo]);

    const [metadata, setMetadata] = useState<any>();

    const [loadingNotebooks, setLoadingNotebooks] = useState<boolean>(false);
    const [loadingMyNotebooks, setLoadingMyNotebooks] =
        useState<boolean>(false);
    // const [changingStarStatus, setChangingStarStatus] = useState<boolean>(false);
    const [error, setError] = useState("");

    const [notebooks, setNotebooks] = useState<INotebook[]>([]);
    const [myNotebooks, setMyNotebooks] = useState<INotebook[]>([]);
    const [noImage, setNoImage] = useState<boolean>(false);
    const [maximized, setMaximized] = useState(false);

    const pageSize = parseInt(
        process.env.React_APP_NOTEBOOKS_DATA_LENGTH || "20"
    );
    const [nbPage, setNbPage] = useState<number>();
    const [nbCount, setNbCount] = useState<number>();

    const [authCreds, setAuthCreds] = useState<IUserAuth0Creds>();

    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const datasetCode = `import antigranular as ag

session = ag.login(
    "********", "************",
    dataset = "${dataset ? dataset.title : "dataset_name"}")`;

    const datasetCopyCode = `import antigranular as ag
session = ag.login("${user ? authCreds?.clientId : "ag_user_id"}", "${(user && authCreds?.clientSecret) || "ag_user_secret"
        }", dataset = "${dataset ? dataset.title : "dataset_name"}")`;

    const getAuthCreds = async () => {
        try {
            const holder = await AuthApi.getAuth0();
            setAuthCreds(holder);
        } catch (err) {
            // setError(HandleError(err)[""]);
        }
    };

    const deleteSelectedDataset = async () => {
        try {
            setLoading(true);
            if (dataset?._id) {
                await dispatch(deleteDataset(dataset?._id)).unwrap();
                navigate(`/${DATASETS}`);
            } else {
                setError("Error deleting dataset: unknown ID");
            }
        } catch (error) {
            setError(HandleError(error)[""]);
        } finally {
            setLoading(false);
            setConfirmDelete(false);
        }
    };

    useEffect(() => {
        if (user && dataset) getAuthCreds();
    }, [user, dataset])

    const getDataset = async () => {
        setLoading(true);
        try {
            if (_slug) {
                dispatch(loadDatasetInfoBySlug({ _slug }))
                    .unwrap()
                    .then((res) => {
                        setMetadata(res.metadata)
                        if (res.slug && _slug !== res.slug) {
                            window.history.replaceState(null, "", `/${DATASETS}/${res.slug}`);
                        }
                    })
                    .catch((error) => {
                        setError(HandleError(error)[""]);
                        navigate(`/${ERROR}`)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                setError("Dataset not found.");
                navigate(`/${ERROR}`)
            }
        } catch (error) {
            setError(HandleError(error)[""]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataset();
    }, [_slug, user]);

    useEffect(() => {
        return (() => {
            dispatch(setDatasetInfo(undefined))
        })
    }, [dispatch]);

    const fetchNotebooks = (page: number = 1) => {
        if (dataset) {
            setLoadingNotebooks(true);
            NotebookApi.getAllDatasetNotebooks(dataset?._id, { page, size: pageSize })
                .then((nbData) => {
                    setNotebooks(nbData.data);
                    setNbPage(nbData.page);
                    setNbCount(nbData.totalCount);
                })
                .catch((error) => setError(HandleError(error)[""]))
                .finally(() => {
                    setLoadingNotebooks(false);
                });
        }
    }

    useEffect(() => {
        if (dataset) {
            fetchNotebooks(nbPage);
        } else {
            setNotebooks([]);
            setNbPage(undefined);
            setNbCount(undefined);
        }
    }, [dataset]);

    const handleNotebooksPageChange = (newPage: number) => {
        setNbPage(newPage);
        fetchNotebooks(newPage);
    }

    useEffect(() => {
        setError("");
        if (dataset && user) {
            setLoadingMyNotebooks(true);
            NotebookApi.getMyNotebooksByDataset(dataset?._id)
                .then((nbData) => {
                    setMyNotebooks(nbData);
                })
                .catch((error) => setError(HandleError(error)[""]))
                .finally(() => {
                    setLoadingMyNotebooks(false);
                });
        } else {
            setMyNotebooks([]);
        }
    }, [user, dataset]);

    // const star = async () => {
    //     try {
    //         if (!dataset) return;
    //         setChangingStarStatus(true);
    //         const response = await dispatch(
    //             starDataset({
    //                 _id: dataset._id,
    //                 starStatus: !dataset.stared,
    //             })
    //         ).unwrap();
    //         setDataset({ ...dataset, stared: response.response });
    //     } catch (error) {
    //         setError(HandleError(error)[""]);
    //     } finally {
    //         setChangingStarStatus(false);
    //     }
    // };

    // const onNotebookClick = (nb: INotebook) => {
    //     if (nb?._id) {
    //         navigate("/notebooks/" + nb._id);
    //     }
    // };

    const renderNotebookList = () => (
        <>
            <div id="notebooks-list" style={{ minHeight: '250px' }}>
                {loadingNotebooks ? (
                    <p className="text-center">Loading notebooks</p>
                ) : notebooks?.length > 0 ? (
                    notebooks.map((item) => (
                        <NotebookListItem
                            item={item}
                            key={item._id}
                            to={`/${NOTEBOOKS}/` + (item.slug ?? item._id)}
                        />
                    ))
                ) : (
                    <p className="text-color-tertiary text-sm text-center py-5">
                        No notebook using this dataset
                    </p>
                )}
            </div>

            {notebooks?.length > 0 && nbCount && !!pageSize && (nbCount > pageSize) && (
                <div className="pagination-cover justify-content-center align-items-center d-flex w-100">
                    <ReactPaginate
                        breakLabel="..."
                        previousLabel={window.innerWidth >= 992 ? "< Prev" : "<"}
                        nextLabel={window.innerWidth >= 992 ? "Next >" : ">"}
                        onPageChange={(event) => {
                            handleNotebooksPageChange(event.selected + 1)
                        }}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={Math.ceil(nbCount / pageSize)}
                        className="d-flex pagination-container"
                        renderOnZeroPageCount={null}
                    />
                </div>
            )}
        </>
    )

    const renderNotebookTabContent = () => {
        if (user) {
            return (
                <Tabs id="tab-data" defaultActiveKey={"tabAll"} className="">
                    <Tab eventKey={"tabAll"} title="All">
                        {renderNotebookList()}
                    </Tab>
                    <Tab eventKey={"myWork"} title="My Work">
                        <div className="">
                            {loadingMyNotebooks ? (
                                <p>Loading you work</p>
                            ) : myNotebooks?.length > 0 ? (
                                myNotebooks.map((item) => (
                                    <NotebookListItem
                                        item={item}
                                        key={item._id}
                                        to={item._id}
                                    />
                                ))
                            ) : (
                                <p className="text-color-tertiary text-sm text-center py-5">
                                    No notebook using this dataset
                                </p>
                            )}
                        </div>
                    </Tab>
                </Tabs>
            );
        } else {
            return (
                renderNotebookList()
            )
        }
    };

    useEffect(() => {
        const tabs = document.querySelector(".tab-content");

        if (maximized) tabs?.classList.add("tab-content-maximized")
        else tabs?.classList.remove("tab-content-maximized")
    })

    const tabOptions = () => {
        return (
            <div className="screen-menu-cover">
                <div className="screen-menu">
                    <span className={`screen-menu-item mb-md-2 me-2 me-md-0 mb-0 ${maximized ? "selected" : ""}`}
                        onClick={() => setMaximized(true)} >
                        <img alt="" src={maximize} />
                    </span>
                    <span className={`screen-menu-item mb-md-2 me-2 me-md-0 mb-0 ${maximized ? "" : "selected"}`}
                        onClick={() => setMaximized(false)} >
                        <img alt="" src={minimize} />
                    </span>
                </div>
            </div >
        );
    };

    return (
        <div className="page-container lg-box">
            <ErrorAlert show={error ? true : false} errorMessage={error} />

            <div id="dataset-info" className="d-flex flex-wrap">
                <div className="page-heading-container mb-0">

                    <div className="d-flex flex-sm-row flex-column-reverse">
                        {noImage ? (
                            <div className="page-heading-banner mb-3 mb-sm-0">
                                <img src={trophy} alt="" className="w-100" />
                            </div>
                        ) : undefined}
                        {dataset && !noImage ? (
                            <div className="page-heading-banner mb-3 mb-sm-0">
                                <img
                                    src={
                                        DATASET_IMG_URL(dataset?._id ?? "") ??
                                        trophy
                                    }
                                    className="w-100"
                                    alt=""
                                    onError={() => setNoImage(true)}
                                />
                            </div>
                        ) : undefined}

                        <div className="page-data-intro text-xs mb-4 d-sm-none d-block">
                            {dataset?.subTitle}
                        </div>

                        <div className="mb-21 mb-sm-0">
                            <div className="d-flex">
                                <span className="mb-2 pb-1 text-3xl">{dataset?.title}</span>
                                {user?.roles.includes(UserRole.Admin) ? (
                                    <FontAwesomeIcon icon={faEdit} className="ms-3 feature-icon"
                                        title="Edit" size="sm"
                                        onClick={() => navigate(`/${DATASETS}/${EDIT}/` + (dataset?.slug ?? dataset?._id))}
                                    />
                                ) : <></>}
                            </div >

                            <div className="d-flex flex-column">
                                {dataset?.type ? (
                                    <span className="d-flex align-items-center">
                                        <img
                                            className="me-2"
                                            width={12}
                                            alt=""
                                            src={CategoryIcons(dataset.type)}
                                        />
                                        <span className="text-capitalize text-xs text-color-tertiary">
                                            {dataset.type}
                                        </span>
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div >
                    </div >

                    <div className="page-data-intro text-xs my-3 d-sm-block d-none">
                        {dataset?.subTitle}
                    </div>
                </div >

                <div className="d-none d-sm-block">
                    <CodeBlock private copyText={datasetCopyCode}>
                        {datasetCode}
                    </CodeBlock>

                    {user?.roles?.includes(UserRole.Admin) ? (
                        <Button
                            variant="secondary"
                            className="mt-3 mb-3 rounded-pill"
                            onClick={() => setConfirmDelete(true)}
                        >
                            Delete
                        </Button>
                    ) : undefined}
                </div>
            </div >

            {
                dataset ? (
                    <>
                        <div className={maximized ? "back-cover" : " d-none"} />

                        <Tabs className={`${maximized ? "info-page-tab-container" : ""}`}
                            id="tab-dataset" defaultActiveKey={"tabAbout"}>

                            <Tab id="data-tab" className={`info-page-tab ${maximized ? "" : "onFit"}`}
                                eventKey={"tabAbout"} title="Data">
                                {tabOptions()}

                                <div className="info-page-tab-content text-color-secondary">
                                    <p className="text-lg w-75">About the dataset</p>
                                    {dataset?.about ? (
                                        <div className="about-dataset-content"
                                            dangerouslySetInnerHTML={{
                                                __html: dataset?.about ?? "",
                                            }}
                                        />
                                    ) : (
                                        <div className="text-xs">
                                            Enter dataset description{" "}
                                        </div>
                                    )}

                                    {dataset.columnData &&
                                        dataset.columnData.length > 0 ? (
                                        <div className="radius-base mt-3">
                                            <div className="dataset-columns">
                                                <p className="text-base text-thick ps-3 me-21 ps-md-4 column-heading mb-0 pb-21">{dataset.title}</p>

                                                <div className="columns-table">
                                                    <Table bordered>
                                                        <thead className="">
                                                            <tr className="sticky-row bg-primary">
                                                                <th className="sticky-cell" key="columnName">
                                                                    <div>Name</div>
                                                                </th>
                                                                {dataset.columnData.map((column, id) =>
                                                                    <td key={"columnName" + id} className="text-nowrap overflow-">
                                                                        <span className="me-1">{column.columnName.split(" ")[0]}</span>
                                                                        <span className="text-xxs text-color-tertiary">{column.columnName.split(" ").slice(1).join(" ")}</span>
                                                                    </td>
                                                                )}
                                                            </tr >
                                                        </thead >

                                                        <tbody className="">
                                                            <tr>
                                                                <th className="sticky-cell" key="columnDesc">
                                                                    <div>
                                                                        Description
                                                                    </div>
                                                                </th>
                                                                {dataset.columnData.map((column, idx) =>
                                                                    <td key={"columnDesc" + idx}>{column.columnDescription}</td>
                                                                )}
                                                            </tr >
                                                            <tr>
                                                                <th className="sticky-cell" key="columnMetaColumn">
                                                                    <div>
                                                                        {metadata
                                                                            ? "Metadata"
                                                                            : "Categories"}
                                                                    </div>
                                                                </th>
                                                                {dataset.columnData.map((column, idx) =>
                                                                    <td key={"columnMeta" + idx}>
                                                                        {metadata ? <div className="d-flex flex-column">
                                                                            {metadata["x_meta"]?.[column.columnName.split(" ")[0]] ?
                                                                                metadata["x_meta"]?.[column.columnName.split(" ")[0]]?.join(" - ") :
                                                                                metadata["y_meta"] && metadata["y_meta"]?.[column.columnName.split(" ")[0]] ?
                                                                                    metadata["y_meta"]?.[column.columnName.split(" ")[0]]?.join(" - ") :

                                                                                    column?.categories?.length > 0 ? column.categories.split(",").map((category, id) =>
                                                                                        <span key={column.columnName + "Cat" + id}>{category}</span>
                                                                                    ) : "-"}
                                                                        </div> :
                                                                            <div className="d-flex flex-column">
                                                                                {column?.categories?.length > 0 ? column.categories.split(",").map((category, id) =>
                                                                                    <span key={column.columnName + "Cat" + id}>{category}</span>
                                                                                ) : "-"}
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                )}
                                                            </tr >
                                                        </tbody >
                                                    </Table >
                                                </div >
                                            </div >
                                        </div >
                                    ) : undefined
                                    }

                                    {/* <CustomAccordian expanded className="mt-31" id="loadingDataset" heading="Loading Dataset" /> */}

                                    {dataset.sources && dataset.sources.length > 0 && dataset.sources[0].trim().length !== 0 ? (
                                        <CustomAccordian className="mt-31" id="DatasetSources" heading="Sources">
                                            <div>{dataset.sources.map((source, id) =>
                                                <p className="mb-1" key={"source" + id}>
                                                    <span className={`${source.toLowerCase().startsWith("http") ? "link-ag-1 cursor-pointer" : ""}`}
                                                        onClick={() => {
                                                            if (source.toLowerCase().startsWith("http")) window.open(source, '_blank', 'noopener')?.focus();
                                                        }}
                                                    >
                                                        {source}
                                                    </span>
                                                </p>)}
                                            </div>
                                        </CustomAccordian>
                                    ) : undefined}

                                    {dataset.collaborators && dataset.collaborators.length > 0 && dataset.collaborators[0].trim().length !== 0 ? (
                                        <CustomAccordian id="DatasetCollaborators" heading="Collaborators">
                                            <div>{dataset.collaborators.map((collaborator, id) =>
                                                <p className="mb-1" key={"collaborator" + id}>{collaborator}</p>)}
                                            </div>
                                        </CustomAccordian>
                                    ) : undefined}

                                    {dataset.license ? (
                                        <CustomAccordian id="DatasetLicense" heading="License">
                                            <div>{dataset.license}</div>
                                        </CustomAccordian>
                                    ) : undefined}
                                </div>
                            </Tab >

                            <Tab id="notebooks-tab" className={`info-page-tab ${maximized ? "glass-card" : "onFit"}`}
                                eventKey={"tabNotebooks"} title="Notebooks">
                                {notebooks && notebooks.length > 0 && tabOptions()}
                                <div className={`${maximized ? "p-2 p-md-3" : "p-0"}`}>
                                    {renderNotebookTabContent()}
                                </div>
                            </Tab>

                            {user?.roles.includes(UserRole.Admin) && dataset ? (
                                <Tab className={`admin-update-tab ${maximized ? "glass-card" : "onFit"}`}
                                    eventKey={"tabFileUpload"} title="Upload Files">
                                    <div className={`${maximized ? "p-2 p-md-3" : "p-0"}`}>
                                        <ChunkedFileUploader id={dataset._id} />
                                    </div>
                                </Tab>
                            ) : (
                                <></>
                            )}
                        </Tabs>
                    </>
                ) : undefined
            }
            <LoadingSpinner show={loading} text="Loading" />

            <Modal
                show={confirmDelete}
                className="login-modal"
                centered
                onHide={() => setConfirmDelete(false)}
            >
                <Modal.Header className="border-0 p-3" closeButton>
                    <p className="lead text-center">Are you sure?</p>
                </Modal.Header>

                <Modal.Body className=" pt-0">
                    <p className=" mb-5 mt-4">
                        Are you sure you want to delete{" "}
                        {dataset?.title || "this"} dataset?
                    </p>

                    <div className="d-flex">
                        <Button
                            onClick={() => setConfirmDelete(false)}
                            className="rounded-pill ms-auto me-4"
                            variant="secondary"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={deleteSelectedDataset}
                            className="rounded-pill"
                            variant="primary"
                        >
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );
}
