import {
    ICompetitionsData,
    ICompetition,
    ICreateCompetitionPayload,
    IUpdateCompetitionPayload,
    IFetchCompetitionListPayload,
    ICompetitionLeaderboard,
    ICompetitionAllowedMembersPayload,
    ICompetitionAllowedMembersData,
    ICompetitionInvitedMembers
} from "../types/competition-types";
import { BaseApi } from "./base-api";

export default class CompetitionApi extends BaseApi {
    private static url = (action: string) => "competition/" + action;

    /**
     * Create new competition
     * @returns
     */
    public static async create( data: ICreateCompetitionPayload ): Promise<ICompetition> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._post(this.url("create"), fd);
    }

    /**
     * Get all competitions
     * @returns
     */
    public static async getAll(
        data: IFetchCompetitionListPayload
    ): Promise<ICompetitionsData> {
        return this._get(this.url("getall"), data);
    }

    /**
     * Get all competitions
     * @returns
     */
    public static async getJoinedCompetitions(
        data: IFetchCompetitionListPayload
    ): Promise<ICompetitionsData> {
        return this._get(`${this.url("getjoined")}/${(data.userId && data.userId !== "undefined" ? data.userId : "")}`, data);
        
    }

    /**
     * Get competition details
     * @param id competition id
     * @returns
     */
    public static async get(id: string): Promise<ICompetition> {
        return this._get(`${this.url("get")}/${id}`);
    }

    /**
     * Get competition invited members
     * @param id competition id
     * @returns
     */
    public static async getInvitedMembers(id: string): Promise<ICompetitionInvitedMembers[]> {
        return this._get(`${this.url("getInvitedMembers")}/${id}`);
    }

    /**
     * Get competition allowed members
     * @returns
     */
    public static async getAllowedMembers(data: ICompetitionAllowedMembersPayload): Promise<ICompetitionAllowedMembersData> {
        return this._get(this.url("getAllowedUsers"), data);
    }


    /**
     * Get competition leaderboard
     * @param id competition id
     * @returns
     */
    public static async getLeaderboard(id: string): Promise<ICompetitionLeaderboard[]> {
        return this._get(`${this.url("leaderboard")}/${id}`);
    }

    /**
     * Update competition
     * @param data
     * @returns
     */
    public static async update(
        data: IUpdateCompetitionPayload
    ): Promise<ICompetition> {
        let fd = new FormData();
        for (let key in data as any) {
            if ((data as any)[key]) {
                fd.append(
                    key,
                    (typeof (data as any)[key] === "object" ||
                        Array.isArray((data as any)[key])) &&
                        !((data as any)[key] instanceof File) &&
                        !((data as any)[key] instanceof Date)
                        ? JSON.stringify((data as any)[key])
                        : (data as any)[key]
                );
            }
        }
        return this._patch(this.url("update"), fd);
    }


    public static async delete(id: string): Promise<void> {
        return this._delete(`${this.url("delete")}/${id}`);
    }
}
