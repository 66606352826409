import { IBadRequestError } from "../types/error-types";
import { AppError } from "./app-error";

export function HandleError(error: AppError | any): any {
    let message: string = "";
    switch (error.status) {
        case 400:
            if (error?.data) {
                const errors = parseErrors(error.data.errors);
                return errors;
            } else {
                return {
                    "": "Unknow error.",
                };
            }
        default:
            message = error?.message ?? "Unknow error occured.";
    }
    return { "": message };
}

function parseErrors(data: any) {
    let errors: any = {};
    if (data?.length) {
        const parsedErrors = data as IBadRequestError[];
        parsedErrors.forEach((er) => {
            if (er.children?.length) {
                errors[er.field] = parseErrors(er.children);
            } else {
                errors[er.field] = er.errors[0] ?? "";
            }
        });
    } else {
        errors[""] = "Unknown error occured.";
    }
    return errors;
}
