import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NotebookApi from "../../api/notebook-api";
import { IStarItem } from "../../types/common-types";
import {
    ICreateNotebookPayload,
    INotebooksData,
    IUpdateNotebookStatusPayload,
    IFetchNotebookListPayload,
    INotebook
} from "../../types/notebook-types";

interface INotebookState {
    approvedData: INotebooksData;
    disapprovedData: INotebooksData;
    fetching: boolean;

    notebookInfo: INotebook | null;
}

const initialState: INotebookState = {
    approvedData: {
        data: [],
    },
    disapprovedData: {
        data: [],
    },
    fetching: false,

    notebookInfo: null,
};

/*thunks*/
const createNotebook = createAsyncThunk('notebook/create', async (payload: ICreateNotebookPayload, thunkAPI) => {
    try {
        const response = await NotebookApi.create(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const updateStatus = createAsyncThunk('notebook/updateStatus', async (payload: IUpdateNotebookStatusPayload, thunkAPI) => {
    try {
        const response = await NotebookApi.updateStatus(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const loadNotebooks = createAsyncThunk('notebook/getAll', async (payload: IFetchNotebookListPayload, thunkAPI) => {
    try {
        const response = await NotebookApi.getAll(payload);
        return { ...response, page: payload.page, title: payload.title };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const loadDisapprovedNotebooks = createAsyncThunk('notebook/getDisapproved', async (payload: IFetchNotebookListPayload, thunkAPI) => {
    try {
        const response = await NotebookApi.getDisapproved(payload);
        return { ...response, page: payload.page, title: payload.title };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const deleteNotebook = createAsyncThunk('notebook/delete', async (id: string, thunkAPI) => {
    try {
        await NotebookApi.delete(id);
        return id;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})


const starNotebook = createAsyncThunk('dataset/starNotebook', async (data: IStarItem, thunkAPI) => {
    try {
        const response = await NotebookApi.star(data);
        return { response, ...data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const loadNotebookInfoBySlug = createAsyncThunk(
    'notebook/get/:id',
    async ({ _slug }: { _slug: string }, thunkAPI) => {
        try {
            const response = await NotebookApi.get(_slug);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
)

export const notebookSlice = createSlice({
    name: 'notebook',
    initialState,
    reducers: {
        setFetching: (s, a) => {
            s.fetching = a.payload;
        },

        setApprovedNbData: (s, a) => {
            s.approvedData = a.payload;
        },
        setDisapprovedNbData: (s, a) => {
            s.disapprovedData = a.payload;
        },

        setNotebookInfo: (s, a) => {
            s.notebookInfo = a.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(createNotebook.fulfilled, (s, a) => {
            if (a.payload.approved) {
                s.approvedData.data.push(a.payload);
            } else {
                s.disapprovedData.data.push(a.payload);
            }
        })
        builder.addCase(updateStatus.fulfilled, (s, a) => {
            if (a.payload.approved) {
                let index = s.disapprovedData.data?.findIndex(x => x._id === a.payload._id);
                if (index > -1) {
                    s.disapprovedData.data.splice(index, 1);
                }
                s.approvedData.data.push(a.payload);
            } else {
                let index = s.approvedData.data?.findIndex(x => x._id === a.payload._id);
                if (index > -1) {
                    s.approvedData.data.splice(index, 1);
                }
                s.disapprovedData.data.push(a.payload);
            }
        })
        builder.addCase(deleteNotebook.fulfilled, (s, a) => {
            let index = s.approvedData.data?.findIndex(x => x._id === a.payload);
            if (index > -1) {
                s.approvedData.data.splice(index, 1)
            } else {
                index = s.disapprovedData.data.findIndex(x => x._id === a.payload);
                if (index > -1) {
                    s.disapprovedData.data.splice(index, 1);
                }
            }
        })
        builder.addCase(loadNotebooks.pending, (s, a) => {
            s.fetching = true;
        })
        builder.addCase(loadNotebooks.fulfilled, (s, a) => {
            const { data, totalCount, page } = a.payload
            if (page === 1) {
                s.approvedData.data = data;
            } else {
                s.approvedData.data = [...s.approvedData.data, ...data];
            }
            s.approvedData.totalCount = totalCount;

            s.fetching = false;
        })
        builder.addCase(loadNotebooks.rejected, (s, a) => {
            s.fetching = false;
        })
        builder.addCase(loadDisapprovedNotebooks.pending, (s, a) => {
            s.fetching = true;
        })
        builder.addCase(loadDisapprovedNotebooks.fulfilled, (s, a) => {
            const { data, totalCount, page } = a.payload
            if (page === 1) {
                s.disapprovedData.data = data;
            } else {
                s.disapprovedData.data = [...s.disapprovedData.data, ...data];
            }
            s.disapprovedData.totalCount = totalCount;

            s.fetching = false;
        })
        builder.addCase(loadDisapprovedNotebooks.rejected, (s, a) => {
            s.fetching = false;
        })
        builder.addCase(starNotebook.fulfilled, (s, a) => {
            let index = s.approvedData.data?.findIndex(x => x._id === a.payload._id);
            if (index > -1) {
                const ds = s.approvedData.data[index];
                s.approvedData.data[index] = { ...ds, stared: a.payload.response };
            }
        })

        builder.addCase(loadNotebookInfoBySlug.fulfilled, (s, a) => {
            s.notebookInfo = a.payload
        });
    }
})

export {
    createNotebook,
    loadNotebooks,
    updateStatus,
    deleteNotebook,
    loadDisapprovedNotebooks,
    starNotebook,

    loadNotebookInfoBySlug,
};
export const {
    setFetching,

    setApprovedNbData,
    setDisapprovedNbData,

    setNotebookInfo,
} = notebookSlice.actions;
export default notebookSlice.reducer;
