import React from "react";
// import ReactDOM from "react-dom/client";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import ReactGA from "react-ga4";
import { initializeHotjar } from "./utils/initialize-hotjar";

window.addEventListener("CookiebotOnConsentReady", () => {
    initializeHotjar();
    ReactGA.initialize("G-S2780RS3XN");
});

const root = createRoot(document.getElementById("root") as HTMLElement);
// const rootElement = document.getElementById("root") as HTMLElement;

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
    // </React.StrictMode>
);

// if (rootElement.hasChildNodes()) {
//     hydrateRoot(
//         rootElement,
//         <Provider store={store}>
//             <App />
//         </Provider>
//     );
// } else {
//     root.render(
//         <Provider store={store}>
//             <App />
//         </Provider>
//     );
// }

const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
