import React from "react";
import { Link } from "react-router-dom";

// import: assets
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import dataset from "../../assets/icons/notebook.svg";
import competition from "../../assets/icons/competitions.svg";

// import: styles
import "./index.scss";

// import: constants
import { PROFILE } from "../../router/routes";

// import: enums
// import: types
import { INotebook } from "../../types/notebook-types";

// import: utils
import { timeElapsed } from "../../utils/helper-methods";

// import: data
// import: store
import { useAppSelector } from "../../store/store-hooks";

// import: api
// import: config
// import: components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import UserImg from "../user-img";

type TNotebookListItemProps = {
    item: INotebook;
    contextMenu?: {
        title: string;
        onClick: () => void;
    }[];
    to: string;
    userNbItem?: boolean;
};
export default function NotebookListItem(props: TNotebookListItemProps) {
    const user = useAppSelector((s) => s.auth.user);

    return (
        <div className="slide_in_appear_top listing_row ">
            <div className="listing-user-details">
                {props.userNbItem ? (
                    <div className="listing-user-icon">
                        <UserImg
                            userId={props.item.createdBy?._id}
                            alt="user img"
                        />
                    </div>
                ) : (
                    <div className="listing-user-icon cursor-pointer">
                        <Link
                            to={
                                user
                                    ? `/${PROFILE}/` + props.item.createdBy?._id
                                    : props.to
                            }
                        >
                            <UserImg
                                userId={props.item.createdBy?._id}
                                alt="user img"
                            />
                        </Link>
                    </div>
                )}
                <div className="ms-sm-3 ms-2 d-flex flex-column">
                    {props.item.approved === true ? (
                        <p className="listTitle cursor-pointer">
                            <Link className="unanchored" to={props.to}>
                                {props.item.title}
                            </Link>
                        </p>
                    ) : (
                        <p className="listTitle">{props.item.title}</p>
                    )}
                    <p className="listDetails">
                        {props.item?.dataset?.title && (
                            <>
                                <img
                                    width={20}
                                    className="me-2"
                                    height={20}
                                    src={dataset}
                                    alt=""
                                />
                                {props.item?.dataset?.title}
                            </>
                        )}
                        {props.item?.competition?.title && (
                            <>
                                <img
                                    width={20}
                                    height={20}
                                    className="me-2"
                                    src={competition}
                                    alt=""
                                />
                                {props.item?.competition?.title}
                            </>
                        )}
                    </p>
                    <p className="text-color-tertiary text-xs d-flex flex-wrap mb-0">
                        <span>{props.item.createdBy.fullName}</span>
                        <li className="ms-1">
                            {timeElapsed(
                                props.item?.updatedAt ?? props.item?.createdAt,
                                new Date()
                            ) + " ago"}
                        </li>
                    </p>
                </div>
            </div>

            <div className="d-flex flex-column">
                {props.contextMenu?.length ? (
                    <div className="col-auto ms-auto">
                        <NotebookContextMenu
                            contextMenuItems={props.contextMenu}
                        />
                    </div>
                ) : undefined}
                {props.userNbItem && props.item.approved === false ? (
                    <span className="text-color-red-normal mt-auto mb-2 text-xs">
                        Dissapproved
                    </span>
                ) : undefined}
            </div>
        </div>
    );
}

function NotebookContextMenu({
    contextMenuItems,
}: {
    contextMenuItems?: {
        title: string;
        onClick: () => void;
    }[];
}) {
    return (
        <Dropdown>
            <Dropdown.Toggle
                as={MenuToggleButton}
                className="notebook-dropdown-toggle"
            />

            <Dropdown.Menu
                as="div"
                className="notebook-dropdown-menu glass-card text-sm"
            >
                {contextMenuItems?.map((m, i) => (
                    <Dropdown.Item
                        className="notebook-dropdown-menu-item"
                        key={m.title}
                        onClick={() => m.onClick?.()}
                    >
                        {m.title}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

const MenuToggleButton = React.forwardRef(({ onClick }: any, ref: any) => (
    <span className="p-1" onClick={(e) => onClick(e)} ref={ref}>
        <FontAwesomeIcon
            className=" mt-2 cursor-pointer rotate"
            size="lg"
            icon={faEllipsisV}
        />
    </span>
));
