import Button from "react-bootstrap/Button";
import "./index.scss";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { useEffect, useState } from "react";
import { setForgotPasswordModalVisible } from "../../store/slices/auth-slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { HandleError } from "../../errors/handler";
import Input from "../input/input";
import { validateEmail } from "../../utils/helper-methods";
import AuthApi from "../../api/auth-api";
import { showInfoModal } from "../../store/slices/modal-states-slice";
import { executeV3Captcha } from "../../utils/captcha-methods";

export default function ForgotPasswordModal() {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const show = useAppSelector((s) => s.auth.showForgotPasswordModal);
    const [formErrors, setFormErrors] = useState<any>({});
    const [email, setEmail] = useState("");

    useEffect(() => {
        setFormErrors({});
        setEmail("");
        setLoading(false);
    }, [show]);

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setFormErrors({});
            e.preventDefault();
            e.stopPropagation();
            if (!validateEmail(email)) {
                setFormErrors({
                    email: "Please enter valid email address.",
                });
                return;
            }
            setLoading(true);
            const token = await executeV3Captcha("forgotpassword");
            await AuthApi.forgotPassword(email, token);
            dispatch(setForgotPasswordModalVisible(false));
            dispatch(
                showInfoModal({
                    title: "Email Sent",
                    message: "Please check you inbox for password reset link.",
                })
            );
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => dispatch(setForgotPasswordModalVisible(false))}
            centered
            dialogClassName="login-modal"
        >
            <Modal.Header className="border-0" closeButton>
                <div>Forgot Password</div>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={submit} className="login-modal-form">
                    <fieldset disabled={loading}>
                        <div className="form-input-fields text-xs text-color-secondary">
                            <Form.Group>
                                <Input
                                    value={email}
                                    label="Email"
                                    placeholder="Email Address"
                                    maxLength={100}
                                    onChange={(e) => {
                                        if (formErrors.email || formErrors[""]) {
                                            setFormErrors({});
                                        }
                                        setEmail(e.target.value);
                                    }}
                                    error={
                                        formErrors["email"] ||
                                        formErrors[""] ||
                                        formErrors["captcha"]
                                    }
                                />
                            </Form.Group>
                        </div>
                        
                        <div className="mt-4 pt-3 mb-1">
                            <Button type="submit" variant="primary" className="rounded-pill mx-auto">
                                {loading ? (
                                    <>
                                        <FontAwesomeIcon spin className="me-2" icon={faSpinner} />
                                        Please wait
                                    </>
                                ) : (
                                    "Send Reset Link"
                                )}
                            </Button>
                        </div>
                    </fieldset>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
