import { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Select, { SelectInstance } from "react-select";
import "./index.scss";

type TInputSelectProps = {
    error?: string;
    onChange?: (val: any) => void;
    options: any[];
    placeholder?: string;
    value?: any;
    className?: string;
    disabled?: boolean;
    label?: string;
    focusOnError?: boolean;
    labelKey: string;
    valueKey: string;
    isLoading?: boolean;
    loadingMessage?: string;
};
export default function InputSelect(props: TInputSelectProps) {
    const ref = useRef<SelectInstance<any, any, any>>(null);
    useEffect(() => {
        if (props.error) {
            ref.current?.focus();
        }
    }, [props.error]);
    return (
        <>
            {props.label ? <Form.Label>{props.label}</Form.Label> : undefined}
            <Select
                isLoading={props.isLoading}
                loadingMessage={(val) => props.loadingMessage}
                ref={ref}
                isDisabled={props.disabled}
                placeholder={props.placeholder ?? "Select "}
                value={props.value}
                isSearchable
                isClearable
                onChange={(newVal) => props.onChange?.(newVal)}
                className={
                    props.error
                        ? "react-select-invalid"
                        : "react-select-container"
                }
                classNamePrefix="react-select"
                getOptionValue={(o: any) => o[props.valueKey]}
                getOptionLabel={(o: any) => o[props.labelKey]}
                options={props.options}
            />
            {props.error ? (
                <div className="text-invalid-feedback mt-1">{props.error}</div>
            ) : ("")}
        </>
    );
}
