
import React, { Fragment } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import vscDarkPlus from "../notebook-view/vsc-dark-plus";
import "./index.scss";

import AnsiPre from "../notebook-view/nbviewer/AnsiPre";

var showdown  = require('showdown');
var showdownKatex = require('@clevy/showdown-katex')


function NotebookViewer({source, className}) {
    
    const renderMarkdownCell = (cell) => {
        var converter = new showdown.Converter({
            extensions: [
              showdownKatex({
                // maybe you want katex to throwOnError
                throwOnError: true,
                // disable displayMode
                displayMode: false,
                // change errorColor to blue
                errorColor: '#1500ff',
              }),
            ],
          }),
        html = converter.makeHtml(typeof cell.source === "string" ? cell.source : cell.source.join(""))
        // You may want to use a markdown parser library to convert markdown to HTML.
        // Example: https://github.com/markedjs/marked
        return html;
    }


    return (
        <div className={"notebookView" + className}>
            {source.worksheets[0].cells.map((cell, idx) => {
                if (cell.cell_type === 'code') {
                    return (
                    <div key={"block" + idx}>
                        <div id="inner_cell" className="inner_cell"  key={"code" + idx}>
                            {/* <div className="input_prompt">
                                <span>{`In [${cell.prompt_number || " "}]:`}</span>
                            </div> */}
                            
                            <div className="codeBlock">
                                <SyntaxHighlighter style={vscDarkPlus} language={cell.language || "python"}>
                                    {typeof cell.input === "string" ? cell.input : cell.input.join("")}
                                </SyntaxHighlighter>
                            </div>
                        </div>
                        

                        {cell.outputs.map((outputItem, index) => {
                            return (
                                <div className="inner_cell ps-3 mt-0 mb-4" key={"output" + index}>
                                    {(() => {
                                        switch (outputItem.output_type) {
                                            case "pyout":
                                                return (
                                                    <div className="d-flex w-100">
                                                        {/* <div className="output_prompt">
                                                            <pre>{`Out[${outputItem.prompt_number}]:`}</pre>
                                                        </div> */}
                                                        <div className="pb-2 text-sm overflow-x-auto">
                                                            {outputItem.html ? 
                                                                <div dangerouslySetInnerHTML={{ __html: (typeof outputItem.html === "string" ? outputItem.html : outputItem.html.join(""))}} /> : 
                                                                <div className=""><AnsiPre>{outputItem.text.join("")}</AnsiPre></div>
                                                                    
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            case "stream":
                                                return (
                                                    <div className={`inner_cell m-0 ${outputItem.stream === 'stderr' ? "output_stderr" : ""}`}>
                                                        <div className="mb-0 text-sm w-100">
                                                            <AnsiPre>{outputItem.text.join("")}</AnsiPre>
                                                        </div>
                                                    </div>
                                                )
                                            case "display_data":
                                                return (
                                                    <div className="output_stream">
                                                        <img src={`data:image/png;base64,${outputItem.png}`} alt={outputItem.text}/>
                                                    </div>
                                                );
                                            default:
                                                return <></>;
                                        }
                                    })()}
                                </div>
                            )
                        })}
                    </div>
                    );
                } else if (cell.cell_type === 'markdown') {
                    return (
                        <div className="codeBlock"  key={"markdown" + idx}>
                            <div dangerouslySetInnerHTML={{ __html: renderMarkdownCell(cell) }} />
                        </div>
                    );
                } else if (cell.cell_type === 'heading') {
                    return <h className="codeBlock"  key={"heading" + idx}>${typeof cell.source === "string" ? cell.source : cell.source.join("")}</h>
                } else {
                    console.error('Unknown cell type:', cell.cell_type);
                    return undefined;
                }
            })}
        </div>
    );
}

export default NotebookViewer;