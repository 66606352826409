import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// import: assets
import logo from "../../assets/logo.svg";
import logOutIcon from "../../assets/icons/log-out.png";
import userCircle from "../../assets/icons/user-circle.png";
import settings from "../../assets/icons/settings.png";
import dropdownIcon from "../../assets/icons/dropdown-1.svg";
import search from "../../assets/icons/search.svg";
import discord from "../../assets/icons/discord.svg";
import discordSelected from "../../assets/icons/discord-selected.svg";

// import: styles
import "./index.scss";

// import: constants
import { BLOG, COMPETITIONS, DATASETS, DISCORD, NOTEBOOKS, ORGANISATIONS, PROFILE, ROOT, SETTINGS } from "../../router/routes";
// import: enums
// import: types
// import: utils
// import: data
import { SidebarMenus } from "../../router/sidebar-menu";

// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    logout,
    setRegistrationModalVisible,
    showLoginModal,
} from "../../store/slices/auth-slice";
import { onChangeSearchText } from "../../store/slices/shared-states-slice";

// import: api
import ProfileApi from "../../api/profile-api";
import OrganizationApi from "../../api/organization-api";

// import: config
import { REF_CODE_URL_PARAM } from "../../configs/constants";

// import: components
import { InputGroup, Form, Button, Container, Dropdown } from "react-bootstrap";
import UserImg from "../user-img";
import ShareButton from "../share-button";

export type TNavbarProps = {
    onToggleClick?: () => void;
};

export default function Navbar(props: TNavbarProps) {
    const location = useLocation();
    const [breadcrums, setBreadcrums] = useState<string[]>();

    const competitions = useAppSelector((s) => s.competition.pageData.data);
    const competitionInfo = useAppSelector((store) => store.competition.competitionInfo);
    const competitionMetadata = useCallback((_id: string) => {
        const filteredCompetitions = competitions.filter((c) => c._id === _id);
        if (!competitionInfo && filteredCompetitions.length > 0) return filteredCompetitions[0];
        return competitionInfo;
    }, [competitions, competitionInfo]);

    const datasets = useAppSelector((s) => s.dataset.pageData.data);
    const datasetInfo = useAppSelector((store) => store.dataset.datasetInfo);
    const datasetMetadata = useCallback((_id: string) => {
        const filteredDatasets = datasets.filter((c) => c._id === _id);
        if (!datasetInfo && filteredDatasets.length > 0) return filteredDatasets[0];
        return datasetInfo;
    }, [datasets, datasetInfo]);

    const notebooks = useAppSelector((s) => s.notebook.approvedData.data);
    const notebookInfo = useAppSelector((store) => store.notebook.notebookInfo);
    const notebookMetadata = useCallback((_id: string) => {
        const filteredNotebooks = notebooks.filter((c) => c._id === _id);
        if (!notebookInfo && filteredNotebooks.length > 0) return filteredNotebooks[0];
        return notebookInfo;
    }, [notebooks, notebookInfo]);

    const blogPosts = useAppSelector((s) => s.blog.pageData.data);
    const blogPostInfo = useAppSelector((store) => store.blog.blogInfo);
    const blogMetadata = useCallback((_id: string) => {
        const filteredBlogs = blogPosts.filter((c) => c._id === _id);
        if (!blogPostInfo && filteredBlogs.length > 0) return filteredBlogs[0];
        return blogPostInfo;
    }, [blogPosts, blogPostInfo]);

    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const user = useAppSelector((s) => s.auth.user);
    const [shareUrl, setShareUrl] = useState<URL>();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const mailDescription = `Hey there!

Have you heard about Antigranular?

It's an incredible data science community that enables you to work with privacy-enhancing technologies, learn from other data scientists, enhance your skills, and participate in exciting hackathons.
Use this link to create a profile and join me to explore the world of Eyes Off Data Science!
`;
    // const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const searchText = useAppSelector((s) => s.sharedStates.navbarSearchText);
    const searchPlaceholder = useAppSelector(
        (s) => s.sharedStates.searchPlaceholder
    );
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (user && process.env.REACT_APP_URL) {
            var url = new URL(process.env.REACT_APP_URL);
            ProfileApi.getReferralCode()
                .then((refCode) => {
                    url.searchParams.append(REF_CODE_URL_PARAM, refCode);
                    setShareUrl(url);
                })
                .catch((err) => { });
        }
    }, [user]);

    useEffect(() => {
        const locationArray = location.pathname.substring(1).split("/");

        if (locationArray.length > 0) {
            if (locationArray.length > 1) {
                if (
                    locationArray[1] === "create" ||
                    locationArray[1] === "edit" ||
                    locationArray[1] === "update"
                ) {
                    setBreadcrums(locationArray);
                } else if (locationArray[0] === COMPETITIONS) {
                    const breadcrumbs = [COMPETITIONS];
                    const comp = competitionMetadata(locationArray[1]);
                    if (comp?.title) {
                        breadcrumbs.push(comp?.title)
                    }
                    setBreadcrums(breadcrumbs);
                } else if (locationArray[0] === DATASETS) {
                    const breadcrumbs = [DATASETS];
                    const dataset = datasetMetadata(locationArray[1]);
                    if (dataset?.title) {
                        breadcrumbs.push(dataset?.title)
                    }
                    setBreadcrums(breadcrumbs);
                } else if (locationArray[0] === BLOG) {
                    const breadcrumbs = [BLOG];
                    const blog = blogMetadata(locationArray[1]);
                    if (blog?.title) {
                        breadcrumbs.push(blog.title.length > 33
                            ? blog.title.substring(0, 30) + "..."
                            : blog.title,
                        )
                    }
                    setBreadcrums(breadcrumbs);
                } else if (locationArray[0] === NOTEBOOKS) {
                    const breadcrumbs = [NOTEBOOKS];
                    const notebook = notebookMetadata(locationArray[1])
                    if (notebook?.title || notebook?._id) {
                        breadcrumbs.push(notebook?.title || notebook?._id)
                    }
                    setBreadcrums(breadcrumbs);
                } else if (locationArray[0] === ORGANISATIONS) {
                    OrganizationApi.get(locationArray[1])
                        .then((org) => {
                            setBreadcrums([
                                ORGANISATIONS,
                                org.title || org._id,
                            ]);
                        })
                        .catch((err) => { });
                } else if (locationArray[0] === PROFILE) {
                    ProfileApi.get(locationArray[1])
                        .then((profile) => {
                            setBreadcrums([
                                PROFILE,
                                profile.fullName || profile._id,
                            ]);
                        })
                        .catch((err) => { });
                }
            } else {
                setBreadcrums([locationArray[0]]);
            }
        }

        if (locationArray.length === 1) {
            switch (locationArray[0]) {
                case COMPETITIONS:
                    setShowSearch(true);
                    break;
                case DATASETS:
                    setShowSearch(true);
                    break;
                // case BLOG:
                //     setShowSearch(true);
                //     break;
                case NOTEBOOKS:
                    setShowSearch(true);
                    break;
                default:
                    setShowSearch(false);
            }
        } else {
            setShowSearch(false);
        }
    }, [
        location,
        competitionMetadata,
        datasetMetadata,
        notebookMetadata,
        blogMetadata,
    ]);

    const clickOut = (e: MouseEvent) => {
        const modal = document.querySelector(`.navbar-search-filter-container`);
        if (modal) {
            if (!modal.contains(e.target as HTMLElement)) {
                setShowFilter(false);
            }
        }
    };

    useEffect(() => {
        if (showFilter) window.addEventListener("click", clickOut);

        return () => window.removeEventListener("click", clickOut);
    }, [showFilter]);

    // const returnFilterOptions = () => {
    //   return <div></div>;
    // };

    return (
        <Container fluid className="navContainer">
            <nav className="navbar lg-box">
                <div className="navbar-toggle-button order-1">
                    <div
                        className="d-lg-none burger-container"
                        onClick={() => setOpenMenu(!openMenu)}
                    >
                        <div className={`br-1 bg-octonary ${openMenu && "rotate-br-1"}`} ></div>
                        <div className={`br-2 bg-octonary ${openMenu && "rotate-br-2"}`}></div>
                    </div>
                    {/* <FontAwesomeIcon
                        onClick={() => props.onToggleClick?.()}
                        icon={faBars}
                        className="toggle-icon"
                    /> */}
                    <div
                        className={`glazed-card-cover hidden-options-container d-lg-none ${!openMenu && "hide-options"
                            }`}
                    >
                        <div className="glazed-card hidden-options-menu">
                            {SidebarMenus?.map((menu, i) =>
                                menu.external ? (
                                    <a
                                        key={"hiddenLinks" + i}
                                        href={menu.path}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={`text-color-tertiary px-3 dropdown-links link`}
                                    >
                                        <img
                                            src={menu.iconImg}
                                            alt=""
                                            className=" me-21 defaultImage"
                                        />
                                        <img
                                            src={menu.activeIconImg}
                                            alt=""
                                            className=" me-21 selectedImage"
                                        />
                                        <span className="text-base">
                                            {menu.text}
                                        </span>
                                    </a>
                                ) : (
                                    <div
                                        key={"hiddenLinks" + i}
                                        onClick={() =>
                                            menu.path === "#"
                                                ? setIsDropdownOpen(
                                                    !isDropdownOpen
                                                )
                                                : navigate(menu.path)
                                        }
                                        className={`px-3 dropdown-links ${menu.path === "/"
                                            ? "link text-color-secondary"
                                            : "text-color-tertiary link"
                                            }`}
                                    >
                                        {menu.dropdownItems ? (
                                            <div className="d-flex flex-column mobile-menu">
                                                <div>
                                                    <img
                                                        src={menu.iconImg}
                                                        alt=""
                                                        className="me-21 defaultImage"
                                                    />
                                                    <img
                                                        src={menu.activeIconImg}
                                                        alt=""
                                                        className="me-21 selectedImage"
                                                    />

                                                    <span className="text-base">
                                                        {menu.text}
                                                    </span>

                                                    <img
                                                        src={dropdownIcon}
                                                        alt=""
                                                        className="dropdown-icon"
                                                    />
                                                </div>

                                                {isDropdownOpen && (
                                                    <div className="d-flex flex-column m-auto mt-2">
                                                        {menu.dropdownItems?.map(
                                                            (item, i) => (
                                                                <div
                                                                    onClick={() => navigate(item.path)}
                                                                    className="d-flex"
                                                                    key={i}
                                                                >
                                                                    <h6 className="title">
                                                                        {item.text.title}
                                                                    </h6>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                <img
                                                    src={menu.iconImg}
                                                    alt=""
                                                    className="me-21 defaultImage"
                                                />
                                                <img
                                                    src={menu.activeIconImg}
                                                    alt=""
                                                    className="me-21 selectedImage"
                                                />
                                                <span className="text-base">
                                                    {menu.text}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                )
                            )}
                            <a
                                key={"hiddenLinks45"}
                                href={DISCORD}
                                target="_blank"
                                rel="noreferrer"
                                className={`px-3 dropdown-links text-color-tertiary link`}
                            >
                                <img
                                    src={discord}
                                    alt=""
                                    className=" me-21 defaultImage"
                                />
                                <img
                                    src={discordSelected}
                                    alt=""
                                    className=" me-21 selectedImage"
                                />
                                <span className="text-base">Discord</span>
                            </a>

                            {user && shareUrl ? (
                                <ShareButton
                                    className="ms-3 mt-1"
                                    url={shareUrl?.href}
                                    subject={`Invitation to the Eyes Off Data Science from ${user?.fullName}`}
                                    description={mailDescription}
                                    modalHead="Spread the word and earn rewards"
                                    modalBody="Share your unique referral link to invite friends to the platform:"
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>

                <div className="order-2">
                    <img
                        alt="logo"
                        className="navbar-logo cursor-pointer"
                        src={logo}
                        onClick={() => navigate(ROOT)}
                    />
                </div>

                {showSearch ? (
                    <div className="navbar-search-input-container mt-3 mt-sm-0">
                        <InputGroup className="">
                            <img
                                width={18}
                                height={18}
                                className="border-circle my-auto ms-2"
                                src={search}
                                alt=""
                            />
                            <Form.Control
                                value={searchText}
                                type={"search"}
                                onChange={(e) =>
                                    dispatch(onChangeSearchText(e.target.value))
                                }
                                placeholder={
                                    searchPlaceholder
                                        ? searchPlaceholder
                                        : "Search..."
                                }
                                aria-label="Search"
                            />
                            {/* <img
                            width={24}
                            height={24}
                            className="border-circle my-auto me-2 cursor-pointer"
                            onClick={(e) => {e.stopPropagation(); setShowFilter(!showFilter)}}
                            src={filter}
                            alt=""
                        /> */}
                        </InputGroup>
                        {/* <div className={`navbar-search-filter-container p-md-4 p-3 radius-base glass-card ${showFilter ? "" : "hide-filter"}`}>
                        {returnFilterOptions()}
                    </div> */}
                    </div>
                ) : (
                    <></>
                )}

                {!showSearch && breadcrums && breadcrums?.length > 0 ? (
                    <div className="breadcrum-holder order-sm-1 order-4 mt-4 mb-2 my-sm-0 d-none d-sm-flex">
                        <span
                            className={
                                breadcrums && breadcrums?.length > 1
                                    ? "selected-crum faded"
                                    : "selected-crum"
                            }
                            onClick={() =>
                                navigate(breadcrums ? "/" + breadcrums[0] : "/")
                            }
                        >
                            {breadcrums && breadcrums[0].replaceAll("_", " ")}
                        </span>
                        {breadcrums && breadcrums[1] && (
                            <>
                                <p className="selected-crum">{" > "}</p>
                                <span className="selected-crum">
                                    {breadcrums[1]}
                                </span>
                            </>
                        )}
                    </div>
                ) : (
                    <></>
                )}

                <div className="navbar-right">
                    {user && shareUrl ? (
                        <ShareButton
                            className="me-3 my-auto d-none d-md-block"
                            url={shareUrl?.href}
                            subject={`Invitation to the Eyes Off Data Science from ${user?.fullName}`}
                            description={mailDescription}
                            modalHead="Spread the word and earn rewards"
                            modalBody="Share your unique referral link to invite friends to the platform:"
                            messageTitle="Refer a friend"
                            messageText="Know someone who would love Antigranular? Spread the word and earn rewards! Invite others to the platform through your unique referral link."
                        />
                    ) : (
                        <></>
                    )}

                    {user ? (
                        <Dropdown className="">
                            <Dropdown.Toggle
                                as={"span"}
                                className="navbar-user-dropdown-toggle"
                            >
                                <div className="no-select text-lg d-none d-lg-block text-thick me-3">
                                    {user?.fullName ?? ""}
                                </div>
                                <UserImg
                                    imgcode={
                                        user?.imgcode ? `${user?.imgcode}` : ""
                                    }
                                    width={32}
                                    height={32}
                                    userId={user._id}
                                    className="rounded-circle me-2 me-md-0"
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={"div"}
                                className="p-0 navbar-user-menu-dropdown"
                            >
                                <div className="glazed-card d-flex flex-column p-3 radius-base">
                                    <Dropdown.Item
                                        as="div"
                                        className="navbar-user-menu-dropdown-item text-sm text-bold mb-2"
                                        onClick={() => navigate(`/${PROFILE}`)}
                                    >
                                        <img
                                            alt=""
                                            className="me-2"
                                            width={24}
                                            height={24}
                                            src={userCircle}
                                        />
                                        Your Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="div"
                                        className="navbar-user-menu-dropdown-item text-sm text-bold mb-2"
                                        onClick={() => navigate(`/${SETTINGS}`)}
                                    >
                                        <img
                                            alt=""
                                            className="me-2"
                                            width={24}
                                            height={24}
                                            src={settings}
                                        />
                                        Settings
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="div"
                                        className="navbar-user-menu-dropdown-item text-sm text-bold"
                                        onClick={() => {
                                            navigate(ROOT);
                                            dispatch(logout());
                                        }}
                                    >
                                        <img
                                            alt=""
                                            className="me-2"
                                            width={24}
                                            height={24}
                                            src={logOutIcon}
                                        />
                                        Sign Out
                                    </Dropdown.Item>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <div className="order-3 navbar-buttons">
                            <Button
                                type="button"
                                size="sm"
                                variant="primary"
                                onClick={() =>
                                    dispatch(setRegistrationModalVisible(true))
                                }
                                className="d-block rounded-pill me-2 me-md-4"
                            >
                                Sign up
                            </Button>

                            <Button
                                type="button"
                                variant="secondary"
                                onClick={() => dispatch(showLoginModal())}
                                className="d-none d-md-block rounded-pill"
                            >
                                Log in
                            </Button>
                        </div>
                    )}
                </div>
            </nav>
        </Container>
    );
}
