import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrganizationApi from "../../api/organization-api";
import { IOrganizationsData, ICreateOrganizationPayload, IUpdateOrganizationPayload, IFetchOrganizationListPayload } from "../../types/organization-types";

interface ICompetitionState {
    pageData: IOrganizationsData;
    fetching: boolean;
}

const initialState: ICompetitionState = {
    pageData: {
        data: [],
    },
    fetching: false
};

/*thunks*/
const createOrganization = createAsyncThunk('organization/create', async (payload: ICreateOrganizationPayload, thunkAPI) => {
    try {
        const response = await OrganizationApi.create(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const loadOrganizations = createAsyncThunk('organization/getAll', async (payload: IFetchOrganizationListPayload, thunkAPI) => {
    try {
        const response = await OrganizationApi.getAll(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const updateOrganization = createAsyncThunk('organization/update', async (payload: IUpdateOrganizationPayload, thunkAPI) => {
    try {
        const response = await OrganizationApi.update(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const deleteOrganization = createAsyncThunk("organization/delete", async (id: string, thunkAPI) => {
        try {
            await OrganizationApi.delete(id);
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setFetching: (s, a) => {
            s.fetching = a.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(createOrganization.fulfilled, (s, a) => {
            s.pageData.data?.push(a.payload);
        })
        builder.addCase(loadOrganizations.pending, (s, a) => {
            s.fetching = true;
        })
        builder.addCase(loadOrganizations.fulfilled, (s, a) => {
            s.pageData = a.payload;
            s.fetching = false;
        })
        builder.addCase(loadOrganizations.rejected, (s, a) => {
            s.fetching = false;
        })
        builder.addCase(updateOrganization.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex(x => x._id === a.payload._id);
            if (index > -1) {
                const ds = s.pageData?.data[index];
                s.pageData.data[index] = { ...ds, ...a.payload };
            }
        })
        builder.addCase(deleteOrganization.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex((x) => x._id === a.payload);
            if (index > -1) {
                s.pageData.data.splice(index, 1);
            }
        });
    }
})

export { 
    createOrganization, 
    loadOrganizations, 
    updateOrganization, 
    deleteOrganization 
};
export const { setFetching } = organizationSlice.actions;
export default organizationSlice.reducer;