import "./index.scss";
import Button from "react-bootstrap/Button";
import check from "../../assets/icons/check-circle.svg";
import {
    setRegistrationModalVisible,
} from "../../store/slices/auth-slice";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import bannerImg from "../../assets/images/banner-image.png";
import { DISCORD } from "../../router/routes";

export default function HomeBanner() {
    const dispatch = useAppDispatch();
    const user = useAppSelector((s) => s.auth.user);

    return (
        <div className="section-container md-box home-banner-section">
            <div className="d-flex w-100 flex-column flex-wrap flex-lg-nowrap position-relative mb-md-5 pb-md-4">
                <div className="tokens">
                    <img src={check} alt="" className="me-2" />
                    <div className="token-slider">
                        <div>Learn</div>
                        <div>Compete</div>
                        <div>Practice</div>
                        <div>Learn</div>
                    </div>
                </div>
                <div className="d-flex flex-column w-100 text-color-primary me-auto">
                    <p className="glazed-bw-text1 text-heading mb-31 banner-11">
                        Build the Future of Eyes-Off Data Science Today
                    </p>
                    <p className="text-sm mb-43 banner-11">
                        Explore privacy-enhancing technologies,
                        collaborate with and learn from other data scientists,
                        hone your skills with sample datasets, and showcase
                        your abilities by joining data science competitions.
                    </p>

                    <div className="d-flex banner-image mb-4">
                        <img src={bannerImg} alt="" className="" />
                    </div>
                </div>

                <div className="d-flex mt-auto">
                    {!user?._id && <Button type="button" size='sm' variant="primary"
                        onClick={() => dispatch(setRegistrationModalVisible(true))} className="rounded-pill me-4">
                        Sign up
                    </Button>}

                    <Button type="button" size='sm' variant="secondary" className="rounded-pill"
                        onClick={() => window.open(DISCORD, "_blank", 'noopener')}>
                        Join Discord
                    </Button>
                </div>
            </div>
        </div>
    );
}
