
import { useEffect, useState } from "react";
import "./index.scss";

type CustomAccordianProps = {
    id: string;
    heading: string;
    children?: any;
    expanded?: boolean;
    switch?: () => void;
    className?: string;
    dark?: boolean;
    inline?: boolean
};

export default function CustomAccordian(props: CustomAccordianProps) {

    const [expanded, setExpanded] = useState(props.expanded ?? false);
    const [ctHeight, setCtHeight] = useState(0)

    useEffect(() => {
        const holder = document.getElementById(props.id);
        if(expanded) setCtHeight((holder?.scrollHeight && holder?.scrollHeight + 6) || 0);
        else setCtHeight(0);
    }, [expanded])

    return (
        <div className={`${props.inline ? "" : "mt-4"} ${props.className}`}>
            <div className={`d-flex align-items-center custom-accordian-header ${expanded && "mb-2"}`}>
                <div onClick={() => {setExpanded(!expanded); if(props.switch) props.switch();}} className={props.dark ? "accordian-switch-dark" : "accordian-switch"}>
                    <span className={`bar1 ${ expanded ? "" : "bar2"}`}></span>
                    <span className="bar1"></span>
                </div>
                <p onClick={() => {setExpanded(!expanded); if(props.switch) props.switch();}} 
                    className={`cursor-pointer mb-0 ms-4 ${props.dark ? "text-lg text-thick" : "text-sm text-bold"}`}>
                    {props.heading}
                </p>
            </div>
            <div style={{height: `${ctHeight}px`, opacity: expanded ? 1 : 0}} className="custom-accordian-container">
                <div id={props.id} className="custom-accordian-content text-xs">{props.children}</div>
            </div>
        </div>
    );
}
