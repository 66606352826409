import { useState } from "react";
import checkedMini from "../../assets/icons/checkInput.svg";
import "./index.scss";

type TCheckboxProps = {
    placeholder?: string;
    onChange: (checked: boolean) => void;
    value: boolean;
    className?: string;
};

export default function Checkbox(props: TCheckboxProps) {    
    return (
        <div className={`d-flex ${props.className}`}>
            <div
                onClick={() => props.onChange(!props.value)}
                className="input-checkbox bg-quinary d-flex flex-column justify-content-center align-content-center"
            >
                <img
                    className={`input-checkbox-icon ${!props.value && " d-none"}`}
                    src={checkedMini}
                    alt=""
                />
            </div>
            <span className="text-white text-sm ms-2">{props.placeholder}</span>
        </div>
    );
}
