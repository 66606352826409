import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CompetitionApi from "../../api/competition-api";
import { ICompetitionsData, ICreateCompetitionPayload, IUpdateCompetitionPayload, IFetchCompetitionListPayload, ICompetitionFilterData, ICompetition } from "../../types/competition-types";

interface ICompetitionState {
    pageData: ICompetitionsData;
    filterData: ICompetitionFilterData;
    fetching: boolean;

    competitionInfo: ICompetition | null;
}

const initialState: ICompetitionState = {
    pageData: {
        data: [],
    },
    filterData: {},
    fetching: false,

    competitionInfo: null,
};

/*thunks*/
const createCompetition = createAsyncThunk('competition/create', async (payload: ICreateCompetitionPayload, thunkAPI) => {
    try {
        const response = await CompetitionApi.create(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const loadCompetitions = createAsyncThunk('competition/getAll', async (payload: IFetchCompetitionListPayload, thunkAPI) => {
    try {
        const response = await CompetitionApi.getAll(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const updateCompetition = createAsyncThunk('competition/update', async (payload: IUpdateCompetitionPayload, thunkAPI) => {
    try {
        const response = await CompetitionApi.update(payload);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const deleteCompetition = createAsyncThunk("competition/delete", async (id: string, thunkAPI) => {
    try {
        await CompetitionApi.delete(id);
        return id;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
}
);

const loadCompetitionInfoBySlug = createAsyncThunk(
    'competition/get/:slug',
    async ({ _slug }: { _slug: string }, thunkAPI) => {
        try {
            const response = await CompetitionApi.get(_slug);

            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
)

export const competitionSlice = createSlice({
    name: 'competition',
    initialState,
    reducers: {
        setFetching: (s, a) => {
            s.fetching = a.payload;
        },
        setFilterData: (s, a) => {
            s.filterData = a.payload;
        },

        setCompetitionInfo: (s, a) => {
            s.competitionInfo = a.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(createCompetition.fulfilled, (s, a) => {
            s.pageData.data?.push(a.payload);
        })

        builder.addCase(loadCompetitions.pending, (s, a) => {
            s.fetching = true;
        })
        builder.addCase(loadCompetitions.fulfilled, (s, a) => {
            s.pageData = a.payload;
            s.fetching = false;
        })
        builder.addCase(loadCompetitions.rejected, (s, a) => {
            s.fetching = false;
        })

        builder.addCase(loadCompetitionInfoBySlug.fulfilled, (s, a) => {
            s.competitionInfo = a.payload
        })

        builder.addCase(updateCompetition.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex(x => x._id === a.payload._id);
            if (index > -1) {
                const ds = s.pageData?.data[index];
                s.pageData.data[index] = { ...ds, ...a.payload };
            }
        })
        builder.addCase(deleteCompetition.fulfilled, (s, a) => {
            let index = s.pageData.data?.findIndex((x) => x._id === a.payload);
            if (index > -1) {
                s.pageData.data.splice(index, 1);
            }
        });
    }
})

export {
    createCompetition,
    loadCompetitions,
    updateCompetition,
    deleteCompetition,

    loadCompetitionInfoBySlug,
};
export const {
    setFetching,

    setCompetitionInfo,
} = competitionSlice.actions;
export default competitionSlice.reducer;
