import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// import: assets
// import: styles
// import: constants
import { NOTEBOOKS } from "../../router/routes";
// import: enums
import { UserRole } from "../../enums/roles";

// import: types
import { INotebook } from "../../types/notebook-types";

// import: utils
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import {
    deleteNotebook,
    loadDisapprovedNotebooks,
    loadNotebooks,
    setApprovedNbData,
    setDisapprovedNbData,
    updateStatus,
} from "../../store/slices/notebook-slice";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import {
    onChangeSearchText,
    setSearchPlaceholder,
} from "../../store/slices/shared-states-slice";

// import: api
// import: config
// import: components
import LoadingSpinner from "../../components/loading-spinner";
import NotebookListItem from "../../components/list-items/notebook-list-item";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import ErrorAlert from "../../components/error-alert";
import AddNotebookModal from "../../components/add-notebook-modal";

export default function NotebookPage() {
    //#region  states
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const approvedListEnd = useRef<HTMLDivElement>(null);
    const disapprovedListEnd = useRef<HTMLDivElement>(null);

    const user = useAppSelector((s) => s.auth.user);

    const searchText = useAppSelector((s) => s.sharedStates.navbarSearchText);

    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const fetchingNotebooks = useAppSelector((s) => s.notebook.fetching);

    const notebooks = useAppSelector((s) => s.notebook.approvedData.data);
    const pageSize = parseInt(
        process.env.React_APP_NOTEBOOKS_DATA_LENGTH || "20"
    );
    const [page, setPage] = useState(0);
    const [totalApprovedPages, setTotalApprovedPages] = useState(1);

    const disapprovedNotebooks = useAppSelector(
        (s) => s.notebook.disapprovedData.data
    );
    const dissAppPageSize = parseInt(
        process.env.React_APP_NOTEBOOKS_DATA_LENGTH || "20"
    );
    const [dissAppPage, setDissAppPage] = useState(0);
    const [totalDisapprovedPages, setTotalDisapprovedPages] = useState(1);

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const hasAdminRole = useMemo(
        () => user?.roles?.includes(UserRole.Admin),
        [user?.roles],
    );

    useEffect(() => {
        dispatch(onChangeSearchText(""));
        dispatch(setSearchPlaceholder("Search Notebooks"));
        return () => {
            dispatch(setSearchPlaceholder(""));

            /* reset data to reset height of container
             * so the observer can trigger fresh data fetch
             */
            dispatch(setApprovedNbData({
                data: [],
            }));
            dispatch(setDisapprovedNbData({
                data: [],
            }));
        };
    }, [dispatch]);


    const fetchApprovedData = useCallback(({ newPage }: { newPage?: number }) => {
        if (newPage === 0) {
            setPage(0)
        }

        const pageToFetch = newPage ?? page;
        if (pageToFetch < totalApprovedPages && !fetchingNotebooks) {
            dispatch(loadNotebooks({
                page: pageToFetch + 1,
                size: pageSize,
                title: searchText && searchText.length >= 3 ? searchText : "",
                approved: true
            }))
                .unwrap()
                .then((res) => {
                    setPage(res.page);
                    setTotalApprovedPages(Math.ceil((res.totalCount || pageSize) / pageSize));
                })
        }
    }, [
        dispatch,
        page,
        totalApprovedPages,
        pageSize,
        fetchingNotebooks,
        searchText,
    ]);

    const fetchDisapprovedData = useCallback(({ newPage }: { newPage?: number }) => {
        if (hasAdminRole) {
            if (newPage === 0) {
                setDissAppPage(0)
                dispatch(setDisapprovedNbData({
                    data: [],
                    totalCount: dissAppPageSize,
                }))
            }

            const pageToFetch = newPage ?? dissAppPage;
            if (pageToFetch < totalDisapprovedPages && !fetchingNotebooks) {
                dispatch(loadDisapprovedNotebooks({
                    page: pageToFetch + 1,
                    size: dissAppPageSize,
                    title: searchText && searchText.length >= 3 ? searchText : ""
                }))
                    .unwrap()
                    .then((res) => {
                        setDissAppPage(res.page);
                        setTotalDisapprovedPages(Math.ceil((res.totalCount || dissAppPageSize) / dissAppPageSize));
                    })
            }
        }
    }, [
        dispatch,
        hasAdminRole,
        dissAppPage,
        totalDisapprovedPages,
        dissAppPageSize,
        fetchingNotebooks,
        searchText,
    ]);

    useEffect(() => {
        const aprovedObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !fetchingNotebooks) {
                    console.log('intersectiong');
                    fetchApprovedData({});
                }
            });
        });
        if (approvedListEnd.current) aprovedObserver.observe(approvedListEnd.current);

        const disapprovedObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !fetchingNotebooks) {
                    fetchDisapprovedData({});
                }
            });
        });
        if (disapprovedListEnd.current) disapprovedObserver.observe(disapprovedListEnd.current);

        return () => {
            if (approvedListEnd.current) aprovedObserver.unobserve(approvedListEnd.current);
            if (disapprovedListEnd.current) disapprovedObserver.unobserve(disapprovedListEnd.current);

        };
    }, [
        fetchApprovedData,
        fetchDisapprovedData,
        fetchingNotebooks,
    ]);

    useEffect(() => {
        setError("");
        fetchDisapprovedData({});
    }, [user]);

    const [hasMounted, setHasMounted] = useState<boolean>(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            // to avoid duplicate API call on mount & subsequent searchText initialisation
            if (hasMounted) {
                fetchApprovedData({ newPage: 0 });
                fetchDisapprovedData({ newPage: 0 });
            } else {
                setHasMounted(true);
            }
        }, 1000)

        return () => clearTimeout(timer);
    }, [searchText]);

    // const onNotebookClick = (nb: INotebook) => {
    //     if (nb?._id) {
    //         navigate(`/${NOTEBOOKS}/` + (nb.slug ?? nb._id));
    //     }
    // };

    // const filterNotebooks = (x : INotebook) => {
    //     if(x.competition && x.competition.prizeDetail?.prizeType === PrizeType.Monetary){
    //         if(competitionStatus(x.competition.startDate, x.competition.endDate) !== "completed") return false;
    //         else return true;
    //     }
    //     else return true;
    // }

    const renderList = (items: INotebook[]) => {
        return (
            <Tabs className="pb-1">
                <Tab title={"All Notebooks"} eventKey={"tabNotebooks"}>
                    <div className="Notebook-table-container">
                        {items && items?.length <= 0 && !fetchingNotebooks && !loading ? (
                            <p className="text-sm text-color-tertiary mt-4
                            d-flex w-100 justify-content-center align-items-center">
                                No notebooks found
                            </p>
                        ) : (
                            (items && items?.length > 0) && (
                                items?.map((item) => (
                                    <NotebookListItem
                                        to={item.slug ?? item._id}
                                        key={item._id}
                                        item={item}
                                    />
                                ))
                            )
                        )}
                    </div>

                    <div id="approved-list-end" ref={approvedListEnd} className="" />
                </Tab>
            </Tabs>
        );
    };

    const deleteNb = async (id: string) => {
        try {
            if (window.confirm("Are you sure?")) {
                setLoading(true);
                await dispatch(deleteNotebook(id)).unwrap();
            }
        } catch (error) {
            setError(HandleError(error)[""]);
        } finally {
            setLoading(false);
        }
    };

    const updateNbStatus = async (id: string, approved: boolean) => {
        try {
            setLoading(true);
            await dispatch(
                updateStatus({
                    _id: id,
                    approved,
                })
            ).unwrap();
        } catch (error) {
            setError(HandleError(error)[""]);
        } finally {
            setLoading(false);
        }
    };

    const adminRender = () => (
        <Tabs
            onSelect={() => {
                dispatch(onChangeSearchText(""));
            }}
            defaultActiveKey="tabNotebooks"
            className="pb-1"
        >
            <Tab title={"Approved"} eventKey="tabNotebooks">
                {notebooks && notebooks?.length > 0 ? (
                    <div className="listing_container">
                        {notebooks.map((item) => (
                            <NotebookListItem
                                to={item.slug ?? item._id}
                                contextMenu={[
                                    {
                                        title: "Disapprove",
                                        onClick: () => { updateNbStatus(item._id, false) },
                                    },
                                    {
                                        title: "Delete",
                                        onClick: () => deleteNb(item._id),
                                    },
                                ]}
                                key={item._id}
                                item={item}
                            />
                        ))}
                    </div>
                ) : <></>}

                <div id="approved-list-end" ref={approvedListEnd} className="" />
            </Tab>
            <Tab title={"Disapproved"} eventKey="tabDisapprovedNotebooks">
                {disapprovedNotebooks && disapprovedNotebooks.length > 0 ?
                    <div className="listing_container">
                        {disapprovedNotebooks?.map((item) => (
                            <NotebookListItem
                                to={item.slug ?? item._id}
                                contextMenu={[
                                    {
                                        title: "Approve",
                                        onClick: () =>
                                            updateNbStatus(item._id, true),
                                    },
                                    {
                                        title: "Delete",
                                        onClick: () => deleteNb(item._id),
                                    },
                                ]}
                                key={item._id}
                                item={item}
                            />
                        ))}
                    </div> : <></>
                }

                <div id="disapproved-list-end" ref={disapprovedListEnd} className="" />
            </Tab>
        </Tabs>
    );
    //#endregion

    return (
        <div className="page-container lg-box">
            <ErrorAlert show={error ? true : false} errorMessage={error} />

            <div className="d-flex flex-wrap flex-md-nowrap md-box">
                <div className="page-heading-container">
                    <span className="text-3xl mb-21">Notebooks</span>
                    <div className="text-xs">
                        <p>
                            New to Antigranular Notebooks? Head over to the{" "}
                            <a
                                className="link-ag-1"
                                href="https://docs.antigranular.com/category/quickstart-guides"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Quickstart guide
                            </a>{" "}
                            to find out everything you need to know about
                            notebooks.
                        </p>
                        <p className="mb-0">
                            Explore user notebooks and share your own that
                            showcase working with privacy-enhancing technologies
                            and use this knowledge in our data science
                            competitions.
                        </p>
                    </div>
                </div>

                <div className="ms-sm-auto mt-3 mt-sm-auto">
                    {user ? (
                        <Button
                            className="mb-4 rounded-pill"
                            onClick={() => setShowAddModal(true)}
                        >
                            Upload Notebook
                            <svg width="15" height="15" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" className="ms-2" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 11.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V11.5M13.1667 5.66667L9 1.5M9 1.5L4.83333 5.66667M9 1.5V11.5" />
                            </svg>
                        </Button>
                    ) : undefined}
                </div>
                {/* <MessageCard onClick={() => window.open("https://docs.antigranular.com/antigranular/quickstart-guides/notebooks", "_blank", 'noopener')}
                    heading="New to Antigranular Notebooks?" linkText="Quick Start" visibility={messageVisibility}>
                    Head over to the Quickstart guide to find out everything you need to know about notebooks.
                </MessageCard> */}
            </div>

            <div className=" md-box">
                {hasAdminRole ? (
                    adminRender()
                ) : (
                    <div className="listing_container">
                        {renderList(notebooks)}
                    </div>
                )}
            </div>
            <LoadingSpinner
                show={loading || fetchingNotebooks}
                text={"Getting Notebooks"}
            />

            <AddNotebookModal
                show={showAddModal}
                onAdded={(n) => {
                    setShowAddModal(false);
                    navigate(`/${NOTEBOOKS}/` + (n.slug ?? n._id));
                }}
                onHide={() => setShowAddModal(false)}
            />
        </div>
    );
}
