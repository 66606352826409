import { 
    forwardRef,
    useCallback,
    useEffect,
    useState
} from "react";
import { UserProfileAvatarUrl } from "../../configs/api-config";
import userTemplate from "../../assets/images/userTemplate.png";
interface IUserImgProps extends React.DetailedHTMLProps< React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    userId?: string;
    imgcode?: string;
    className?: string;
}
const UserImg = forwardRef((props: IUserImgProps, ref: any) => {
    const [src, setSrc] = useState<string>();
    const [fallback, setFallback] = useState<boolean>(true);

    useEffect(() => {
        if (props.userId && props.imgcode && props.imgcode !== "") {
            setSrc(`${UserProfileAvatarUrl(props.userId)}/${props.imgcode}`);
            setFallback(true);
        }
    }, [props.imgcode]);
    
    useEffect(() => {
        if (props.userId) {
            setSrc(`${UserProfileAvatarUrl(props.userId)}/${Math.random()}`);
        } else {
            setSrc(userTemplate);
        }
    }, [props.userId]);

    const onError = useCallback(
        (e: any) => {
            if (fallback) {
                setSrc(userTemplate);
                setFallback(false);
            }
        },
        [fallback]
    );

    // const onError = (e: any) => setSrc(userTemplate);
    
    const { userId, ...other } = { ...props };
    return (
        <img {...other} src={src} ref={ref} onError={onError} alt="avatar" className={`rounded-circle ${props.className}`} />
    );
});
export default UserImg;
