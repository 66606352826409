import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import: assets
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// import: styles
// import: constants
import { ORGANISATIONS } from "../../router/routes";

// import: enums
import { UserRole } from "../../enums/roles";

// import: types
import { IUser } from "../../types/user-type";
import { IUserAuth0Creds } from "../../types/auth-types";

// import: utils
import { HandleError } from "../../errors/handler";

// import: data
// import: store
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { showInfoModal } from "../../store/slices/modal-states-slice";

// import: api
import ProfileApi from "../../api/profile-api";
import AuthApi from "../../api/auth-api";

// import: config
// import: components
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ToggleSwitch from "../../components/checkbox/toggle-switch";
import ChangePasswordModal from "../../components/auth-modals/change-password-modal";
import ErrorAlert from "../../components/error-alert";
import Modal from "react-bootstrap/Modal";
import Input from "../../components/input/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import LoadingSpinner from "../../components/loading-spinner";

interface UserProviders {
    Email: boolean,
    Google: boolean,
    Github: boolean,
}
interface ProviderUserNames {
    Email: string,
    Google: string,
    Github: string,
}

const initialProviders = {
    Email: false,
    Google: false,
    Github: false,
}

const providedUsers = {
    Email: "",
    Google: "",
    Github: "",
}

export default function UpdateProfilePage() {
    const user = useAppSelector((s) => s.auth.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState<boolean>(false);
    const [profile, setProfile] = useState<IUser>();
    const [formErrors, setFormErrors] = useState<any>({});
    const [allowEmail, setAllowEmail] = useState<boolean>(false);
    const [allowNews, setAllowNews] = useState<boolean>(false);
    const [userProv, setUserprov] = useState<UserProviders>(initialProviders);
    const [provUsernames, setProvUsernames] = useState<ProviderUserNames>(providedUsers);

    const [showMsg1, setShowMsg1] = useState<boolean>(false);
    const [showMsg2, setShowMsg2] = useState<boolean>(false);
    const [alertUser, setAlertUser] = useState<boolean>(false);
    const [authCreds, setAuthCreds] = useState<IUserAuth0Creds>();
    const [showUnlinkPasswordModal, setShowUnlinkPasswordModal] = useState<boolean>(false);
    const [providerToUnlink, setProviderToUnlink] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();

    useEffect(() => {
        if (profile && profile.providers) {
            const holder = { ...userProv };
            const names = { ...provUsernames }
            profile.providers.forEach((prov, id) => {
                holder[prov.provider] = true;
                names[prov.provider] = prov.providerUserId
            });
            setUserprov({ ...holder });
            setProvUsernames({ ...names });
        }
    }, [profile]);

    const fetchProfile = () => {
        if (user) {
            setLoading(true);
            ProfileApi.get(user._id).then((profile) => {
                setProfile({ ...profile });
                setAllowEmail(profile?.notifications?.site || false);
                setAllowNews(profile?.notifications?.news || false);
            })
                .catch((err) => {
                    setError(HandleError(err)[""]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (user) {
            setLoading(true);
            fetchProfile();
            getAuthCreds();
        }
    }, [user]);

    const reset = () => {
        setShowUnlinkPasswordModal(false);
        setPassword(undefined);
        setConfirmPassword(undefined);
    }

    const validateForm = () => {
        setFormErrors({});
        const errors: any = {};
        if (!password || password.length < 6) {
            errors.password = "Password should be at least 6 characters";
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = "Passwords do not match.";
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            setFormErrors({});
            if (validateForm() && password) {
                setLoading(true);
                AuthApi.setUnlinkPassword(password).then(() => {
                    if (providerToUnlink) AuthApi.unlinkAuthProvider(providerToUnlink).then(() => {
                        fetchProfile();
                        setTimeout(() => {
                            dispatch(
                                showInfoModal({
                                    title: "Unlinked Provider",
                                    message: `Successfully unlinked ${providerToUnlink} account`,
                                })
                            );
                        }, 1000);
                    });
                    reset();
                });
            }
        } catch (error) {
            setFormErrors(HandleError(error));
        } finally {
            setLoading(false);
        }
    };

    const getAuthCreds = async () => {
        try {
            const holder = await AuthApi.getAuth0();
            setAuthCreds(holder);
        } catch (err) {
            // setError(HandleError(err)[""]);
        }
    };

    const rotateAuth0 = async () => {
        setAlertUser(false);
        try {
            setLoading(true);
            const holder = await AuthApi.rotateAuth0();
            setAuthCreds(holder);
        } catch (err) {
            // setError(HandleError(err)[""]);
        } finally {
            getAuthCreds();
            setLoading(false);
        }
    };

    const updatePreferences = (type: string) => {
        let allowed = undefined;
        if (type === "site") {
            setAllowEmail(!allowEmail);
            allowed = !allowEmail;
        } else if (type === "news") {
            setAllowNews(!allowNews);
            allowed = !allowNews;
        }

        try {
            setLoading(true);
            if (allowed !== undefined) {
                ProfileApi.updateNotficiationPreference({ type, allowed }).then(profile => {
                    setProfile(profile);
                }).catch(err => { });
            }
        } catch (e) { } finally {
            setLoading(false);
        }
    }

    const unlinkProvider = (provider: string) => {
        setLoading(true);
        try {
            if (userProv?.Email || (profile?.providers?.length && profile?.providers?.length >= 2)) {
                AuthApi.unlinkAuthProvider(provider).then(() => {
                    fetchProfile();
                    setTimeout(() => {
                        dispatch(
                            showInfoModal({
                                title: "Unlinked Provider",
                                message: `Successfully unlinked ${provider} account`,
                            })
                        );
                    }, 1000);
                });
            } else {
                setProviderToUnlink(provider);
                setShowUnlinkPasswordModal(true);
            }
        } catch (err) {
            setError(HandleError(err)[""]);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="page-container">
            <ErrorAlert show={error ? true : false} errorMessage={error} />

            <div className="page-heading-container">
                <h1 className="text-3xl mb-21">Settings</h1>
                <div className="text-xs">Modify your account settings and preferences.</div>
            </div>

            <Tabs className="" id="tab-settings" defaultActiveKey={"tabAccount"}>
                <Tab className="info-page-tab onFit"
                    eventKey={"tabAccount"} title="Account">

                    {profile?.email ? <div className="mb-5">
                        <p className="text-lg text-thick mb-0">User details</p>
                        <div className="d-flex pt-3 flex-md-nowrap flex-wrap">
                            <div className="settings-item-container">
                                <p className="text-lg text-thick mb-2">Email address</p>
                                <span className="text-xs text-color-secondary">
                                    {profile?.email}
                                </span>
                            </div>
                        </div>
                    </div> : <></>}

                    <div className="mb-5">
                        <p className="text-lg text-thick mb-3">User linked accounts</p>
                        <p className="text-xs mb-0 text-color-secondary">Configure your login. Simply use the credentials of your existing Gmail or GitHub account to authorise the connection with Antigranular.
                        </p>
                        <div className="d-flex pt-4 flex-md-nowrap flex-wrap">
                            {userProv.Google && <div className="settings-item-container">
                                <p className="text-lg text-thick mb-3">Gmail</p>
                                {/* <span className="text-xs mb-3">
                                    {provUsernames.Google}
                                </span> */}
                                <Button variant="secondary" className="rounded-pill"
                                    onClick={() => unlinkProvider("Google")} disabled={loading}>
                                    Disconnect Gmail
                                </Button>
                            </div>}

                            {userProv.Github && <div className="settings-item-container">
                                <p className="text-lg text-thick mb-3">Github</p>
                                {/* <span className="text-xs mb-3">
                                    {provUsernames.Github}
                                </span> */}
                                <Button variant="secondary" className="rounded-pill"
                                    onClick={() => unlinkProvider("Github")} disabled={loading}>
                                    Disconnect Github
                                </Button>
                            </div>}

                            {!userProv.Google && !userProv.Github && <div className="settings-item-container">
                                <span className="text-xs text-color-tertiary">
                                    No linked accounts
                                </span>
                            </div>}
                        </div>
                    </div>

                    <div className="mb-5">
                        <p className="text-lg text-thick mb-3">Antigranular client credentials</p>
                        <p className="text-xs mb-0 text-color-secondary">Use these credentials to login your account with
                            <a className="cursor-pointer ms-1 text-thick link-ag-1" href="https://pypi.org/project/antigranular/" target="_blank" rel='noreferrer'>
                                Antigranular Python package
                            </a>
                            . You need this package to use any Antigranular datasets or participate in competitions.
                        </p>
                        <div className="d-flex pt-4 flex-md-nowrap flex-wrap">
                            <div className="settings-item-container secret-container"
                                onClick={() => {
                                    navigator.clipboard.writeText(authCreds ? authCreds.clientId : "")
                                    if (!showMsg1) {
                                        setShowMsg1(true);
                                        setTimeout(() => {
                                            setShowMsg1(false)
                                        }, 2000)
                                    }
                                }}
                            >
                                <span className={`smooth_in text-xs text-color-tertiary popup-message ${showMsg1 ? "d-block" : "d-none"}`}>
                                    Client ID copied
                                </span>
                                <p className="text-thick mb-2">client_id</p>
                                <span className="text-xs secret smooth_in text-overflow">
                                    {authCreds && authCreds.clientId}
                                </span>
                                <span className="text-xs cover">
                                    {"***************************"}
                                </span>
                            </div>

                            <div className="settings-item-container mt-md-0 mt-3 secret-container"
                                onClick={() => {
                                    navigator.clipboard.writeText(authCreds ? authCreds.clientSecret : "")
                                    if (!showMsg2) {
                                        setShowMsg2(true);
                                        setTimeout(() => {
                                            setShowMsg2(false)
                                        }, 2000)
                                    }
                                }}
                            >
                                <span className={`smooth_in text-xs text-color-tertiary popup-message ${showMsg2 ? "d-block" : "d-none"}`}>
                                    Client secret copied
                                </span>
                                <p className="text-thick mb-2">client_secret</p>
                                <span className="text-xs secret smooth_in text-overflow w-100">
                                    {authCreds && authCreds.clientSecret}
                                </span>
                                <span className="text-xs cover">
                                    {"***************************"}
                                </span>
                            </div>
                        </div>
                        <Button variant="secondary" className="rounded-pill mt-3"
                            onClick={() => setAlertUser(true)} disabled={loading}>
                            Reset credentials
                        </Button>
                    </div>

                    <div className="mb-5">
                        <p className="text-lg text-thick mb-2">Reset password</p>
                        <p className="text-xs mb-3 text-color-secondary">
                            Overcome password mishaps and enhance the security of your account with a quick password reset.
                        </p>
                        <Button variant="secondary" className="rounded-pill"
                            onClick={() => setShowChangePassword(true)} disabled={loading}>
                            Reset password
                        </Button>
                    </div>

                    {/* <div className="mb-5">
                        <span className="text-lg text-thick mb-3">Delete your Antigranular account</span>
                        <p className="text-xs">{`Permanently delete your  Antigranular account`}</p>
                        <div className="d-flex py-3">
                            <Button variant="secondary" className="rounded-pill"
                                onClick={() => { }} disabled={loading}>
                                Delete account
                            </Button>
                        </div>
                    </div> */}
                    {user && user?.roles.includes(UserRole.Admin) ?
                        <div className="mb-5">
                            <span className="text-lg text-thick mb-3">Organizations</span>
                            <p className="text-xs">
                                Organizations associated with Antigranular have their specific
                                datasets and competitions hosted accessible by members only.
                            </p>
                            <div className="d-flex py-3">
                                <Button variant="secondary" className="rounded-pill"
                                    onClick={() => navigate(`/${ORGANISATIONS}`)} disabled={loading}>
                                    Manage Organizations
                                </Button>
                            </div>
                        </div> : <></>}
                </Tab>


                <Tab className="info-page-tab"
                    eventKey={"tabNotifications"} title="Notifications">
                    <div className="d-flex mb-5">
                        <div className="d-flex flex-column w-100">
                            <p className="text-lg mb-2 text-thick">Allow site and email notifications</p>
                            <span className="text-xs">Updates on your work or the leaderboard</span>
                        </div>
                        <div>
                            <ToggleSwitch value={allowEmail} onClick={() => updatePreferences("site")} disabled={loading} />
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="d-flex flex-column w-100">
                            <p className="text-lg mb-2 text-thick">Allow news and tips emails</p>
                            <span className="text-xs">Updates from Antigranular like new competitions, events and features</span>
                        </div>
                        <div>
                            <ToggleSwitch value={allowNews} onClick={() => updatePreferences("news")} disabled={loading} />
                        </div>
                    </div>
                </Tab>
            </Tabs>

            <ChangePasswordModal
                show={showChangePassword}
                onHide={() => setShowChangePassword(false)}
                onPasswordChanged={() => {
                    setShowChangePassword(false);
                    setTimeout(() => {
                        dispatch(
                            showInfoModal({
                                title: "Updated Successfully",
                                message:
                                    "Password Successfully Updated",
                            })
                        );
                    }, 500);
                }}
            />

            <Modal show={alertUser} onHide={() => setAlertUser(false)} centered dialogClassName="login-modal" >
                <Modal.Header className="border-0" closeButton>
                    <p className="text-lg text-danger">Alert!</p>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="d-flex flex-column justify-content-center">
                        <p className="mb-5">Are you sure you want to regenerate Antgranular client credentials</p>
                        <div className="w-100 d-flex">
                            <Button onClick={rotateAuth0} className="ms-auto rounded-pill">
                                Okay
                            </Button>
                            <Button variant="secondary" onClick={() => setAlertUser(false)} className="ms-4 rounded-pill">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showUnlinkPasswordModal}
                onHide={() => reset()}
                centered
                dialogClassName="login-modal"
            >
                <Modal.Header className="border-0" closeButton>
                    <p className="text-lg">
                        Set Profile password
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={submit} className="login-modal-form">
                        <fieldset disabled={loading}>
                            <div className="form-input-fields text-xs text-color-secondary">
                                <Form.Group className="mb-4">
                                    <Input
                                        value={password}
                                        label="Password"
                                        placeholder="Enter your new password"
                                        maxLength={100}
                                        autoFocus
                                        type="password"
                                        autoComplete="new-password"
                                        onChange={(e) => {
                                            if (formErrors["password"]) {
                                                delete formErrors?.password;
                                                setFormErrors(formErrors);
                                            }
                                            setPassword(e.target.value);
                                        }}
                                        error={formErrors["password"]}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Input
                                        label="Confirm password"
                                        placeholder="Confirm your new password"
                                        type="password"
                                        value={confirmPassword}
                                        autoComplete="new-password"
                                        error={formErrors.confirmPassword}
                                        onChange={(e) => {
                                            if (formErrors["confirmPassword"]) {
                                                delete formErrors.confirmPassword;
                                                setFormErrors(formErrors);
                                            }
                                            setConfirmPassword(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="my-2">
                                    {formErrors[""] ? (
                                        <label className="text-danger">
                                            {formErrors[""]}
                                        </label>
                                    ) : undefined}
                                </Form.Group>
                            </div>
                            <Button type="submit" className="mt-4 ms-auto rounded-pill">
                                {loading ? (
                                    <>
                                        <FontAwesomeIcon className="me-2" spin icon={faSpinner} />
                                        Please wait
                                    </>
                                ) : (
                                    "Set Password"
                                )}
                            </Button>
                        </fieldset>
                    </Form>
                </Modal.Body>
            </Modal>

            <LoadingSpinner show={loading} text="Loading user settings" />
        </div>
    );
}
